.Naming-Conv-Popup {
    max-width: 57vw !important;
    width: 34vw !important;
    max-height: 77vh !important;
    height: 29vh !important;
    overflow: hidden;
}
.Naming-Conv-Popup .ant-modal-content {
    max-width: 57vw !important;
    width: 34vw !important;
    max-height: 77vh !important;
    height: 29vh !important;
    border: 1px solid #c4c4c4 !important;
    overflow: hidden;
}

.Naming-Conv-Popup .ant-modal-header{
    margin: 0px !important;
    height: 54px !important;
    border-bottom: 1px solid #d7d7d7 !important;
    background: #f1f1f1 !important;
    padding: 10px !important;
    border-radius: 0px !important;
}
.Naming-Conv-Popup .ant-modal-content .ant-modal-body{
    height: calc(100% - 54px) !important;
    width: 100%;
    padding: 0px 0px !important;
    overflow: hidden;
}
.Naming-Conv-Popup .ant-modal-close{
    display: none !important;
}
.Naming-Conv-Popup .Sub-Header{
    border-bottom: 1px solid #d7d7d7 !important;
    background: #f1f1f1 !important;
}
/* -- */
.Field-Picker-Popover .ant-popover-content{
    max-height: 22rem !important;
    overflow: scroll !important;
    background-color: white !important;
    box-shadow: 0px 0px 5px 1px #d5d5d5 !important;
}
