.All-Reminder-Modal{
    display: flex !important;
    top: 141px !important;
    border-radius: 50px !important;
    justify-content: center !important;
    width: 40% !important;
    height: 30% !important;
    right: auto !important;
    max-height: 73% !important;
}
.All-Reminder-Modal .ant-modal-body{
    padding: 0px 0px 0px 8px !important;
    overflow: scroll;
    height: max-content !important;
}
.All-Reminder-Modal .ant-modal-content{
    /* height: auto !important; */
    height: max-content !important;
}
.All-Reminder-Modal .Card{
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    background-color: #fbfbfb;
    border-radius: 5px;
    margin: 8px !important;
    border: 1px solid #d6d6d645;
}
.All-Reminder-Modal .row{
    margin: 0px;
    padding: 0px;
}
.All-Reminder-Modal .col{
    margin: 0px;
    padding: 0px;
}
.All-Reminder-Modal .Reminder-List-Wrapper .Header{
    position: relative;
    padding: 1px 9px;
    color: dimgrey;
    font-weight: 500;
    font-size: 1rem;
    margin: 2px 0px;
    background-color: #e0e0e0;
    box-shadow: 0px 2px 5px -3px black;
}
.All-Reminder-Modal .Reminder-List-Wrapper .Option-Wrapper{
    position: relative;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}
.Reminder-List-Wrapper .Option-Wrapper .Options{
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    gap:10px;
    color: #007bff;
}
.All-Reminder-Modal .Snooze-Popover .ant-popover-inner-content{
    width: max-content !important;
    border-radius: 2px !important;
    padding: 2px 2px !important;
}
.All-Reminder-Modal .Snooze-Popover .ant-popover-inner-content ul{
    list-style: none;
    width: max-content !important;
    padding: 0px;
}
.All-Reminder-Modal .Snooze-Popover .ant-popover-inner-content ul li{
    cursor: pointer;
    padding: 1px 13px;
    margin: 2px 0px;
}
.All-Reminder-Modal .Snooze-Popover .ant-popover-inner-content ul li:hover{
    background-color: rgb(247 247 247);
}
.All-Reminder-Modal .Snooze-Popover .ant-popover-inner{
   padding: 4px !important;
}
.Reminder-List-Wrapper .Option-Wrapper .Options span{
    cursor: pointer;
    opacity: 0.5;
}
.Reminder-List-Wrapper .Option-Wrapper .Options span:hover{
    opacity: 1;
}
.Reminder-List-Wrapper .Option-Wrapper .Options span:last-child:hover{
    color: red !important;
}
.Reminder-List-Wrapper .Header .Expand-Icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    right: 6px;
    width: max-content;
}
.All-Reminder-Modal .Custome-Snooze-Time{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5px;
    gap: 5px;
}
.All-Reminder-Modal .Preset-Cards{
    display: flex;
    font-size: small;
    padding: 1px;
    margin: 2px;
    /* height: 1.8rem; */
    cursor: pointer;
    border-radius: 27px;
    align-items: center;
    justify-content: center;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
}
.All-Reminder-Modal .Snooze-Content .row{
    margin: 0px !important;
    justify-content: center;
}
.All-Reminder-Modal .Snooze-Content{
    width: 14rem !important;
}
.All-Reminder-Modal .Triggered-Alerts-Wrapper .Icon-Wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.All-Reminder-Modal .Triggered-Alerts-Wrapper { 
    padding: 0px;
    margin-bottom: 5px;
    background-color: #f3f9ff;
    margin-top: 5px;
    border-radius: 6px;
    border-left: 3px solid #0072ec;
}
.All-Reminder-Modal .Triggered-Alerts-Wrapper .Icon-Wrapper img{
    max-height: 2.3rem;
}
.Reminder-List-Wrapper .Card{
    cursor: pointer;
}
.Reminder-List-Wrapper .Card:hover{
    border-color: #add5ff !important;
    background-color: #ecf5ff;
}

