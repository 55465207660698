.vl {
    border-left: 1px solid lightgray;
    height: 100px;
  }

  .antModalBody .ant-modal-body{
    word-wrap: normal !important;
  }
  .ant-modal-header{
    border: 1px solid #f0f0f0 !important;
    border-radius: 12px !important;
  }

  .task_filter_box {
    /* margin-top: 1px !important; */
    /* background-color: rgb(1, 148, 247); */
    border-radius: 3px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    margin-left: 6px;
    border: 1px solid #018EE0;
  }

  .task_filter_box span{
    background: #fff !important;
  }
  
  .task_filter_box span svg {
    margin: 0 8px;
    color: #018EE0;
  }

.checkboxes label{
  width: max-content !important;
}

.ant-picker-dropdown-range{
  z-index: 100000 !important;
}
.Error-Msg-For-Filter{
  position: relative !important;
  left: -45% !important;
  color: red;
}

.groupFilter{
  /* line-height: 30px; */
  padding-left: 0px;
  display: flex;
  justify-content: center;
}

.ant-picker-range{
  padding: 8px 11px 7px; 
}  

.btn-group, .btn-group-vertical{
  height: 32px !important;
}


.taskFilterModal .ant-modal-content{
  width: 900px !important;
  border-radius: 12px !important;
}

.insideSwitch{
  line-height: 30px;
  padding-left: 25px;
  max-width: 5%;
  display: flex;
  justify-content: center;
}

.filter-div-wrapper{
  margin: 0.5rem !important;
}
.filter-div-wrapper .ant-picker-range{
  position: relative;
  top: 4px;
  height: 34px;
  border-radius: 4px;
}
.taskFilterModal .ant-modal-body .row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.group-wrapper {
  padding-right: 0px !important;
  border-left: 3px solid #018ee0;
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: #eeeeee;
  /* padding-right: 0px !important;
  border-left: 2px solid #fc5300;
  border-radius: 5px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background: linear-gradient(90deg, rgba(60,184,255,1) 0%, rgba(116,192,233,0.8801645658263305) 21%, rgba(207,238,255,0.6280637254901961) 56%, rgba(126,199,237,0.6336659663865547) 100%, rgba(36,99,252,0.709296218487395) 100%); */
}
.filter-wrapper-class {
  background-color: lightgrey;
  color: white;
  border-radius: 5px;
  padding-top: 0.25rem;
  padding-bottom: 0.1rem;
  border-left: 2px solid black;
}
.hr-datepicker-btns{
  display: flex !important;
  position: absolute !important;
  right: 13px !important;
  top: 4px !important;
  border: none !important;
  padding-bottom: 2px !important;
  z-index: 1 !important;
}
.Globaltable-Header-Wrapper .hr-datepicker-btns{
  display: flex !important;
  position: absolute !important;
  right: 13px !important;
  top: 0px !important;
  border: none !important;
  padding-bottom: 2px !important;
  z-index: 1 !important;
}
.DayViewWrapper .hr-datepicker-btns{
  display: flex !important;
  position: absolute !important;
  right: 13px !important;
  top: 0px !important;
  border: none !important;
  padding-bottom: 2px !important;
  z-index: 1 !important;
}

.filter-div-wrapper .col-sm-7{
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.Date-Picker-Calender{
  position: absolute;
  right: 9px;
  width: 15.5px;
  top: 5px;
  opacity: 0.2;
}
.filter-span{
  padding: 6px;
  margin-left: 2px;
  min-width: max-content;
  max-width: max-content;
  margin-right: 5px;
  background-color: #ffffff;
  border: 1.2px solid #014e7b;
  border-radius: 5px;
  color: #00448c;
  font-weight: 500;
}
.Col-CustomeClass{
  display: flex !important;
    align-items: center !important;
    /* justify-content: center !important; */
}
.column-filter-row{
  margin-top: 5px;
  margin-bottom: 5px;
}
.Filter-Value-Wrapper{
  display: flex;
  overflow-x: scroll;
  padding: 5px;
}
.Column-Filter-Wrapper{
  margin-top: 10px;
  padding: 7px;
  border-radius: 10px;
  background: #eeeeee;
}
.Delete-Icon-For-ColFilters:hover{
  color: red;
}
.ant-picker-dropdown{
  z-index: 10000 !important;
}
 
.ant-picker{
  height: 34px !important;
  border-radius: 5px !important;
}

.trashIcon:hover{
  color: #fc5300;
 }

 .ant-switch-handle::before{
  background: #fff !important;
} 
.ant-switch-inner{
  color: #fff !important;
}

.ant-switch{
  border: 1px solid #018EE0 !important;
  border-radius: 20px !important;
  width: 3.8rem !important;
  height: 26px !important;
  background-color: #018EE0 !important;
}

.Column-Filter-Wrapper .w-100:nth-child(1){
  padding-left: 10px !important;
}

.Column-Filter-Wrapper .w-100:nth-child(2){
  padding: 10px !important; 
}
.switchBtn{
  padding: 0%;
  display: flex;
  position: relative !important;
  /* right: 0rem !important; */
  align-items: center;
  justify-content: center;
}

.taskFilterModal .task-filter-buttons{
    display: flex;
    justify-content: end;
    gap: 10px;
}

.taskFilterModal .ant-select {
  height: 34px !important;
}
