.UserRoles-Wrapper{
    display: flex;
    width: 100%;
    height: calc(100% - 1rem);
    position: relative;
    flex-direction: column;
    top: -1rem !important;
    z-index: 1;
}
.UserRoles-Wrapper .Blueprint-Body{
    display: flex;
    width: 100%;
    height: calc(100%);
    background-color: white;
}