.Pipline-Panel-hover .ant-btn{
    padding: 0px 15px !important;
}
.pipeline-checkbox .ant-checkbox-inner {
    border-radius: 12px !important;
    border-color: #1677ff !important;
    outline: none !important;
}
.pipeline-checkbox .ant-checkbox-input {
    z-index: 111  !important;
}
.pipeline-checkbox .ant-checkbox{
    opacity: 0;
    transition: opacity 0.3s;
}
  
.Pipline-Panel-hover .ant-collapse-header:hover .pipeline-checkbox .ant-checkbox{
  opacity: 1;
}
.pipeline-checkbox .ant-checkbox-checked, .pipeline-checkbox .ant-checkbox-indeterminate{
  opacity: 1 !important;
}

.pipeline-checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after{
  height: 2px !important;
  background-color: #000 !important;
}