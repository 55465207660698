.outer-border-3 {
  height: calc(100vh - 142px);
  margin-bottom: 12px;
  background-color: #fff;
  border: 2px solid #B0BED0;
  border-radius: 1rem;
  display: flex;
  align-items: normal;
  justify-content: start;
  flex-direction: column;
  padding-bottom: 15px;
}



.outer-border-6 {
  height: calc(100vh - 142px);
  margin-bottom: 12px;
  background-color: #fff;
  border: 2px solid #B0BED0;
  border-radius: 1rem;
  overflow: hidden;
  /* padding: 30px; */
}

.deal-details-topbox {

  /* border-bottom: 1px solid #B0BED0; */
  font-size: 13px;
  padding: 15px 15px 0px 15px;
  font-family: 'Avenir Next LT W02';
  color: #5B6C97;
}

.deal-details-right-topbox {

  /* border-bottom: 1px solid #B0BED0; */
  font-size: 13px;
  padding: 0px;
  font-family: 'Avenir Next LT W02';
  color: #5B6C97;
}

.deal-details-right-midbox {

  /* border-bottom: 1px solid #B0BED0; */
  font-size: 13px;
  /* padding: 10px 0px 15px 15px; */
  font-family: 'Avenir Next LT W02';
  color: #5B6C97;
}

.deal-details-bottumbox {
  font-size: 13px;
  padding: 0 0px 15px 15px;
  font-family: 'Avenir Next LT W02';
  color: #5B6C97;
}

.about-overflow {
  overflow-y: auto;
  flex-grow: 1;
}

.job-rightbox-overflow {
  overflow-y: auto;
  /* height: 447px; */
  margin-top: 4%;
  padding: 0px 15px;
}

.job-middle-tab {
  color: #5b6c97;
  border-bottom: 0px solid #000;
  position: relative;
  width: calc(100% - 70px);
  margin: auto;
  justify-content: space-evenly;
}

.job-middle-tab::after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background-color: #B0BED0;
  position: absolute;
  bottom: 1px;
  z-index: 0;
  left: 0;
}

.job-middle-tab>.nav-item {
  color: #5b6c97 !important;
  /* Properties */
}


.job-middle-tabs>.nav-link {
  line-height: 34px;
  font-size: 15px;
  font-family: 'Avenir Next LT W02';
  font-weight: bold;
  border: 0;
  padding: .4rem 1rem;
  border-radius: 0;
  /* background-color: #D0D4DF; */
}


.middle-tab-padding>.nav-link {
  padding: 0px 33px 10px 33px;
}

.middle-tab-padding-active>.nav-link {
  padding: 0px 56px 10px 56px;
}

.middle-tab-padding-doubleActive>.nav-link {
  padding: 0px 79px 10px 79px;
}

.widthper-21 {
  width: 21%;
  padding: 0px 13px;
}

.widthper-58 {
  width: 58%;
}

.fontsize-11 {
  font-size: 11px;
}

.mid-right-icon {
  position: absolute;
  width: 52px;
  right: 0px;
  cursor: pointer;
  top: 0px;
  z-index: 2;
}

.mid-left-icon {
  position: absolute;
  left: 0px;
  width: 52px;
  cursor: pointer;
  top: 0px;
  z-index: 9;
}

.mid-left-icon-active {
  position: absolute;
  left: 12px;
  width: 52px;
  cursor: pointer;
  top: 0px;
  z-index: 9;
  margin-left: 12px;
}

.mid-right-icon-active {
  position: absolute;
  width: 52px;
  top: 0px;
  cursor: pointer;
  right: 12px;
  z-index: 2;
  margin-right: 12px;
}

.display-none {
  display: none;
}

.widthper-79 {
  width: 79%;
}

.widthper-100 {
  width: 100%;
}

.padl-10 {
  padding-left: 10px;
}

.mar-Inprogess-icon {
  margin-bottom: -2px;
  margin-left: 8px;
  font-size: 16px;
  color: #0180C7;
  cursor: pointer;
}

.inprogress-text {
  color: #0180C7;
  font-weight: bold;
}

.client-text-style {
  text-decoration: underline;
  color: #0070C0 !important;
  font-weight: bold;
}

.custom-label-job-head {
  width: 100% !important;
  display: inline-block;
  vertical-align: top;
  position: relative;
  word-break: break-word;
  color: #2c2c2c;
}

.custom-label-job-head+span {
  display: inline-block !important;
  color: gray;

}


/* .custom-label-job-head:after{
    position: absolute;
    content: ":";
    right: -3px;
    font-size: 15px;
    color: #5b6c97;
    top: 0;
    line-height: 1.1;
  } */
.abt-deal-field {
  position: relative;
  margin-top: 0px !important;
  border-bottom: 0px !important;
  margin-right: 10px;
}

.custom-label-job-head.custom-label-job-detail {
  width: 100%;
  font-size: 12px;
  color: #2c2c2c;
}

.custom-label-job-head.custom-label-job-detail+span {
  width: 100%;
  border-bottom: 1px solid transparent;
  color: gray;
  padding-right: 15px;
}

.abt-deal-field:hover span {
  border-color: #0180c7;
}

.abt-deal-field.field-locked:hover span {
  border-color: transparent;
}

.abt-deal-field svg {
  display: none;
  position: absolute;
  right: 0;
  top: 20px;
  font-size: 12px;
  cursor: pointer;
}

.abt-deal-field:hover svg {
  display: block;
}

.abt-deal-field input {
  background: white;
  padding: 0;
  border: 0;
  width: 100%;
  color: gray;
  border-bottom: 2px solid #0180c7;
  z-index: 99;
  position: relative;
  outline: 0;
}

.job-middle-tab .nav-item.show .nav-link,
.job-middle-tab .nav-link.active {
  z-index: 1;
  border-bottom: 5px solid;
}

.width100 {
  width: 100px;
}

.width109 {
  width: 109px;
}

.cursor-pointer {
  cursor: pointer;
}

.thumbnail-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 4%;
  padding: 0;
}

.thumbnail-list li {
  width: 44%;
}

.thumbnail-list li a {
  display: block;
}

.thumbnail-list li a img {
  width: 100%;
}

.job-parent-tabs {
  margin-left: 50px;
  border: 0;
  position: sticky;
  top: 0;
}


.job-parent-tabs .nav-link {
  font-size: 14px;
  font-family: 'Avenir Next LT W02';
  font-weight: bold;
  border: 0;
  padding: 12.7px 1.9rem;
  color: #5b6c97;
  background: #d0d4df;
  border-bottom: 2px solid #B0BED0 !important;
  border-right: 2px solid #B0BED0 !important;
  border-radius: 0;
}

.job-parent-tabs .nav-link.active {
  border-bottom: 0 !important;
  outline: 0;
  border-right: 0 !important;
}

.padl-14 {
  padding-left: 14px;
}

.about-deal-search {
  margin-bottom: 0px;
  position: sticky !important;
  padding-top: 3px;
  top: 0px;
  background: #ffffff;
  z-index: 999;
  width: 98% !important;
}

.about-deal-search input {
  width: 100%;
  border: 2px solid #b0bed0;
  /* margin: 0px 0 10px; */
  padding: 2px 10px;
  border-radius: 15px;
  /* margin: 0 auto; */
  /* text-align: center; */
  justify-content: center;
  position: relative;
  bottom: 2px;
  left: -4px;
}

.about-deal-search input:focus {
  outline: 0;
  border: 2px solid #b0bed0;
}

.about-deal-search svg {
  right: 12px;
  top: 11px;
}

.about-deals-collapse {
  background: white;
  position: sticky;
  top: 0;
  z-index: 999;
  padding: 5px 0;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding-bottom: 0px;
}

.about-deals-collapse a {
  cursor: pointer;
  font-weight: 100;
  font-size: 13px;
  text-decoration: underline !important;
}

.dealInfo-btnGrp {
  background: #5b6c97;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.dealInfo-btnGrp .btn-white {
  background-color: white;
  border: 1px solid white;
  color: black;
}

.dealInfo-btnGrp .btn-white.btn-outline {
  background-color: transparent;
  color: white;
}

.select-client {
  width: 93%;
  border: 0;
  margin-left: 0px;
  border-bottom: 2px solid #0180c7;
}


.show>.add-contactRole-btn.dropdown-toggle {
  padding: 1px 6px;
  color: #ffffff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  border-radius: 9px;
  font-size: 13px;
}
.add-contactRole-btn{
  padding: 1px 6px;
  color: #ffffff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  border-radius: 3px;
  font-size: 13px;
}

.contact-role-label {
  margin: 0 0 2px;
  color: black;
  font-size: 15px;
}

.contact-role-label-child {
  margin: 0 0 2px;
  color: black;

}

.fr-addposition {
  position: relative;
  float: right !important;
  left: 90px;
  top: 5px;
}

/* .email-warning{
    width: 100%;
    height: 74vh;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

/* .email-warning {
    width: 100%;
    margin-top: 0.2%;
   
    display: flex;
   
    align-items: center;
  } */
.task-group-tabs {
  position: relative;
}

.email-warning>strong {
  font-size: 1rem !important;
}

.email-warning {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project_status_custom {
  cursor: pointer;
  min-width: 120px !important;
  font-weight: 600 !important;
  height: 24px !important;
  border-radius: 11px;
  text-align: center;
  padding-top: 2px !important;
  display: inherit;
  position: relative;
}



.project_status_custom svg {
  font-size: 16px;
  padding-top: 6px;
  margin-left: 0px !important;
  position: absolute;
  right: 10px;
  top: 1px;
  display: none;
}

.project_status_custom:hover svg {
  display: block;
}

.project-status .status {
  background-color: rgb(245, 107, 98);
  display: inline-block;
  width: 12px;
  margin-right: 13px;
  height: 12px;
  border-radius: 2px;
}

.deal-details-topbox p:last-child {
  display: flex;
  /* padding-right: 34px; */
}

.deal-details-topbox .project_status_custom {
  background-color: rgb(251, 193, 30);
  display: inline-flex;
  min-width: 75px;
  padding: 0px 5px;
  border-radius: 25px;
  /* width: 145% !important; */
  min-width: 120px;
  text-align: center;
  display: inline-block;
  position: relative;
  /* display: flex !important;
  justify-content: space-around; */
}





.collapse-body-tiles {

  margin-left: 11px;
  /* margin-right: 14px; */
  margin-top: 10px;
  color: #fff;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  background: #5b6c97;
  padding: 4px 10px;
  border-radius: 12px 12px 0 0;
  font-size: 12px;
  font-weight: 500;

  font-family: "Avenir Next LT W02";
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

}

.d-flex {
  display: flex !important;
}

.collapse-body-tiles-border {
  margin-left: 11px;
  margin-bottom: 1px solid #cccc;
  width: 92%;
  position: relative;
  bottom: 0px;
  /* height: 25px; */
  border-bottom: 1px solid #B0BED0;
  font-size: 13px;
  /* padding: 15px 0px 0px 15px; */
  font-family: 'Avenir Next LT W02';
  color: #5B6C97;
}

.collapse-body-tiles-right-links {
  margin-top: 0px;
  margin-left: -4px !important;

}

.collapse-body-tiles-right-links-border {
  /* margin: 0px; */
  margin-left: -4px !important;

}

.about-this-deal-content {
  height: 155px !important;
  overflow: auto !important;
  width: calc(100vw - 82.5vw);
}

.deal-details-right-midbox .collapse p {
  padding: 5px 0 !important;
}
.contact-roles{
  padding: 5px !important;
}

.deal-details-bottumbox .collapse p {
  margin-bottom: 7px !important;
  margin-top: 7px !important;
}

.more-tab-body-icon {
  position: absolute;
  left: 89%;
  bottom: 95%;
  width: 30px !important;
}

.ant-popover-inner-content{
  width: 200px !important;
}

.ant-popover-content{
  position: relative !important;
  left: -15px !important;
}

.deal-dash-link{
  width: 16px;
  height: 16px;
  margin-bottom: 6px;
  margin-right: 8px;
  fill: #ae561f !important;
}

.deal-dash-link-wrapper li span{
  font-size: 14px;
  color: #ae561f;
  font-weight: 600;
  margin-left: 3px
}

.deal-dash-link-wrapper{
  padding-left: 0px;
}
.deal-dash-link-wrapper a:hover{
  color: #be631f !important;
    text-decoration: underline !important;
}
.deal-dash-link-wrapper li{
  list-style: none;
  margin-bottom: 5px;
}

.custom-select-project-status .ant-popover-inner-content{
  width: 120px !important;
}

.deasble-link{
  pointer-events: none;
}
.text-end {
  text-align: end;
}

.Deal-Dash .User-Role-Wrapper {
  display: flex !important;
  align-items: center;
  gap: 5px;
}