.BluePrint-Reminder .ant-popover-inner{
    width: 40rem;
}
.BluePrint-Reminder .ant-popover-inner-content{
    width: 100% !important;
    height: auto;
}
.BluePrint-Reminder .Reminder-B-Component{
    max-height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
.BluePrint-Reminder .Task-Reminder-Card{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
}