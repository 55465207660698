.Time-Track-Wrapper {
    display: flex;
    padding: 6px;
    gap: 6px;
}
/* .Time-Track-Popover{
    top: 12rem !important;
} */
.Time-Track-Popover .ant-popover-inner-content{
    min-width: 408px !important;
    width: auto !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: auto !important;
    max-height: 55vh !important;
}
/* .Time-Track-Popover .ant-popover-inner{
    position: relative !important;
    top: 6em !important; 
} */

.Time-Track-Popover .ant-popover-inner-content::-webkit-scrollbar{
    width: 7px !important;
}
.Time-Track-Popover .ant-popover-inner-content .ant-collapse-item::-webkit-scrollbar, .Time-Track-Popover .ant-collapse-content-box::-webkit-scrollbar{
    width: 7px !important;
}
.Time-Track-Popover .ant-popover-inner-content .ant-collapse-item::-webkit-scrollbar:hover{
    width: 5px !important;
}
.Time-Track-Popover .ant-popover-inner-content::-webkit-scrollbar:hover{
    width: 5px !important;
}
.Time-Track-Popover .ant-collapse-content-box::-webkit-scrollbar:hover{
    width: 7px !important;
}

.Time-Track-Popover .Time-Track-Collapse .ant-collapse-item{
    width: 400px !important;
    box-shadow: 0px 2px 3px -1px #f2f2f2 !important;
}
.Time-Track-Popover .Time-Track-Collapse .ant-collapse-content-box{
    overflow: auto !important;
    max-height: 18rem !important;
}
.Time-Track-Popover .user-time-track ul li+li{
    margin: 0px !important;
}
.Time-Track-Popover .ant-collapse-content-box li{
   padding: 3px 5px !important;
}
.Time-Track-Popover .text-right{
   display: flex !important;
   justify-content: flex-end !important;
}
.timetracklist2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 0.5rem;
}
.Time-Track-Popover .Time-Track-Collapse{
    border: 0px !important;
    background: #f9f9f9 !important;
}
/* .Time-Track-Popover .Time-Track-Collapse .ant-collapse-expand-icon{
    display: none !important;
} */
.Time-Track-Popover .Time-Track-Collapse .user-time-track:first-child {
    padding: 8px 10px;
    border-top: 0px !important;
}

.View-Time-Collapse .View-Time-Ettry{
    padding: 0.4rem 0.4rem 0.4rem 1rem!important;
    background: #edf0f4 !important;
}
.View-Time-Collapse .View-Time-Ettry p{ 
    margin-bottom: 0.2rem !important;
    display: flex;
    gap: 12px;
}
.View-Time-Collapse .View-Time-Ettry p span{ 
    font-weight: bold !important;
    width: 5rem !important;
}
.Time-Tracker-Collapse-Wrapper .ant-collapse-content{
    margin-left: 0.5rem !important;
    width: 95% !important;
    padding: 0.2rem !important;
}
.Edit-Time-Record-Desc{
    height: 14px !important;
    width: 14px !important;
    color: #5b6c97 !important;
    cursor: pointer !important;
}
.Delete-Time-Record .swal-button--cancel, .Delete-Time-Record .swal-button--confirm {
    padding: 7px; /* Ensure same padding for both buttons */
    font-size: 16px; /* Make the font size consistent */
    width: 90px; /* Make both buttons the same width */
  }
  
  .Delete-Time-Record .swal-button--cancel {
    background-color: #e0e0e0; /* Neutral color for cancel button */
    color: #000;
    box-shadow: none;
  }
  
  .Delete-Time-Record .swal-button--confirm {
    background-color: #f44336; /* Red color for danger button */
    color: #fff;
    box-shadow: none;
  }
  .swalDiv{
    cursor: pointer;
    color: #005faa;
}
/* .Time-Track-Popover .ant-popover-arrow {
    position: absolute !important;
    top: 6em !important;
    --arrow-x: 100px !important;
    --arrow-y: 10px !important;  
  } */
.Timer-Comment .ant-popover-content{
    width: 450px !important;
}
.Timer-Comment .ant-popover-content .ant-popover-inner{
    padding: 1rem 1.5rem 0.5rem;;
}
.Timer-Comment .ant-popover-inner-content{
    width: 408px !important;
}
.Timer-Comment .ant-popconfirm-message-text{
    width: 100% !important;
}
  .Time-Entries-Collapse .ant-collapse-content-active{
    height: max-content !important;
  }
  .Time-Entries-Collapse .ant-collapse-item{
    /* margin: 6px 0px !important; */
    margin: 9px -4px !important;
  }
  .divForTimeTrackCmp{
    width: fit-content;
    padding: 0 7px;
    border-radius: 5px;
  }
  .Time-Track-Collapse .ant-collapse-header{
    display: flex;
    align-items: center;
    position: sticky !important;
    top: 0px !important;
    background: #fff !important;
    z-index: 1111 !important;
  }

  .Time-Track-Collapse .Time-Entries-Collapse .ant-collapse-header{
    position: relative !important;
    z-index: 111 !important;
    display: flex !important;
    align-items: center !important;
  }
  .Time-Track-Collapse .ant-collapse-expand-icon{
    height: 3rem !important;
    background: #fff;
  }
  .viewButtonInRecords{
    width: 50%;
    height: 70%;
    align-items: center;
    color: #84868a;
    font-size: smaller;
  }
  .Time-Track-Popover .ant-popover-content{
    left: 0px !important;
    right: 0px !important;
  }