#frame {
  width: 52%;
  min-width: 360px;
  max-width: 1000px;
  background: #ededed;
  position: fixed;
  z-index: 9999;
  right: 18px;
  bottom: 50px;
  top: 52px;
  border: 2px solid;
  /* border-radius: 6px; */
  padding: 4px;
  border-color: rgb(1, 142, 224);
  }
  .meta {
      float: left;
  }
  .m-time {
    text-align: right;
    font-size: 12px;
    display: block;
    position: absolute;
    right: 7px;
    bottom: 0px;
  }
.m-name {
    display: block;
    color: #7cc313;
    padding-bottom: 5px;
}
  @media screen and (max-width: 360px) {
    #frame {
      width: 100%;
      height: 100vh;
    }
  }
  #frame #sidepanel {
    float: left;
    min-width: 36%;
    max-width: 36%;
    width: 40%;
    height: 100%;
    background-color: rgb(1, 142, 224);
    color: #f5f5f5;
    overflow: hidden;
    position: relative;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel {
      width: 58px;
      min-width: 58px;
    }
  }
  #frame #sidepanel #profile {
    width: 100%;
    margin: 15px 0 0 0;
    padding-left: 20px;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile {
      width: 100%;
      margin: 0 auto;
      padding: 5px 0 0 0;
      background: #32465a;
    }
  }
  #frame #sidepanel #profile.expanded .wrap {
    height: 210px;
    line-height: initial;
  }

  #profile .wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
  }
  #frame #sidepanel #profile.expanded .wrap p {
    margin-top: 20px;
    flex: 1;
  }
  #frame #sidepanel #profile.expanded .wrap i.expand-button {
    -moz-transform: scaleY(-1);
    -o-transform: scaleY(-1);
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
  }

  #frame #sidepanel #profile .wrap {
    height: 60px;
    line-height: 60px;
    overflow: hidden;
    -moz-transition: 0.3s height ease;
    -o-transition: 0.3s height ease;
    -webkit-transition: 0.3s height ease;
    transition: 0.3s height ease;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap {
      height: 55px;
    }
  }
  #frame #sidepanel #profile .wrap img {
    width: 50px;
    border-radius: 50%;
    padding: 3px;
    border: 2px solid #e74c3c;
    height: auto;
    float: left;
    cursor: pointer;
    -moz-transition: 0.3s border ease;
    -o-transition: 0.3s border ease;
    -webkit-transition: 0.3s border ease;
    transition: 0.3s border ease;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap img {
      width: 40px;
      margin-left: 4px;
    }
  }
  #frame #sidepanel #profile .wrap img.online {
    border: 0px solid #2ecc71;
  }
  #frame #sidepanel #profile .wrap img.away {
    border: 2px solid #f1c40f;
  }
  #frame #sidepanel #profile .wrap img.busy {
    border: 2px solid #e74c3c;
  }
  #frame #sidepanel #profile .wrap img.offline {
    border: 2px solid #95a5a6;
  }
  #frame #sidepanel #profile .wrap p {
    float: left;
    margin-left: 15px;
    line-height: 50px;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap p {
      display: none;
    }
  }
  #frame #sidepanel #profile .wrap i.expand-button {
    float: right;
    margin-top: 23px;
    font-size: 0.8em;
    cursor: pointer;
    color: #435f7a;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap i.expand-button {
      display: none;
    }
  }
  #frame #sidepanel #profile .wrap #status-options {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: 150px;
    margin: 70px 0 0 0;
    border-radius: 6px;
    z-index: 99;
    line-height: initial;
    background-color: rgb(1, 142, 224);
    -moz-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options {
      width: 58px;
      margin-top: 57px;
    }
  }
  #frame #sidepanel #profile .wrap #status-options.active {
    opacity: 1;
    visibility: visible;
    margin: 75px 0 0 0;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options.active {
      margin-top: 62px;
    }
  }
  #frame #sidepanel #profile .wrap #status-options:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #435f7a;
    margin: -8px 0 0 24px;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options:before {
      margin-left: 23px;
    }
  }
  #frame #sidepanel #profile .wrap #status-options ul {
    overflow: hidden;
    border-radius: 6px;
  }
  #frame #sidepanel #profile .wrap #status-options ul li {
    padding: 15px 0 30px 18px;
    display: block;
    cursor: pointer;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li {
      padding: 15px 0 35px 22px;
    }
  }
  #frame #sidepanel #profile .wrap #status-options ul li:hover {
    background:#414042;
  }
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 5px 0 0 0;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle {
      width: 14px;
      height: 14px;
    }
  }
  #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
    content: '';
    position: absolute;
    width: 14px;
    height: 14px;
    margin: -3px 0 0 -3px;
    background: transparent;
    border-radius: 50%;
    z-index: 0;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li span.status-circle:before {
      height: 18px;
      width: 18px;
    }
  }
  #frame #sidepanel #profile .wrap #status-options ul li p {
    padding-left: 12px;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #profile .wrap #status-options ul li p {
      display: none;
    }
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-online span.status-circle {
    background: #2ecc71;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-online.active span.status-circle:before {
    border: 1px solid #2ecc71;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-away span.status-circle {
    background: #f1c40f;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-away.active span.status-circle:before {
    border: 1px solid #f1c40f;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-busy span.status-circle {
    background: #e74c3c;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-busy.active span.status-circle:before {
    border: 1px solid #e74c3c;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-offline span.status-circle {
    background: #95a5a6;
  }
  #frame #sidepanel #profile .wrap #status-options ul li#status-offline.active span.status-circle:before {
    border: 1px solid #95a5a6;
  }
  #frame #sidepanel #profile .wrap #expanded {
    padding: 100px 0 0 0;
    display: block;
    line-height: initial !important;
  }
  #frame #sidepanel #profile .wrap #expanded label {
    float: left;
    clear: both;
    margin: 0 8px 5px 0;
    padding: 5px 0;
  }
  #frame #sidepanel #profile .wrap #expanded input {
    border: none;
    margin-bottom: 6px;
    background: #32465a;
    border-radius: 3px;
    color: #f5f5f5;
    padding: 7px;
    width: calc(100% - 43px);
  }
  #frame #sidepanel #profile .wrap #expanded input:focus {
    outline: none;
    background: #435f7a;
  }
  #frame #sidepanel #search {
    display: flex;
    font-weight: 300;
    /* background: #018ee0; */
    justify-content: center;
    align-items: center;
    position: relative;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #search {
      display: none;
    }
  }
  #frame #sidepanel #search label {
    position: absolute;
    left: 8%;
    top: 16%;
  }
  #frame #sidepanel #search input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    padding: 5px 5px 5px 37px;
    width: calc(100% - 25px);
    border: 1px solid #018ee0;
    border-radius: 47px;
    color: #000 !important;
  }
  #frame #sidepanel #search input:focus {
    outline: none;
    /* background: #435f7a; */
  }
  #frame #sidepanel #search input::-webkit-input-placeholder {
    opacity: 0.5;
    color: #000000;
  }
  #frame #sidepanel #search input:focus::-webkit-input-placeholder {
    opacity: 1;
    color: #000000;
  }
  #frame #sidepanel #search input::-moz-placeholder {
    color: #000000;
  }
  #frame #sidepanel #search input:-ms-input-placeholder {
    color: #000000;
  }
  #frame #sidepanel #search input:-moz-placeholder {
    color: #000000;
  }
  #frame #sidepanel #contacts {
    height: calc(100% - 177px);
    overflow-y: scroll;
    overflow-x: hidden;
  }
  #search .fa{
    color: #0055ba;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar {
    display: none !important;
  }
  #contacts > ul {
    padding-left: 0px;
    margin: 5px 0px !important;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #contacts {
      height: calc(100% - 149px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
    #frame #sidepanel #contacts::-webkit-scrollbar {
      display: none;
     
    }
  }
  #frame #sidepanel #contacts.expanded {
    height: calc(100% - 334px);
  }
  #frame #sidepanel #contacts::-webkit-scrollbar {
    width: 8px;
    background: #2c3e50;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar-thumb {
    background-color: #243140;
  }
  #frame #sidepanel #contacts::-webkit-scrollbar-track {
    background: #018ee0; 
  }
  #frame #sidepanel #contacts ul li.contact {
    align-items: center;
    display: flex;
    position: relative;
    padding: 10px 15px 10px 20px;
    font-size: 0.9em;
    cursor: pointer;
    list-style: none;
    flex-direction: row;
    min-height: 60px;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact {
      padding: 6px 0 46px 8px;
    }
  }
  /* #frame #sidepanel #contacts ul li.contact:hover {
    color:#414042;
  }
  #frame #sidepanel #contacts ul li.contact.active {
    color:#414042;
  } */
  #frame #sidepanel #contacts ul li.contact.active span.contact-status {
    border: 2px solid #32465a !important;
  }
  #frame #sidepanel #contacts ul li.contact .wrap {
    width: 100%;
    margin: 0 auto;
    display: flex;
    position: relative;
    align-items: center;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap {
      width: 100%;
    }
  }
  #frame #sidepanel #contacts ul li.contact .wrap span {
    position: absolute;
    left: 0;
    margin: -2px 0 0 -2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #2c3e50;
    background: #95a5a6;
  }
  #frame #sidepanel #contacts ul li.contact .wrap span.online {
    background: #2ecc71;
  }
  #frame #sidepanel #contacts ul li.contact .wrap span.away {
    background: #f1c40f;
  }
  #frame #sidepanel #contacts ul li.contact .wrap span.busy {
    background: #e74c3c;
  }
  #frame #sidepanel #contacts ul li.contact .wrap img {
    width: 40px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap img {
      margin-right: 0px;
    }
  }
  .meta .name {
    margin-bottom: 0 !important;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #contacts ul li.contact .wrap .meta {
      display: none;
    }
  }
  #frame #sidepanel #contacts ul li.contact .wrap .meta .name {
    font-weight: 600;
    /* padding-top: 8px;
    padding-left: 10px; */
  }
  #frame #sidepanel #contacts ul li.contact .wrap .meta .preview {
    margin: 5px 0 0 0;
    padding: 0 0 1px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -moz-transition: 1s all ease;
    -o-transition: 1s all ease;
    -webkit-transition: 1s all ease;
    transition: 1s all ease;
  }
  #frame #sidepanel #contacts ul li.contact .wrap .meta .preview span {
    position: initial;
    border-radius: initial;
    background: none;
    border: none;
    padding: 0 2px 0 0;
    margin: 0 0 0 1px;
    opacity: .5;
  }
  #frame #sidepanel #bottom-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  #frame #sidepanel #bottom-bar button {
    float: left;
    border: none;
    width: 50%;
    padding: 10px 0;
    background:#736790;
    color: #f5f5f5;
    cursor: pointer;
    font-size: 0.85em;
    font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button {
      float: none;
      width: 100%;
      padding: 15px 0;
    }
  }
  #frame #sidepanel #bottom-bar button:focus {
    outline: none;
  }
  #frame #sidepanel #bottom-bar button:nth-child(1) {
    border-right: 1px solid #414042;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button:nth-child(1) {
      border-right: none;
      border-bottom: 1px solid #414042;
    }
  }
  #frame #sidepanel #bottom-bar button:hover {
    background:#414042;
  }
  #frame #sidepanel #bottom-bar button i {
    margin-right: 3px;
    font-size: 1em;
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button i {
      font-size: 1.3em;
    }
  }
  @media screen and (max-width: 735px) {
    #frame #sidepanel #bottom-bar button span {
      display: none;
    }
  }
  #frame .content {
    float: right;
    min-width: 64% !important;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
  @media screen and (max-width: 735px) {
    #frame .content {
      width: calc(100% - 58px);
      min-width: 300px !important;
    }
  }
  @media screen and (min-width: 900px) {
    #frame .content {
      width: calc(100% - 357px);
    }
  }
  #frame .content .contact-profile {
    display: flex;
    width: 100%;
    height: 63px;
    line-height: 63px;
    background: #ffffff;
    align-items: center;
  }
  #frame .content .contact-profile img {
    width: 40px;
    border-radius: 50%;
    float: left;
    /* margin: 9px 12px 0 9px; */
    margin-left: 12px;
    padding: 3px;
    border: 1px solid #cdcdcd;
  }
  #frame .content .contact-profile p {
    float: left;
  }
  #frame .content .contact-profile .social-media {
    float: right;
  }
  #frame .content .contact-profile .social-media i {
    margin-left: 14px;
    cursor: pointer;
  }
  #frame .content .contact-profile .social-media i:nth-last-child(1) {
    margin-right: 20px;
  }
  #frame .content .contact-profile .social-media i:hover {
    color: #435f7a;
  }
  #frame .content .messages {
    /* height: auto;
    min-height: calc(100% - 114px);
    max-height: calc(100% - 114px); */
    height: 80% !important;
    width: 100%;
  }
  @media screen and (max-width: 735px) {
    #frame .content .messages {
      max-height: calc(100% - 135px);
    }
  }
  #frame .content .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
  }
  #frame .content .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
  }
  #frame .content .messages ul li {
    display: flex;
    clear: both;
    position: relative;
    justify-content: end;
    margin: 15px 15px 5px 15px;
    padding: 5px 0px;
    width: calc(100% - 25px);
    font-size: 0.9em;
  }
  #frame .content .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
  }
  #frame .content .messages ul li.sent img {
    margin: 6px 8px 0 0;
  }
  #frame .content .messages ul li.sent p {
    background: #d4f2ff;
    color: #000;
    word-break: break-all;
    word-wrap: break-word;
    padding: 10px 10px 22px 10px;
    position: relative;
    min-width: 150px;
  }
  #frame .content .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
  }
  li.replies{
    text-align: right;
  }
  .messages ul li p{
     word-break: break-word; 
    white-space: break-spaces;
    
  }
  #frame .content .messages ul li.replies p {
    background: #ffffff;
    float: right;
    /* word-break: break-word;
    word-wrap: break-word; */
    padding: 5px 3px 8px 4px;
    position: relative;
    max-width: 450px;
    line-height: 20px;
    text-align: left;
    min-width: 150px;
    
  }
  #frame .content .messages ul li img {
    width: 22px;
    border-radius: 50%; 
    float: left;
  }
  .content .chat-container-body .Group-Profile-Photo img {
    width: 30px !important;
    border-radius: 50%; 
    float: left;
  }
  
  #frame .content .messages ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 8px;
    max-width: 205px;
    line-height: 130%;
  }
  @media screen and (min-width: 735px) {
    #frame .content .messages ul li p {
      max-width: 300px;
    }
  }

/* _________________________________________ */

  #frame .content .message-input {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 99;
    margin-bottom: 6px;
    padding: 0px 11px;
  }
  .text-with-attachement {
    width: calc(100% - 115px);
    float: left;
    position: relative;
    height: 40px;
    margin-left: 10px;
    padding: 0;
    border: 0;
    background: transparent;
    max-height: 80px;

}
.pos-relative {
  position: relative;
}
.btn-send {
  position: absolute;
  bottom: 0;
  right: 0;
}
.btn-file {
  position: absolute;
  bottom: 0;
  right: 55px;
}
.emoticon-icon{
  font-size: 15px;
  position: absolute;
  bottom: 14px;
  right: 18px;
  color: black;
}
.text-with-attachement 
  #frame .content .message-input .wrap {
    position: relative;
  }
  #frame .content .message-input .wrap input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    border: none;
    width: 100%;
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    color: #32465a;
    border-radius: 50px;
  }
  @media screen and (max-width: 735px) {
    #frame .content .message-input .wrap input {
      padding: 15px 32px 16px 8px;
    }
  }
  #frame .content .message-input .wrap input:focus {
    outline: none;
  }
  #frame .content .message-input .wrap textarea {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    border: none;
    width: 100%;
    /* min-height: 280px;
    max-height: 340px; */
    padding: 11px 65px 10px 8px;;
    font-size: 0.8em;
    color: #32465a;
    border-radius: 12px;
  }
  @media screen and (max-width: 735px) {
    #frame .content .message-input .wrap textarea {
      padding: 15px 32px 16px 8px;
    }
  }
  #frame .content .message-input .wrap textarea:focus {
    outline: none;
  }

  #frame .content .normal-width .wrap textarea {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    border: none;
    
    height: 100%;
    padding: 11px 32px 10px 8px;
    font-size: 0.8em;
    color: #32465a;
    border-radius: 30px;
  }
  @media screen and (max-width: 735px) {
    #frame .content .normal-width .wrap textarea {
      padding: 15px 32px 16px 8px;
    }
  }
  #frame .content .normal-width .wrap textarea:focus {
    outline: none;
  }

  #frame .content .message-input .wrap .attachment {
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.1em;
    color: #435f7a;
    opacity: .5;
    cursor: pointer;
  }
  @media screen and (max-width: 735px) {
    #frame .content .message-input .wrap .attachment {
      margin-top: 17px;
      right: 65px;
    }
  }
  #frame .content .message-input .wrap .attachment:hover {
    opacity: 1;
  }
  #frame .content .message-input .wrap button {
    float: right;
    border: none;
    width: 30px;
    height: 30px;
    padding: 5px 0;
    margin-top: 3px;
    cursor: pointer;
    color: #f5f5f5;
    background-color: rgb(1, 142, 224);
    border-radius: 50%;
  }
  #frame .content .message-input .wrap button:hover {
    background:#414042;
  }
  #frame .content .message-input .wrap button:focus {
    outline: none;
  }
  img {
    max-width: 100%;
}

.groupName {
  background: #eeeeee;
  border: 0;
  padding: 10px 15px;
  width: 100%;
  outline: 0;
}
.searchContactName {
  background: #018ee0;
  background-image: linear-gradient( to left , #0055ba, #009df5);
  background-image: -webkit-linear-gradient( to left , #0055ba, #009df5);
  border: 0;
  width: 100%;
  display: flex;
  margin: 10px 0 15px;
  align-items: center;
  padding-right: 10px;
}
.searchContactName input::placeholder{
  color: #a3ddff;
}
.searchContactName label{
  padding: 10px 10px 10px 15px;
  margin: 0;
  color: #ffffff;
}
.searchContactName input{
  background: transparent;
  border: 0;
  padding: 10px 15px;
  flex-grow: 1;
  outline: 0;
  color: white;
}
.searchContactName .fa-times{
  color: white;
}
.create-user-group-icon{
  float: right;
  margin: 0px 18px;
}
.select-user-cross-item {
  position: absolute;
  cursor: pointer;
  right: -4px;
  top: -3px;
  background-color: #0e80c1;
  color: #fff;
  padding: 2px;
  font-size: 18px;
  min-width: 18px;
  border-radius: 50%;
  border: 2px solid white;
}
.select-user-div {
  position: relative;
  margin-right: 15px;
  margin-bottom: 15px;
}

.select-user-img{ 
  border-radius: 50%;
  width: 40px;
  height: 40px;
  overflow: hidden;
}
.select-user-div p{
  margin: 0;
  font-size: 12px;
}

.contactList{
  height: 36vh;
  overflow-y: auto;
}

.addContact-list li{
  display: flex;
  padding: 0 15px;
}
.addContact-list li:hover{
  background: #f5f5f5;
}
.addContact-list li .contactimg{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 5px 10px 5px 0;
}
.mtlb-5 {
  margin: 5px 10px 5px 0;
}
.addContact-list li .contactName{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  border-bottom: 1px solid #d6d6d6;
}
.addContact-list li .contactName p{
  margin: 0;
}
.contactCheck {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 22px;
  margin: 0;
}

/* Hide the browser's default checkbox */
.contactCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: white;
    border: 1px solid #afafaf;
    border-radius: 50%;
}

/* When the checkbox is checked, add a blue background */
.contactCheck input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.contactCheck input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.contactCheck .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 11px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.createGroupModal{
  margin: 8% 6% 0 auto;
}
.user-name-box {
  
  float: left;
  line-height: 20px;
  padding: 7px 0;
}
.user-name-box div:nth-child(1){
  font-weight: bold;
}
.user-typing-box {
  display: block;
  color: #666;
  font-size: 14px;
}
.messages > ul {
  padding: 0;
}
.messages > ul > li.sent > p, .messages > ul > li.replies > p {
  margin-bottom: 2px;
}
.messages > ul > li.sent > span {
  display: block;
  font-size: 12px;
  clear: both;
  padding-left: 15px;
}
.messages > ul > li.replies > span {
  display: block;
    font-size: 12px;
    padding-right: 15px;
    clear: both;
    text-align: right;
}
.online-ico-online {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: #008000;
}

.status-icon{
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
}
.Available{
  background-color: green !important;
}
.Busy{
  background-color: red !important;
}
.Away{
  background-color: yellow !important;
}
.offline{
  background-color: gray !important;
}

.online-ico-offline {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  background-color: gray;
}
#frame #sidepanel #contacts ul li.contact:hover {
  background-color:#5ebef6;
}

.no-image-profile{
  width: 40px;
  height: 40px;
  float: left;
  /* background-color: #add8e6; */
  background-color: #736790;
  color: #000;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 11px 5px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 18px;
}
.mt-10 {
  margin: 10px;
}
.mt-8 {
  margin: 8px;
}

.attache-icon{
  padding: 2px;
    margin: 10px;
    cursor: pointer;
}

.chat-upload-box {
  position: absolute;
  bottom: 0px;
  background-color: #fff;
  width: 100%;
  padding: 7px;
  border-bottom: 1px solid #ccc;
}
.close-ico {
position: absolute;
right: -6px;
top: -6px;
background-color: #018ee0;
color: #fff;
font-size: 14px;
padding: 2px;
cursor: pointer;
text-align: center;
width: 15px !important;
height: 15px;
border-radius: 50%;
}

.border-radius-4{
border-radius: 4px !important;
}

.file-replie-div {
  width: 200px;
  height: auto;
  background-color: #ccc;
  float: right;
  padding: 10px;
  border-radius: 8px;
}
.file-received-div {
  width: 200px;
  height: auto;
  background-color: #ccc;
  float: left;
  padding: 10px;
  border-radius: 8px;
}

.chat-file-name{
  
    text-align: left;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 18px;
    word-break: break-all;

}

.chat-file-icon {
  text-align: left;
  padding-bottom: 15px;
}

.chat-file-download {
  text-align: center;
  border-top: 1px solid #fff;
  padding-top: 5px;
}

.pending-message-count {
  float: right;
  background-color: white;
  color: black;
  width: 20px;
  height: 20px;
  text-align: center;
  padding: 4px;
  border-radius: 50%;
  font-size: 14px;
  line-height: 14px;
  margin-top: 10px;
}

.profile-img {
  position: relative;
  width: 40px;
  text-align: center;
  margin: 0 auto;
}

.view-participants{
  color: gray;
  font-size: 15px;
  cursor: pointer;
}
.view-participants:hover{
  cursor: pointer;
  color: #018ee0;
}
.selected-user-color{
  background-color: #5ebef6;
}
.mr-10{
  margin-right: 10px;
}

.emoji-select-box{
  /* width: 530px !important; */
  position: absolute;
    /* bottom: 102%; */
    left: 8px;
    box-shadow: none !important;
}

.aside.emoji-picker-react {
  width: 394px !important;
  background-color: white;
}
.emoji-categories{
  background-color: white !important;
}

.setting-dropdown{
  top: 25px;
  position: absolute;
  right: 15px;
}
.chatsetting-toggle{
  background-color: transparent;
    border-color: transparent;
}
.chatsetting-toggle:focus{
  outline: 0px !important;
  border: 0px !important;
  color: #fff !important;
}
.chatsetting-toggle:hover {
  background-color: transparent;
    border-color: transparent;
}
/* .chatdisplay{
  display: flex;
  padding: 4px;
}
.M-Left{
  margin-left:16px;
} */
.chat_settingwidth{
  width:217px;
  transform: translate(-150px, 40px) !important;
  margin-right: -150px !important;
  margin-bottom: 25px !important;
  font-weight: 500
}
.p-pd{
  padding:4px;
}

.common-chat-module .comment-here-box{
  padding-left: 28px;
}

.tox-tinymce{
  left: 701px !important;
}
.reaction-chat-wrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
  color: #5b6c97;
  position: absolute;
  /* background: transparent; */
  background-color: #efefef;
  bottom: -0.5rem;
  left: -8px;
  border-radius: 8px;
}
.chat-left{
  left: -7rem !important;
}
.Date-Band{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.Date-Band span{
  display: flex !important;
  background-color: #00a1ff36 !important;
  padding: 0px 7px !important;
  box-shadow: 0px 2px 3px -1px gray !important;
  border-radius: 5px !important;
}
.chat-container-body{
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  padding-bottom: 2rem !important;
  flex-direction: column-reverse;
}
.reply-chat{
  justify-content: flex-start !important;
}
.Reply-Container{
  display: flex;
  flex-direction: column;
  padding: 5px 6px;
  background-color: #ffffff;
  border-radius: 10px;
}
.Reply-Text-Wrapper{
  display: flex;
  color: #018ee0;
}
.Reply-Text-Wrapper .Reply-Paragraph{
  border-left: 3px solid #018ee0;
  padding: 0px !important;
  margin: 0px !important;
  margin-bottom: 3px !important;
  box-shadow: 0px 0px 1px black;
}
.Reply-Container .Main-Paragraph{
  margin: 0px !important;
}
.Reply-Paragraph .user{
  margin-left: 5px;
}
.massage-body-wrapper,.Reply-Container{
  position: relative;
  box-shadow: 0px 3px 18px -5px grey;
}
.massage-body-wrapper .Options-Icon,
.Reply-Container .Options-Icon{
  position: absolute;
  z-index: 1;
  min-width: 20px;
  max-width: max-content;
  display: none;
  height: 20px;
  background-color: #ffffff;
  right: 0rem;
  top: -1.3rem;
  align-items: center;
  justify-content: center;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  box-shadow: 0px 3px 18px -5px grey;
}
.massage-body-wrapper:hover .Options-Icon{
  display: flex !important;
}
.Reply-Container:hover .Options-Icon{
  display: flex !important;
}
.content .message-input .wrap button{
  float: right !important;
  border: none !important;
  display: flex !important;
  width: 15px !important;
  /* height: 23px; */
  height: 15px !important;
  padding: 2px !important;
  margin: 1px !important;
  cursor: pointer !important;
  color: #f5f5f5 !important;
  background-color: rgb(1, 142, 224) !important;
  border-radius: 0px !important;
  align-items: center !important;
}
.content .message-input .wrap button .IconButton__icon___3YgOS{
  width: 100% !important;
    height: 100% !important;
    background-position: 50% !important;
    background-repeat: no-repeat !important;
    background-size: 15px !important;
}
.content .message-input .wrap .ButtonGroup__root___3lEAn{
  margin: 0px !important;
}
.Chat-Input-Wrapper{
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  align-items: center;
}
.Chat-Input-Wrapper .Input-Box{
  width: 100%;
}
.Inpute-Body{
  display: flex;
  align-items: center;
  position: relative;
}
.Inpute-Body .Buttons-Groups{
  position: absolute;
    right: 0.5rem;
    display: flex;
    /* border: 1px solid #00a2ff; */
    padding: 3px;
    border-radius: 50%;
    background-color: #ffffff;
}
.Chat-Input-Wrapper .Pinned-Reply-Massage-Wrapper{
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 3.5rem;
  background: white;
  padding: 0px;
  border-left: 2px solid #018bdc;
  border-radius: 10px;
}
.Chat-Input-Wrapper .Pinned-Reply-Massage-Wrapper .massage-body-wrapper{
  padding: 10px;
  margin: 0px;
  min-width: 5rem !important;
}
.Pinned-Message-Wrapper{
  display: flex;
  width: 100%;
  height: max-content;
  align-items: center;
  justify-content: center;
}
.Pinned-Message-Wrapper .Pinned-Message-Body{
  display: flex;
  width: 97%;
  align-items: center;
  justify-content: space-between;
  border-left: 3px solid #27cda5;
  padding: 5px;
  box-shadow: 0px 3px 18px -5px grey;
  background-color: white;
  margin-top: 3px;
  border-radius: 5px;
}
.Pinned-Message-Body .time-wrapper{
  display: flex;
  gap: 18px;
  align-items: center;
}
.Pinned-Chat-Class{
  border-left: 3px solid #27cda5;
}
.All-Reaction-List-Icon{
  display: flex;
  position: relative;
}
.Pinned-Message-Body p{
  margin: 0px;
}
.Pinned-Chat-Class .Pin-Icon{
  display: flex;
    position: absolute;
    height: 100%;
    z-index: 1;
    /* top: 1rem; */
    justify-content: center;
    opacity: 0.3;
    align-items: center;
}
.reply-chat .Pinned-Chat-Class .Pin-Icon{
  right: -2rem !important;
  top: 0px !important;
}
.main-chat .Pinned-Chat-Class .Pin-Icon{
  left: -2rem ;
  top: 0px;
}
.Pinned-Chat-Class .Pin-Icon img{
  margin: 0px !important;
  padding: 0px;
}
/* .ant-dropdown{
  z-index: 9000 !important;
} */
.replies .CheckBox{
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  left: 0px;
}
.Selected-Chat{
  background-color: #d0e5ff78;
}
.contact-profile .Actions-For-Selection{
  display: flex;
  position: absolute;
  align-items: center;
  height: 100%;
  justify-content: end;
  right: 0px;
}
.contact-profile .Actions-For-Selection .Selected-User-Actions{
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.contact-profile .Actions-For-Selection .Fade-Btn{
  opacity: 0.5;
  cursor: pointer;
}
.contact-profile .Actions-For-Selection .Fade-Btn:hover{
  opacity: 1;
}
.Inpute-Body .Buttons-Groups img{
  height: 1.5rem !important;
  width: auto;
  cursor: pointer;
}
.Inpute-Body textarea{
  text-indent: 4px;
  min-height: 3rem !important;
  height: 3rem;
  max-height: max-content !important;
  font-size: 1rem !important;
}
.attachment-icon{
  position: relative;
    right: 10px;
    top: 4px;
    font-size: 18px;
    color: #018ee0;
    background: white;
    cursor: pointer;
}
.text-search-field{
  width: -webkit-fill-available;
  height: 50%;
  border-radius: 10px;
  text-indent: 4px;
}
.Attachment-Btn{
  position: absolute;
    z-index: 1;
    right: 16px;
    top: -39px;
    padding: 5px;
    background-color: #018ee0;
    color: white;
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #018cdd;
    border-radius: 60px;
    /* box-shadow: 2px 2px 2px grey; */
    box-shadow: 0px 3px 18px -5px grey;
    font-weight: 500;
}
.display-user-name{
  display: flex;
  width: 100%;
  padding-left: 5px;
  font-style: italic;
  font-weight: bold;
  /* color: red; */
}
.Attachment-Wrapper-Chats img{
  width: 13rem !important;
  height: auto !important;
  border: none !important;
  margin: 0px !important;
  padding: 3px !important;
  border-radius: 0px !important;
}
.Group-Profile-Photo{
  display: flex;
  align-items: flex-start;
  padding: 5px;
  justify-content: center;
}
.Group-Profile-Photo img{
  margin: 0px !important;
  border: 1px solid #0188d7;
  padding: 2px;
}

.Attachment-Wrapper-Chats{
  position: relative;
  overflow: hidden;
}
.Attachment-Wrapper-Chats .overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-weight: 500;
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0); /* Adjust the overlay color and opacity */
  opacity: 1;
  transition: all 0.3s ease; /* Add a smooth transition effect */
}

.Attachment-Wrapper-Chats:hover .overlay {
  opacity: 1;
  color: rgb(0 0 0);
  font-weight: 500;
  background-color: rgba(214, 214, 214, 0.381); 
  content: "Preview";
}
.Inpute-Body .Buttons-Groups .scroll-icon{
  position: absolute;
  bottom: 3.5rem;
  font-size: larger;
  right: 0.6rem;
  opacity: 0.5;
}
.Inpute-Body .Buttons-Groups .scroll-icon:hover{
  opacity: 1;
}
.highlight {
  background-color: rgb(255, 255, 96);
  /* border: 1px solid lightgray; */
}
.Buttons-Groups .scrolldown{
  opacity: 0.5;
}
.Buttons-Groups .scrolldown:hover{
  opacity: 1;
}
.chat-pending-count{
  position: absolute !important;
  right: 0px !important;
}
.New-Message-Highlite{
  background-color: #d0e5ff78 !important;
}
#search input:focus::-webkit-input-placeholder {
  opacity: 0 !important;
}
#frame #sidepanel #contacts ul li.contact:hover .Chat-Close-Icon{
  opacity: 1;
}
#frame #sidepanel #contacts ul li.contact .Chat-Close-Icon{
  display: flex;
  position: absolute;
  right: 11px;
  opacity: 0;
  color: #4c646f;
  font-size: 15px;
}
#frame #sidepanel #contacts ul li.contact .Chat-Close-Icon:hover{
  cursor: pointer;
  color: red;
}
.Setting-In-Chat .ant-collapse-content{
  min-width: max-content !important;
}
.Setting-In-Chat .collapseOptionsSettings{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: max-content;
}
/* .collapseOptionsSettingsLi{
  position: relative;
  right: 2.5rem;
} */
.Setting-In-Chat{
  width: max-content;
}
.Setting-In-Chat .ant-collapse-expand-icon {
  display: none !important;
}
.scroll-down-icon{
  position: absolute;
  bottom: 3.5rem;
  right: 0.6rem;
  height: 1.1rem !important;
  width: 1.1rem !important;
}
.Setting-In-Chat .ant-collapse{
  width: max-content !important;
  position: relative !important;
  right: 4.2rem !important;
  top: -1rem !important;
  background: white !important;
}
.Setting-In-Chat .ant-popover-inner{
  position: relative !important;
  top: 1rem !important;
}
.Setting-In-Chat .ant-popover-inner-content{
  width: max-content !important;
  position: absolute !important;
  left: 4rem !important;
}