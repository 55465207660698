 .jodit-text-editor-wrapper .jodit-react-container{
    height: 90% !important;
  }
 .jodit-text-editor-wrapper .jodit-react-container .jodit-container{
    height: 100% !important;
    overflow: hidden !important;
  }
 .jodit-text-editor-wrapper .jodit-react-container .jodit-workplace{
    height: 91% !important;
    overflow: scroll !important;
  }

  .jodit-react-container .jodit-wysiwyg {
    height: 100% !important; 
    overflow: auto !important;
}
.jodit-react-container .jodit-add-new-line{
  display: none !important;
}
.email-buttons{
    display: flex;
    justify-content: right;
    gap: 10px;
    margin-top: 0.5rem;
}
.email-buttons .Attachmnets-Btn{
  display: flex;
  border: 1px solid #d9d9d9;
  padding: 5.5px 11px;
  color: #4e4e4e;
  border-radius: 13px;
  height: 1.8rem;
  line-height: 0.9rem;
  margin-top: 0.5rem;
  cursor: pointer;
  font-size: 0.9rem;
}

.email-buttons .disabled{
    width: max-content !important;
    background-color: #9f9e9e !important;
    border-color: #9f9e9e !important;
}
.Email-Body-Wrapper .jodit-wysiwyg p {
  margin-bottom: 0px !important;
}
.transitionCommentPopup .jodit-wysiwyg p , .Timer-Comment .jodit-wysiwyg p{
 margin-bottom: 0px !important;
}
.transitionCommentPopup .jodit-wysiwyg, .Timer-Comment .jodit-wysiwyg{
  max-height: 10rem !important;
  min-height: 200px !important;
  overflow-y: auto !important;
}
/* .jodit-ui-group{
  background-color: #018ee0 !important;
}
.jodit-icon{
  fill: #fff !important;
}
.jodit-toolbar-button__text , .jodit-toolbar-button__text span{
  color: #fff !important;
}
.jodit-toolbar-button__trigger svg{
  fill: #fff !important;
} */
/* .jodit-toolbar-button__button[aria-pressed=true]:not([disabled]){
  background-color:  hsla(0, 0%, 86%, .6) !important;
} */
.forward-content{
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}


