.Custom-Color-Picker-Wrapper .color-picker {
    display: flex;
    font-size: 10px;
    align-items: center;
}
.Custom-Color-Picker-Wrapper .iconPosition{
    position: relative;
    /* / margin-left: 1rem; / */
    left: 1.2rem;
  }
.Custom-Color-Picker-Wrapper span.circle {
    height: 20px;
    width: 20px;
    /* background: red; */
    display: block;
    border-radius: 50%;
    border: 1px solid gray;
    margin-right: 5px;
}
.Custom-Color-Picker-Wrapper .compactColorPicker{
    position: absolute; 
    top: 2rem;
    left: 0;
    z-index: 100
  }