.custom-edit-column button{
    height: 29px !important;
    font-size: 13px !important;
    padding: 0px 10px !important;
}

.custom-edit-column button:hover{
    font-size: 13px !important;
    padding: 0px 10px !important;
}

.custom-edit-column button:focus{
    font-size: 13px !important;
    padding: 0px 10px !important;
}

.editcol-searchpad{
    height: 78px;
    border-bottom: 1px solid #c3c3c3;
    padding-left: 18px;
    padding-right: 18px;
}

.editColumn-custom>.dropdown-menu {
    position: absolute;
      top: -2px !important;
      box-shadow: none;
      width: fit-content;
      max-width: fit-content;
      border: 1px solid #B0BED0 !important;
      border-radius: 6px;
      background-color: #ffffff;
      font-family: 'Avenir Next LT W02';
      max-height: 420px;
      padding: 0;
      overflow: hidden;
  }
  .editColumn-custom>.dropdown-menu>.dropdown-item {
    margin: 0px auto;
    width: 100%;
    /* color: #007bff !important; */
    color: #000 !important;
    padding: 5px 27px;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
  }
  .editColumn-custom>.dropdown-menu>.dropdown-item:hover {
    background-color: #EDF0F4;
  }
  .editColumnlist-text{
    color: #000;
      font-family: 'Avenir Next LT W02';
      font-size: 13px;
      font-weight: 400;
      cursor: pointer;
      padding: 5px 10px 3px 10px !important;
  }
  span.editColumnlist-label {
    white-space: nowrap;
    padding-left: 8px !important;
  }


  /* .editColumn-custom>.dropdown-menu .editColummn-list{
    padding: 0;
    height: calc(52vh - 94px);
    overflow-y: auto;
    width: max-content;
    padding-right: 50px;
    padding-left: 8px;
    margin-top: 6px;
  }

  .editColumnlist-text:hover .icon-grip-vertical{
    display: block; 
      width: 19px;
      margin-bottom: -19px;
      margin-left: -18px;
  } */

  .editColumn-custom>.dropdown-menu .editColummn-list{
    padding: 0;
    height: calc(52vh - 94px);
    overflow-y: auto;
    width: max-content;
    padding-right: 75px;
    padding-left: 8px;
    margin-top: 6px;
  }

  .editColummn-list .editColumnlist-text{
    display: flex !important;
  }
  .editColummn-list .editColumnlist-text .icon-grip-vertical{
    margin-top: 4px !important;
    color: #a7a7a7;
  }
  .editColumnlist-text:hover .icon-grip-vertical{
    display: block; 
      width: 19px;
      margin-bottom: -19px;
      margin-left: -18px;
  }