.PiplinesList-Wrapper {
    display: flex;
    height: 93%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 12px 0px;
    /* background-color: rgb(230, 230, 230); */
}

.PiplinesList-Wrapper .ant-collapse-item{
    border: none;
}
.PiplinesList-Wrapper .ant-collapse-content {
    border-top: 1px solid #e4e4e4 !important;
    overflow-y: auto !important;
    /* margin: 7px 0rem; */
    /* padding: 10px; */
}
.PiplinesList-Wrapper .ant-collapse-content-box{
    overflow: hidden !important;
}

.PiplinesList-Wrapper .ant-collapse-item {
    margin-bottom: 5px;
    border: 1px solid #e4e4e4 !important;
    border-radius: 9px !important;
}

.PiplinesList-Wrapper .ant-collapse {
    width: 100%;
    border-radius: 0px;
    border: none;
    background-color: white;
}
.PiplinesList-Wrapper .ant-collapse-arrow {
    padding: 3px;
    background: #5b6c97;
    color: white !important;
    font-weight: bold;
    border-radius: 12px;
}
.PiplinesList-Wrapper .Group1-Card {
    padding: 2px 18px;
    border-radius: 78px;
    background: aliceblue;
    font-weight: 600;
    margin-left: 10px;
}

.PiplinesList-Wrapper .ant-collapse .ant-collapse-header {
    align-items: center;
}
.TaskGroup2Card{
    overflow-x: auto;
    padding: 0px;
    margin: 0rem 0rem 1rem 1.8rem;
}
.TaskGroup2Card .task-card-title{
    display: flex;
    justify-content: start;
    padding: 0px;
}
.TaskGroup2Card .Group-Titel-Card{
    border-radius: 12px 12px 0px 0px;
    padding: 8px 3px 8px 25px;
    background: #5b6c97;
    cursor: pointer;
    color: white;
    min-width: 8rem;
    max-width: 15rem;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    bottom: 2px;
}
.TaskGroup2Card .HIDEROW{
   display: none;
}
.No-Border{
    border: none !important;
    background: transparent !important;
}
.No-Border .ant-select-selector{
    background: transparent !important;
}
.No-Border .ant-select-arrow{
    /* opacity: 0.4 !important; */
    display: none !important;
}
.No-Border .anticon svg, .Task-Modal-Body-Wrapper .priority-icon .anticon svg{
    width: 18px !important;
    height: 18px !important;
    vertical-align: initial !important;
}
.ant-select-selection-item .Icon svg , .ant-select-dropdown .ant-tag .Icon svg{
    width: 1.2rem !important;
    height: 1.2rem !important;
    opacity: 0.5;
}
.table-dropdown{
    width: auto !important;
}
.table-dropdown .ant-select-selector{
    box-shadow: none !important;
}
.Expand-Icon-Section .SHOW{
    display: block !important;
}
.Expand-Icon-Section .HIDE{
    display: none !important;
}
.TaskIdSpan{
    margin-left: 5px;
    /* opacity: 0.5; */
    color: #b5b5b5;
}
.Group-Titel-Card span{
    color: white;
}
.table-hover tr:hover .Task-View-Btn{
    display: flex;
    gap: 4px;
    cursor: pointer;
}
.Task-View-Btn{
    display: none;
    margin-left: 7px;
    height: auto;
    border: 0px;
}

.dragOptionIcon {
    visibility: hidden;
    position: absolute;
    top: 12px;
    right: auto;
    margin-left: 1rem;
} 
.column-header:hover .dragOptionIcon {
    visibility: visible;
}
.column-header:hover{
    cursor: move; 
}
.column-header{
    /* border-left: 1px solid lightgray !important;
    border-right: 1px solid lightgray !important; */
}

/* .PiplinesList-Wrapper .ant-select-selector .ant-select-selection-item .ant-row{
    display: flex !important;
    justify-content: center !important;
} */
 /* .priority-icon .anticon svg{
    height: 11px !important;
    width: 11px !important;
 } */
 .dependencyicon{
    /* width: 2em; */
    display: grid;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 11111 !important;
 }
 .dependencyicon svg{
    height: 13px !important;
    width: 14px !important;
    margin: 3px !important;
 }

 .Task-Table-TimePicker-Class svg{
    height: 15px !important;
    width: 15px !important;
}
.Task-Table-TimePicker-Class1{
    border:1px dashed #979595 !important;
    border-radius: 50%;
    padding: 0.3rem;
    cursor: pointer;
}
.Task-Table-TimePicker-Class1{
    cursor: pointer;
}
.Task-Table-TimePicker-Class .remove-icon svg{
    height: 11px !important;
    width: 11px !important;
    position: relative;
    left: 8px;
    z-index: 11;
}
 .Task-Table-TimePicker-Class1 svg{
    height: 14px !important;
    width: 17px !important;
}
.Task-Table-TimePicker-Class1 .remove-icon svg{
    height: 11px !important;
    width: 11px !important;
    position: relative;
    left: 8px;
    z-index: 11;
}
.Task-Table-TimePicker .ant-popover-inner-content{
    width: 16rem !important;
}
.Task-Table-TimePicker .ant-popover-content .ant-popover-inner{
    border-radius: 20px !important;
    padding: 1.2rem !important;
    background: #f6f6f6 !important;
}
.Task-Table-TimePicker .ant-popover-title{
    font-size: 17px !important;
}
.Task-Table-TimePicker .ant-popover-arrow::before{
    background-color: #fcfcfc !important;
}
.task-content-table {
    margin-bottom: 0px !important;
  }
.checkBoxClass{
    opacity:0;
}
.showCheckBox:hover .checkBoxClass{
    opacity: 1;
}
.Description-Popover .ant-popover-inner-content{
    min-width: 6rem !important;
    max-width: 12rem !important;
    width: auto !important;
}
.showCheckBox .checkBoxClass .ant-checkbox .ant-checkbox-inner{
    border-color: #1677ff !important;
    border-radius: 12px !important;
}
.TaskGroup2Card .table-dropdown .ant-select-clear{
    top: 15% !important;
    right: 40% !important;
    color: #5b6c97 !important;
}
.TaskGroup2Card .table-dropdown .ant-select-clear svg{
    width: 17px !important;
    height: 14px !important;
}
.Subtask-Table .TaskGroup2Card .No-Border img{
    cursor: pointer;
    height: 30px;
    width: 30px;
    border-radius: 50%;
}

.Add-task-Wrapper .Subtask-Table .ant-select-clear{
    top: 7% !important;
    right: 43% !important;
    color: #5b6c97 !important;
}
.Add-task-Wrapper .Subtask-Table .ant-select-clear svg{
    height: 13px !important;
    width: 13px !important;
}
.Category-CheckBox{
    position: absolute;
    z-index: 111;
    left: 2.2rem;
    top: 1.4rem;
}
.Category-CheckBox:hover{
    visibility: visible !important;
}

.checkboxAndSearch .Category-CheckBox{
    left: 2.5rem !important; 
    top: 0.4rem !important;
}

.Category-CheckBox .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    height: 2px !important;
    background-color: #000 !important;
}

.Project-Checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    height: 2px !important;
    background-color: #000 !important;
}
.Individual-Checkbox .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    height: 2px !important;
    background-color: #000 !important;
}

.Project-Checkbox {
    visibility: hidden;
}
.Group-Panel-hover .ant-collapse-header:hover .Project-Checkbox {
    visibility: visible;
}
.Project-Checkbox .ant-checkbox-checked, .Project-Checkbox .ant-checkbox-indeterminate{
    visibility: visible !important;
}
.Category-CheckBox .ant-checkbox-checked, .Category-CheckBox .ant-checkbox-indeterminate{
    visibility: visible !important;
}
.TaskGroup2Card .subtask-parent-name{
    position: relative;
    left: 6rem;
}
.TaskGroup2Card .Category-Row{
    margin: 1rem 1.8rem 0px 0px !important;
}
.Category-CheckBox{
    visibility: hidden;
}
.checkboxAndSearch .Category-CheckBox{
    visibility: visible;
}
.showCheckBox .Individual-Checkbox  .ant-checkbox{
    opacity: 0;
    transition: opacity 0.3s;
}
.showCheckBox:hover .Individual-Checkbox  .ant-checkbox{
    opacity: 1;
}
.Individual-Checkbox .ant-checkbox-checked {
    opacity: 1 !important;
}
.Group-Titel-Card .card-expand-icon{
    margin-top: 4px;
    margin-left: 5px;
}
.Group-Titel-Card .card-expand-icon svg{
    width: 12px !important;
    height: 12px !important;
}