.Compact-View-Wrapper{
    display: flex;
    height: 100% !important ;
    padding-left: 0px !important;
}
.Hover-Over{
    display: none !important;
}
.Compact-body-wrapper{
    /* //background-color: aqua; */
    overflow-x: scroll !important;
    width: 85.5% !important;
    display: flex;
    flex-direction: row;
}
.Deal-wrapper-div img{
    position: absolute;
    z-index: 1;
    top: 2rem;
    left: 5.5rem;
}
.Deal-wrapper-div .ag-popup {
    position: fixed !important;
    z-index: 1 !important;
}
/* .Verticle-Bar:nth-child(4) .ag-popup{
    position: fixed !important;
    z-index: 1 !important;
} */
.Deal-wrapper-div{
    /* background-color: rgb(171 196 252);
    border-top: 3px solid #018ee0; */
    /* border-radius: 8px; */
    max-width: 15rem !important;
    padding-left: 0px !important;
    padding-bottom: 5px !important;
}
.Deal-wrapper-div .ag-header-row:first-child{
    left: 15px !important;
}
.Deal-wrapper-div .ag-header-cell:nth-child(2){
    padding-left: 0px !important;
    margin-left: 0px !important;
}
.Deal-wrapper-div .ag-header{
    min-height: 8.8rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-top: 2px solid #018ee0  !important;
    /* border-radius: 0px 0px 12px 12px !important; */
}
.Deal-wrapper-div .ag-cell,
.Deal-wrapper-div .ag-header-cell{
    padding-right: 62px !important;
} 
.Deal-wrapper-div .ag-header .ag-pinned-left-header{
    display: flex !important;
    flex-direction: column !important;
    justify-content: flex-end !important;
}
.Deal-wrapper-div .ag-header .ag-pinned-left-header .ag-header-row{
    top: auto !important;
    height: 2.4rem !important;
    border-top: solid 1px;
    border-top-color: #babfc7;
    border-top-color: var(--ag-border-color, #babfc7);
}
.Deal-wrapper-div .ag-header-cell-resize{
    display: none !important;
}
.Deal-wrapper-div .ag-pinned-left-header,
.ag-row,
.ag-root-wrapper{
    /* border: none !important; */
    border-color: transparent !important;
}
.Deal-wrapper-div .ag-header-cell{
    background-color: transparent !important;
}

.Verticle-Bar{
    display: flex;
    flex-direction: column;
    margin-left: 10px !important;
    height: 100% !important;
    overflow-y:hidden ;
}
.Expand-Icon-Wrapp{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    width: 100%;
    color: white;
    border-radius: 3px;
    border-top: 2px solid #018ee0;
    background: #018ee0;
    font-weight: bold;
}
.Verticle-Header-Name{
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-orientation: mixed;
    font-weight: bold;
    margin-top: 25px;
    color: #014F7C;
}
.Expand-Icon-Body{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 5px;
    background-color: #f8f8f8;
    margin-top: 10px;
    align-items: center;
    border-top: 2px solid #00c83e;
    border-radius: 3px;
}
.Expand-Icon-Body .ag-header-icon{
    color: white !important;
}
.Expand-Icon-Body .ag-header{
    height: 5.5rem !important;
    min-height: 5.5rem !important;
}
.Expand-Icon-Body .ag-header .ag-header-row-column-group{
    height: 2.6rem !important;
}
.Expand-Icon-Body .ag-header .ag-header-row-column{
    height: 2.5rem !important;
}
.Expand-Icon-Body .ag-header-group-cell-with-group{
    background-color: #27cda5 !important;
    color: white !important;
}
.Compact-View-Wrapper .ag-body-viewport::-webkit-scrollbar {
    display: none !important;
}
.Compact-View-Wrapper .ag-body-viewport:nth-last-child()::-webkit-scrollbar{
    display: block !important;
}
.Expand-Icon-Body .ag-root-wrapper {
    padding-bottom: 23px !important;
}

.Deal-wrapper-div .ag-theme-alpine .ag-list{
    position: absolute;
    margin-left: 1.5em !important;
}