.outer{
  height: max-content !important;
  overflow: hidden !important;
  /* margin-bottom: 12px; */
  /* overflow-y: auto; */
  padding: 0 !important;
}
/* .outer{
    height: 475px;
    margin-bottom: 12px;
    overflow-y: auto;
} */
.active-deal{
    margin: 0 auto !important;
    /* width: calc(100% - 32px) !important; */
    flex: inherit !important;
    border: none !important;
}
.top-mar-16{
    margin-top: 16px;
}
.col-2-border{
    background-color: #fff;
    border: 2px solid #B0BED0 ;
    border-radius: 1rem;
    height: calc(100vh - 142px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: normal;
    padding-bottom: 10px;

}
.col-8-border{
    background-color: #fff;
    border: 2px solid #B0BED0 ;
    border-radius: 1rem;
    position: relative;
    
}

.container-box{
  background-color: #EDF0F4;
  overflow: hidden;
}

.deal-nav-link{
    color: #000 !important;
    font-size: 15px;
    font-weight: 600 !important;

}

.create-deal-button{
padding-top: 0.5px;
padding-bottom: 0.5px;
padding-right: 25px;
padding-left: 25px;
background-color: #fff;
color: #007bff;
font-size: 15px;
font-weight: bold;
}

.text-colr{
    color: #5b6c97;
}

.text-colr > .nav-item {
    color: #5b6c97 !important;
    /* Properties */
  }

  .merge-tabs{
      width: 50%;

  }

  .alldeal-icon{
    font-size: 20px;
    position: absolute;
    right: -27px;
    top: 8px;
  }

  .pinned-drop-icon{
    font-size: 20px;
    position: absolute;
    right: -17px;
    top: 0px;
  }

  .pad-right-40{
      padding-right: 40px !important;
  }

  /* #controlled-tab-example-tab-alldeals {
    padding-right: 50px !important;
    padding-left: 24px !important;
} */
  .right-padding-span-active{
      padding-right: 150px;
  }
  .right-padding-span{
    padding-right: 375px;
}

  .edit-colum-button,
  .edit-colum-button:focus,
  .edit-colum-button:hover{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 9px !important;
    padding-right: 25px !important;
    padding-left: 25px !important;
    background-image: url('../../assets/images/btn-gray-bg.png');
    background-repeat: repeat-x;
    color: #5b6c97 !important;
    border: 1px solid #B0BED0 !important;
    font-size: 16px !important;
    font-weight: bold !important;
     height: 34px;
  }

  .pad-r0{
      padding-right: 0;
  }

  .leftpad-6{
      padding-left: 6px;
      text-decoration: underline;
  }

  .mar-r15{
      margin-right: 15px;
  }

  /* #controlled-tab-example-tab-dobfiling{
      padding-right: 14px !important;
      margin-right: 0 !important;
      border-right: 1px solid #B0BED0;

  } */

  /* .alldealtabs > .nav-link{
    padding: 6px 36px 6px 12px;
    line-height: 34px;
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
    font-weight: bold;
    border-left: 0px !important;
   
    border-radius: 0;
    border-right: 1px solid #B0BED0 !important;
    
   
  } */

  .alldealtabs > .nav-link{
    /* padding: 6px 23px 6px 12px;
    line-height: 34px;
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
    font-weight: bold;
    border-left: 0px !important;
    background-color: #D0D4DF !important;
    border-radius: 0;
    border-right: 1px solid #B0BED0 !important; */
    padding: 6px 23px 6px 12px;
    margin-left: 0.6rem;
    line-height: 34px;
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
    font-weight: bold;
    /* border-left: 0px !important; */
    background-color: #D0D4DF !important;
    border-radius: 0;
    /* border-right: 1px solid #B0BED0 !important; */
  }



  .alldealtabs.nav-tabs .nav-item.show .nav-link,
  .alldealtabs.nav-tabs .nav-link.active{
    background-color: #fff !important;
  }
  /* .alldealtabs > .nav-link .active{
   
  } */
  
  /* .alldealtabs > .nav-link:hover{
    border-right: 1px solid #B0BED0 !important;
  } */
    
  .non-tab-div{
    position: absolute;
    top: 6px;
    right: 15px;
    width: 200px;
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
    font-weight: bold;

  }

  .non-tab-div-active {
    position: absolute;
    top: 6px;
    right: 15px;
    width: 200px;
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
    font-weight: bold;
}
  .line-height36{
    line-height: 36px;
    color: #0070C0 !important;
  }
  .margin-top33{
    margin-top: 33px;
    height: 100%;
  }

  .pin-icon{
    padding-right: 8px;
  }


.fav-star-icon{
  font-size: 15px;
  color: #FFC000;
  cursor: pointer;
}

.unfav-star-icon{
  font-size: 15px;
  color: #b3b3b3;
  cursor: pointer;
}

.pop-asbes ul {
    width: 200px;
    padding-top: 15px;
}
  .pop-asbes li{
    font-family: 'Avenir Next LT W02' !important;
    color: rgb(119, 119, 139);
    padding: 2%;
    margin-top: 1%;
    margin-bottom: 1.5%;
    font-size: 1rem;
  
  }
  
  .asbes-list{
    padding-left: 0px;
    padding-top: 10px;

  }
  .asbes-list li:hover{
    background-color: #EDF0F4;
  }
  .asbes-list li{
    padding-left: 25px;
    padding-right: 25px;
    font-size: 15px;
    font-family: 'Avenir Next LT W02' !important;
  }
  
  .pop-deals li{
    font-family: 'Avenir Next LT W02' !important;
    color: rgb(119, 119, 139);
    padding-left:20px ;
    margin-top: 1%;
    margin-bottom: 1.5%;
    font-size: 15px;
    width: 190px;
  }

  .pop-deals ul{
    padding-top: 10px;
    padding-left: 0;
    font-size: 15px;
  }

  .pop-deals li:hover{
    background-color: #EDF0F4;
  }

  .bs-popover-auto[x-placement^=bottom]>.arrow, .bs-popover-bottom>.arrow .pop-deals {
     /* top: calc(0px 0px); */
  }
  .bs-popover-auto[x-placement^=bottom]>.arrow::after, .bs-popover-bottom>.arrow::after.pop-deals {
    top: 0px;
  }
  
  .addView-drop{
    width: 500px;
  }
  .addView-drop li{
    font-family: 'Avenir Next LT W02' !important;
    color: black;
    padding: 3%;
    
    margin-top: 1%;
    margin-bottom: 1.5%;
    font-size: 1rem;
  }
  .addView-drop ul{
    list-style-type: none;
  }
  
  
  .addView-list li{
    font-family: 'Avenir Next LT W02' !important;
    padding: 3px 15px;
    font-family: Arial;
    color: #000;
  }
  .addView-list  li:hover{
    background-color: #EDF0F4;
    cursor: pointer;
  }
  .addView-list{
    padding: 0px 0;
      margin: 0;
  }
  ul .addView-list{
    width: 200px;
  }
  .cursor-point{
    cursor: pointer;
  }
  .pad-left20{
    padding-left: 20px;
  }
  .pad-right10{
    padding-right: 10px;
  }
  .font-size20{
    font-size: 20px;
  }

  .unpin-tab{
    padding-left: 24px;
  }

  #controlled-tab-example-tab-addview{
    padding: 6px 0px 6px 12px;
    border-right: 0;
    background-color: #fff;
  }
  #controlled-tab-example-tab-addview.active{
    border-bottom: 1px solid #B0BED0;
    
  }

  #controlled-tab-example-tab-blank{
    border-bottom-color: #fff;
  }

  .react-datepicker-wrapper {
    display: flex !important;
}

  .alldealtabs{
    border-bottom: 1px solid #B0BED0;
  }

  .filter-expand-lefticon{
    position: absolute;
    left: -2px;
    width: 52px;
    cursor: pointer;
    top: -2px;
    z-index: 9;
  }
  .filter-expand-lefticonactive {
    position: absolute;
    left: -2px;
    width: 52px;
    cursor: pointer;
    top: -2px;
    z-index: 9;
}

.editColumn>.dropdown-menu {
  position: absolute;
    top: -2px !important;
    box-shadow: none;
    width: 200px !important;
    max-width: fit-content;
    border: 1px solid #B0BED0 !important;
    border-radius: 6px;
    background-color: #ffffff;
    font-family: 'Avenir Next LT W02';
    max-height: 420px;
    padding: 0;
    overflow: hidden;
}
.editColumn>.dropdown-menu>.dropdown-item {
  margin: 0px auto;
  width: 100%;
  /* color: #007bff !important; */
  color: #000 !important;
  padding: 5px 27px;
  font-family: 'Avenir Next LT W02';
  font-size: 15px;
}
.editColumn>.dropdown-menu>.dropdown-item:hover {
  background-color: #EDF0F4;
}
.editColumnlist-text{
  color: #000;
    font-family: 'Avenir Next LT W02';
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    padding: 5px 10px 3px 10px !important;
}
span.editColumnlist-label {
  white-space: nowrap;
}
.mar-r10{
  margin-right: 10px;
}

.mar-0{
  margin: 0px;
}

.edit-colum-button::after{
display: none;
}
.alltab{
  padding: 6px 36px 6px 12px;
  line-height: 34px;
  font-size: 13px;
  font-family: 'Avenir Next LT W02';
  font-weight: bold;
  border-right: 1px solid #ffffff !important;
  border-bottom: 1px solid #ffffff !important;
  border-radius: 0;
  background-color: white !important;
}

.hieght85{
  height:85%;
}
.height-overflow85{
  height: 85%;
  overflow-y: auto;
}


.star-display{
  /* display: none; */
  visibility: hidden;
}
.star-display1{
  /* display: none; */
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
}


.star-display1 svg:hover{
  overflow: hidden !important;
}

.favtablist:hover .star-display{
  /* display: inline; */
visibility: visible;
}

.favtablist:hover .star-display1{
visibility: visible;
  display: flex;
  flex-direction: row;
}


.icon-grip-vertical{display: none;}

.editColumnlist-text:hover .icon-grip-vertical{
  display: block; 
    width: 19px;
    margin-bottom: -19px;
    margin-left: -18px;
}

  .triangle-topleft {
    width: 0;
    height: 0;
    border-top: 25px solid #ffc000;
    border-right: 25px solid transparent;
  }
  
  .cc {
    margin-top: -35px;
    padding: 8px;
    position: absolute;
    color: white;
    font-size: 10px;
}


.star-display2{
  /* display: none; */
  visibility: hidden;
}

.favtablist:hover .star-display2{
  visibility: visible;
  display: flex;
  flex-direction: row;
}

.padding-newview{
  padding-left: 26px;
}
.favtablist:hover .padding-newview{
  padding-left: 0px;
}
.mar-bottom0{
  margin-bottom: 0px;
}
.pad-l45{
  padding-left: 45px;
}
.fontsize14{
  font-size: 14px;
}

.editcol-searchpad{
  padding: 10px;
    position: sticky;
    background: white;
    margin: 0;
}
.editcol-searchpad .search-ico{
  top: 16px;
  font-size: 13px;
  right: 22px;
}
.editColumn>.dropdown-menu .editColummn-list{
  padding: 0;
  height: calc(52vh - 94px);
  overflow-y: auto;
  width: max-content;
  margin-top: 6px;
}
.font14{
  font-size: 14px;
}

.ag-tabs-header .ag-tab:nth-of-type(3) {
  display: none;
}

.notify-interconnection-text{
  font-weight: bold;
    font-size: 12px;
  color: red;
}

.clear-filter{
    float: right;
    margin-right: 15px;
    padding: 2px 5px;

}
.editColumn>.dropdown-menu .hr-line{
  margin: 5px 0;
  border: 0;
}

.deal-module{
  padding: 0px !important;
}

.deal-module .active-deal .alldealtabs a:first-child{
  display: none !important;
}
.deal-module .active-deal .alldealtabs a:nth-child(2){
  /* border-top-left-radius: 13px !important; */
  margin-left: 1.1rem;
}
.client-module .active-deal .alldealtabs a:first-child{
  display: none !important;
}
.client-module .active-deal .alldealtabs a:nth-child(2){
  /* border-top-left-radius: 13px !important; */
  margin-left: 1.1rem;
}

.deal-status-dropdown-custom ul{
  text-align: center;
  padding: 5px;
}

.deal-status-dropdown-custom ul li{
    border-radius: 4px;
    padding: 0px 10px;
    display: flex;
    height: 30px;
    align-items: center;
    margin-bottom: 5px;
}
.deal-status-dropdown-custom button:hover{
  color: #fff;
}

.deal-status-dropdown-custom button{
  border-radius: 30px;
  background-color: #fbc11e;
    height: 24px;
    padding: 0 8px;
    font-weight: 600;
    min-width: 80px;
    color: #fff;
}

.deal-status-dropdown-custom ul{
  min-width: 0px !important;
}

.deal-status-dropdown-custom ul li a{
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  padding: 0px !important;
  color: #fff;
}

.deal-status-dropdown-custom ul li a:hover{
  background-color: unset !important;
  color: #fff;
}


.deal-status-dropdown-custom button::after{
  visibility: hidden;
}

.deal-status-dropdown-custom .account-name-tile{
  white-space: nowrap;
  width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;
}

.deal-status-dropdown-custom button span{
  display: inherit;
}

.deal-status-dropdown-custom button:hover::after {
  visibility: visible;
  position: absolute;
  right: 10px;
  top: 10px;
}

.deal-status-dropdown-custom-name button:hover::after {
  visibility: hidden;
}

.deal-status-dropdown-custom-client button:hover::after {
  visibility: hidden;
}
/* .deal-status-dropdown-custom button{
  visibility: hidden;
} */
/* .deal-status-dropdown-custom button::after {

  position: relative;
  float: right;
  top: 10px;
} */






.ag-row {
    z-index: 0;
}

.ag-row.ag-row-focus {
    z-index: 1;
}
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height {
    overflow: visible;
}

.non-tab-div .editColumn .edit-colum-button{
  width: 12.4rem !important;
}
.non-tab-div .editColumn .edit-colum-button::after{
 margin-left: 15%;
}
.ant-modal-header,
.ant-modal-footer{
  border-radius: 12px !important;
}
.modal-backdrop{
  background-color: transparent !important;
}
.Edit-Column-Modal .modal-content{
  width: 17rem !important;
  position: absolute !important;
  overflow: hidden !important;
  height: 33rem !important;
  left: 153% !important;
  top: 6px;
}
.modal-content {
  border: 2px solid #e5e5e5 !important;
}
.Edit-Column-Modal .modal-content ul{
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    padding: 0px !important;
    height: 21rem !important;
}
.Eye-Icon-Custome svg{
  position: absolute !important;
  height: 100% !important;
  opacity: 0.4 !important;
  top: 1px !important;
  margin-left: 10px !important;
}
.Eye-Icon-Custome svg:hover{
  opacity: 1 !important;
}

.render-status{
  min-width: max-content !important;
}

.ag-theme-alpine .ag-select .ag-picker-field-wrapper{
  display: flex !important;
}

.search-filter .search-deal{
  padding: .5rem .5rem !important;
  height: calc(0.8em + .75rem + 2px) !important;
}
.ant-switch{
  border: 1px solid #018EE0 !important;
  border-radius: 3px !important;
  width: 5.5rem !important;
  height: 26px !important;
  background-color: #fff !important;
}
.ant-switch-handle::before{
  width: 0.7rem !important;
  height: 0.7rem !important;
  top: 5px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 5px !important;
  background: #018EE1 !important;
} 
.ant-switch-inner{
  color: #018EE0 !important; 
  font-weight: bold !important;
}
.deal-list-wrap .pad-col{
  position: relative !important;
    top: -72px !important;
}
.client-list-wrap .pad-col{
  position: relative !important;
    top: -72px !important;
}
.create-dealbox{
  display: flex !important;
  position: relative !important;
  z-index: 1 !important;
  justify-content: end !important;
  width: 100%;
}
.example-wrapper .ag-theme-alpine{
 height: calc(100vh - 170px) !important;
}
.client-list-wrap .example-wrapper #myGrid {
 height: calc(100vh - 170px) !important;
}
.deal-list-wrap .example-wrapper #myGrid {
 height: calc(100vh - 170px) !important;
}
.input-search-wrapper{
  display: flex;
  flex-direction: row;
  min-width: max-content;
  max-width: max-content;
  align-items: center;
  justify-content: center;
}
.search-icon-wrapper{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0.5rem;
}
.search-icon-wrapper svg{
  margin-top: 2px !important;
}
.search-icon-wrapper .search-ico{
  position: relative !important;
  /* right: 5px;
  top: 5px; */
  top: 0px !important;
  right: 0px !important;
  color: #2D2D2C;
  cursor: pointer;
}

.search-filter{
  padding: 4px 30px 3px 30px !important;
}
.list-compact-view{
  display: flex;
  flex-direction: row;
  border: 1px solid #018EE0;
  border-radius: 4px;
  /* padding: 4px; */
  cursor: pointer;
  width: 14.2rem;
}
.list-view{
  /* background: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  border-radius: 4px;
  width: 50%;
  padding: 4px;
  font-weight: bold;
  font-family: 'Avenir Next LT W02';
  text-align: center;
}
.compact-view{
  /* background: #e0d5d5; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: bold;
  padding: 4px;
  font-family: 'Avenir Next LT W02';
  text-align: center;
}

.ag-theme-alpine .ag-popup{
  position: absolute;
  z-index: 111 !important;
}

.ag-theme-alpine .ag-ltr .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-ltr .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell, .ag-theme-alpine .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-theme-alpine .ag-rtl .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-theme-alpine .ag-rtl .ag-has-focus .ag-full-width-row.ag-row-focus .ag-cell-wrapper.ag-row-group, .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell, .ag-theme-alpine .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle{
  border: 0px !important; 
  border-color: none !important; 
  outline: none !important;
}

.faded-deal{
  opacity: 0.3 !important;
}
.faded-deal svg{
  visibility: hidden;
}

.task-group-tabs .deal-list-wrap .list-compact-view{
  display: none !important;
}

.vendor-list-wrap .pad-col{
  position: relative !important;
    top: -72px !important;
}

.vendors-module .active-deal .alldealtabs a:first-child{
  display: none !important;
}
.vendors-module .ag-root-wrapper{
  height: 100vh !important;
}