.Actvity-Items-Wrapper{
    padding: 20px 40px;
    /* background: rgb(249 249 249); */
    border-radius: 10px;
    max-height: 100vh;
    height: auto;
    /* overflow-y: hidden; */
}
.Activity-Data-Wrapper{
    display: flex;
    gap: 20px;
}
.taskNameClass{
    color:rgb(64 169 255);
    cursor:pointer;
}
.taskNameClass:hover{
    text-decoration:underline;
    color:rgb(2, 117, 210);
}
.Activity-Data-Wrapper .User-Image{
    cursor: default;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid #e3e3e3;
    padding: 3px;
}
.Activity-Data-Wrapper .activity-message{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0.3rem;
}
.Activity-Data-Wrapper .activity-date{
    color: #6d6d6d;
    font-style: italic;
}
.LoadMore-Class{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 16px 0px;
    cursor: pointer;
    font-weight: 600;
    color: #7dc5ff;
}
.LoadMore-Class:hover{
    color: #3ea8ff;
}
.Activity-Priority{
    display: inline-flex;
    align-items: center;
    font-weight: bold;
}

.Activity-Priority .anticon svg{
    height: 14px !important;
    width: 14px !important;
}
.activity-message .old-status, .activity-message .new-status{
    border-radius: 6px;
    padding: 3px 15px;
    white-space: nowrap;
    max-width: 9rem;
    font-size: 11.7px;
    font-weight: 500;
    position: relative;
    top: 8px;
    cursor: default;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .activity-message .new-status{
    border-radius: 6px;
    padding: 3px 15px 5px;
    white-space: nowrap;
    max-width: 9rem;
    font-size: 13.7px;
    font-weight: 600;
    position: relative;
    top: 8px;
    cursor: default;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
} */
.notifications-hyperlinks .old-status, .notifications-hyperlinks .new-status{
    border-radius: 6px;
    padding: 3px 15px;
    white-space: nowrap;
    max-width: 9rem;
    font-size: 11.7px;
    font-weight: 500;
    position: relative;
    /* top: 8px; */
    cursor: default;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .notifications-hyperlinks .new-status{
    border-radius: 6px;
    padding: 3px 15px 5px;
    white-space: nowrap;
    max-width: 9rem;
    font-size: 11px;
    font-weight: 600;
    position: relative;
    top: 8px;
    cursor: default;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
} */
span.to-text{
    position: relative;
    bottom: 8px;
}
