/* .example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  #myGrid {
    flex: 1 1 0px;
    width: 100%;
  } */

  .view-name{
    /* display: flex;
    flex-direction: row; */
    white-space: nowrap;
    width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    /* margin-left: 1.7rem; */
  }

  /* .view-name:hover{
    margin-left: 0px !important;
  } */
  .favtablist:hover {
    display: flex;
    flex-direction: row;
  }
  
  .example-header {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 13px;
    padding: 13px;
  }

  .tablefolder-icon{
    font-size: 20px;
    color: #5b6c97;
    margin-left: 16px;
    margin-top: -3px;
    cursor: pointer !important;
}

.underline-text{
    text-decoration: underline;
    font-weight: bold;
    color: #0070C0;
}
.underline-orange{
    text-decoration: underline;
    font-weight: bold;
    color: #F28C28;
}

.pad-icon{
    font-size: 20px;
    margin-right: 15px;
    margin-left: 15px;
    cursor: pointer;
    color: #B0BED0;
    }

.pad-icon-active{
        font-size: 20px;
        margin-right: 15px;
        margin-left: 15px;
        cursor: pointer;
        color: #5B6C97;
    }

.pad-icon-circle{
    font-size: 7px;
    margin-right: 15px;
    margin-left: 15px;
    color: #A6A6A6;
}

.pad-icon-circle-active{
    font-size: 7px;
    margin-right: 15px;
    margin-left: 15px;
    color: #5B6C97;
}

/* .ag-paging-panel {
     display: none; 
} */

.pad-left100{
    padding-left: 100px;
}

.pad-left55{
    padding-left: 55px;
}

.dealtable-dropdown {
    border: 1px solid #B0BED0;
    color: #5B6C97;
    border-radius: 5px;
    padding: 4px 7px 3px 7px;    
    outline: none;
    float: left;
}
.ag-header-cell{
    background-color: #F3F6FA;
    color:#5B6C97;
}

.ag-header-cell:hover{
    background-color: #DDE3EB;
    color:#5B6C97;
}

.ag-header-cell-label {
    justify-content: center;
 }
 .ag-theme-alpine .ag-header{
    border-top: 1px solid #babfc7 !important;
 }

 /* .ag-theme-alpine .ag-row-odd{
    background-color: #f7f7f7 !important;
 } */

.ag-theme-alpine .ag-header-cell-resize::after {
 
    left: calc(50% - 2px);
    width: 4px;
    height: 80%;
    top: calc(50% - 40%);
 
}





 .ag-checkbox-input-wrapper::after {
    outline: none;
    border: 0 solid #B0BED0;
    color: #B0BED0 !important;
}

.dot-fix {
    width: auto;
    float: left;
    padding-top: 2px;
}

.rownum-fix {
    width: auto;
    float: left;
    padding-top: 4px;
    color: #5B6C97 ;
}

 .ag-paging-panel {

    color: #5B6C97 !important;
    
}

.tablesearchdiv-active {
    padding-right: 88px;
    padding-left: 60px !important;
}
.tablesearchdiv {
    padding-right: 54px;
    padding-left: 60px !important;
}

.dealtablesearchdiv-active {
    padding-right: 88px;
    padding-left: 16px !important;
}
.dealtablesearchdiv {
    padding-right: 54px;
    padding-left: 16px !important;
}

.deal-table-style{
    height: 350px;
    width: 100% ;
    padding: 15px;
    padding-top: 0; 
}

#controlled-tab-example2-tab-addview{
    background-color: #fff !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 0px !important;
}

#controlled-tab-example2-tab-addview:hover{
    border-right: none !important;
}

.alldealtabs.nav-tabs > .nav-link{
    line-height: 34px;
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
    font-weight: bold;
    box-shadow: inset 1px 0px 12px -3px #cbcbcb !important;
    background-color: #fff !important;
    margin-left: 0.5rem;
    color: #222121 !important;
    width: 8rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

/* .view_Tabs:hover .close-tab-icon{
    display: block !important;
} */

.alldealtabs.nav-tabs .nav-link:not(:nth-child(1)){
    width: 10.9%;
}


.alldealtabs.nav-tabs .nav-link.active{
    position: relative !important;
    /* box-shadow: inset 1px 0px 12px -3px #cbcbcb !important; */
    color: #fff !important;
    background-color: #018ee0 !important;
}
.alldealtabs.nav-tabs .nav-link.active:hover .close-tab-icon{
    display: block  !important;
} 

/* .alldealtabs.nav-tabs .nav-link.active .unpin-tab > svg{
    opacity: 0;
}
.alldealtabs.nav-tabs .nav-link.active:hover .unpin-tab > svg {
  opacity: 1 !important;
} */

.close-tab-icon svg{
    color: #fff;
    background: red;
    border-radius: 50%;
}
.close-tab-icon{
    position: absolute;
    top: -7px;
    left: 4px;
    display: none;
    font-size: 12px;
    z-index: 100;
}

.alldealtabs.nav-tabs .nav-link:nth-child(2) .close-tab-icon{
    left: 18px !important;
}

 /* .unpin-tab{
    padding-left: 0px !important;
} */

.drpdwn{
    justify-content: center;
}
.pinned-drop-icon{
    background: transparent !important;
    padding: 3.5px !important;
    color: #fff !important; 
    width: 15% !important;
    height: 100% !important;
}

a#controlled-tab-example2-tab-addview {
    border-right: none !important;
    border-left: none;
    border-top: none;
    box-shadow: none !important;
}

.ant-collapse-content > .ant-collapse-content-box{
    padding: 0px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
    padding: 7px 7px !important;
}
.anticon svg{
    width: 11px !important;
    height: 11px !important;
    vertical-align: initial !important;
}

.ant-popover-content{
    position: relative !important;
    left: 20px !important;
}

.ant-popover-inner-content{
   width: 200px !important;
}

.addView-list li{
    display: flex !important;
    flex-direction: row !important;
    height: 30px !important;
}
.ant-collapse-content-box li{
    padding: 3px 15px !important;
    display: flex;
}
.fav-tab{
    display: block;
    white-space: nowrap;
    width: 6.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ant-collapse-content-box li span{
font-family: 'Avenir Next LT W02';
}

.tabs-wrapper .ant-collapse-content-active{
    list-style: none !important;
    max-height: 20vh !important;
    overflow-y: auto !important;
}

.favtablist:hover .star-display1{
    visibility: visible !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
}
.alldealtabs{
    display: flex !important;
    flex-wrap: nowrap !important;
    padding-left: 0 !important;
    margin-bottom: 0 !important;
    list-style: none !important;
    overflow-x: auto !important;
    width: 75% !important;
    overflow-y: hidden !important;
    border-bottom: 0px !important;
    position: relative !important; 
    z-index: 111 !important;
}

.alldealtabs.nav-tabs .nav-link.active{
    display: flex !important;
    position: sticky !important;
    right: 105px !important;
}
#controlled-tab-example2-tab-addview{
    display: flex !important;
    position: sticky !important;
    right: 0px !important   ;
}

.text-truncate{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: 85px !important;
    text-align: center !important;
}

.alldealtabs.nav-tabs .nav-link.active .text-truncate:hover .close-tab-icon{
    visibility: visible  !important;
}

/* .clientAddView .ant-popover-placement-bottom{
    top: 97px !important;
    transform-origin: 50% -4px !important;
} */

.alldealtabs::-webkit-scrollbar{
    width: 0px !important;
    height: 0px !important;
    /* visibility: hidden !important; */
}
.alldealtabs::-webkit-scrollbar-thumb:hover{
    /* border: 1px solid #fff !important; */
    /* bord */
    /* visibility: hidden !important; */
}

.ant-collapse-content .ant-collapse-content-active{
    height: 250px !important;
    overflow-y: scroll !important;
}

.fav-icon{
    color: #FFC000;
    cursor: pointer;
  }

.check-custom-wrapper{
    display: flex !important;
    align-items: center !important;
}

.check-custom-wrapper .form-check-input{
    margin-top: 0px !important;
    margin-left: 0px !important;
}