.ant-select-arrow .anticon{
    font-size: 10px !important;
}
.ant-select-arrow {
    right: 3.7px !important;
}
.ant-select-selector:hover {
    border: 0px !important;
}
.ant-select-selector{
    border: 0px !important;
}
.ant-select-arrow{
    color: black !important;
}
.ant-select{
    border: 1px solid #cdcdcd !important;
    border-radius: 5px !important;
}
.ant-select:focus{
    color: #495057 !important;
    background-color: #fff !important;
    border-color: #80bdff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25) !important;
}
.tile-span .remove-tile{
    /* position: relative;
    height: 12px;
    bottom: 6px;
    left: 9px;
    color: rgb(250, 43, 43);
    background-color: #fff;
    border-radius: 50%; */
    visibility: hidden;
} 
.tile-span:hover .remove-tile{
    visibility: visible;
    position: relative;
    height: 12px;
    bottom: 6px;
    left: 9px;
    color: rgb(250, 43, 43);
    background-color: #fff;
    border-radius: 50%;
} 
.tile-span{
    display: flex;
    flex-direction: row;
}
