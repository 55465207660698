.Custome-Inpute-Wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Custome-Inpute-Wrapper .Input-Wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
}
.Custome-Inpute-Wrapper .Select-Wrapper{
    display: flex;
    padding: 5px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 10;
    left: 1rem;
    top: 2rem;
    border: 1px solid #e3e3e3;
    background-color: #f3f3f3;
    border-radius: 5px;
    font-size: 14px;
}
.Custome-Inpute-Wrapper .selected-value-card{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    font-size: 14px;
    margin: 2px;
    padding: 2px;
    padding: 0px 5px;
    border: 1px solid #e4e4e4;
    background-color: #f6f6f6;
    border-radius: 32px;
}
.Custome-Inpute-Wrapper .selected-value-card span:nth-child(3){
    position: absolute;
    right: 6px;
    height: 100%;
    display: flex;
    align-items: center;
}
.Custome-Inpute-Wrapper .selected-value-card span:nth-child(1){
    height: 100%;
    display: flex;
    margin-left: 3px;
    margin-right: 3px;
    align-items: center;
}
.Custome-Inpute-Wrapper .selected-value-card span:nth-child(2){
    overflow: hidden;
    align-items: center;
    width: 76%;
    height: 100%;
    text-align: center;
    text-overflow: ellipsis;
}
.Custome-Inpute-Wrapper .inpute-col{
    /* border-bottom: 1px solid #f6f6f6; */
}
.Custome-Inpute-Wrapper .Custome-Row{
    border: 1px solid #f6f6f6;
}
.Custome-Inpute-Wrapper .Custome-Row .Titels{
    color: #a2a9ad;
    font-weight: bold;
    background-color: #fafafa;
}
.Values-Dropdown .value-card{
    cursor: pointer;
}