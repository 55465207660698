.DayViewWrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 85%;
    padding: 0px 5px;
    font-size: 13px;
    overflow: scroll;
}

.DayViewWrapper .Day-Wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.DayViewWrapper .Day-View-Content{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: scroll;
    border-bottom: 1px solid #babfc7;
}
.DayViewWrapper .User-Header{
    display: flex;
    /* height: 10%; */
    flex-direction: column;
    /* align-items: center; */
    width: 100%;
    padding: 5px 5px;
    /* justify-content: center; */
     border-bottom: 1px solid #babfc7;
}
.DayViewWrapper .Day{
    height: 100%;
    font-weight: bold;
    gap: 3px !important;
    color: white !important;
    padding: 0px !important;
    background-color: #018bdc;
}
.DayViewWrapper .Day-Row-Wrapper{
    /* border-bottom: 1px solid black; */
}
.DayViewWrapper .Table-Headers{
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #64749c;
    background-color: #F3F6FA;
    min-height: 2rem !important;
    /* border-top: 1px solid #babfc7; */
    /* border-radius: 9px 9px 0px 0px; */
    /* border-bottom: 1px solid #babfc7;*/
}
.DayViewWrapper .Table-Footer{
    font-weight: bold;
    display: flex;
    align-items: center;
    color: #64749c;
    background-color: #ffe984;
    min-height: 2rem !important;
    /* border-top: 1px solid #babfc7; */
    /* border-radius: 9px 9px 0px 0px; */
    /* border-bottom: 1px solid #babfc7;*/
}
.DayViewWrapper .custome-col{
    display: flex;
    align-items: center;
    padding: 0px !important;
    justify-content: center;
}
.DayViewWrapper .Total-Wrapper{
    background-color: #018bdc !important;
    font-weight: bold !important;
    color: white;
    /* border-bottom: 1px solid #babfc7 !important; */
}
.DayViewWrapper .Total-Wrapper .Day{
    background-color: #018bdc !important;
}
.EvenClass{
    background-color: white;
}
.OddClass{
    background-color: rgb(251 251 251);
}
.DayViewWrapper .Current-Week-Class{
    color: green !important;
}
.DayViewWrapper .Less-Time-Than-Expected{
    color: rgb(249 99 99) !important;
}
.DayViewWrapper .Show-This-Day-Band{
    background-color: rgb(211 255 211) !important;
}
.DayViewWrapper .Highlite{
    color: rgb(0 170 0) !important;
}
.dataText{
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Avenir Next LT W02';
    font-size: large;
    position: relative;
    top: 1rem;
}
.DayViewWrapper .User-Header .ant-picker{
    height: 28px !important;
}
/* .DayViewWrapper .User-Header .ant-space-item{
   margin: -3px -3px -3px 1rem !important;
} */