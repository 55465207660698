.create-form-header{ 
    padding: 15px;
    background-color: #e5ecff;
    color: #018EE0;
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #018EE0;
    font-size: 18px;
    font-weight: 600;
    padding-left: 5%;
}

.user-form-border {
    margin-left: 5%;
    padding: 1%;
}
.create-user-label{
    font-weight: 600;
    font-size: 14px;
    margin-top: 3%;
}
.create-user-input{
    padding: 9px 10px;
    /* height: auto; */
    font-size: 12px;
}

.mar-right13{margin-right: 13px;}


.create-user-checkbox{
    margin-left: 0;
}

.padr-15{
    padding-right: 15px;
}

.activelabel{
    font-size: 12px;
    font-weight: bold;
}
.custom-margin{
    margin-bottom: 1rem !important;
}
.Action-Service{
    display: flex;
    /* justify-content: flex-end; */
}
.Client-Service-Popover{
    width: 32rem !important;
}
.Action-Service .ant-dropdown-trigger{
    display: flex;
    align-items: center;
    padding: 5px;
    color: #018ee0;
    font-size: 1rem;
    cursor: pointer;
}

.currency-input::-webkit-outer-spin-button,
.currency-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.currency-input {
  -moz-appearance: textfield;
  padding-left: 1.8rem !important;
  text-align: right;
}

.dollar-sign{
    position: absolute;
    padding: 0.2rem 0.5rem 0.3rem 0.5rem;
    border-right: 1px solid #ced4da;
    font-weight: bold;
    color: #898c8f;
}