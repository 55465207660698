.dependencyClassDiv{
  border: 1px solid #ebebeb;
  border-radius: 5px;
}
.waitingOndivs .renderTasksDiv .dropdownForWaiting .ant-select{
  height: 25px !important;
}
.subDivForDependencyClass{
 margin: 20px 0px;
 padding: 10px;
 position: relative;
 width: 100%;
}
.subDivForDependencyClass .D-Task-Name:hover .Highlight-Class{
  color: #46abff !important;
  cursor: pointer !important;
}
.WaitingOnCollapse{
  color: #5b6d97 !important;
  background-color: rgb(255 245 221) !important
}
.BlockingCollapse{
  color: #5b6d97 !important;
  background-color: rgb(255 232 232) !important
}
.subDivForDependencyClass .Collapse-Header-Dependancies{
 text-indent: 1rem;
 color: #5b6c97;
 background-color: rgb(237 240 244);
 display: flex;
 box-shadow: 0px 1px 6px -3px gray;
 /* margin-left: 10px !important;
 margin-right: 10px !important; */
 border-radius: 5px;
 align-items: center;
}
.labelStatus{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4px;
}
.innerRenderTaskDiv{
  height: 2em;
  display: grid;
  background: aliceblue;
  border-radius: 5px; 
  margin-top: .1rem;
  align-items: center;
}
.innerRenderTaskDiv:hover{
  background-color: rgb(226, 229, 233);
}
.subDivForDependencyClass .ant-select-selector{
  height: 29px !important;
  box-shadow: none !important;
  min-width: 10rem !important; 
  opacity: 0.85 !important;
}

.subDivForDependencyClass table {
  width: 100%;
  border-collapse: collapse;
}
.subDivForDependencyClass th{
 color: #5b6c97;
 font-weight: 500;
}
.subDivForDependencyClass th, .subDivForDependencyClass td {
 padding: 10px;
 border-bottom: 1px solid #ddd;
 text-align: center;
 font-size: small;
}
.subDivForDependencyClass td {
  padding: 5px !important;
}
.subDivForDependencyClass th:first-child{
  text-align: left !important;
  padding-left: 15px !important;
}
.subDivForDependencyClass td:first-child{
  text-align: left !important;
  /* padding: 5px !important; */
  /* padding-left: 15px !important; */
}

.subDivForDependencyClass tbody > tr{
  background-color: #f4f4f4;
  color: #5a6c97;
}  

.task-name {
 max-width: 175px;  
 min-width: 100px;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap; 
 position: relative;
}
.popup-content{
display: flex;
flex-direction: column;
align-items: start;
gap: 1px
}
/* Improved dropdown styling */
.dependency-list-dropdowns {
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
padding: 8px;
background-color: #ffffff;
}

/* Task option layout */
.custom-option {
display: flex;
justify-content: space-between;
align-items: center;
padding: 5px 0px;
border-bottom: 1px solid #f0f0f0;
transition: background-color 0.3s ease;
margin: 0px !important;
}

.custom-option-header{
  padding: 4px 8px !important;
  font-weight: bold;
  background-color: rgb(240, 240, 240);
  border-bottom: 1px solid rgb(204, 204, 204);
  margin: 0px !important;
}

/* Task name styling */
.option-task-name {
flex: 1;
font-weight: 500;
color: #5a6c97;
}

/* Status label styling */
.option-status {
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  min-width: 6rem;
  text-align: center;
  width: 6rem;
}
.dependncy-list-dropdowns .ant-select-item{
  padding: 0px 12px !important;
}
.dependncy-list-dropdowns .ant-select-item:nth-child(odd){
  background-color: #f0f0f0 !important;
}
.dependncy-list-dropdowns .ant-select-item:nth-child(even){
  background-color: #ffffff !important;
}
.Waiting-on-popover .ant-popover-content{
  width: 430px !important;
  left: 0px !important;
  right: 0px !important;
}
.Waiting-on-popover .ant-popover-inner-content{
  width: 410px !important;
}
.Mapping-Dependency-popover .ant-popover-content{
  width: 300px !important;
  left: 0px !important;
  right: 0px !important;
}
.Mapping-Dependency-popover .ant-popover-inner-content{
  width: 280px !important;
  font-size: 14px !important;
  font-family: 'Avenir Next LT W02' !important;
  font-weight: 600 !important;
  color: #5a6c97 !important;
}
.Status-Change-Dependency span{
  display: block !important;
  cursor: default !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  width: 5.5rem !important;
}
.dependncy-list-dropdowns .ant-input-group-addon{
  border: 0.1px solid #dbdbdb !important;
}
.dependency-search-input .ant-input{
  padding: 3px !important;
}
.reminderPopover{
    display: flex;
    width: 100%;
    align-items: baseline;
    padding-right: 3px;
    padding-top: 2px;
    flex-direction: row;
    justify-content: flex-end;

    /*new change */
    position: absolute;
    z-index: 1111;
    right: 0px;
}
.checkBoxForReminder{
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    /* grid-column-gap: 3rem; */
}
.TaskReminderPopover .ant-popover-inner-content{
  width: 19rem !important;
}
.TaskReminderPopover .selectForTaskReminder .ant-select-selector{
  height: 30px !important;
}
.selectForTaskReminder{
  margin-left: 1em !important;
  width: 14rem !important;
}
.TaskReminderPopover .dateTimeForTaskReminder .ant-picker{
  width: 60% !important;
  margin-left: 2.4em !important;
}
.TaskReminderPopover .ant-select{
  width: 80% !important;
}
.dateTimeForTaskReminder{
  display: flex;
  margin-top: 1em;
  align-items: center;
}
.buttonsForTaskRem{
    display: flex;
    justify-content: end;
    gap: 1em;
    align-items: center;
    margin-top: 2em;
}
.TaskReminderPopover .ant-popover-content{
  left:0px !important
}
.popupHeader{
  margin-bottom: 1em;
  color: #5f709a;
  font-weight: 700;
}
.Activity-Body .Dependency-Table-Wrapper{
  position: relative !important;
  top: 1rem !important;
}