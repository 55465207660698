  .Reminder-DropDown .dropdown-toggle::after{
    display: none !important;
  }
  .Reminder-DropDown .form-control{
    width: 100% !important;
    padding: 3px 0px 3px 6px !important;
  }
  .Reminder-DropDown .notificationText {
    color: #40a9ff !important;
  }
  .Reminder-DropDown .about-deal-search{
    padding: 0px 5px 0px 16px !important;
    width: 100% !important;
  }
  .Reminder-DropDown .about-deal-search input{
    padding: 4px !important;
    margin: 0px !important;
    position: relative !important;
    top: 0px !important;
  }

  .Reminder-DropDown .Reminder-Toggle{
    border: none !important;
  }
  .Reminder-DropDown .dropdown-menu .dropdown-toggle{
    /* border: 1px solid #007bff !important;  */
    border-radius: 3px !important;
  }
  .Reminder-DropDown .Reminder-Toggle{
    display: flex;
    background: none;
    border: none;
    width: 100% !important;
    padding: 0px;
  }
  .Reminder-DropDown .Reminder-Menu .list-unstyled{
    padding: 0px !important;
  }
  .Reminder-DropDown .Reminder-Menu::before{
    position: absolute;
    border-top: 1px solid #fff;
    content: "";
    border-left: 1px solid #fff;
    display: block;
    height: 12px;
    width: 12px;
    top: -6px;
    right: 1.2rem;
    transform: rotate(45deg);
    z-index: 99999999;
    background: rgb(1, 142, 224);
    color: #000;
    margin: auto;
  }
  .Reminder-DropDown .Reminder-Menu{
    padding: 0px !important;
    width: max-content !important;
    box-shadow: 0 6px 15px #cfcfcf !important;
    width: 23vw !important;
    /* left: -29rem; */
    height: max-content !important;
    border: 1px solid #fff;
    border-radius: 0px !important;
    /* overflow: hidden !important; */
  }
  .Reminder-DropDown .Reminder-Menu .Header{
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    font-weight: 600;
    background: rgb(1, 142, 224);
    color: #fff;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    /* justify-content: space-between; */
    height: 50px;
  }
  .Reminder-DropDown .Reminder-Menu .Icon{
    position: absolute;
    right: 1rem;
    top: -15px;
    font-size: 24px;
    color: #018ee0;
  }
  .Reminder-DropDown .Event-Card{
    padding: 4px 4px;
    border-bottom: 1px solid #e3e3e3;
    cursor: default;
  }
  .Reminder-DropDown .Date{
    display: flex;
      /* padding: 9px; */
    /* background: #ededed; */
    width: 3rem;
    font-size: 1.3rem;
    height: 3rem;
    font-weight: 500;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .Reminder-DropDown .Date-String{
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    font-size: small;
    font-weight: 500;
    color: #7d7d7d;
  }
  /* .Reminder-DropDown .Date:hover{
    background: #ededed;
  } */
  .Reminder-DropDown .row{
    margin: 0px !important;
  }
  .Reminder-DropDown .Column{
    padding: 0px !important;
  }
  .Reminder-DropDown .Event-Details{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Reminder-DropDown .ant-input-group-wrapper{
    padding: 0px !important;
  }
  .Reminder-DropDown .ant-input-group-wrapper{
    border: 1px solid #cccccc !important;
    border-radius: 4px !important;
  }
  .Reminder-DropDown .ant-input-group-wrapper input,.Reminder-DropDown .ant-input-wrapper,.Reminder-DropDown .ant-input-group-addon,.Reminder-DropDown .ant-input-search-button{
    border: none !important;
    height: 100% !important;
  }
  .Reminder-DropDown .ant-input-group-wrapper .ant-btn-loading{
    border: none;
    background-color: white;
    box-shadow: none;
  }
  .Reminder-DropDown .Event-Details-Row{
    display: flex;
    align-items: center;
    padding: 4px 0px;
    /* background-color: #ededed; */
    border-radius: 32px;
    cursor: pointer !important;
    font-size: 14px;
    color: #2f2f2f;
    font-weight: 500;
  }
  .Reminder-DropDown .Event-Details-Row:hover{
    background-color: #ededed;
  }
  .Reminder-DropDown .Event-Type{
    font-size: small;
    font-weight: 500;
    color: #7d7d7d;
  }
  .Reminder-DropDown .Content{
    height: max-content;
    max-height: 70vh;
    overflow: scroll;
    overflow-x: hidden;
  }
  
  .Reminder-DropDown .notification-unread-button{
    text-align: right;
    display: flex;
    text-decoration: underline;
    gap: 10px;
    color: #5b6c97;
  }