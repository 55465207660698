.create-form-header{ 
    padding: 15px;
    background-color: #e5ecff;
    color: #018EE0;
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #018EE0;
    font-size: 18px;
    font-weight: 600;
    padding-left: 5%;
}

.createuser-border{
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 1px;
    /* height: calc(100vh - 154px); */
    /* overflow-y: auto; */
    height: 100%;
    overflow: hidden;
    padding: 5px 5px 5px 5px;
}
.mainWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* height: 100%; */
    padding-bottom: 1rem;
    border: 1px solid #018EE0;
    border-radius: 6px;
}

.create-user-box{
    padding: 0 8px 0 15px;
    height: 100% !important;
    overflow-y: auto; 
}
.user-form-border {
    margin-left: 5%;
    padding: 1%;
}
.create-user-label{
    font-weight: 600;
    font-size: 14px;
    margin-top: 3%;
}
.create-user-input{
    padding: 9px 10px;
    height: auto;
    font-size: 14px;
    font-family: 'Avenir Next LT W02';
}

.mar-right13{margin-right: 13px;}


.create-user-checkbox{
    margin-left: 0;
}

.create-user-input-select{
    padding: 0.12rem 0.3rem !important;
    height: auto !important;
    font-size: 12px !important;
    border-radius: 5px !important;
    min-height: auto !important;
}

.ant-upload-text{
    font-size: 10px;
}
input[type="file"] {
    display: flex;
  }
  .Table-workingDays td, .table th{
    padding: 0.75rem;
    vertical-align: top;
    border: 0px solid #dee2e6;
    font-size: 12px;
    padding: 10px 9px;

  }

  .isWorking-checkbox{
    box-sizing: border-box;
    margin-left: 10px;
    padding-top: -93px;
    position: relative;
    top: 3px;
}

.save-cancelbox{
    padding-top: 14px;
    width: 285px;
    float: right;
    padding-bottom: 8px;
}


.createuser-cancel {
    padding-top: 14px;
    padding-bottom: 8px;
    float: right;
}
.createuser-border .user-form-border .hexpicker{
    position: absolute;
    bottom: 2.6rem;
    border: 0px solid grey;
    box-shadow: 3px 3px 10px gray;
    border-radius: 12px;
}
.createuser-border2{
    border: 1px solid #fff;
    border-radius: 4px;
    background-color: #fff;
    margin-bottom: 1px;
    /* height: calc(100vh - 154px); */
    /* overflow-y: auto; */
    height: 100%;
    overflow: hidden;
    padding: 5px 5px 5px 5px;
}
.user-form-border2 {
    margin-left: 5%;
    padding: 1%;
}
.user-form-border2 .ant-select{
    height: 2.5rem !important;
}
.user-form-border2 .ant-select .ant-select-selector{
    height: 100% !important;
}
.user-form-border2 .ant-select .ant-select-selector.ant-select-selection-item{
    line-height: 40px !important;
}