.Global-Search-Wrapper{
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: row;
}
.Global-Search-Wrapper .Search-Inpute-Wrapper input{
    border-radius: 42px !important;
    border: none ;
    font-size: 13px;
    height: 2rem;
    transition: all ease-in-out 1s;
}
.Global-Search-Wrapper .Search-Inpute-Wrapper input,.Global-Search-Wrapper .Search-Inpute-Wrapper textarea{
    background-color: #f0f0f0;
}
.Global-Search-Wrapper .Search-Inpute-Wrapper input:focus{
    /* border: 2px solid #0180c9 !important; */
    outline: none;

}
.Global-Search-Wrapper .Icons-Wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.8rem;
    padding: 5px;
    border-radius: 50%;
    width: 1.8rem;
}
.Global-Search-Wrapper .Search-Inpute-Wrapper .open-search{
    width: 13rem !important;
    /* border: 2px solid #0180c9 !important; */
}
.Global-Search-Wrapper .Search-Inpute-Wrapper .open-search, .Global-Search-Wrapper .Search-Inpute-Wrapper textarea{
    padding-left: 5px;
}
.Global-Search-Wrapper .Search-Inpute-Wrapper .close-search{
    width: 0px !important;
    /* border: 0px !important; */
}
.Global-Search-Wrapper .Search-Inpute-Wrapper{
    justify-content: space-between;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.Global-Search-Wrapper .Search-Inpute-Wrapper .classforicons{
    position: absolute ;
    right: 11px ;
    gap: 6px ; 
}