.Blueprint-Wrapper{
    display: flex;
    width: 100%;
    height: calc(100% - 1rem);
    position: relative;
    /* background-color: aqua; */
    flex-direction: column;
    top: -4.2rem !important;
    z-index: 1;
}

.Blueprint-Wrapper .Blueprint-Body{
    display: flex;
    width: 100%;
    height: calc(100%);
    background-color: white;
}
/* .Blueprint-Body div:nth-child(1){
    width: 100% !important;
} */
.Task-Name-Wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.Task-Name-Wrapper label{
    margin: 0px !important;
}


.Task-Name-Wrapper img{
    height: 14px;
}
.Setting-Iconwrapper-For-Flow{
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    right: 10px;
    visibility: hidden;
}
.Task-Name-Wrapper:hover .Setting-Iconwrapper-For-Flow{
    visibility: visible;
}
.Setting-Iconwrapper-For-Flow{
    position: absolute;
    display: flex;
    height: 100%;
    align-items: center;
    right: 10px;
    visibility: hidden;
}