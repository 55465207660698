.CustomeUserTag{
    /* padding: 3px !important; */
    display: flex !important;
    justify-content: space-between !important;
    background: transparent;
    border: none;
    align-items: center;
    gap: 3px !important;
}
.CustomeUserTag .Icon{
    width: 1.5rem;
    height: auto;
    border-radius: 50%;
    border: 1px solid #d7d7d7;
}
.ant-select-selection-item .Icon svg , .ant-select-dropdown .ant-tag .Icon svg{
    width: 1.2rem !important;
    height: 1.2rem !important;
    opacity: 0.5;
}
 /* White placeholder for bottom bar condition */
  .white-placeholder .ant-select-selection-placeholder {
    color: white;
  }
  .New-User-Picker-Popover .Content{
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 3px;
  }
  .New-User-Picker-Popover .Content .Option{
    
  }
