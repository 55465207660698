.Email-Sidebar-Wrapper{
    display: flex;
    position: relative;
    flex-direction: column;
    min-height: 100%;
    min-width: 2rem;
    width: 15rem;
    color: #5b6c97 !important;
    background-color: #f0f0f0;
}
.Email-Sidebar-Wrapper .List-Wrapper{
    /* margin-top: 2rem; */
}
.Email-Sidebar-Wrapper .List{
    list-style: none;
    padding: 8px 0px 0px 8px !important;
    margin-bottom: 0px !important;
}
.Email-Sidebar-Wrapper .Section-Header{
    display: flex;
    width: 100%;
    /* background-color: #dddddd; */
    padding-left: 9px;
    font-weight: bold;
    font-size: 1.1rem;
    /* color: #767676; */
    /* margin: 0px 0px; */
    border-bottom: 2px solid #d8d8d8;
    border-top: 2px solid #d8d8d8;
}
.Email-Sidebar-Wrapper .List li{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    padding-left: 8px;
    font-weight: 600 !important;
    border-radius: 11px 0px 0px 11px;
}
.Email-Sidebar-Wrapper .List li .Add-Folder-Icon{
    display: none;
    position: absolute;
    right: 7px;
    font-size: small;
    height: max-content;
    /* top: 0px; */
    justify-content: center;
    align-items: center;
}
.Email-Sidebar-Wrapper .List li a{
    text-decoration: none;
}
.Email-Sidebar-Wrapper .List li:hover{
    background-color: #ebebeb;
}
.Email-Sidebar-Wrapper .List li:hover .Add-Folder-Icon{
    display: flex;
}
.Show{
    width: 15rem !important;
}
.Hide{
    width: 2rem !important;
}
.Email-Sidebar-Wrapper .Sidebar-Collapse-Icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    z-index: 1000;
    height: 4rem;
    background-color: #f0f0f0;
    color: #5b6c97;
    padding: 0rem;
    position: absolute;
    right: -1.45rem;
    top: 45%;
    /* animation: horizontal-shaking 3s infinite; */
    border-radius: 0px 8px 10px 0px;
}
.Email-Sidebar-Wrapper .Verticle-Folder-Name{
    display: flex;
    writing-mode: vertical-rl;
    position: relative;
    align-items: center;
    /* flex-direction: revert-layer; */
    justify-content: center;
    top: 3rem;
   
}
.Email-Sidebar-Wrapper .active{
    background-color: #018ee0 !important;
    color: white !important;
}
.List-Wrapper .Compose-Btn{
    position: relative;
    z-index: 1000;
}
.Compose-Btn .btn-primary{
    background-color: #018ee0 !important;
    border-color: #018ee0 !important;
    font-weight: bold !important;
}
@keyframes horizontal-shaking {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}
.sidebar-li .ShowCollapse{
    display: block !important;
    width: auto !important;
    margin-right: 3px !important;
}
.sidebar-li .HideCollapse{
    display: none !important;
}
.List-Wrapper-scroll{
    height: 75%;
    overflow: auto;
}