.list-group-item{
    padding: .75rem 1.75rem;
    border: 0px;
    
}
 .filter-list-style{
     font-weight: bold;
     color: #5B6C97;
     padding: 4px;
     font-family: 'Avenir Next LT W02';
 }
 .filter-list-style::after{
    font-weight: bold;
    color: #5B6C97;
    padding: 4px;
    font-family: 'Avenir Next LT W02';
    border: 0;
}
 .filter-box{
    font-size: 14px;
    padding: 15px 0 15px 0;
    font-family: 'Avenir Next LT W02';
    color: #5B6C97;
 }
 .pos-relative{
     position: relative;
 }

 .search-filter{
    border-radius: 14px;
    padding: 3px 30px 3px 15px;
    font-size: 12px;
    height: auto;
 }
 
 .search-filter1{
    border-radius: 14px;
    padding: 3px 15px 3px 15px;
    font-size: 12px;
    height: auto;
 }

 .search-filter3{
    border-radius: 14px;
    padding: 3px 15px 3px 15px;
    font-size: 12px;
    height: auto;
    width:40%; 
 }
 .search-filter2{
    border-radius: 14px;
    padding: 3px 15px 3px 15px;
    font-size: 12px;
    height: auto;
    width:100%; 
 }
 .pad-00{
     padding:0px;
 }
 .search-ico{
    position: absolute;
    /* right: 5px;
    top: 5px; */
    top: 0.4rem;
    right: 9.8rem;
    color: #2D2D2C;
    cursor: pointer;
 }
 .font-13{
     font-size: 13px;
 }
 .filter-list-item{
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
 }
 .font-14{
     font-size: 14px;
 }
 .mar-right-5{
     margin-right: 5px;
 }
 .pad-9{
    padding: 9px 1.75rem;
 }

 .form-check-input2{
    margin-top: 3px;
 }

 .filter-overflow{
     overflow-y: auto;
     height: 70%;
    margin-right: -15px;
    flex-grow: 1;
 }
 .applyfilter-btn{
    padding: 3px 7px 0px 7px;
    font-size: 14px;
    font-family: 'Avenir Next LT W02';
    background-color: #0062cc;
    margin-right: 10px;
    border-color: #005cbf;
 }

 .clear-btn{
    padding: 3px 7px 3px 7px;
    font-size: 14px;
    font-family: 'Avenir Next LT W02';
    color: #5b6c97 !important;
    border: 1px solid #B0BED0 !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAgCAMAAADgzjIVAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAE5QTFRF7O/0/P7//P3//P3++/3++/z++vz++vv9+fv9+fr9+Pr9+Pr89/r89/n89vn89vj79fj79ff79Pf79Pb68/b68vX58fT58PP47/L37vH3VwLb5wAAAFRJREFUeJwdw4UNwEAMBLB7Zub9F20VSwZ+jDFwziGEgJQSSimqtYYxBtZa6pyD9x4hBMQYkVKiOWeUUmitFa019N7pGANzTrrWwt4b5xzce/He+wDMvAS9bXGhXAAAAABJRU5ErkJggg==);
 }

 .blue-background3D-signfilter {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAgCAMAAADgzjIVAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGBQTFRFAJ/0AJ70AJ31AJz1AJv1AJr2AJn2AZj2AZf3AZX3AZT3AZP4AZL4AZH4AZD4AY/5AY75AY35AYv6AYr6Aon6Aoj7Aof7Aob7AoX8AoT8AoD4AnnwAXLoAWvgAWXYAF7QQJE3PwAAAGVJREFUeJwNwYkChBAAQMGXUiIdyBHl//9ydwb+hmFACME4jkzThJSSeZ5ZlgWlFOu6orXGGMO2bVhr2fed4zg4z5PrunDO4b0nhMB938QYSSmRc6aUwvM81FpprfG+L9/30Xv/AfSgBdHYOfQ4AAAAAElFTkSuQmCC);
    background-repeat: repeat-x;
    height: 28px;
}
.filterbtn-box{
    margin-top: 5px;
}



.filterType-toggle::after {
    margin-left: 1px;
    top: 15px;
}


.filterType-toggle{
    padding: 0px 16px 0px 5px;
    margin: 5px;
    font-size: 14px;
    color: #5b6c97 !important;
    border: 1px solid #B0BED0 !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAgCAMAAADgzjIVAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAE5QTFRF7O/0/P7//P3//P3++/3++/z++vz++vv9+fv9+fr9+Pr9+Pr89/r89/n89vn89vj79fj79ff79Pf79Pb68/b68vX58fT58PP47/L37vH3VwLb5wAAAFRJREFUeJwdw4UNwEAMBLB7Zub9F20VSwZ+jDFwziGEgJQSSimqtYYxBtZa6pyD9x4hBMQYkVKiOWeUUmitFa019N7pGANzTrrWwt4b5xzce/He+wDMvAS9bXGhXAAAAABJRU5ErkJggg==);
}
.filterTypeMenu{
    position: absolute;
    top: -2px !important;
    box-shadow: none;
    width: 150px;
    border: 1px solid #B0BED0 !important;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 7px 0;
    max-height: 170px;
    min-height: 170px;
    overflow-y: auto;
}

.filterType-dropdown>.filterTypeMenu>.dropdown-item {
    margin: 0px auto;
    width: 100%;
    color: #000 !important;
    padding: 2px 19px;
    font-family: 'Avenir Next LT W02';
    font-size: 13px;
}
.filterType-dropdown>.filterTypeMenu>.dropdown-item:hover {
    background-color: #e9ecef;
}

.pad-r5{
    padding-right: 5px;
}