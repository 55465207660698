@keyframes dash-animation {
    to {
      stroke-dashoffset: -200; 
    }
  }
.Criteria-ToggleButton .ant-switch-inner span{
  width: fit-content !important;
}
.Criteria-Footer{
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  height: 1.4rem !important;
}
.Criteria-Footer button{
 position: absolute;
 right: 10px !important
}
.Criteria-Footer .Error{
 color: red !important;
}
.Node-Delete-Conformation-Popover{
  justify-content: center !important;
  display: flex !important;
  width: 100vw !important;
}
.Node-Delete-Conformation-Popover .ant-modal-content{
  width: 50vw !important;
}
.Node-Delete-Conformation-Popover .ant-modal-body{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  max-height: 60vh;
  overflow: scroll;
}
.Node-Delete-Conformation-Popover .Custome-Base-Column{
  display: flex;
  align-items: center;
  color: "#5b6c97";
}
.Node-Delete-Conformation-Popover .Custom-Select{
  width: 100%;
  height: 100%;
}
.Node-Delete-Conformation-Popover .ant-modal-header{
  padding: 0px !important;
  height: 3rem;
  align-items: center;
  /* display: flex; */
  margin: 0px;
}
.Node-Delete-Conformation-Popover .ant-modal-header .ant-modal-title{
  height: 100%;
}
.Node-Delete-Conformation-Popover .ant-modal-header .ant-modal-title .ant-ribbon-wrapper{
  height: 100%;
}
.Node-Delete-Conformation-Popover .ant-modal-header .ant-modal-title .ant-ribbon{
  height: 2rem;
  display: flex;
  align-items: center;
}
.Node-Delete-Conformation-Popover .ant-modal-header .ant-modal-title .ant-ribbon-wrapper .row{
  display: flex;
  height: 100%;
  align-items: center;
  color: #5b6cb2;
}
.Node-Delete-Conformation-Popover .ant-modal-header .ant-modal-title .ant-ribbon-wrapper .ant-spin-dot{
 color: white !important;
}
.Node-Delete-Conformation-Popover .Heading{
  background: #f8f8f8;
  padding: 2px 18px;
  font-size: 15px;
  font-weight: 500;
  color: #5b6c97;
  margin: 0px !important;
}
.Node-Delete-Conformation-Popover .Table-Row{
  padding: 4px;
  /* background-color: #fbfbfb; */
  margin-top: 5px !important;
}
.Node-Delete-Conformation-Popover{
  height: 100vh;
  top: 0px;
  display: flex;
  align-items: center;
}
.Node-Delete-Conformation-Popover .ant-modal-footer{
 display: block !important;
}
.Node-Delete-Conformation-Popover .Msg-Block{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #5b6c97;
  font-weight: 600;
}
.Node-Delete-Conformation-Popover .ant-modal-footer{
  border-top: none !important;
  margin-top: 0px !important;
}
.Node-Delete-Conformation-Popover .ant-modal-header{
  border-bottom: none !important;
}
.Input-Field-Column .labeltext label {
  position: absolute;
  top: -11px;
  left: 6px;
  z-index: 1;
  color: #5b6cb2;
  background: white;
  padding: 0px 8px;
}
.Custome-Disabled .ant-select-selector{
  background-color: transparent !important;
}
.Custome-Disabled .ant-select-arrow{
  display: none !important;
}
.Custom-Step .Custome-Icon .ant-steps-icon{
  display: flex;
  width: auto;
  align-items: center;
}
.Custom-Step .Icon-Circle{
  display: flex;
  width: 27px;
  height: 27px;
  align-items: center;
  justify-content: center;
  background-color: #e6f2ff;
  border-radius: 25px;
}
.Custom-Step .Icon-Circle svg{
  font-size: 15px !important;
  color: #5b6cb2;
}
.Custome-Class .ant-collapse-content{
  padding: 10px !important;
}

.Error-Border{
  border: 1px solid #f80000 !important;
  box-shadow: 0px 0px 3px 0px #ff0000 !important;
}
.On-Hover-Show{
  opacity: 0;
}
.On-Hover-Show:hover{
  opacity: 1 !important;
}

.WarningPopupWaitingOn{
  justify-content: center !important;
  display: flex !important;
  width: 100vw !important;
}
.WarningPopupWaitingOn .ant-modal-content{
  width: 37vw !important;
}
.WarningPopupWaitingOn .ant-modal-body{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  max-height: 60vh;
  overflow: scroll;
}
.WarningPopupWaitingOn .Custome-Base-Column{
  display: flex;
  align-items: center;
  color: "#5b6c97";
}
.WarningPopupWaitingOn .Custom-Select{
  width: 100%;
  height: 100%;
}
.WarningPopupWaitingOn .ant-modal-header{
  padding: 0px !important;
  height: 3rem;
  align-items: center;
  /* display: flex; */
  margin: 0px;
}
.WarningPopupWaitingOn .ant-modal-header .ant-modal-title{
  height: 100%;
}
.WarningPopupWaitingOn .ant-modal-header .ant-modal-title .ant-ribbon-wrapper{
  height: 100%;
}
.WarningPopupWaitingOn .ant-modal-header .ant-modal-title .ant-ribbon{
  height: 2rem;
  display: flex;
  align-items: center;
}
.WarningPopupWaitingOn .ant-modal-header .ant-modal-title .ant-ribbon-wrapper .row{
  display: flex;
  height: 100%;
  align-items: center;
  color: #5b6cb2;
}
.WarningPopupWaitingOn .ant-modal-header .ant-modal-title .ant-ribbon-wrapper .ant-spin-dot{
 color: white !important;
}
.WarningPopupWaitingOn .Heading{
  background: #f8f8f8;
  padding: 2px 18px;
  font-size: 15px;
  font-weight: 500;
  color: #5b6c97;
  margin: 0px !important;
}
.WarningPopupWaitingOn .Table-Row{
  padding: 4px;
  /* background-color: #fbfbfb; */
  margin-top: 5px !important;
}
.WarningPopupWaitingOn{
  height: 100vh;
  top: 0px;
  display: flex;
  align-items: center;
}
.WarningPopupWaitingOn .ant-modal-footer{
 display: block !important;
}
.WarningPopupWaitingOn .Msg-Block{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #5b6c97;
  font-weight: 600;
}
.WarningPopupWaitingOn .ant-modal-footer{
  border-top: none !important;
  margin-top: 0px !important;
}
.WarningPopupWaitingOn .ant-modal-header{
  border-bottom: none !important;
}