.deal-table-chart {
    max-width: 74vw;
    overflow: auto;
}

.deal-table-chart table tbody tr td:first-child{
    /* display: inline-flex; */
    vertical-align: text-top;
}

.table-scroll {
	position:relative;
	overflow:hidden;
    padding: 0 14px;
}
.table-wrap {
	overflow:auto;
}
.table-scroll table{
	margin:auto;
}
.table-scroll th, .table-scroll td {
	vertical-align:top;
}
.clone {
	position:absolute;
	top:0.25px;
	left:13.5px;
	pointer-events:none;
}
.clone th, .clone td {
	visibility:hidden
}
.clone td, .clone th {
	border-color:transparent !important
}
.clone tbody th {
    background-color: rgb(255, 0, 0) !important;
	visibility:visible;
}
.clone .fixed-side {
	border-color: #dee2e6 !important;
	visibility:visible;
}
.clone .grey-bg{
    background: rgb(217, 217, 217) !important;
}
.clone .blue-bg{
	border: 1px solid #a8a8a8 !important;
    background: #018EE0 !important;
}

.clone thead, .clone tfoot{background:transparent;}