.loader-contentV1 {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.05);
  z-index: 99999;
}
.loader {
  text-align: center;
  z-index: 1000;
  padding: 7px 50px 5px 50px;
  width: 166px;
  background-color: transparent;
  border-radius: 10px;
  filter: alpha(opacity=100);
  opacity: 1;
  -moz-opacity: 1;
  left: 50%;
  top: 50%;
  position: absolute;
  margin-left: -60px;
  margin-top: -60px;
}

.loading {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid black;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 0.75s linear infinite; /* Safari */
  animation: spin 0.75s linear infinite;
  margin: 7px auto !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
