.createdeal-height {
  /* height: 92vh; */
  overflow: hidden;
}

.createdeal-custom-scroll {
  height: calc(100vh - 210px);
  overflow-y: auto;
}

.color-white {
  background-color: #fff;
}

/* .sticky-column {
    position: sticky;
    left: 0;
    background-color: grey; 
    z-index: 1;
  } */
.sticky-col {
  position: sticky;
  left: 0;
  background-color: #dbeef9;
  z-index: 1;
}

.sticky-column-plus {
  position: sticky;
  left: 0;
  z-index: 1;
}

/* .table-wrapper {
    overflow-x: auto;
    max-width: 100%;
  } */

.logo-txt {
  width: auto;
  float: left;
  font-size: 18px;
  font-weight: 600;
  margin-right: 32px;
}

.pad-bottom250 {
  padding-bottom: 250px;
}

.clear {
  clear: both;
}

.user-box {
  width: auto;
  float: right;
}

.text-bold {
  font-weight: bold;
  font-size: 12px;
  padding-top: 0.5rem;
}

.text-bold2 {
  font-size: 12px;
  padding-right: 24px;
}

.text-bold2-left {
  font-size: 12px;
  padding-left: 24px;
}

.occupancy {
  display: flex;
  justify-content: center;
}

.construction {
  display: flex;
  justify-content: center;
}

.header2 {
  background-color: #f9f8f9;
  padding: 12px 15px;
  width: 100%;
  color: #000;
  box-sizing: border-box;
}

.left-box {
  width: auto;
  float: left;
}

.right-box {
  padding-top: 25px;
  padding-right: 25px;
  width: auto;
  float: right;
}

.breadcrumb {
  padding: 15px;
}

.breadcrumb i {
  font-size: 16px;
  color: #999;
}

.txt-bliue {
  color: #70ad47;
  margin-left: 5px;
}

.form-box {
  padding: 15px;
  min-height: 428px;
}

.form-boxs {
  width: 90%;
  margin: 0 auto;
}

.form-box h1 {
  color: #000;
  font-size: 24px;
}

.marb-16 {
  margin-bottom: 6px !important;
}

.txt-label {
  width: 188px;
  float: left;
  text-align: right;
  margin-right: 15px;
  padding-top: 5px;
}

.input-control {
  width: 250px;
  padding: 5px 7px;
  border-radius: 4px;
  border: 1px solid #000;
}

.select-control {
  width: 266px;
  padding: 6.57px 7px;
  border-radius: 4px;
  border: 1px solid #000;
}

.pad-15 {
  padding: 15px;
}

.padtop-15 {
  padding-top: 0px;
}

.padtop-14 {
  padding-top: 16px;
}

.btn-100 {
  background-color: #fff;
  color: #018EE0;
  border-radius: 6px;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.5rem;
  width: 100%;
  text-align: left;
  border: 1px solid #018EE0;
  box-shadow: none !important;
}

.btn-100:hover {
  background-color: #018EE0;
  color: #fff;
  box-shadow: none !important;
  border: 1px solid #018EE0;
}

.btn-100-active {
  background-color: #018EE0 !important;
  color: #fff;
  border-radius: 6px 6px 0 0;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 0.5rem;
  width: 100%;
  text-align: left;
  border: 1px solid #018EE0 !important;
  box-shadow: none !important;
}

.btn-100-active:hover {
  background-color: #018EE0;
  color: #fff;
  box-shadow: none !important;
  border: 1px solid #018EE0;
}

/* .list-group-item {
    padding: 5px 12px;
  } */

.list-group li:hover {
  background-color: #018EE0;
  color: #fff;
}


.mar-15 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.float-right {
  float: right;
}

.list-heading {
  background-color: #018EE0;
  color: #fff;
}

/* .btn-check:focus + .btn-secondary,
  .btn-secondary:focus,
  .btn-secondary:hover {
    background-color: #5b6c97;
    color: #fff;
    box-shadow: none;
    border-color: #5b6c97;
  } */
/*   
  .form-control {
    padding: 0.12rem 0.75rem !important;
  
        padding: 0.12rem 0.3rem;

    font-size: 12px;
  } */

.createdeal-textfield {
  /* padding: 0.12rem 0.3rem; */
  font-size: 12px;
  border-radius: 5px !important;
}

.valid-date {
  border: 1px solid #ced4da;
}

.invalid-date {
  border: 1px solid red;
  transition: "width 0.4s ease-in-out";
  box-shadow: rgb(255 98 98) 0px 0px 7px 1px
}

.modalwidth-500 .form-check-input,.modalwidth-500 .form-check-input:checked{
  top: 2px;
}

.form-check-input:checked {
  background-color: #018EE0;
  border-color: #018EE0;
}

.react-datepicker__input-container {
  font-size: 12px;
  border-radius: 0.25rem;
}

.react-datepicker__input-container input {
  /* border: 0px solid #ced4da; */
  padding: 0.12rem 0.3rem;
  border-radius: 0.25rem;
  width: 100%;
  text-align: center;
}

.react-datepicker-ignore-onclickoutside {
  border: 1px solid #ced4da;
  font-size: 12px;
  /* text-align: center; */
}

.font12 {
  font-size: 11px;
}

.address-text {
  outline: none;
  margin-left: -8px;
  width: 100%;
  padding: 0.12rem 0.3rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}

.address-text2 {
  /* margin-left: -22px; */
  outline: none;
  width: 100%;
  padding: 0.12rem 0.3rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}

.continue-button {
  margin-bottom: 0.1rem;
  width: 100%;
  text-align: left;
  background-color: #018EE0;
  border: 0px;
  color: #fff;
  border-radius: 0.3rem;
  padding: 1px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.continue-button:focus+.continue-button,
.continue-button:focus,
.continue-button:hover {
  background-color: #018EE0;
  color: #fff;
  box-shadow: none;
  border-color: #018EE0;
}

.dropdown-text {
  font-size: 12px;
  height: auto;
  padding: .12rem .3rem;
}

.basicpage-drop-text {
  font-size: 15px;
}

.plus-button {
  background-color: #70ad47;
  color: #fff;
  font-weight: bold;
  border: 0px;
  padding: 0;
  border-radius: 50%;
  width: 18px;
  text-align: center;
  height: 18px;
  line-height: 17px;
}

.minus-button {
  background-color: #dc3545 !important;
  margin-left: -16px;
  margin-top: -2.5px;
}

.minus-button:hover {
  background-color: #dc3545 !important;

}

.margin-l-t {
  margin-left: -16px;
  margin-top: -4.5px;
}

.application-none {
  display: none;
}

.appSupersede-none {
  display: none;
}

.generalContractor-none {
  display: none;
}

.conjunction-none {
  display: none;
}

.partial-none {
  display: none;
}

.l2code-none {
  display: none;
}

.error-display {
  display: none;
}

.nycOption-none {
  display: none;
}

.civilpenalty-none {
  display: none;
}



.red-text {
  color: red;
}
.show-error{
  border-color: red;
}

.create-user-input .css-587182-DropDown{
  width: 256px !important;
}

.code-border {
  border: 1px solid #018EE0;
  border-radius: 7px 7px 0 0;
  text-align: center;
  border-bottom: 0;
}

.code-border2 {
  border: 1px solid #018EE0;
  border-radius: 7px;
}

.col-2 {
  width: 50%;
  float: left;
  max-width: 50% !important;
}

.border-left-right {
  border-left: 1px solid #018EE0;
  border-right: 1px solid #018EE0;
}

.code-pad {
  padding: 10px 10px;
}

.boxplate-heading {
  padding: 15px;
  background-color: #e5ecff;
  color: #018EE0;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #018EE0;
  font-size: 18px;
  font-weight: 600;
}

.boxplate {
  border: 1px solid #018EE0;
  border-radius: 6px;
}

.sidenav-card {
  border-radius: 0 0 6px 6px;
}

.width20 {
  width: 20%;
}

.width20-4 {
  width: 20.4%;
}

.width20-nycproperty {
  width: 23.6%;
  max-width: 23.6%;
  flex: 0 0 23.6%;
}

.createdeal-card {
  border: 1px solid #018EE0;
  border-radius: 0px 0px 6px 6px !important;
}


.map-padding {
  padding-top: 12px;
  padding-bottom: 7px;
}

.textbox-mar-left11 {
  margin-left: 11px;
}

.textbox-mar-left5 {
  margin-left: -5px;
}

.textbox-mar-left15 {
  margin-left: -15px;
}

.textbox-mar-left20 {
  margin-left: -20px;
}

.textbox-mar-left14 {
  margin-left: -14px;
}

.width-98 {
  width: 98%;
}

.select-padding {
  padding-right: 20px;
  padding-left: 8px;
}

.btn-wwp-plus {
  background-color: #70ad47;
  color: #fff;
  box-shadow: none;
  border-color: #70ad47;
  padding: 3px 15.5px;
  border-radius: 3px;
  margin: 4px 0px;
  line-height: 12px;
}

.pad0 {
  padding: 0 !important;
  padding-left: 2px !important;
}

.opacity0 {
  opacity: 0;
}

.backcolor {
  background-color: #a6a6a6 !important;
}

.backcolor-bor-r {
  border-right: 1px solid #a6a6a6 !important;
}

.left-th {
  border-left: 1px solid #018EE0 !important;
  border-top: 1px solid #018EE0 !important;
  border-bottom: 1px solid #018EE0 !important;
}

.right-th {
  border-right: 1px solid #018EE0 !important;
  border-top: 1px solid #018EE0 !important;
  border-bottom: 1px solid #018EE0 !important;
}

.middle-th {
  border-top: 1px solid #018EE0 !important;
  border-bottom: 1px solid #018EE0 !important;
}

.btn-info2 {
  color: #fff;
  margin-right: 3px;
  padding: 3px 4px;
  font-size: 12px;
  border-radius: 3PX;
}

.btn-info:hover {
  background-color: #0dcaf0 !important;
  border-color: #0dcaf0 !important;
}

.btn-danger:hover {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.bg-header {
  background-color: #018EE0;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.bg-header-2 {
  background-color: #018EE0;
  color: #fff;
  font-size: 12px;
  text-align: center;
}

.table-pad tbody th,
.table-pad tbody td {
  padding: 4px;
  text-align: center;
  vertical-align: middle;
}

.margin-top-18 {
  margin-top: 18px;
}

.pad-12 {
  padding: 12px;
}

.width-64 {
  width: 54px;
}

.bg-header>tr>th {
  padding: 0.3rem 0.5rem;
}

.bg-header-2>tr>th {
  padding: 0.3rem 0.5rem;
}

.modal-header-class {
  background-color: #018EE0;
  color: #fff;
  /* border-radius: 6px 6px 0 0; */
  padding: 5px 20px;
  font-size: 14px;
  font-weight: bold;
  /* margin-top: 0.5rem; */
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  width: 100%;
  text-align: left;
  border: 1px solid #018EE0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scrollingSideBar {
  height: 74vh;
  overflow: scroll;
}

.modal-content {
  border: 2px solid #d9d9d9 !important;
  border-radius: 10px;
  box-shadow: 0px 11px 14px 1px #dadada;
}

.sureMessage {
  position: absolute;
  left: 17%;
  top: 22%;
}

.messageButton {
  position: absolute;
  bottom: 8%;
  width: 94%;
}

.h500-overflow {
  max-height: 500px;
  overflow-y: auto;
}

.modal-dialog {
  max-width: 500px;
  margin: 6rem auto;
}

.modalwidth-600 {
  max-width: 600px;
}

.modalwidth-500 {
  max-width: 500px;
}

.modalwidth-400 {
  max-width: 400px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.align-center-class {
  text-align: center;
  text-align-last: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  -webkit-text-align-last: center;
}

.width-36 {
  width: 36px;
}

/* Chrome, Safari, Edge, Opera */

.arrow-hide::-webkit-outer-spin-button,
.arrow-hide::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

.arrow-hide[type="number"] {
  -moz-appearance: textfield;
}

.text-align-center {
  text-align: center;
}

.width-13 {
  width: 3.333333%;
}

.width-75 {
  width: 90px;
}

.witdh-datepicker {
  width: 162px !important;
}

.autoname-text {
  outline: none;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  padding: 0.12rem 0.3rem;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin-left: -8px;
}

.currency_input {
  outline: none;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  font-size: 12px;
  padding: 0.2rem 0.3rem;
  height: 24px;
}

.font-centered {
  margin-top: 4px !important;
  margin-left: 5px;
}

.pad-right-15 {
  padding-right: 15px;
}

.width-22 {
  width: 22px;
}

.pad-left-0 {
  padding-left: 0px;
}

.pad-right-7 {
  padding-right: 7px;
}

.pad-right-3 {
  padding-right: 3px;
}

.rightbutton-checkbox {
  margin-top: 4px;
}

.rightbutton-checkbox-active {
  margin-top: 4px;
  border-color: #fff !important;
}

.next-button {
  margin-bottom: 0.1rem;
  width: 100%;
  background-color: #018EE0;
  border: 0px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Avenir Next LT W02';
  /* width: 135px; */
  font-size: 16px;
  font-weight: bold;
  border-radius: 9px;
}

.next-button:focus+.next-button,
.next-button:focus,
.next-button:hover {
  background-color: #018EE0;
  color: #fff;
  box-shadow: none;
  border-color: #018EE0;
}

.nextbtn-box-pad {
  padding-right: 30px;
  padding-top: 0.5rem;
}

.next-btn3D {
  background-image: url("../../../assets/images/btn-blue-bg.png");
  background-repeat: repeat-x;
  height: 34px;
}

.pad-right5 {
  padding-right: 5px;
}

.nycProject-hidden {
  display: none;
}


.plus-hidden {
  display: none;
}

.plus-img-size {
  width: 17px;
  margin-left: -6px;
  margin-top: 5px;
  cursor: pointer;
  width: 1.4rem;
}

.minus-img-size {
  width: 16px;
  margin-left: -10px;
  margin-top: 5px;
  cursor: pointer;
  width: 1.4rem;
}

.mar-right-img {
  margin-right: 30px;
}

.create-option-text {
  color: #018EE0;
  font-size: 13px;
  font-weight: bold;
  border-top: 1px solid #dee2e6;
}

.create-option-text:hover {
  text-decoration: underline;
}

.acris-text:hover {
  text-decoration: none;
  color: #fff;
}

.dropdown-text {
  font-size: 12px;
  /* width: 150px; */
  height: 25px;
  padding: .12rem .3rem;
}

.dropdown-text {
  font-size: 12px;
  /* width: 150px; */
  height: 34px;
  padding: .12rem .3rem;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 32px !important;
  border-radius: 5px !important;
}

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height: 20px !important;
} */

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 33px !important;
}

/* create client popup */
.create-client-modal .create-user-input {
  height: 24px !important;
}

.mt-40 {
  margin-top: 32px;
}

.mb-20 {
  margin-top: 0.5rem;
}

.bulletlist {
  list-style: none;
  text-align: left;
  padding-left: 86px;
}

.duplicate-items .modal-body {
  height: 370px !important;
}

.Acrisbtn {
  margin-left: 0.9rem;
}

.radioStyle {
  margin-top: 6px;
}

.zolabisbtn a {
  min-width: 12%;
  margin: 0rem 0.9rem;
}

.zolabisbtn .acris-link{
  margin: 0rem 1rem !important;
}
.zolabisbtn .zola-link{
  width: 8% !important;
}
.zolabisbtn .bis-link{
  width: 8% !important;
}

.zolaBtn {
  /* margin-bottom: 0.1rem;
  width: 100%;
  background-color: #018EE0;
  border: 0px;
  color: #fff;
  border-radius: 0.3rem;
  padding: 1px;
  font-size: 15px;
  font-weight: bold;
  text-align: center; */
  width: 100% !important;
  height: 100% !important;
  line-height: 50% !important;
  font-weight: bold !important;
}

.nxtBtn {
  margin-bottom: 0.1rem;
  background-color: #018EE0;
  border: 0px;
  color: #fff;
  border-radius: 0.3rem;
  padding: 1px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  width: 10%;
  margin-left: 15px;
  height: 25px;
  margin-top: 3px;
}

.disabledbtn {
  margin-bottom: 0.1rem;
  margin-left: 1rem;
  width: 15%;
  background-color: #a6a6a6;
  border: 0px;
  color: #fff;
  border-radius: 0.3rem;
  padding: 1px;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.zolabisbtn {
  display: inline-flex;
  width: 88.6%;
  justify-content: flex-end;
}

.sectitle {
  width: 10%;
  margin-left: 20px;
}

/* Select css */

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

  .Add-new-users{
    overflow: hidden !important;
  }
  .Add-new-users-content{
    position: relative !important;
    bottom: 2.5rem !important;
  }