@media (max-width: 769px) {
    .email-wrapper .mail-sidebar {
      position: relative;
    }
  }
  
  @media (max-width: 767.98px) {
    .email-wrapper .mail-sidebar {
      position: fixed;
      z-index: 99;
      background: #ffffff;
      width: 45%;
      min-width: 300px;
      left: -100%;
      display: block;
      transition: 0.4s ease;
      -webkit-transition: 0.4s ease;
      -moz-transition: 0.4s ease;
    }
  }
  
  .email-wrapper .mail-sidebar .menu-bar {
    width: 100%;
    float: right;
    height: 100%;
    min-height: 100%;
  }
  
  @media (max-width: 767.98px) {
    .email-wrapper .mail-sidebar .menu-bar {
      min-height: 100vh;
      max-height: 100%;
      height: auto;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items {
    padding: 0;
    margin-bottom: 0;
    height: auto;
    list-style-type: none;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items li {
    padding: 10px 15px;
    transition: 0.4s;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items li:hover {
    background: rgba(240, 244, 249, 0.8);
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items li a {
    color: #303a40;
    font-size: 0.75rem;
    text-decoration: none;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items li a i {
    margin-right: 8px;
    font-size: 0.75rem;
    line-height: 1.5;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items li.active {
    background: #e6e9ed;
    border-radius: 4px;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items li.active a {
    color: #464dee;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .menu-items li.compose:hover {
    background: transparent;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status {
    margin-top: 1rem;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status .chat {
    font-size: 0.75rem;
    color: #464dee;
    margin-bottom: 0;
    font-weight: 600;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status .status {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    display: inline-flex;
    justify-content: flex-start;
    transform: translateX(-43px) translateY(2px);
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status .status:after {
    font-size: 12px;
    color: #2e383e;
    margin: -5px 0 0 18px;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status .status.offline {
    background: #ef5958;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status .status.offline:after {
    content: "Offline";
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status .status.online {
    background: #0ddbb9;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .online-status .status.online:after {
    content: "Online";
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list {
    padding: 10px 0;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item {
    border-bottom: 1px solid #e9e9e9;
    padding: 6px 0;
    display: block;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item:last-child {
    border-bottom: 0;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item a {
    text-decoration: none;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item a .pro-pic {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0;
    width: 20%;
    max-width: 40px;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item a .pro-pic img {
    max-width: 100%;
    width: 100%;
    border-radius: 100%;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user {
    width: 100%;
    padding: 5px 10px 0 15px;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-name {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1;
    color: #000000;
  }
  
  .email-wrapper .mail-sidebar .menu-bar .profile-list-item a .user .u-designation {
    font-size: calc(0.75rem - 0.1rem);
    margin-bottom: 0;
  }
  
  .email-wrapper .sidebar.open {
    left: 0;
  }
  
  /* Mail List Container */
  .email-wrapper .mail-list-container {
    border-left: 1px solid #e9e9e9;
    height: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .email-wrapper .mail-list-container a {
    text-decoration: none;
  }
  
  .email-wrapper .mail-list-container .mail-list {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    flex-direction: row;
    padding: 10px 15px;
    width: 100%;
  }
  
  .email-wrapper .mail-list-container .mail-list:last-child {
    border-bottom: none;
  }
  
  .email-wrapper .mail-list-container .mail-list .form-check {
    margin-top: -15px;
    width: 11%;
    min-width: 20px;
  }
  
  .email-wrapper .mail-list-container .mail-list .content {
    width: 83%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .email-wrapper .mail-list-container .mail-list .content .sender-name {
    font-size: 0.75rem;
    font-weight: 400;
    max-width: 95%;
  }
  
  .email-wrapper .mail-list-container .mail-list .content .message_text {
    margin: 0;
    max-width: 93%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .email-wrapper .mail-list-container .mail-list .details {
    width: 15.5%;
  }
  
  .email-wrapper .mail-list-container .mail-list .details .date {
    text-align: right;
    margin: auto 15px auto 0;
    white-space: nowrap;
  }
  
  .email-wrapper .mail-list-container .mail-list .details i {
    margin: auto 0;
    color: #ddd;
  }
  
  .email-wrapper .mail-list-container .mail-list .details i.favorite {
    color: #fcd539;
  }
  
  .email-wrapper .mail-list-container .mail-list.new_mail {
    background: #e6e9ed;
  }
  
  .email-wrapper .mail-list-container .mail-list.new_mail .details .date {
    color: #000000;
  }
  
  /* Message Content */
  .email-wrapper .message-body .sender-details {
    padding: 20px 15px 0;
    border-bottom: 1px solid #e9e9e9;
    display: -webkit-flex;
    display: flex;
  }
  
  .email-wrapper .message-body .sender-details .details {
    padding-bottom: 0;
  }
  
  .email-wrapper .message-body .sender-details .details .msg-subject {
    font-weight: 600;
  }
  
  .email-wrapper .message-body .sender-details .details .sender-email {
    margin-bottom: 20px;
    font-weight: 400;
  }
  
  .email-wrapper .message-body .sender-details .details .sender-email i {
    font-size: 1rem;
    font-weight: 600;
    margin: 0 1px 0 7px;
  }
  
  .email-wrapper .message-body .message-content {
    padding: 50px 15px;
  }
  
  .email-wrapper .message-body .attachments-sections ul {
    list-style: none;
    border-top: 1px solid #e9e9e9;
    padding: 30px 15px 20px;
  }
  
  .email-wrapper .message-body .attachments-sections ul li {
    padding: 10px;
    margin-right: 20px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
  }
  
  .email-wrapper .message-body .attachments-sections ul li .thumb {
    display: inline-block;
    margin-right: 10px;
  }
  
  .email-wrapper .message-body .attachments-sections ul li .thumb i {
    font-size: 30px;
    margin: 0;
    color: #2e383e;
  }
  
  .email-wrapper .message-body .attachments-sections ul li .details p.file-name {
    display: block;
    margin-bottom: 0;
    color: #2e383e;
  }
  
  .email-wrapper .message-body .attachments-sections ul li .details .buttons .file-size {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 13px;
  }
  
  .email-wrapper .message-body .attachments-sections ul li .details .buttons a {
    font-size: 13px;
    margin-right: 10px;
  }
  
  .email-wrapper .message-body .attachments-sections ul li .details .buttons a:last-child {
    margin-right: 0;
  }

  .email_container{
    padding: 0px 15px;
    overflow-x: hidden;
    /* margin: 0 14px;
    border-radius: 8px; */
    width: -webkit-fill-available;
    position: relative;
    top: -1rem !important;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  }

  .email_container .mail-view ,
  .email_container .mail-list-container , .email_container .mail-sidebar{
    max-height: 78vh;
    min-height: 82vh;
    overflow: auto;
  } 

  .custom_container{
    position: sticky;
    top: 0px;
    background: #fff;
    z-index: 100;
  }

  .custom_email_header{
    position: sticky;
    top: 0px;
    background: #fff;
  }

  .no-image-icon{
    font-size: 22px;
    padding: 17px;
    line-height: 14px;
    width: 49px;
    background: skyblue;
    height: 49px;
   
  }

  .email-comment-box  .last-span{
    margin-right: 27em !important;
  }
  .tox-tinymce{
    /* left: 381px !important; */
  }
  .border-bottom .email_cancel_icon{
    position: absolute;
    top: 11px;
    right: 15px;
    cursor: pointer;
    color: grey;
  }

  .comment-here-box .callLog-desc-editor>.mce-content-body{
    height: 300px !important;
    text-align: left !important;
  }

  .email-body-inputs input{
 height: 2em !important;
  }


  .compose-email-popup .modal-body{
    padding: 10px 35px !important;
  }

  .reply-content .callLog-desc-editor>.mce-content-body {
    height: 110px!important;
    text-align: left !important;
}
.cross-btn{
  position: relative;
    top: -97px;
    left: 31px;
}

.reply-message-body .custom_email_header
{
  padding: 14px 14px 0px 14px;
}
.email-wrapper .message-body .reply-content {
  padding: 4px 15px;
}
.reply-content{
  max-height: 100%;
    height: 371px;
    overflow-y: scroll;
}

.reply-message-body{
  background-color: #fff !important;
 
    left: 10px;
    position: relative;
}
/* new css */

.email-wrapper .mail-list-container .mail-list .details p{
  text-align: end !important;
}
.email-wrapper .Collapse-Icon-Wrapper{
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.List-Wrapper .emailTabs-wrapper{
  background-color: #fff;
  display: flex;
  justify-content: center;
}

.emailTabs.nav-tabs{
  border-bottom: none !important;
}

.emailTabs.nav-tabs .nav-link.active{
    background: transparent !important;
    color: #018ee0 !important;
    box-shadow: none !important;
    border: none;
    border-bottom: 2px solid #018ee0 !important;
    width: max-content !important;
    font-weight: bold;
    font-size: 14px;
    padding: .5rem 0.5rem;
    margin-right: 0.4rem;
}

.emailTabs > .nav-link{
    font-family: 'Avenir Next LT W02';
    font-weight: bold;
    box-shadow: none !important;
    color: #222121 !important;
    width: max-content !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 14px;
    padding: .5rem 0.5rem;
    margin-right: 0.4rem;
    border-bottom: 2px solid transparent !important;
}

.Email-sort{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dee2e6 !important;
  position: sticky;
  top: 0px;
  background: white;
  z-index: 3;
}

.Email-sort .sort-by{
  font-size: 14px;
  color: #222121;
  font-weight: bold;
  opacity: 0.9;
  margin-right: 0.5rem;
}
.emailTabs .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover{
  border-color: transparent !important;
}
.Display-Visible{
  display: flex !important;
}
.Display-Hidden{
  display: none !important;
}
.arrowicon{
  height: 100%;
  text-align: center;
  position: relative;
  margin-right: 10px;
}
.arrowicon:hover{
  cursor: pointer;
}

.sender-email a:hover {
  text-decoration: none !important;
  color: #40a9ff !important;
  cursor: default;
}

.Email-Body-Wrapper .DraftEditor-editorContainer .RichTextEditor__block___2Vs_D{
  margin: 5px 0px !important;
}

.Email-Body-Wrapper .radioStyle input[type='radio'],.Email-Body-Wrapper input[type='checkbox']{
  margin-top: 0.7rem !important;
}
.schedule-input-wrapper .schedule-option{
  margin-left: 1rem !important;
  margin-bottom: 0px !important;
}

.comment-cancel-button .disabled{
  height: 1.8rem !important;
    width: max-content !important;
    padding: 0.5rem 1rem !important;
    line-height: 0.9rem !important;
    background-color: #9f9e9e !important;
}

.schedule-date-wrapper{
  padding: 10px 15px !important;
  margin: 0px !important;
}
.schedule-formGroup{
  margin-bottom: 0px !important;
}
.Schedule-PopUp .ant-modal-body{
  padding: 0px !important;
}

.jodit-react-container .jodit-status-bar{
  display: none !important;
}
.Compose-Email-Wrapper .compose-btns{
  width: max-content !important;
  margin-top: 0.5rem !important;
}

.Compose-Email-Wrapper .disabled{
  width: max-content !important;
  background-color: #9f9e9e !important;
  border-color: #9f9e9e !important;
  margin-top: 0.5rem !important;
}

.Compose-Email-Wrapper .email-buttons{
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-top: 0rem;
}
.Compose-Email-Wrapper .email-setting-icon{
  height: 1.8rem !important;
  line-height: 0.9rem !important;
  border-radius: 0px;
  background: #fff;
  color: #4e4e4e;
  font-size: 0.9rem;
  border-color: #d9d9d9;
  padding: 0.4rem;
}
.Compose-Email-Wrapper .email-buttons button{
  height: 1.8rem !important;
  line-height: 0.9rem !important;
  border-radius: 0px;
  background: #fff;
  color: #4e4e4e;
  font-size: 0.9rem;
  border-color: #d9d9d9;
}

.Compose-Email-Wrapper .email-buttons .ant-dropdown-button .ant-btn-compact-first-item{
  background: #fff;
  padding: 0.6rem !important;
  line-height: 0px !important;
  color: #4e4e4e !important;
  font-size: 0.9rem !important;
  border-radius: 0px;
}
.Compose-Email-Wrapper .email-buttons .ant-dropdown-button .ant-btn-compact-last-item{
  background: #fff !important;
  padding: 0.5rem !important;
  border-radius: 0px;
  color: #4e4e4e !important;
}
.Compose-Email-Wrapper .email-buttons .ant-dropdown-button .ant-btn-compact-last-item:focus-visible{
  outline: none !important;
}
.Compose-Email-Wrapper .email-buttons .ant-dropdown-button .ant-btn-compact-last-item:visited{
  --antd-wave-shadow-color: none !important;
}
.Compose-Email-Wrapper .email-buttons .ant-dropdown-button .ant-btn-compact-last-item span{
  position: relative !important;
  /* bottom: 0.2rem !important; */
  /* right: 0.3rem !important; */
}
.Compose-Email-Wrapper .email-buttons .ant-dropdown-button .ant-btn-default:hover{
  border-color: #d9d9d9 !important;
}
.Compose-Email-Wrapper .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle{
  background-color: #fff !important;
  color: #4e4e4e !important;
  box-shadow: none !important;
}
.Compose-Email-Wrapper .btn.focus, .btn:focus{
  box-shadow: none !important;
}
.Compose-Email-Header .email-setting-icon .Action-Btn-Ant-Dropdown{
  margin-left: 0px !important;
}
.Schedule-PopUp .ant-modal-footer{
  display: block !important;
}
