.global-search-icon{
    position: relative;
    top: 8px;
    left: 0px;
    color: #ccc;
}

.task_global_search>div>input:focus{
    box-shadow: none;
}

.task_global_search>div>input::placeholder{
    color: #ccc;
}