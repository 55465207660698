.bottom-bar {
  position: fixed;
  bottom: 1rem;
  left: 10rem;
  width: 80%;
  background-color: #3c3c3c;
  padding: 10px;
  color: rgb(255 255 255);
  z-index: 999;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  font-family: "Avenir Next LT W02";
  font-size: 13px;
  font-weight: 500;
  }
  
  .bottom-bar .ant-tag {
    /* background-color: #1890ff; */
    color: #000;
  }
 .bottom-bar .Bottom-Bar-Count-Tag {
  padding: 0.3rem;
  display: flex;
  width: 10rem;
  font-size: 13px;
  font-weight: 500;
  justify-content: space-around;
 }
 .bottom-bar Button{
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #fff !important;
 }
 .bottom-bar .anticon-close-circle svg {
  width: 13px !important;
  height: 13px !important;
 }
 .bottombar-delete{
  color: red;
  opacity: 0.8;
 }
 .bottom-bar .ant-select {
  width: 9% !important;
 }
 .Subtask-Table .bottom-bar .ant-select-selector {
  min-width: 10% !important;
 }

.Subtask-Table .bottom-bar{
  padding: 10px !important;
}
 .TasksList-Table .bottom-bar .ant-select-selector {
  min-width: 10% !important;
 }

.TasksList-Table .bottom-bar{
  padding: 10px !important;
}
.bottom-bar .white-placeholder .ant-select-selection-placeholder{
  font-weight: 500 !important;
  padding: 0px !important;
  width: max-content !important;
}