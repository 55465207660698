.Create-Blueprint-Wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    color: #5b6c97;
}

.Create-Blueprint-Wrapper h5,
.Create-Blueprint-Wrapper h4 {
    color: #5b6c97;
}

.Create-Blueprint-Wrapper .Create-Blueprint-Body {
    display: flex;
    width: 100%;
    height: calc(100% - 20%);
    background-color: white;
}

.Create-Blueprint-Wrapper .Create-Blueprint-Body .right-section {
    border-left: 1px solid #e8e8e8;
}

.Create-Blueprint-Wrapper .Blueprint-Header {
    display: flex;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e1e1e1;
}

.Create-Blueprint-Wrapper .Blueprint-Header .row {
    width: 100%;
    height: 100%;
    margin: 0px !important;
}

.Create-Blueprint-Wrapper .Input-Group {
    margin: 18px 5px !important;
    position: relative;
}

.Create-Blueprint-Wrapper .Input-Group .input {
    font-size: 1.5rem;
    padding: 5px 13px;
    font-weight: 600;
}

.Create-Blueprint-Wrapper .Input-Group .labeltext label {
    position: absolute;
    top: -11px;
    left: 6px;
    z-index: 1;
    color: #5b6cb2;
    background: white;
    padding: 0px 8px;
}

.Create-Blueprint-Wrapper .Input-Group .labeltext {
    font-weight: 600;
}

.Create-Blueprint-Wrapper .Blueprint-Header .logo {
    height: 48px;
    color: #42cd4a;
}

.Create-Blueprint-Body .Add-Node-Button {
    display: flex;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
}

.Trasition-Tabs .ant-tabs-nav-list .ant-tabs-tab {
    font-size: 20px !important;
    min-width: 7rem !important;
    display: flex !important;
    justify-content: center !important;
}

.Blueprint-Field-Popover .ant-select-selector {
    height: 30px !important;
}

.Blueprint-Field-Popover .ant-select-selector input {
    height: 30px !important;
}

.Add-criteria-Wrapper .ant-btn svg {
    height: 16px !important;
    width: 16px !important;
}

.Add-criteria-Wrapper .ant-modal-body {
    /* min-height: 45vh !important; */
    max-height: 60vh !important;
    overflow: auto !important;
}

.Add-criteria-Wrapper .ant-modal-footer {
    display: block !important;
}

.andCriteria {
    border: 1px solid lightgray;
    border-radius: 18%;
    background: #d4d9f4;
    width: 4em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: .7em;
}

.Flow-Button {
    display: flex;
    position: absolute;
    margin: 10px;
    align-items: center;
    z-index: 1;
    color: #5b6c97;
    font-weight: 600;
    padding: 4px;
    background: white;
}

.Selected-Input-Field-Wrapper .container {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap onto the next line */
    gap: 10px;
    /* Space between cards */
}

.Selected-Input-Field-Wrapper .Card .Close-Icon {
    visibility: hidden;
    position: absolute;
    right: -9px;
    top: 0px;
    font-size: 12px;
    height: 100%;
    padding: -1px -4px;
    color: #ff2727;
    background: #d7d7d7;
    /* border-radius: 29px; */
    width: 33px;
    padding: 10px;
}

.Selected-Input-Field-Wrapper .Add-Icon {
    min-width: max-content !important;
    max-width: max-content !important;
    background-color: #018ee0 !important;
    color: white !important;
}

.Selected-Input-Field-Wrapper .Card {
    position: relative;
    background-color: #f3f3f3;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    flex: 1 1 auto;
    min-width: 60px;
    font-weight: 500;
    width: auto;
    color: #5b6c97;
    max-width: 215px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    white-space: nowrap;
    /* Prevents the text from wrapping */
    overflow: hidden;
    /* Hides the overflowed text */
    text-overflow: ellipsis;
    /* Displays an ellipsis (...) */
}

.Selected-Input-Field-Wrapper .Card:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.Selected-Input-Field-Wrapper .Card:hover .Close-Icon {
    visibility: visible;
}
.Selected-Input-Field-Wrapper{
    max-height: 12rem;
    overflow-y: auto;
}
.Add-Diverse-Popover {
    top: 195px !important;
}

.Direction-Wrapper {
    margin: 0px !important;
}

.Direction-icons {
    height: 2rem;
    width: 2rem;
    background: aliceblue;
    padding: 6px;
    opacity: 0.5;
    border-radius: 37px;
    border: 1px solid #dedede;
    margin: 3px;
}

.Direction-icons:hover {
    opacity: 1;
}

.Timeline-Custom-Dot {
    display: flex;
    /* background: #ffdfb4; */
    padding: 5px 6px;
    border-radius: 25px;
    font-weight: 600;
    border: 1px solid;
    border-color: #c8c8c8;
    color: #959595;
}

.Timeline-Custom-Class .AND {
    background: #ffdfb4;
}
.Timeline-Custom-Class .Custom-Dot .ant-timeline-item-head {
    background: transparent !important;
}

.Timeline-Custom-Class .OR {
    background: #a3e2ff;
}

.Criteria-Preview-container {
    display: flex;
    padding: 21px;
    position: relative;
    height: 10rem;
    /* background-color: #f0f0f0; */
    overflow: scroll;
    margin-top: 10px;

    /* new */
    /* background-color: #ffffff; 
    border: 1px solid #ddd;
    border-radius: 10px; 
    padding: 10px; 
    width: 100%; 
    max-width: 600px; 
    box-shadow: 0px 2px 10px 2px gray; 
    margin: 16px auto; 
    transition: transform 0.2s ease, box-shadow 0.2s ease;  */
}
.Criteria-Overlay{
    display: flex;
    visibility: hidden;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: #0081ff0f;
    height: 11rem;
    z-index: 1;
    width: 100%;
    top: 0px;
    left: 0px;
}
.Criteria-Preview-container:hover .Criteria-Overlay{
    visibility: visible !important;
}
/* .Criteria-Preview-container .Criteria-List .Criteria-Item{
    font-size: 15px;
    font-weight: 600;
    color: #5b6c97;
} */

/* .BluePrint-Reminder .ant-popover-inner{
    width: 30rem;
}
.BluePrint-Reminder .ant-popover-inner-content{
    width: 100% !important;
    height: auto;
} */
.renderTemplates{
    display: flex;
    flex-direction: row;
    gap: .8em;
    flex-wrap: wrap;
}
.Email-Template-Modal .ant-modal-content{
    width: 777px !important;
}
.Email-Template-Modal .ant-modal-footer{
    display: block !important;
}
.Email-Templates .ant-popover-inner-content{
    width: 600px !important;
    min-height: 5rem !important;
    padding: 1rem 0.5rem !important;
}

.Email-Templates .ant-popover-inner-content .labeltext{
    position: absolute;
    top: -14px;
    left: 16px;
    z-index: 1;
    color: #5b6cb2;
    background: white;
    padding: 0px 8px;
    height: 1.4rem;
}
.Email-Templates .ant-select{
    width: 100% !important;
    height: 100% !important;
}
.Email-Templates .ant-popover-title{
    color: #5b6c97 !important;
    background-color: #f8f8f8 !important;
    padding: 0px 0.5rem;
}
.Email-Templates .ant-popover-content{
    left: 0px !important;
}
.email-temp-field{
    font-weight: 600;
    position: relative;
    z-index: 1;
    top: .4em;
    left: 1em; 
    width: fit-content;
    background: white;
}
.Global-Text-Color-Temp { 
  width: 100% !important;
  height: auto !important; 
}
.AddFieldButton{
    height: 1.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-weight: 500;
    left: 11px;
    width: 4rem;
    background: #018ee0;
    color: white;
    border-radius: 7px;
}
.Input-Date-FieldUpdate{
    width: -webkit-fill-available !important
}
.renderUpdateFields{
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    row-gap: 1em;
    padding: .6em;
}
.FieldCard{
    display: flex;
    padding: .6em;
    border-radius: 7px;
    background: #f3f3f3;
    border: 1px solid #d1d1d1;
}