.Activity-Comment-Editor .jodit-react-container{
    width: 100% !important;
}
.Comment-Section{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Subtask-Table{
    overflow: scroll;
    border: 1px solid #f1f1f1;
    border-radius: 7px !important;
}
.Subtask-Table .TaskGroup2Card{
    margin: 0px;
    padding: 0px;
}
.checklistTab{
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .2em;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
}
.checklistRow{
    border-bottom: 1px solid #f0f0f0;
    padding: .5em;
}
.headerChecklist{
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: .4em !important;
}
.checklistPopoverSpan{ 
    position: relative;
    z-index: 1;
    background: white;
    width: fit-content;
    top: .6em
}
.checklistPopoverDiv{
    padding: 1em;
}
.footer{
    border-top: 1px solid #edf0f4;
    display: flex;
    padding-top: 0.6em;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}
.checklistDeleteIcon{
    position: relative;
    cursor: pointer; 
    left: 4rem;
    color: red; 
    opacity: .4;
}
.checklistDeleteIcon:hover{
    opacity: 1;
}
.checklistWrapper{
    min-height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
}