
.deal-status-dropdown-custom2{
  width: 10rem !important;
}
.deal-status-dropdown-custom2 ul{
    text-align: center;
    padding: 5px;
  }
  
  .deal-status-dropdown-custom2 ul li{
      border-radius: 4px;
      padding: 0px 10px;
      display: flex;
      height: 30px;
      align-items: center;
      margin-bottom: 5px;
  }
  .deal-status-dropdown-custom2 button:hover{
    color: #fff;
  }
  
  .deal-status-dropdown-custom2 button{
    border-radius: 30px;
    background-color: #fbc11e;
      height: 24px;
      padding: 0 8px;
      font-weight: 600;
      min-width: 80px;
      color: #fff;
  }
  
  .deal-status-dropdown-custom2 ul{
    min-width: 0px !important;
  }
  
  .deal-status-dropdown-custom2 ul li a{
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    padding: 0px !important;
    color: #fff;
  }
  
  .deal-status-dropdown-custom2 ul li a:hover{
    background-color: unset !important;
    color: #fff;
  }
  
  
   .deal-status-dropdown-custom2 button::after{
    display: none !important;
  } 
  
  .deal-status-dropdown-custom2 .account-name-tile{
    white-space: nowrap;
    width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }
  
  .deal-status-dropdown-custom2 button span{
    display: inherit;
  }
  
  .overlayBtn-wrapper{
   display: none;
  }
  .deal-status-dropdown-custom2 button:hover .main-span{
    display: none !important;
  }
  .deal-status-dropdown-custom2 button:hover .overlayBtn-wrapper{
    display: flex;
    transition: all 1s ease-in-out !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 2px !important;
    left: 0% !important;
    top: 0% !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
  }
  
  .deal-status-dropdown-custom2 button:hover::after {
    display: none !important;
  }
  .deal-status-dropdown-custom1 button:hover::after {
    display: none !important;
  }
  .deal-status-dropdown-custom2 button:hover{
    background-color:transparent !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }
  .Action-Buttons{
    width: 50% !important; 
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 20px !important;
    padding: 1px;
  }
  .Action-Checkbox{
    width: 50% !important; 
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 20px !important;
    padding: 1px;
  }
  /* .deal-status-dropdown-custom-name button:hover::after {
    visibility: hidden !important;
  }
  
  .deal-status-dropdown-custom-client button:hover::after {
    visibility: hidden !important;
  } */


  
.deal-status-dropdown-custom1{
  width: 10rem !important;
}
.deal-status-dropdown-custom1 ul{
    text-align: center;
    padding: 5px;
  }
  
  .deal-status-dropdown-custom1 ul li{
      border-radius: 4px;
      padding: 0px 10px;
      display: flex;
      height: 30px;
      align-items: center;
      margin-bottom: 5px;
  }
  .deal-status-dropdown-custom1 button:hover{
    color: #fff;
  }
  
  .deal-status-dropdown-custom1 button{
    border-radius: 30px;
    background-color: #fbc11e;
      height: 24px;
      padding: 0 8px;
      font-weight: 600;
      min-width: 80px;
      color: #fff;
  }
  
  .deal-status-dropdown-custom1 ul{
    min-width: 0px !important;
  }
  
  .deal-status-dropdown-custom1 ul li a{
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    padding: 0px !important;
    color: #fff;
  }
  
  .deal-status-dropdown-custom1 ul li a:hover{
    background-color: unset !important;
    color: #fff;
  }
  
  
  .deal-status-dropdown-custom1 button::after{
    display: none !important;
  }
  .deal-status-dropdown-custom2 button::after{
    display: none !important;
  }
  
  .deal-status-dropdown-custom1 .account-name-tile{
    white-space: nowrap;
    width: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
  }
  
  .deal-status-dropdown-custom1 button span{
    display: inherit;
  }
  .deal-status-dropdown-custom3 .wrapper{
    display: flex !important;
    gap: 12px !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .deal-status-dropdown-custom3 .wrapper .Action-Buttons{
    padding: 0px 31px !important;
  }
  .deal-status-dropdown-custom3 .wrapper .Action-Checkbox{
    padding: 0px !important;
    gap: 10px !important;
    font-weight: bold;
  }
  .deal-status-dropdown-custom3 .wrapper .Pending{
   color: #5B6C97!important;
  }
  .deal-status-dropdown-custom3 .wrapper .Approved{
    color: #5B6C97 !important;
  }
  .deal-status-dropdown-custom3 .wrapper .Rejected{
    color: #5B6C97 !important;
  }

  .deal-status-dropdown-custom3 .wrapper .Active{
    background-color: rgb(0, 102, 255);
    color: white !important;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }

  .Edit-Column-Modal2 .modal-content{
    width: 29% !important;
    display: flex;
    position: relative !important;
    overflow: hidden !important;
    height: 7rem !important;
    left: 11% !important;
    top: 58px;
  }
  .Edit-Column-Modal2 .editColummn-list{
    position: relative !important;
    right: 3rem !important;
  }
  .Edit-Column-Modal2 .editColummn-list .editColumnlist-text{
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    width: max-content !important;
  }

  .addclss{
    transition: width 0.4s ease-in-out;
    border-radius: 5px;
      box-shadow: rgb(255 98 98) 0px 0px 7px 1px;
      border-color: #ff0000;
      border: 1px solid red;
  }