.notes_card_profile{
    border-radius: 50%;
    width: 5%;
    height: 5%;
}

.notes_card_profile img{
    width: 100%;
}


.add-title{
    border-radius: 15px;
    padding-left: 12px;
    border: 1px solid #999999;
    margin-bottom: 12px;
}

.add-title:focus{
    border: 1px solid #999999;
    outline: 0px;
}

.create-notes-btn{
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #007bff !important;
    color: white !important;
    border-color:#007bff !important ;
  
}



.addnotetop-botton-pad {
    padding-top: 0px;
    padding-bottom: 0px;
    font-family: 'Avenir Next LT W02';
    width: 110px;
    font-size: 13px;
    /* font-weight: bold; */
    border-radius: 6px;
    margin: 5px 5px;
    float: right;
    border: none;
  }
  
  .addnoteblue-background3D {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAAgCAMAAADgzjIVAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGBQTFRFAJ/0AJ70AJ31AJz1AJv1AJr2AJn2AZj2AZf3AZX3AZT3AZP4AZL4AZH4AZD4AY/5AY75AY35AYv6AYr6Aon6Aoj7Aof7Aob7AoX8AoT8AoD4AnnwAXLoAWvgAWXYAF7QQJE3PwAAAGVJREFUeJwNwYkChBAAQMGXUiIdyBHl//9ydwb+hmFACME4jkzThJSSeZ5ZlgWlFOu6orXGGMO2bVhr2fed4zg4z5PrunDO4b0nhMB938QYSSmRc6aUwvM81FpprfG+L9/30Xv/AfSgBdHYOfQ4AAAAAElFTkSuQmCC);
    background-repeat: repeat-x;
    height: 25px;
  }
  
  .create-note-box{
  padding-right: 0px;
  }
  
  .note-header-icon{
    cursor: pointer;
  }
  
  .hide-commentbox{
    display: none;
  }
  
  .note-ckeditor .ck.ck-editor__editable_inline{
    height: 250px;
  }
  
  .comment-ckeditor .ck.ck-editor__editable_inline{
    height: 75px;
    border-radius: 14px 14px 1px 1px !important;
  }
  
  .comment-ckeditor .ck.ck-reset_all, .ck.ck-reset_all * {
    font-size: 10px;
  }
  
  .note-container{
    border: 1px solid #dee2e6;
      border-radius: 6px;
      padding: 5px 15px 0px 15px;
      font-size: 13px;
      margin-top: 5px;
      margin-left: 15px;
      margin-right: 10px;
      box-shadow: 0 1px 4px #968d8d;
      background-color: #fff;
  }
  
  .create-note-btn{
    padding: 5px;
      height: auto;
      margin: 12px 12px 12px 0;
      float: right;
      border: none;
  }
  
  .note-container-header{
    padding: 5px 10px;
  }
  .note-comment-body .note-container-header{
    padding: 5px 44px;
  }
  .note-reply-body .note-container-header{
    padding: 5px 44px;
  }
  
  .ck.ck-editor {
    display: flex;
    flex-direction: column-reverse;
  }
  
  /* .note-comment-container{
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  } */
  
  .notes-section{
    background-color: #fff;
  }
 
  .pinned-messages-wrapper{
    display: flex;
    align-items: center;
    width: 92%;
    border-left: 3px solid #018ee0;
    margin-left: 36px;
    border-radius: 5px;
    /* padding: 8px; */
    background: #fff;
    position: relative;
    box-shadow: 0px 3px 18px -5px grey;
  }

  .pinned-messages{
    margin-left: 3px;
    width: 95%;
    color: #000;
    font-size: 15px;
    font-weight: 600;
  }

  .pinned-messages p{
    margin: 0px !important;
    padding: 8px !important;
  }

  .remove-note{
    position: absolute;
    right: 10px;
    /* color: red; */
  }

  .pin-note-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* z-index: 11; */
    /* padding: 5px 0px; */
  }
  
  .notes-scroller-unpinned{
    height: calc(100vh - 260px);
    overflow-y: auto;
    padding: 15px;
    /* padding-right: 10px; */
    /* margin-top: -12px; */
  }
  .notes-scroller{
    height: calc(100vh - 290px);
    overflow-y: auto;
    padding: 15px;
    /* padding-right: 10px; */
    /* margin-top: -12px; */
  }

  .noteheader-date{
    float: right;
  }
  .Pinned-Note-Class{
    border-left: 3px solid #018ee0;
  }
  
  .note-modalwidth {
    right: 3%;
    position: fixed;
    /* border: 2px solid #dee2e6; */
    bottom: -1%;
    width: 100%;
  }
  
  .note-action-text{
    color: #0180C7;
    cursor: pointer;
  }
  .action-drop-icon{
    color: #0180C7;
    cursor: pointer;
  }
  .comment-text-hyperlink{
    color: #5b6c97;
    margin: 3px 0px 15px;
  }
  .comment-text-hyperlink:hover{
    color: #5b6c97;
    /* text-decoration: underline; */
    cursor: pointer;
  }
  
  .note-comment-box{
    background-color: #fff;
    /* padding: 15px; */
    font-size: 13px;
  }
  .note-comment-box img{
    width: 30px;
    border-radius:50%;
  }
  .note-comment-box .media-body{
    border-radius: 5px;
    box-shadow: 0 1px 4px #968d8d;
    margin-bottom: 15px;
    padding-bottom: 0.001rem;
  }

  .note-comment-box .note-reply-body{
    /* box-shadow: none !important; */
    /* border: 1px solid grey !important; */
    background: #f6f6f6 !important;
  }

  .add-comment svg{
    margin-right: 3px;
  }
  
  
  .comment-headername{
    padding: 5px 10px;
  }
  .comment-text-box{
    padding: 0px 26px;
  }
  .comment-action{
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 11px;
  }
  
  
  .writecomment-input{
    border-radius: 14px;
      padding: 3px 30px 3px 15px;
      font-size: 11px;
      height: auto;
      margin: 5px;
  }
  .shadow {
    box-shadow: 0 0.5rem 1rem #b8b8b8 !important;
  }
  
  .note-pin{
    position: absolute;
    right: -2px;
    top: -32px;
  }
  
  .note-action-drop .dropdown-toggle,
  .note-action-drop .dropdown-toggle:hover,
  .note-action-drop .dropdown-toggle:active{
    font-size: 11px;
    color: #5b6c97 !important;
    background-color: #fff !important;
    padding: 0;
    border: 0;
    box-shadow: none !important;
  }
  
  .note-action-drop .dropdown-menu {
    font-size: 11px;
    padding: 0;
  }
  
  .note-action-drop .dropdown-menu {
    font-size: 11px;
    padding: 0;
  }
  .note-action-drop{
    text-align: right;
    margin-right: 15px;
  }
  .date-action-wrapper span{
    font-size: 11px;
  }
  
  .note-caret{
    margin-right: 5px;
  }
  .note-caret-pointer{
    display: flex;
    cursor: pointer;
  }
  
  .date-action-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
  }
  
  .small-3d-button{
    background-image: url("../../assets/images/btn-blue-bg.png");
      background-repeat: repeat-x;
      height: 21px;
      background-position: bottom;
  }
  
  .font-13{
    font-size: 13px !important;
  }
  .pinned-header-text{
    font-size: 14px;
    font-weight: bold;
    padding-left:50px;
    font-family: 'Avenir Next LT W02';
  }
  .media-body p{
    margin-bottom: 0;
  }
  .media-footer{
    padding: 4px 10px;
    border-top: 1px solid #ddd;
  }
  .like-icon{
    width: 15px;
    height: auto;
    color: #5b6c97
  }
  
  .marb-16.padtop-15{
    margin: 0;
    padding: 0;
  }
  
  .ck.ck-editor__main>.ck-editor__editable{
    border: 0 !important;
    box-shadow: none !important;
    padding: 0;
  }
  
  .ck.ck-toolbar{
    background: transparent !important;
    border-color: transparent;
    margin-bottom: 5px;
  }
  
  .notes-btns{
    border-top: 1px solid #cccccc;
    padding-top: 10px;
  }
  .fullNote-width{
    background: #0000004a;
  }
  .fullNote-width .note-modalwidth{
    position: initial;
    max-width: 95%;
    height: 92%;
  }
  .fullNote-width .note-modalwidth .modal-content{
    height: 100%;
  }
  .fullNote-width .note-ckeditor .ck.ck-editor__editable_inline {
    height: 58vh;
  }
  .fullNote-width {
    /* max-width: 1070px;
      margin: 0 auto;
      right: inherit;
      margin-left: -500px;
      left: 50%; */
      top: 0;
      left: 0;
      right: 0;
      margin: 30px auto;
      height: 90%;
      max-width: 95%;
      border: 0;
  }
  
  .ck.ck-balloon-panel{
    z-index: 9999;
  }
  
  .ck-mentions .mention__item {
    display: block;
  }
  
  .ck-mentions .mention__item img {
    border-radius: 100%;
    height: 30px;
  }
  
  .ck-mentions .mention__item span {
    margin-left: .5em;
  }
  
  .ck-mentions .mention__item.ck-on span {
    color: var(--ck-color-base-background);
  }
  
  .ck-mentions .mention__item .mention__item__full-name {
    color: hsl(0, 0%, 45%);
  }
  
  .ck-mentions .mention__item:hover:not(.ck-on) .mention__item__full-name {
    color: hsl(0, 0%, 40%);
  }
  .note-header{
    margin-top: 2px;
    padding-left: 48px;
    display: flex;
    align-items: center;
  }
  .notes-searchbar{
    margin-top: 20px;
  }
 .note-searh-icon{
  position: absolute;
  right: 7px;
  top: 9px;
  color: #ccc;
  cursor: pointer;
  }
  
  
  .notes-searchIcon{
    top: 35px !important;
  }


  .modal-notes-body .modal-content{
    width: 101vh;
    margin-top: 16vh;
  }


 .note-comment-box .comment-here-box{
    width: 99%;
    margin: 0 auto;
  }

  .comment-here-box{
    background-color: #fff !important;
    /* margin: 0 -15px; */
    padding: 0px !important;
    font-size: 14px !important;
  }

  .reply-row{
    padding: 0px 14px 0px 60px;
  }

.comment-headername span img{
  max-width: 100% !important;
}

.reaction-comment-wrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
}

.Mentions-Note-Collapse{
  display: flex;
  position: absolute;
  top: 6.5rem;
  left: 0.1rem;
  width: max-content !important;
  height: auto;
  max-height: 18rem;
  overflow: hidden;
  background-color: white;
  z-index: 11111;
}

.Mentions-Note-Collapse .collapse{
  width: max-content !important;
  height: auto;
  max-height: 18rem;
  overflow: scroll;
}

.Mentions-Note-Collapse .Deal-Account-Wrapper{
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.Mentions-Note-Collapse .User-Mention-Header{
  position: sticky;
  top: 0;
  background-color: #0181cb;
  font-weight: bold;
  padding: 3px;
  color: white;
  z-index: 111;
}

.Bordered-Box-Notes{
  padding: 5px;
  border: 1px solid #d1d1d1;
  border-radius: 6px;
}
.mention-note-wrapper{
    position: absolute;
    left: 0rem;
    bottom: 8.3rem;
}
.comment-body{
  /* padding-right: 0px !important; */
}
.note-comment-body{
  box-shadow: none !important;
  border-top: 1px solid grey !important;
  border-radius: 0px !important;
  /* border-radius: 0px !important; */
}

.comment-here-box .collapse{
  position: relative !important;
}
.note-caret-pointer .Pin-Icon{
  margin-left: 10px;
  opacity: 0.3;
}

.note-caret-pointer .Pin-Icon img{
  width: 20px;
  border-radius: 50%;
}

.dropdown-toggle::after{
  font-size: 18px !important;
}

.note-container-header code{
  color: #5b6c97 !important;
}
.note-container-header h1{
  color: #5b6c97 !important;
}
.note-container-header h2{
  color: #5b6c97 !important;
}
.note-container-header h3{
  color: #5b6c97 !important;
}
.note-container-header p img{
  width: 30% !important;
  height: 30% !important;
  border-radius: 0px !important;
}
.note-container-header p{
  display: flex !important;
  /* flex-direction: column !important; */
}