
textarea#mentions {
    height: 350px;
  }
  
  .tox-tooltip-worker-container div.card,
  .tox div.card {
    /* width: 240px; */
    background: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(34, 47, 62, .1);
    padding: 8px 15px;
    font-size: 14px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  }
  
  .tox-tooltip-worker-container div.card::after,
  .tox div.card::after {
    content: "";
    clear: both;
    display: table;
  }
  
  .tox-tooltip-worker-container div.card h1,
  .tox div.card h1 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 8px;
    padding: 0;
    line-height: normal;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  }
  
  .tox-tooltip-worker-container div.card p,
  .tox div.card p {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  }
  
  .tox-tooltip-worker-container div.card img.avatar,
  .tox div.card img.avatar {
    width: 48px;
    height: 48px;
    margin: 0 auto;
  }

  .comment-bottom-mention-icon{
    padding-left: 15px;
    color: lightgray;
    cursor: pointer;
  }

  .comment-bottom-mention-icon:hover{
    
    color: darkgray;
    
  }
  .tox-mentions__card-common{
    display: none;
  }
/* 
  .callLog-desc-editor .mce-content-body>p>span {
    color:rgb(24, 144, 255) !important;
    cursor:pointer;
  } */
  
/* new css */

.custom-rich-text-editor{
  position: relative;
}

.mention-dropdown{
  position: absolute;
  top: 10em;
  left: 2em;
}
.comment-cancel-button{
  position: relative;
  bottom: 1.5rem;
 
}

/* Example styles for the select dropdown and its options */
.custom-rich-text-editor select {
  /* Styles for the select dropdown */
  background-color: #018EE0 !important;
  padding: 3px 4px 0px !important; 
  color: #fff !important;
}



.custom-rich-text-editor select option {
  /* Styles for the dropdown options */
  background-color: #fff !important;
  color: #333 !important;
  padding: 8px !important;

}



@media screen and (-webkit-min-device-pixel-ratio: 0){
  .Dropdown__root___3ALmx select{
    border-right-width: 0px !important;
    border-left-width: 0px !important;
  }
}
.Dropdown__root___3ALmx select{
  line-height: 15px !important;
}

.IconButton__icon-bold___2zl9t{
  background-image: url('../../assets/images/bold-icon.png') !important;
}

.IconButton__icon-italic___2hHzc{
  background-image: url('../../assets/images/italic-icon.png') !important;
}

.IconButton__icon-strikethrough___QtE2X{
  background-image: url('../../assets/images/strikethrough-icon.png') !important;
}

.IconButton__icon-underline___2EmZJ{
  background-image: url('../../assets/images/underline-icon.png') !important;
}
.IconButton__icon-align_left___1S9rt{
  background-image: url('../../assets/images/align-left.png') !important;
}
.IconButton__icon-align_center___KBWGR{
  background-image: url('../../assets/images/align-center.png') !important;
}
.IconButton__icon-align_right___1bWGZ{
  background-image: url('../../assets/images/align-right.png') !important;
}
.IconButton__icon-align_justify___3eBV5{
  background-image: url('../../assets/images/align-justify.png') !important;
}

.IconButton__icon-unordered-list-item___Pvkrr{
  background-image: url('../../assets/images/icon-ul-list.png') !important;
}
.IconButton__icon-ordered-list-item___2rzD0{
  background-image: url('../../assets/images/icons-ol-list.png') !important;
}
.IconButton__icon-blockquote___17VSX{
  background-image: url('../../assets/images/icon-quote.png') !important;
}
.IconButton__icon-link___2umEl{
  background-image: url('../../assets/images/icon-link.png') !important;
  background-size: 17px !important;
}
.IconButton__icon-remove-link___j61pw{
  background-image: url('../../assets/images/icon-link-remove.png') !important;
  background-size: 17px !important;
}
.IconButton__icon-image___1gW7U{
  background-image: url('../../assets/images/icon-image-up.png') !important;
  background-size: 17px !important;
}
.IconButton__icon-undo___EQSRP{
  background-image: url('../../assets/images/icon-undo.png') !important;
  background-size: 17px !important;
}
.IconButton__icon-redo___30MVz{
  background-image: url('../../assets/images/icon-redo.png') !important;
  background-size: 17px !important;
}
.IconButton__icon-code___3F1pe{
  background-image: url('../../assets/images/icon-monospace.png') !important;
  background-size: 17px !important;
}

.ButtonGroup__root___3lEAn>div>button{
  background: #018ee0 !important;
}

.RichTextEditor__root___2QXK-{
  margin-bottom: 7px !important;
}

.InputPopover__checkOption___32S87 label{
  width: 20% !important;
}
.InputPopover__inner___32V5P .InputPopover__buttonGroup___2c3Sl{
  position: absolute !important;
  right: 0 !important;
  top: 0px !important;
}

.show-users{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1px;
}

.opencommenteditor::placeholder{
  font-size: 13px !important;
  font-weight: 500 !important;
}
.RichTextEditor__editor___1QqIU{
  font-size: 14px !important;
}
.Custome-Height{
  height: 65% !important;
  min-height: 65% !important;
  max-height: 65% !important;
}
.Custome-Height .collapse{
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}
.Custome-Height .collapse .callLog-desc-editor{
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
}
.Custome-Height .collapse .callLog-desc-editor .custom-rich-text-editor,.public-DraftEditor-content,.DraftEditor-editorContainer,.DraftEditor-root{
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
} 
.Custome-Height .collapse .callLog-desc-editor .custom-rich-text-editor .RichTextEditor__editor___1QqIU{
  height: 80% !important;
  min-height: 80% !important;
  max-height: 80% !important;
} 
.comment-cancel-button .Attachmnets-Btn{
  display: flex;
  position: absolute;
  bottom: -2rem;
  left: 7rem;
  border: 1px solid #c7c7c7;
  padding: 2px 11px;
  /* content: "Attachments"; */
  color: black;
  border-radius: 13px;
}
/* .Pop-Over-For-Attachments{
  display: flex;
  position: relative;
} */