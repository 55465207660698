.Status-Timeline-Wrapper {
    display: flex;
    width: 100%;
    height: auto;
    overflow: scroll;
    overflow-x: hidden;
    padding: 10px;
}

.container {
    display: grid;
    grid-auto-flow: dense;
    gap: 5px;
    padding: 5px;
}

.item:nth-child(6n + 6) {
    grid-column: 1;
}

.item:nth-child(6n + 5) {
    grid-column: 2;
}

.item:nth-child(6n + 4) {
    grid-column: 3;
}

.item {
    /* text-align: center;
    padding: 20px;
    background-color: #ccc;
    border: 1px solid #999; */
    height: max-content;
    max-height: max-content;
}

.vertical-timeline::before {
    /* this is the vertical line */

    background: black !important;
}

.empty {
    height: 600px;
    border: 1px solid red;
}

.Status-Timeline-Icon-Class {
    --width: 20px;
    /* Example width */
    width: var(--width) !important;
    height: var(--width) !important;
    margin-left: calc(var(--width) / 2 * -1) !important;
    margin-top: calc(var(--width)) !important;
    background-color: #d3d3d3;
}
.Status-Timeline-Icon-Class-Transition {
    --width: 100px;
    /* Example width */
    border-radius: 12px !important;
    width: var(--width) !important;
    height: max-content !important;
    margin-left: calc(var(--width) / 2 * -1) !important;
    background-color: #d3d3d3;
}
.Status-Timeline-Icon-Class-Transition .Transition-Icon{
    text-align: center !important;
    padding: 0px 8px !important;
}

.vertical-timeline::before {
    background: #d3d3d3 !important;
}

.vertical-timeline .Status {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /* width: max-content; */
    background-color: aliceblue;
    border: 1px solid #e0e0e0;
    border-radius: 36px;
    padding: 4px 0px;
}
.vertical-timeline .Duration {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0em 1em 0em 1em;
    justify-content: space-between;
    font-size: small;
}
.vertical-timeline .vertical-timeline-element-content {
   padding: 4px !important;
}
.vertical-timeline .vertical-timeline-element-content-arrow {
   border-right-color: white !important;
}
.vertical-timeline.vertical-timeline--two-columns{
    height: max-content !important;
}
.recordsPopupForTimeTracked{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}
.testClass .ant-popover-inner-content{
    width: 100% !important;
}
.testClass .ant-popover-content{
    width: 32rem !important;
}
.testClass .ant-collapse{
    width: 100% !important;
}
.renderTimeDiv{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .5em;
}
.Transition-Comment-Popover .ant-popover-inner-content{
    min-width: 200px !important;
    width: auto !important;
    max-width: 350px !important;
  }