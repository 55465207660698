
:root {
  --background-color: #ffffff;
  --text-color: #5b6c97;
  --background-color-gray:#f8f8f8;
}

[data-theme='dark'] {
  --background-color: #333333;
  --text-color: #ffffff;
  --background-color-gray:#ffffff;
}

.Input-Field-Popover-Main{
  width: max-content !important;
}
.Input-Field-Popover .ant-popover-inner {
  width: 400px !important;
  max-height: 512px !important;
  height: auto;
}
.Input-Field-Popover .ant-popover-title {
  color: var(--text-color);
  background: var(--background-color-gray);
  padding: 4px 10px;
}

.Input-Field-Popover {
  /* left: 0px !important; */
}
.Input-Field-Popover .ant-popover-arrow {
  top: 50% !important;
  transform: translateY(-50%) rotate(90deg);
}
.Input-Field-Popover .ant-popover-inner .ant-select {
  width: 100%;
  height: auto;
}
.Input-Field-Popover .ant-popover-inner .ant-popover-inner-content{
  width: 100% !important;
  height: 100% !important;
}
.Input-Field-Wrapper .container {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap onto the next line */
    gap: 10px; /* Space between cards */
  }
  
  .Input-Field-Wrapper .Card .Close-Icon{
    visibility: hidden;
    position: absolute;
    right: -9px;
    top: 0px;
    font-size: 12px;
    height: 100%;
    padding: -1px -4px;
    color: #ff2727;
    background: #d7d7d7;
    /* border-radius: 29px; */
    width: 33px;
    padding: 10px;
  }
  .Input-Field-Wrapper .Add-Icon{
    min-width: max-content !important;
    max-width: max-content !important;
    background-color: #018ee0 !important;
    color: white !important;
  }
  .Input-Field-Wrapper .Card {
    position: relative;
    background-color: #f3f3f3;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    flex: 1 1 auto;
    min-width: 60px;
    font-weight: 500;
    width: auto;
    color: #5b6c97;
    max-width: 215px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    white-space: nowrap;        /* Prevents the text from wrapping */
    overflow: hidden;           /* Hides the overflowed text */
    text-overflow: ellipsis;    /* Displays an ellipsis (...) */
  }
  
  .Input-Field-Wrapper .Card:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  .Input-Field-Wrapper .Card:hover .Close-Icon {
    visibility: visible;
  }
  .Input-Field-Wrapper .Card-Wrapper{
    width: 100% !important;
  }
  .Input-Field-Wrapper{
    display: flex;
    width: 100%;
    margin: 0px;
    padding: 6px 0px 6px 6px;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .Input-Field-Wrapper .row{
    margin: 0px !important;
    padding: 0px !important;
  }
  .PuckList-Btn{
    width: max-content !important;
  }
  .PuckList-Btn .ant-switch-inner{
    width: max-content !important;
  }
  .Create-Input-Fields-Section .labeltext label {
    position: absolute;
    top: -11px;
    left: 6px;
    z-index: 1;
    color: #5b6cb2;
    background: white;
    padding: 0px 8px;
}
.Input-Field-Wrapper .Option-Wrapper{
    padding: 10px;
}
.Input-Field-Wrapper .Option-Wrapper .Card .Close-Icon{
    visibility: hidden;
    display: flex;
    position: absolute;
    padding: 10px;
    /* right: 10px; */
}
.Input-Field-Wrapper .Option-Wrapper .Card:hover .Close-Icon{
    visibility: visible;
}
.Input-Field-Wrapper .Option-Wrapper input{
    border: none !important;
    background: transparent !important;
}
.Input-Field-Wrapper .Option-Wrapper input:focus-visible{
    border: none !important;
    outline: none;
    /* background: transparent !important; */
}
.Input-Field-Wrapper .Option-Wrapper .Card{
    margin: 4px;
    position: relative;
}
.Create-Input-Fields-Section .Card{
  min-width: 20rem !important;
  max-width: 20rem !important;
}
.Create-Input-Fields-Section .ant-select-selection-item{
  z-index: 1 !important;
}
.Create-Input-Fields-Section .Card button{
  right: 4px !important;
  top: 4px;
  position: absolute;
}
/* new */
.InputFields-Table-Wrapper .Field-Verticle-Row{
  /* background-color: white; */
  margin: 5px 14px;
  color: #5b6c97;
  display: flex;
  align-items: center;
  /* border: 2px solid #ededed; */
}
.InputFields-Table-Wrapper .Field-Verticle-Row .Delete-Icon-For-Field{
 visibility: hidden;
 color: #ff4747 !important;
 margin-left: 10px;
}
.InputFields-Table-Wrapper .Field-Verticle-Row:hover .Delete-Icon-For-Field{
 visibility: visible;
}
.InputFields-Table-Wrapper .Field-Name{
  padding: 5px;
  /* background: #fafafa; */
  border-right: 2px solid #5b6c97;
  color: #5b6c97;
  font-size: 12px;
  font-weight: 600;
}
.InputFields-Table-Wrapper .Field-Value{
  padding-left: 5px;

}
.InputFields-Table-Wrapper .Input-Text{
  padding-left: 5px;
}
.InputFields-Table-Wrapper .ant-upload-wrapper{
  display: flex;
  flex-direction: row;
  gap:5px;
}
.InputFields-Table-Wrapper .ant-select-arrow {
  display: flex !important;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .InputFields-Table-Wrapper .Input-Select{
  width: 100% !important;
  height: auto !important;
  border: 1px solid #d9d9d9 !important;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .InputFields-Table-Wrapper .Input-Select .ant-select-selector:hover{
  background-color: transparent !important;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .InputFields-Table-Wrapper .Input-Select .ant-select-selector{
  height: 100% !important;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .InputFields-Table-Wrapper .Input-Date{
  width: 100%;
    color: #5b6c97;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .InputFields-Table-Wrapper .Input-Text{
    color: #5b6c97;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .InputFields-Table-Wrapper .Input-Date .ant-picker-input input{
  font-size: 12px;
  font-weight: 500 !important;
}
.Custome-Css .ant-select-clear{
  left: 21% !important;
  top: 0% !important;
}
.Custome-Css .ant-select-arrow{
  display: none !important;
}
.Custome-Css .ant-select-clear .anticon svg{
  width: 16px !important;
  color: #5b6cb2 !important;
}
.ant-input-number-input-wrap input,.Field-Value input{
  color: #5b6cb2 !important;
}
.validationCssClass{
  transition: width 0.4s ease-in-out !important;
  box-shadow : rgb(255 98 98) 0px 0px 7px 1px !important;
  border-color:#ff0000 !important;
}
.InputFields-Table-Wrapper .ant-upload-list-item-container{
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
}
.InputFields-Table-Wrapper .ant-upload-list-item-container .ant-upload-list-item{
  margin: 0px !important;
}
.Upload-Attachment .ant-upload{
  padding: 2px !important;
}
.InvalidFieldCss{
  border: 1px solid red !important;
  box-shadow: 0px 0px 4px 0px red;
}
.Email-Template-Wrapper{
  height: 100%;
}
.Email-Template-Wrapper .Email-Template-Body{
  display: flex;
  width: 100%;
  height: calc(100%);
  background-color: white;
}