.Switch{
    display: flex;
    gap: 0.5em;
}
.Switch_Toggle{
    margin-top: 2px;
}
.checkboxLabel{
    font-weight: 500;
}
.aTag{
    /* font-weight: 500; */
    font-family: 'Avenir Next LT W02';
}

.ViewContainer .Action-Btn-Ant{
    width: max-content !important;
    padding: 5px !important;
    font-size: 13px !important;
}