.FolderTree .TreeNode.file-node {
    background-color: #f2f2f2;
    border-radius: 2px;
    padding: 2px 3px;
    margin-top: 3px;
    cursor: pointer;
}
.FolderTree .TreeNode.file-node img {
    margin-right: 10px;
}
.FolderTree .TreeNode.file-node .remove-file {
    float: right;
    margin: 3px;
    cursor: pointer;
}
.FolderTree .TreeNode.file-node .fileName{
    margin: 0 10px;
}

.FolderTree .TreeNode.file-node .displayName{
    font-weight: 600;
}


.DocumentTreeContainer{
    max-height: 0vh !important;
    overflow: auto !important;
}

.FolderTree .TreeNode .iconContainer{
    font-size: 16px !important;
}