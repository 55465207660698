.Attachment-Btn-Wrapper{
    display: flex;
    justify-content: space-between;
    /* padding: 3px 10px !important; */
    padding-right: 10px !important;
    padding-left: 10px !important;
}
.Attachment-Btn-Wrapper .Attachment-Btn-Wrapper-Right{
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}
.Attachment-Btn-Wrapper .ant-btn-icon{
    display: flex !important;
}
.Attachment-Prev-Card .ant-card-cover{
    padding: 4px;
}
.Attachment-Prev-Card .ant-card-body{
    padding: 4px 4px !important;
}
.Attachment-Card-Wrapper{
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    gap: 10px;
    padding: 10px;
}
.Hover-Card{
    display: flex;
    position: relative;
}
.Hover-Card .Hover-Content{
    display: flex;
    visibility: hidden;
    position: absolute;
    top: 0px;
    height: 100%;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #ddebff82;
    /* opacity: 0.5; */
}
.Hover-Card:hover .Hover-Content{
   visibility: visible;
}
.Hover-Card .Hover-Buttons{
   display: flex;
   flex-direction: row;
   gap: 5px;
}
.Attachment-Section .list-compact-view{
    height: 1.8rem;
    position: absolute;
    z-index: 111;
    right: 0px;
    top: 14px;
}
.Attachment-Card-Wrapper1{
 max-height: 12rem;
 overflow-y: auto;   
}