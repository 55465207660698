.userlisttable-border{
    background-color: #fff;
    border: 2px solid #B0BED0;
    border-radius: 0.5rem;
    margin: 0 auto ;
    margin-bottom: 12px;
    width: calc(100% - 32px) !important; 
    flex: inherit !important;
    height: calc(100vh - 142px);
    margin-bottom: 12px;
    overflow-y: auto;
}


.react-time-picker {
    display: inline-flex;
    position: relative;
  }
  .react-time-picker,
  .react-time-picker *,
  .react-time-picker *:before,
  .react-time-picker *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-time-picker--disabled {
    background-color: #f0f0f0;
    color: #6d6d6d;
  }
  .react-time-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    /* border: 1px solid gray; */
    border-radius: 3px;
    background: #edf0f4;
    padding: 0 10px;
  }
  .react-time-picker__inputGroup {
    min-width: calc((4px * 3) +  0.54em * 6  +  0.217em * 2);
    flex-grow: 1;
    padding: 0 2px;
    box-sizing: content-box;
  }
  .react-time-picker__inputGroup__divider {
    padding: 1px 0;
    white-space: pre;
    width: 20px;
    display: inline-block;
    text-align: center;
  }
  .react-time-picker__inputGroup__input {
    min-width: 0.54em;
    height: 100%;
    position: relative;
    padding: 0 1px;
    border: 0;
    background: none;
    font: inherit;
    box-sizing: content-box;
    -moz-appearance: textfield;
  }
  .react-time-picker__inputGroup__input::-webkit-outer-spin-button,
  .react-time-picker__inputGroup__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .react-time-picker__inputGroup__input:invalid {
    background: rgba(255, 0, 0, 0.1);
  }
  .react-time-picker__inputGroup__input--hasLeadingZero {
    margin-left: -0.54em;
    padding-left: calc(1px +  0.54em);
  }
  .react-time-picker__inputGroup__amPm {
    font: inherit;
    -moz-appearance: menulist;
  }
  .react-time-picker__button {
    border: 0;
    background: transparent;
    padding: 4px 6px;
  }
  .react-time-picker__button:enabled {
    cursor: pointer;
  }
  .react-time-picker__button:enabled:hover .react-time-picker__button__icon,
  .react-time-picker__button:enabled:focus .react-time-picker__button__icon {
    stroke: #0078d7;
  }
  .react-time-picker__button:disabled .react-time-picker__button__icon {
    stroke: #6d6d6d;
  }
  .react-time-picker__button svg {
    display: inherit;
  }
  .react-time-picker__clock {
    width: 200px;
    height: 200px;
    max-width: 100vw;
    padding: 25px;
    background-color: white;
    border: thin solid #a0a096;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
  }
  .react-time-picker__clock--closed {
    display: none;
  }



  /*     HR MOdule CSS */


  .hr-time-tabs.nav-tabs {
    border-bottom: 0 ;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .hr-time-tabs.nav-tabs .nav-link:first-child {
    border-left: 0;
    border-radius: 0.5rem 0 0 0;
  }
  .hr-time-tabs-height.nav-tabs .nav-link{
    height: 51px;
  }
  .hr-time-tabs.nav-tabs .nav-link {
    color: #5b6c97;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    background: #d0d4df;
    padding: 13px 20px;
    border: 0;
   
  }
  .hr-time-tabs.nav-tabs .nav-link:focus,
  .hr-time-tabs.nav-tabs .nav-link:hover {
    border-color: transparent;
  }
  .hr-time-tabs.nav-tabs .nav-item.show .nav-link,
  .hr-time-tabs.nav-tabs .nav-link.active {
    color: #5b6c97;
    border-right: 1px solid #bac6d6;
    background: white;

    margin-left: -8px !important;
    padding-left: 28px !important;
  }

  

  .userlisttable-border {
    height: auto;
    margin-bottom: 12px;
    overflow-y: initial;
  }
  #myGrid .ag-root-wrapper-body.ag-layout-normal {
    height: auto;
  }
  .hr-time-table {
    height: calc(100vh - 167px);
    /* overflow-y: scroll; */
    padding: 0 9px 13px 13px;
  }
  .hrMenuRight th,
  .hr-time-table th {
    font-family: "Avenir Next LT W02";
    font-size: 12px;
  }
  .hrMenuRight td,
  .hr-time-table td {
    font-family: "Avenir Next LT W02";
    padding: 8px 10px !important;
    vertical-align: middle !important;
    text-align: center;
  }
  .hrMenuRight td label,
  .hr-time-table td label {
    margin: 0;
    padding: 4px;
  }
  .hrMenuRight .table thead th,
  .hr-time-table .table thead th {
    border-bottom: 1px solid #dee2e6 !important;
    background-color: #F3F6FA;
    color: #5B6C97;
    padding: 13px 10px;
    font-size: 13px;
    text-align: center;
    position: relative;
  }
  .hrMenuRight .table thead th:before,
  .hr-time-table .table thead th:before{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 1.5px;
    background-color: #d6d6d6;
    z-index: 9;
  }
  .hrMenuRight .table thead th:first-child:before,
  .hr-time-table .table thead th:first-child::before{
    display: none;
  }
  .hrMenuRight .table-striped tbody tr:nth-of-type(odd),
  .hr-time-table .table-striped tbody tr:nth-of-type(odd) {
    background-color: #f7f7f7;
    --bs-table-accent-bg: none;
  }
  .hrMenuRight .table-striped tbody tr:hover,
  .hr-time-table .table-striped tbody tr:hover{
    background-color: #e9f1fa;
    --bs-table-accent-bg: none;
  }
  .userlisttable-border {
    overflow: hidden;
    border: 1px solid #b0bed0;
  }
  
  .row-fail {
    font-weight: bold;
    background-color: skyblue !important;
  }
  
  .ag-cell--blue-background {
    background-color: white !important;
  }
  
  .odd-row-css {
    background-color: rgb(253, 251, 251) !important;
  }
  
  .even-row-css {
    background-color: white !important;
  }
  
  .total-hr {
    background-color: rgb(202, 238, 136) !important;
  }
  .hrlist-action-btn button {
    background: transparent;
    border: 0;
    color: #018ee0;
    font-weight: 500;
    text-transform: capitalize;
    padding: 1px 6px;
  }
  .hrlist-action-btn button:hover {
    text-decoration: underline;
  }
  .react-time-picker__wrapper {
    border: 1px solid #dadada;
    background: #ffffff !important;
  }
  .react-time-picker__inputGroup__input {
    outline: 0;
  }
  .hr-time-table table thead.pos-sticky {
    position: sticky;
    top: -1px;
  }
  .hrMenuLeft,
  .hrMenuRight {
    background-color: #fff;
    /* border: 1px solid #b0bed0; */
    /* border-radius: 1rem; */
    height: 99%;
    padding-left: 10px;
    padding-top: 5px;
    overflow: hidden;
    min-height: 78vh;
  }
  .hrMenuRight {
    padding: 0;
    overflow-y: hidden !important;
  }
  .hr-tabs-left .nav-item.nav-link.active {
    border-color: #dee2e6 !important;
    margin-bottom: 6px;
    border-radius: 5px;
    background-color: rgb(199 226 255);
  }
  .hr-tabs-left .nav-item.nav-link {
    border-color: #dee2e6 !important;
    margin-bottom: 6px;
    border-radius: 5px;
  }
  .hrRequestBtns {
    padding: 15px;
    /* padding-right: 0; */
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .hrRequestBtns .request-btns{
    height: 2.25rem !important;
  }
  .refresh-hrlist {
    color: #018ee0;
    margin-left: 5px;
    cursor: pointer;
  }
  .where-table {
    height: calc(85vh - 105px);
  }
  /* .where-table table td {
    padding: 0.3rem !important;
  } */
  .refresh-icon :hover {
    color: red;
  }
  .requestTableBody tr td:last-child{
    padding: 0 !important;
  }
  .request-status-dropdown>button{
    width: 100%;
    border-radius: 0;
    height: 100%;
    box-shadow: inset 0 -6px 12px #00000017 !important;
    -webkit-box-shadow: inset 0 -6px 12px #00000017 !important;
    padding: 8px;
    border: 0;
  }
  .request-status-dropdown button{
    text-transform: uppercase;
    text-align: center;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: white;
    border-radius: 5px;
    padding: 2px;
  }
  .request-status-dropdown .dropdown-menu{
    margin: 0;
    padding: 0;
    border: 5px solid #ffffff;
    box-shadow: 0 5px 10px #00000042;
    -webkit-box-shadow: 0 5px 10px #00000030;
  }
  .request-status-dropdown .dropdown-menu button{
    padding: 6px 10px;
    border-radius: 3px;
    color: white;
  }
  .request-status-dropdown .dropdown-menu button+button{
    margin-top: 5px;
  }
  .head-txtv {
    padding-top: 7px;
    font-size: 14px;
  }
  .ant-picker-separator {
    margin-top: -6px;
  }
  
  
  .ant-picker-input > input{
    line-height: 1.099 !important;
    font-weight: bold !important;
  }
  
  /* .ant-picker-input>input {
    text-align: right;
     
  }
  .ant-picker-input-active>input{
    text-align: left;
  } */
  
  .request-btns{
    padding: 2px 0.75rem !important;
      font-size: 12px !important;
  }
  .request-btns > button {
    padding: 2px 0.75rem !important;
      font-size: 12px !important;
  }
  
  
  
  .timesheet-table td:last-child{
    width: 20%;
  }
  
  .hr-table-right{
    width: 107%;
  }
  .hr-table-left{
    width: 70%;
  }
  .pos-static {
    position: static !important;
  }
  
  .ant-picker-input:first-of-type>input {
    text-align: right;
    font-weight: bold;
  }
  .ant-picker-input:last-of-type>input {
    text-align: left;
  }
  
  .height24{
   height: 24px !important;
  }
  
  .hr-table-icon{
    position: absolute;
      left: 15px;
      width: 52px;
      cursor: pointer;
      top: 0px;
      z-index: 9;
  }
  .pl-left{
    padding-left:47px!important;
  }
  .request-note-tooltip{
  height: 2px!important;
  }
  
  .email-warning{
    width: 53%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%,-50%);
  }


  .hrMenuLeft .show-all-deals{
    margin: 0px !important;
    background-color: #64749c;
    margin-right: 0px !important;
    font-size: 16px;
    border-radius: 5px;
    padding: 6px;
    color: #ffffff;
    font-weight: 600;
    cursor: pointer;
  }


  .hrMenuLeft .project-deals-height{
    overflow-y: auto;
    max-height: calc(100vh - 203px);
    padding-right: 0px !important;
  }
  .project-deals-height.sidebar{
    height: 96%;
  }
  .hrMenuLeft .HR-Sidebar-Ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 11px 0px;
  }
  .hrMenuLeft .HR-Sidebar-Ul li{
    background-color: whitesmoke;
    padding: 4px 6px;
    border-radius: 5px;
  }
  .hrMenuLeft .HR-Sidebar-Ul li:hover{
    background-color: rgb(1 142 224);
    padding: 4px 6px;
    color: white;
    border-radius: 5px;
  }
  .li-active{
    background-color: rgb(1 142 224) !important;
    padding: 4px 6px !important;
    color: white !important;
    border-radius: 5px !important;
  }
  .HR-Header-Wrapper .justify-content-start{
    justify-content: center !important;
  }
  .AprovedColor{
    color: white !important;
    background-color: green !important;
  }
  .RejectedColor{
    color: white !important;
    background-color: red !important;
  }
  .PendingColour{
    color: black !important;
    background-color: rgb(255, 225, 0) !important;
  }

  .select-wrap-col .css-b62m3t-container{
    width: 30% !important;
    /* margin: 5px; */
  }
  .Add-entry-popup .modal-title{
    line-height: 1 !important;
    font-size: 16px !important;
  }
  .Add-request-modal .modal-title{
    line-height: 1 !important;
    font-size: 16px !important;
  }
  .Add-absence-modal .modal-title{
    line-height: 1 !important;
    font-size: 16px !important;
  }
  .Add-entry-popup .close-modal-icon{
    position: absolute;
    right: 10px;
    top: 7px;
    opacity: 0.5;
    font-weight: 400;
  }
  .Add-request-modal .close-modal-icon{
    position: absolute;
    right: 10px;
    top: 7px;
    opacity: 0.5;
    font-weight: 400;
  }
  .Add-absence-modal .close-modal-icon{
    position: absolute;
    right: 10px;
    top: 7px;
    opacity: 0.5;
    font-weight: 400;
  }

  .Add-absence-modal .ant-modal-content{
    width: 500px !important;
    border-radius: 10px !important;
  }
  .Add-request-modal .ant-modal-content{
    width: 500px !important;
    border-radius: 10px !important;
  }
  .Add-entry-popup .ant-modal-content{
    width: 500px !important;
    border-radius: 10px !important;
  }
  .select-user{
    width: 200px !important;
    margin-left: 10px !important;
  }
  .User-Name-Header-Span{
    display: flex;
    /* text-align: center; */
    font-weight: bold;
    font-weight: bold;
    color: #64749c;
    font-size: 1rem;
  }

  .hr-main-wrapper .pad-col{
    position: relative !important;
      top: -72px !important;
  }
  .hr-main-wrapper .example-wrapper #myGrid {
    height: calc(100vh - 170px) !important;
   }
  .hr-main-wrapper .hr-sub-wrap{
    padding: 0px !important;
  }
  .hrMenuRight{
    height: 100vh !important;
  }
  .hr-tabs-section{
    position: absolute;
    z-index: 1;
  }

  .hrAllTabs{
    display: flex;
    flex-wrap: nowrap;
    height: 3.22rem;
    margin-top: 0.47rem;
  }

  .hrAllTabs.nav-tabs .nav-link.active{
    position: relative !important;
    color: #fff !important;
    background-color: #018ee0 !important;
}
.hrAllTabs > .nav-link{
    line-height: 34px;
    font-size: 13px;
    font-family: 'Avenir Next LT W02';
    font-weight: bold;
    box-shadow: inset 1px 0px 12px -3px #cbcbcb !important;
    background-color: #fff !important;
    margin-left: 0.5rem;
    color: #222121 !important;
    min-width: 8rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: auto !important;
}
.extra-component-request{
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-direction: row;
}
.hr-main-wrapper .list-compact-view{
  width: 9.2rem !important;
}
/* where extra css */
.DeviceId-Wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.DeviceId-Wrapper .DeviceId-Hover{
  display: none;
}
.DeviceId-Wrapper .DeviceId{
  text-overflow: ellipsis;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DeviceId-Wrapper:hover .DeviceId-Hover{
  display: flex;
  text-overflow: ellipsis;
  color: green;
  font-weight: bold;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.DeviceId-Wrapper:hover .DeviceId{
  display: none;
}
.Add-entry-popup .ant-modal-footer{
  display: block !important;
}
.Add-request-modal .ant-modal-footer, .Add-absence-modal .ant-modal-footer{
  display: block !important;
}