.hiddenRow {
    padding: 0 !important;
}
.milestone-table thead th{
    min-width: auto;
    text-transform: uppercase;
}


.milestone-table tbody > tr > td:first-child span.task-name-highlight{
    
}
.milestone-table tbody > tr > td:first-child .mainTaskArrow{
    position: inherit;
    /* margin-right: 5px; */
}
#progress-tabs-tabpane-milestones .task-card-title{
    width: 168px;
}
#progress-tabs-tabpane-milestones .task-card-title:hover .task-settings-icon{
    float: right;
    margin-top: 2px;
}
.task-table tbody tr.milestone-pipelineStatus,
.task-table tbody tr.milestone-pipelineStatus:hover{
    background-color: lightgreen;
}
.task-table tbody tr.milestone-pipelineStatus td{

}
.milestone-th1{
    width:25%;
}
.milestone-th2{
    width:25%;
}
.milestone-th3{
    width:25%;
}
.milestone-th4{
    width:25%;
}

.common-table-min-width{
  min-width: 215px !important;
}

.milestone-table>thead>th:first-child , .milestone-table>tbody>tr>td:first-child{
    min-width: 162px !important;
}

.milestone-td-status button{
    width: 70% !important;
    padding: 0 10px !important;
    position: relative !important;
}
.milestone-td-status button span{
    /* font-weight: 600 !important; */
}
.milestone-td-status button  svg{
    position: absolute;
    right: 10px;
    z-index: 1000;
    top: 7px;
}

.milestone-td-status button svg{
    display: none;
}

.milestone-table>tbody>tr:hover .milestone-td-status button svg{
  display: block;
}
