.activityContent{
    overflow: auto;
    height: calc(100vh - 200px);
    padding:15px;
    padding-top: 0;
    margin-top: 10px;
}
.time-label{
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}
.time-label span{
    display: inline-block;
    background: #f0f0f0;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 13px;
    border: 1px solid #d2d2d2;
    z-index: 9;
    position: relative;
}
.time-label:before{
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #d2d2d2;
}
.timelineCards-wrapper{
    max-width: 700px;
    margin: auto;
}
.activityCard{
    box-shadow: 0 3px 6px #eeeeee;
    -webkit-box-shadow: 0 3px 6px #eeeeee;
}
.activityCard+.activityCard{
    margin-top: 20px;
}
.activityCard:hover{
    box-shadow: 0 4px 12px #eaeaea;
    -webkit-box-shadow: 0 4px 12px #eaeaea;
}

.activityCard ul{
    list-style-type: none;
    margin: 0;
    padding: 6px 0;
}
.activity-detail li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    padding: 5px 20px;
}
.activity-time {
    font-size: 13px;
    color: #212529 !important;
}
.activity-time span{
    margin: 0 3px !important;
    color: #212529 !important;
}
.activity-time span:last-child{
    margin-right: 0;
}
.userProfile-link{
    color: #007bff;
    font-weight: 600;
    padding: 2px 4px;    
    border-radius: 3px;
    cursor: pointer;
}
.userProfile-link:hover{
    background: #ececec;
}
.activityCard .card-header{
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.activityCard .card-header span.taskName{
    font-weight: 600;
    color: #5b6c97;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
}
.activityCard .card-header span svg{
    font-size: 13px;
    margin-right: 4px;
}
.activityCard .card-header span svg.task-status{
    font-size: 11px;
}

.activity-fixed-top{
    position: sticky;
    top: 0;
    z-index: 99;
    padding: 15px 0 20px;
    background: white;

}

.activitySearch-bar svg{
    position: absolute;
    font-size: 14px;
    top: 26px;
    left: 11px;
    color: gray;
}
.activitySearch-bar input{
    padding-left: 35px;
}
.activity-filters-title{
    display: flex;
    align-items: center;
    font-size: 14px;
}
.activity-filters-title button{
    margin: 0 10px;
    background: transparent !important;
    border: 0;
    padding: 0;
    color: #018ee0 !important;
    line-height: 1;
    font-size: 14px;
    box-shadow: none !important;
}
.activity-filters-title button svg{
    color: #6d6d6d;
    margin-left: 4px
}
.activity-filters .filterName{
    white-space: nowrap;
}
.activityCard .card-body{
    padding: 8px 20px;
}
.activityCard .card-body p{margin: 0;}

.callLog-desc{
    margin: 8px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 0;
}
.callLog-desc:before{
    position: absolute;
    content: "";
    height: 140%;
    width: 103%;
    left: -1.5%;
    top: -20%;
    border: 1px solid #b1bfd1;
    background-color: #edf0f4;
    display: none;
    border-radius: 3px;
    z-index: -1;
}
.callLog-desc:hover:before{
    display: block;
}
.callLog-desc p{
    font-size: 15px;
}
.callLog-desc .callLog-descIcons{
    /* display: none; */
    padding: 0;
    margin: 0;
    z-index: -1;
    opacity: 0;
}
.callLog-desc:hover .callLog-descIcons{
    /* display: block; */
    z-index: 1;
    opacity: 1;
}
.callLog-desc .callLog-descIcons li{
    display: inline-block;
}
.callLog-desc .callLog-descIcons li+li{
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #ccc;
}
.callLog-desc .callLog-descIcons li svg{
    font-size: 13px;
    color: #5b6c97;
    cursor: pointer;
}

.callLog-details{
    display: flex;  
    margin: 15px 0 10px;
    border-top: 1px solid #d2d2d2;
    padding-top: 15px;  
}
.callLog-details div+div{
    margin-left: 15px;
}
.callLog-details .form-control {
    height: 28px;
    font-size: 14px;
    background: #f8f8f8;
}
.callLog-details .muted-heading{
    font-size: 13px;
    margin: 0 0 5px;
}
.callLog-details .muted-heading+h6{
    margin: 8px 0 0;
}
.call-log-contacts-text{
    padding-left: 5px;
    position: relative;
    top: -2px;
}

.callLog-desc-editor .ck-editor{
    border: 2px solid #b0bed0;
    border-radius: 5px;
    margin: 10px 0;
    overflow: hidden;
    box-shadow: 0 1px 3px #d0d4df;
}
.callLog-desc-editor .ck-editor .ck-content{
    padding: 0 10px;
}

.logactivity{
width: 13%;
margin: 4px;
}

.ck.ck-balloon-panel{
    z-index: 9999;
  }
  
  .ck-mentions .mention__item {
    display: block;
  }
  
  .ck-mentions .mention__item img {
    border-radius: 100%;
    height: 30px;
  }
  
  .ck-mentions .mention__item span {
    margin-left: .5em;
  }
  
  .ck-mentions .mention__item.ck-on span {
    color: var(--ck-color-base-background);
  }
  
  .ck-mentions .mention__item .mention__item__full-name {
    color: hsl(0, 0%, 45%);
  }
  
  .ck-mentions .mention__item:hover:not(.ck-on) .mention__item__full-name {
    color: hsl(0, 0%, 40%);
  }


  .activity-filter-icon{
    height: 24px;
    padding: -16px;
    padding-top: 1px;
    margin-right: 13px;
    margin-left: 20px;
  }