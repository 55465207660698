.weekly_tabs span{
    width: 30px;
    border: 1px solid #b7b7b7;
    background: #fff;
    height: 30px;
    line-height: 26px;
    display: inline-block;
    margin-right: 8px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}

input[type='radio']:checked:after{
    background-color: #0069d9!important;
}