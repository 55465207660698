html, body {
    min-height: 100%;
    height: 100%;
}
body {
    margin: 0;
    box-sizing: border-box;
    font-family: Arial;
    font-family: 'Avenir Next LT W02';
     /* Hide scrollbars */
    
  } 
  #root{
      height: 100%;
  }
  .container-box {
    box-sizing: border-box;
    height: inherit;
  }

.row-mar-0  {
    margin-left: 0 !important;
    margin-right: 0 !important;
    height: 100%;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #ffffff; 
  border-radius: 14px;
}
 
::-webkit-scrollbar-thumb {
  background: #5b6c97;
  border: 2px solid white;
  border-radius: 14px;
}

::-webkit-scrollbar-thumb:hover {
  background: #5a6e9f; 
  border-color: #5a6e9f; 
}

.pad-right-38 {
    padding-right: 38px;
}

.mar-left-30{
    margin-left: -30px;
    
}

.text-theme{
    color: #5b6c97 !important;
}
.error-msg{
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}
/* 
.container-box {
    box-sizing: border-box;
}

.header2 {
    background-color: #f9f8f9;
    padding: 12px 15px;
    width: 100%;
    color: #000;
    box-sizing: border-box;
}
.left-box {
    width: auto;
    float: left;
}
.right-box {
    width: auto;
    float: right;
}
.btn-gray {
    background-color: #f1f4f9;
    border: 1px solid #f1f4f9;
    color: #999;
    border-radius: 4px;
    padding: 5px 20px;
}
.btn {
    background-color: #0085e7;
    border: 1px solid #0085e7;
    color: #fff;
    border-radius: 4px;
    padding: 5px 20px;
}
.breadcrumb {
    padding:15px;
}
.breadcrumb i {
    font-size:16px;
    color:#999;
}
.txt-bliue {
    color: #0085e7;
    margin-left: 5px;
}
.form-box {
    padding: 15px;
    min-height: 428px;
}
.form-boxs {
    width:90%;
    margin:0 auto;
}
.form-box h1 {
    color: #000;
    font-size: 24px;
}
.marb-16 {
    margin-bottom:6px;
}
.txt-label {
    width: 188px;
    float: left;
    text-align: right;
    margin-right: 15px;
    padding-top: 5px;
}
.input-control {
    width: 250px;
    padding: 5px 7px;
    border-radius: 4px;
    border: 1px solid #000;
}
.select-control {
    width: 266px;
    padding: 6.57px 7px;
    border-radius: 4px;
    border: 1px solid #000;
} */

.hide-nav{
 color: lightgray !important;
}


/* search-icon */

.search-ico{
    position: absolute;
        right: 5px;
        top: 5px;
        color: #ccc;
        cursor: pointer;
}

.search-filter{
        border-radius: 4px !important;
            padding: 4px 30px 3px 15px !important;
            font-size: 12px !important;
            height: auto !important;
}

.pos-relative{
    position: relative;
}


/* .editColumn .dropdown-menu{
    transform: translate(-265px, 36px) !important;
} */

