.Create-Dependency-Popover .ant-popover-inner-content{
    width: max-content !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    height: fit-content !important;
    max-height: 60vh !important;
}
.Create-Dependency-Popover{
    max-height: 52vh !important;
    z-index: 1000 !important;
}
.headerSection{
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2; 
    padding-bottom: 7px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex; 
    align-items: center;
    justify-content: space-between; 
}
.CustomeCollapseForPopup .ant-collapse-item{
    width: 40rem !important;
    padding-left: 0.5em !important;
    padding-right: 0.5em !important; 
    border: 1px solid lightgray !important;
    border-radius: 6px !important;
    background: #edf0f4 !important;
}
.CustomeCollapseForPopup{
    display: flex !important;
    flex-direction: column !important;
    gap: 1em !important;
    border: none !important;
    background: white !important;

}
.dependncy-list-dropdowns{
    width: 40rem !important;
    max-height: 23rem;
    overflow: scroll;
    overflow-x: hidden;
    padding-left: 1em;
    padding-right: 1em;
}
.Alin-Centre-Class{
   display: flex;
   height: 100%;
   align-items: center;
   justify-content: center;
}
.dependncy-list-dropdowns .custom-option:hover{
    background-color: aliceblue;
}
.Selected-Task{
    background-color: aliceblue;
}

.Create-Dependency-Popover .ant-tabs-content,
.Create-Dependency-Popover .ant-tabs-content .ant-tabs-tabpane{
    height: 100%;
}
.dependncy-list-dropdowns .Header-Row{
    background: #f4f4f4;
    color: #596a95;
    font-weight: 600;
    padding: 0px !important;
    margin: 0px !important;
}
.dependncy-list-dropdowns .dependency-search-input{
    display: flex;
    flex-direction: row;
    margin-bottom: 0.8em;
    width: 99%; 
    position: sticky;
    top: 0px;
    z-index: 1;
    background: white;
    margin-bottom: 5px;
    margin: 0px;
    border-bottom: 1px solid #dcdcdc;
    padding: 8px 0px 8px 0px !important;
}

.dependncy-list-dropdowns .dependency-search-input .Search-Class{
   height: 100%;
}
.dependncy-list-dropdowns .dependency-search-input input,
.dependncy-list-dropdowns .dependency-search-input .ant-input-group-wrapper{
   height: 100%;
}
.dependncy-list-dropdowns .dependency-search-input .ant-input-group-addon{
    padding-right: 2px !important;
}
.dependncy-list-dropdowns .dependency-search-input .ant-input-group-addon .ant-btn{
    border: none !important;
}
.Create-Dependency-Popover .ant-popover-content{
    left: 0px !important;
}
.subtaskContainer  {
    padding-left: '20px';
    margin-top: '10px';
  } 
  .subtaskButton {
    display: 'block';
    margin: '5px 0';
    width: '200px';
  }