.Tabs-Wrapper .Count-Span{
    display: flex;
    padding: 0px 7px;
    font-size: small;
    border-radius: 9px;
    background: #82c3fd3b;
    /* justify-content: center; */
    height: max-content;
    position: absolute;
    /* border: 1px solid; */
    /* align-items: center; */
    right: -5px;
    top: 1px;
}
.Tabs-Wrapper{
    display: flex;
    flex-direction: row;
    gap: 9px;
    border-bottom: 1px solid #c5c5c5;
}
.Tab-Card{
    display: flex;
    position: relative;
    padding: 4px 19px;
    cursor: pointer;
    gap: 9px;
    font-size: medium;
    font-weight: 600;
    /* align-items: center; */
    color: #6b6b6b;
}
.Active-Tab{
    color: #0072ec;
    border-bottom: 2px solid #0072ec;
}