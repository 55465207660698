.scroll-wrapper {
  flex-wrap: nowrap;
  overflow: auto;
  margin: 0;
}

.task-container {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  /* padding: 5px 15px 0px 15px; */
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 1px 4px #968d8d;
}

.task-cardbody {
  padding: 5px;
}

.task-card,
.task-card-header {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 12px;
  margin: 5px;
  box-shadow: 0 1px 4px #a9a9a9;
}

.task-card-header {
  border-top: 2px solid #5b6c97;
  background: whitesmoke;
}

.task-vertical-scroll {
  height: 51vh;
  overflow: auto;
}

.task-card-header .card-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-card-header .card-text .badge {
  /* background-color: #5b6c97; */
  padding: 3px 10px;
  vertical-align: text-top;
  margin-left: 3px;
  border-radius: 10px;
}

.task-card-header .card-text svg:first-of-type {
  width: 3em;
  height: 1.1em;
}

.task-card-header .card-text svg {
  color: #1d4999;
}

.task-container .nav-tabs {
  border-bottom: 1px solid #b0bed0;
}

.task-container .nav-tabs .nav-link {
  font-family: "Avenir Next LT W02";
  font-size: 13px;
  color: #0070c0;
  font-weight: 700;
  text-decoration: underline;
}

.task-container .nav-tabs .nav-item.show .nav-link,
.task-container .nav-tabs .nav-link.active {
  color: #5b6c97;
  border-color: #b0bed0 #b0bed0 #fff;
  text-decoration: none;
}

.task-card .card-text>small {
  line-height: 1;
  line-height: 1.5;
  display: block;
}

.task-card .card-text .deal-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4px;
}

.task-header .search-filter {
  border: 2px solid #b0bed0;
}

.task-header .search-filter svg {
  color: #2f2f2f;
  width: 12px;
}

.assigntask-button {
  width: 23px;
}

.bottom-icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bottom-icons img+img {
  margin-left: 8px;
}

.bottom-icons svg+svg {
  margin-left: 8px;
}

.task-header {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  margin: 15px auto;
}

.open-task-btn {
  font-size: 14px;
  color: #007bff;
  font-weight: 600;
  font-family: "Avenir Next LT W02";
  cursor: pointer;
}

.open-task-btn svg {
  margin-left: 5px;
}

.add-task-btn {
  background: #54b9fe;
  padding-right: 12px;
  border-radius: 20px;
  overflow: hidden;
}

.add-task-btn span {
  color: white;
  background: #0194f7;
  font-size: 14px;
  padding: 3px 20px;
  margin-right: 10px;
  display: inline-block;
  font-weight: normal;
}

.add-task-btn svg {
  color: white;
  width: 14px !important;
  vertical-align: middle;
  font-size: 14px;
  cursor: pointer;
}

.task-filter-btn {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 2px solid #cccccc;
}

.task-filter-btn img {
  width: 20px;
}

/* .task-table thead th {
  position: relative;
  text-align: center!important;
  font-size: 10px;
  font-family: "Avenir Next LT W02";
  font-weight: bold;
  color: #5b6c97;
  border-top: 0;
  min-width: 170px;
  padding: 0.5rem 0.75rem;
  border-left: 0;
  border-right: 0;
  left:10px;
}
.task-table td {
  text-align: center!important;
  padding: 2px 5px;
  font-size: 13px;
  vertical-align: middle;
  font-family: "Avenir Next LT W02";
  font-weight: normal;
  color: #5b6c97;
  border: 0;
}
.task-table tbody tr {
  background-color: #f2f2f2;
  box-shadow: inset 0 -2px 3px 0px #00000012;
} */
/* .task-table tbody tr:nth-of-type(odd) {
  background-color: white;
} */

.task-table thead th {
  position: relative;
  text-align: center !important;
  font-size: 10px;
  font-family: "Avenir Next LT W02";
  font-weight: bold;
  color: #5b6c97;
  border-top: 0;
  min-width: 170px;
  padding: 0.5rem 0.75rem;
  border-left: 0;
  border-right: 0;
  left: -10px;
}

.task-table td {
  text-align: center !important;
  /* padding: 2px 5px; */
  font-size: 13px;
  vertical-align: middle;
  font-family: "Avenir Next LT W02";
  font-weight: normal;
  color: #5b6c97;
  border: 0;
  padding-left: 50px;
  padding-top: 0px;
  padding-bottom: 0px;

}

/* .task-table tbody tr:last-child  td:first-child{
  
  padding-left: 190.5px !important;

} */

.task-table tbody tr {
  background-color: #f2f2f2;
  /* box-shadow: inset 0 -2px 3px 0px #00000012; */
  border-bottom: 1px solid lightgray;
}

.task-table-icon {
  width: 25px;
}

.task-table td.status {
  padding: 0;
}

.task-table td.status .dropdown {
  margin-left: 40px;
}

.task-table td.status .dropdown-menu {
  padding: 0;
  min-width: 100%;
  border: 5px solid #ffffff;
  box-shadow: 0 5px 10px #00000042;
  -webkit-box-shadow: 0 5px 10px #00000042;
}

.transparent-dropdown .dropdown-menu {
  padding: 0;
  width: 180px;
}

.task-table td.status button {
  color: white;
  /* text-transform: uppercase; */
  text-align: center;
  width: 60%;
  margin-left: 40px;
  height: 100%;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: none;
  border: 0;
  padding: 8px 0px;
  outline: 0;
  box-shadow: inset 0 -6px 12px #00000026;
}

.task-table td.status button {
  border-radius: 30px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-left: 25px !important;
  box-shadow: none !important;
  justify-content: space-around;
}

.task-table td.status button svg {
  font-size: 10px;
}

.task-table td.status button+button {
  margin-top: 5px;
}

.task-table td.status .dropdown>button {
  border-radius: 3px !important;
  width: 85% !important;
}

.task-progress {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px !important;
}

.task-progress .progress-wrapper {
  display: inline-block !important;
  width: 70px !important;
  vertical-align: text-bottom !important;
  margin-left: 20px;
}

.task-progress span {
  min-width: 35px;
  display: inline-block;
  vertical-align: super;
}

.task-progress .progress {
  width: 80%;
  margin: 6px 5px 6px 0;
  border-radius: 20px;
  border: 1px solid #5b6c97;
  background: transparent;
  height: 12px;
}

.task-progress .progress .progress-bar {
  background-color: #5b6c97;
}

.task-settings-icon {
  opacity: 0;

}

.task-card-title:hover .task-settings-icon {
  opacity: 1;
}

.task-card-title span:hover .task-settings-icon {
  opacity: 1;
}

.task-pen-icon {
  font-size: 12px;
}

.task-toggle-down {
  border-radius: 50%;
  background: white;
  color: #5b6c97;
  font-size: 12px;
  cursor: pointer;
  /* / position: absolute; / */
  z-index: 9;
  top: 9px;
  left: 0px;
  border: 1px solid #5b6c97;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .task-toggle-down {
  border-radius: 50%;
  background: white;
  color: #5b6c97;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  top: 9px;
  left: 0px;
  border: 1px solid #5b6c97;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

/* .task-card-title {
  position: absolute;
  top: 4px;
  width: fit-content;
  background: #5b6c97;
  color: white;
 padding: 8px 10px;
  border-radius: 12px 12px 0 0;
  font-size: 11px;
  font-weight: 500;
 height: 33px;
  font-family: "Avenir Next LT W02";
  z-index: 100;
} */

.task-card-title {
  position: absolute;
  /* top: 12px; */
  color: #fff;
  width: -moz-fit-content;
  width: fit-content;
  background: #5b6c97;
  padding: 4px 10px;
  border-radius: 12px 12px 0 0;
  font-size: 11px;
  font-weight: 500;
  height: 33px;
  font-family: "Avenir Next LT W02";
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainTaskArrow {
  min-width: 8.12px;
  background: #f2f2f2;
  z-index: 9;
  line-height: 1;
}

.mainTaskArrow svg {
  width: 10px !important;
}

/* .task-table tbody > tr > td:first-child {
  min-width: 200px;
  width: 25%;
  text-align: left;
  padding: 2px 0;
  padding-left: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
    justify-content: left;
    align-items: center;
} */

.task-table tbody>tr>td:first-child {
  /* min-width: 250px;
  width: 250px; */
  text-align: left;
  /* padding-left: 10px; */
  font-weight: bold;
  /* display: inline-block; */
  align-items: center;
  /* padding: 2px 0; */
  width: 60%;
}

.task-content-table1 tbody>tr>td:first-child {
  display: inline-block;
}

.task-content-table tbody>tr>td:first-child {
  display: flex !important;
}

.Task-name-input {
  border: 0px;
}

.Task-name-input:focus {
  outline: 0px;

}

.task-table tbody>tr>td:first-child span.task-name-highlight {
  padding-left: 0px;
  min-height: 31px;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.task-table tbody>tr>td:first-child span.task-name-highlight .task-table tbody>tr>td:first-child span svg:first-of-type {
  vertical-align: middle;
}

.paddding-0 {
  padding: 0 !important;
}

.task-table tbody>tr>td.is-subtask:first-child {
  padding-left: 40px;
}

.task-table tbody>tr>td.is-metatask:first-child {
  padding-left: 70px;
}

/* .task-table tbody>tr>td.is-subtask:before,
.task-table tbody>tr>td.is-metatask:before,
.task-table tbody>tr>td.connecting-task-2:before,
.task-table tbody>tr>td.connecting-task-3:before,
.task-table tbody>tr>td.connecting-task-4:before,
.task-table tbody>tr>td.connecting-task-5:before,
.task-table tbody>tr>td.connecting-task-6:before,
.task-table tbody>tr>td.connecting-task-7:before,
.task-table tbody>tr>td.connecting-task-8:before,
.task-table tbody>tr>td.connecting-task-9:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 67%;
  border-left: 2px solid #8692b1;
  left: 24px;
  top: -14.5px;
} */

.task-table tbody>tr>td.connecting-task-3:before,
.task-table tbody>tr>td.connecting-task-3::after {
  left: 62px !important;
}

.task-table tbody>tr>td.connecting-task-4:before,
.task-table tbody>tr>td.connecting-task-4::after {
  left: 98px !important;
}

.task-table tbody>tr>td.connecting-task-5:before,
.task-table tbody>tr>td.connecting-task-5::after {
  left: 136px !important;
}

.task-table tbody>tr>td.connecting-task-6:before,
.task-table tbody>tr>td.connecting-task-6::after {
  left: 174px !important;
}

.task-table tbody>tr>td.connecting-task-7:before,
.task-table tbody>tr>td.connecting-task-7::after {
  left: 212px !important;
}

.task-table tbody>tr>td.connecting-task-8:before,
.task-table tbody>tr>td.connecting-task-8::after {
  left: 250px !important;
}

.task-table tbody>tr>td.is-subtask-last:before {
  top: -238.5px;
  height: 621%;
}

.task-table tbody>tr>td.is-metatask:before {
  left: 14px;
  width: 31px;
  /* background: yellow; */
  border-right: 2px solid #8692b1;
}

.task-table tbody>tr>td.metas-of-last-subtask.is-metatask:before {
  border-left: 2px solid transparent;
}

/* .task-table tbody>tr>td.is-subtask:after,
.task-table tbody>tr>td.is-metatask:after,
.task-table tbody>tr>td.connecting-task-2:after,
.task-table tbody>tr>td.connecting-task-3:after,
.task-table tbody>tr>td.connecting-task-4:after,
.task-table tbody>tr>td.connecting-task-5:after,
.task-table tbody>tr>td.connecting-task-6:after,
.task-table tbody>tr>td.connecting-task-7:after,
.task-table tbody>tr>td.connecting-task-8:after {
  position: absolute;
  content: "";
  width: 29px;
  height: 1px;
  top: -7px;
  bottom: 0;
  margin: auto;
  left: 24px;

  border-color: transparent !important;
  height: 8px !important;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
  background-position: bottom;
  background-image: url("../../assets/images/curved-border.png");

} */

.task-table tbody>tr>td.is-metatask:after {
  height: 8px !important;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 99;
  background-position: bottom;
  background-image: url("../../assets/images/curved-border-subtask.png");

}

.task-table tbody>tr>td.is-subtask.has-subtask:after {
  background-image: url("../../assets/images/curved-border-subtask.png");
  width: 21px;
}

.task-table tbody>tr>td:after {
  background-image: url("../../assets/images/curved-border-subtask.png");
  width: 21px;
}

/* .last-meta:after,
.last-subtask:after {
  background-image: url("../../../../../assets/images/curved-border.png");
  border-color: transparent !important;
  height: 8px !important;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 99;
} */

/* .connecting-child-task:before,
.connecting-task-2:before,
.connecting-task-3:before,
.connecting-task-4:before {
  position: absolute;
  content: "";
  left: 25px;
  border-left: 2px solid #8692b1;
  width: 1px;
  height: 102%;
  z-index: 9;
  top: 0;
}

.connecting-task-2:after,
.connecting-task-3:after,
.connecting-task-4:after {
  position: absolute;
  content: "";
  left: 25px;
  width: 28px;
  height: 10px;
  top: 12px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/curved-border.png");
}

.connecting-task-3:before,
.connecting-task-3:after {
  left: 63px;
}

.task-first-child:before {
  top: 0;
} */

.table-hover tbody tr:hover .mainTaskArrow {
  background: #dadada;
}

.task-table tbody>tr>td.last-meta.is-metatask:before {
  height: 85%;
  top: -45%;
}

.task-table tbody>tr>td.is-subtask.last-subtask:before {
  height: 94%;
  top: -64%;
}

.task-table tbody>tr>td.is-metatask:after {
  width: 20px;
  left: 43.5px;
}

.taskopen-popup li {
  cursor: pointer;
  padding: 8px 10px;
  font-family: "Avenir Next LT W02";
  color: #5b6c97;
  font-size: 14px;
}

.taskopen-popup li:hover {
  background-color: #edf0f4;
}

.taskopen-popup {
  padding: 0;
  margin-bottom: 0;
}

.plusView-popup li {
  cursor: pointer;
  width: 150px;
  text-align: center;
  padding: 3px 2px;
  font-family: "Avenir Next LT W02";
  color: #5b6c97;
  font-weight: bold;
}

.plusView-popup li:hover {
  background-color: #edf0f4;
}

.plusView-popup {
  padding: 0;
}

.cursor-point {
  cursor: pointer;
}

.min-width-250 {
  min-width: 200px !important;
}

.min-width-150 {
  min-width: 150px !important;
}

.subtasktask-toggle-icon {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 15px;
}

.parenttask-align {
  text-align: left !important;
}

.padding-l30 {
  padding-left: 30px;
}

.hourGlass-icon {
  font-size: 15px;
  margin-right: 10px;
}

.font-size15 {
  font-size: 15px;
}

.taskTable-border {
  border: #5b6c97 1px solid;
}

.taskdetail-modal {
  max-width: 95%;
  border-radius: 1rem;
  border: none;
  /* font-family: "Avenir Next LT W02"; */
  overflow: hidden;
  height: 90%;
  margin: 4% auto 0;
}

.taskdetail-modal-subtask {
  max-width: 100%;
  border: none;
  bottom: 17px !important;
  font-family: "Avenir Next LT W02";
  overflow: hidden;
}
.Create-Task-Attachment-Modal{
  z-index: 9999 !important;
}
.taskdetail-modal-metatask {
  max-width: 89%;
  border-radius: 1rem;
  border: none;
  font-family: "Avenir Next LT W02";
  overflow: hidden;
  height: 86%;
  margin: 2% auto 0;
}

.taskdetail-modal-height {
  max-height: inherit;
  color: #5a6c97;
  min-height: 80vh;
  padding-top: 0;
}

.attachmnt-file {
  font-weight: 600;
  font-size: 12px;
}

.taskdetail-modal-header {
  background-color: #edf0f4;
  color: #5b6c97;
  border-bottom: 1px solid #5b6c97;
  padding: 5px 20px;
  /* font-family: "Avenir Next LT W02"; */
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: start;
}

.taskdetail-modal .modal-content {
  border-radius: 1rem !important;
  border: 1px solid transparent !important;
  overflow: hidden;
}

.taskstatus-heading {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-right: 2px solid #c9cedc;
  padding-top: 15px;
}

.taskstatus-text {
  background-color: #e22033;
  color: #fff;
  padding: 8px 84px 12px 8px;
  height: 40px;
}

.taskstatus-text-icon {
  background-color: #e22033;
  color: #fff;
  width: 22px !important;
  padding: 5px;
  margin-left: 1px;
  height: 2.5em;
  font-size: inherit;
  display: inline-block;
}

.taskstatus-text-tags {
  margin-left: 15px;
  width: 40px;
  padding: 0px 0px 5px 5px;
}

.taskstatus-heading-seperator {
  margin-top: -3px;
  margin-bottom: 0px;
  border: 1px solid;
  margin: 0 15px;
  height: 80% !important;
}

.taskstatus-heading-right {
  color: #5b6c97;
  font-family: "Avenir Next LT W02";
  padding-top: 15px;
}

.taskstatus-heading .assignee-dropdown>button {
  width: 35px;
  height: 35px;
}

.taskstatus-heading .assignee-dropdown>button .assignee-img {
  height: 35px;
}

.taskstatus-heading .transparent-dropdown button .selected-avtar {
  width: 35px;
  height: 35px;
}

.fontsize-13 {
  font-size: 13px;
}

.fontsize-12 {
  font-size: 12px;
}

.taskstatus-heading-wrapper {
  border-bottom: 1px solid #5b6c97;
}

.font-s12 {
  font-size: 12px;
}

.pad-bottom10 {
  padding-bottom: 5px;
  margin-bottom: 0;
}

.mar-l50 {
  margin-left: 50%;
}

.taskInfo-inputs,
.task-category {
  width: 95%;
  margin: 0 auto 15px;
}

.taskmodal-title-wrapper {
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 12px;
  width: 95%;
  margin: 15px 15px 15px 18px;
  display: flex;
  align-items: start;

}

.taskmodal-title-wrapper {
  font-size: 25px !important;
  font-weight: bold !important;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 12px;
  color: #5b6c97 !important;
  width: 95%;
  margin: 15px 15px 15px 18px;
}

.taskmodal-description-area {
  font-size: 13px;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 12px;
  color: #5b6c97;
  box-shadow: none;
}

.taskmodal-title-wrapper::placeholder {
  color: #aeb1ba;
}

.taskmodal-title-wrapper:-ms-input-placeholder {
  color: #aeb1ba;
}

.taskmodal-title-wrapper::-ms-input-placeholder {
  color: #aeb1ba;
}

.taskmodal-description-area::placeholder {
  font-weight: 300 !important;
}

.taskmodal-description-area:-ms-input-placeholder {
  font-weight: 300 !important;
}

.taskmodal-description-area::-ms-input-placeholder {
  font-weight: 300 !important;
}

.taskmodal-title-wrapper:hover,
.taskmodal-description-area:hover {
  border-color: #abb4c9;
}

textarea.form-control {
  font-weight: 600;
  color: #847199;
  font-size: 12px;
}

.font-bold {
  /* font-weight: 600; */
  font-size: 12px;
}

.progress-table {
  width: 100%;
  margin-bottom: 15px;
}

.progress {
  height: 11px;
  background-color: #fbfbfb;
  border-radius: 8px;
  width: calc(100% - 73px);
  float: left;
  margin-top: 6px;
  margin-right: 15px;
  border: 1px solid #5a6c97;
}

.progress-bar {
  background-color: #5a6c97;
}

.progress-bar-tdwidth {
  width: 280px;
}

.pad-l20 {
  padding-left: 20px;
  font-weight: 100;
}

.in-progress {
  color: #5de367;
}

.dots-3 {
  text-align: center;
  padding: 15px 0 0 0;
  color: #acb4c9;
}

.name-tasklist {
  font-size: 13px;
  margin: 0 0 15px 0 !important;
}

.name-tasklist span small {
  font-size: 13px;
  font-weight: 600;
  padding: 2px;
  border-radius: 2px;
  color: #007bff;
}

.name-tasklist span small:hover {
  background: #dadada;
}

.right-box-popup {
  border-right: 1px solid #5a6c97;
}

.left-box-popup {
  background-color: #edf0f4;
  padding-top: 15px;
  height: 70vh;
  padding-right: 0;
}

.off-gray {
  color: #afb8cc;
}

.just-now {
  text-align: right;
  padding-top: 10px;
}

.dots-padd-t {
  padding-top: 182px;
}

.pad-t15 {
  padding-top: 15px;
}

.Comment-Section {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.name-comment-box {
  background-color: #fff;
  border-radius: 12px;
  font-size: 12px;
  padding: 0;
}

.name-comment-box .commentBox-header {
  display: flex;
  justify-content: space-between;
}

.name-comment-box .comment-replyBox {
  border-top: 1px solid #d6d6d6;
  padding: 5px 15px;
}

.name-comment-box .comment-replyBox .replyUserImg {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.comment-here-box {
  background-color: #fff;
  margin: 0 -15px;
  padding: 15px;
  font-size: 14px;
}

.comment-here-box input {
  width: 100%;
  border: none;
  outline: none;
}

.quiz-checkbox-container {
  position: relative;
  margin-bottom: 5px;
}

.quiz-checkbox-container label {
  cursor: pointer;
  display: block;
  position: absolute;
  border: 2px solid #5a6c97;
  border-radius: 100%;
  height: 13px;
  width: 13px;
  top: 3px;
  left: 12px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
  box-sizing: border-box;
  padding: 0;
}

.quiz-checkbox-square-container label {
  border-radius: 0;
  left: 15px;
}

.quiz-checkbox-container span {
  position: absolute;
  left: 40px;
  top: 0;
  font-size: 12px;
}

.quiz-checkbox-square-container span {
  left: 32px;
}

.mar-t10 {
  margin-top: 10px;
}

.quiz-checkbox-container label:after {
  display: block;
  position: absolute;
  content: "";
}

.quiz-checkbox-container input[type="checkbox"] {
  visibility: hidden;
}

.quiz-checkbox-container input[type="checkbox"]:checked+label {
  border: 2px solid #5a6c97;
}

.quiz-checkbox-container input[type="checkbox"]:checked+label:after {
  opacity: 1;
  border-radius: 0;
  margin: auto;
  border-left: 2px solid #5a6c97;
  border-bottom: 2px solid #5a6c97;
  transform: rotate(-45deg);
  transition: opacity 300ms ease;
  top: -1px;
  left: 1px;
  width: 11px;
  height: 5px;
}

.dropdown-list {
  padding: 15px 15px 10px !important;
  font-size: 12px;
  color: #adb5cb !important;
  text-align: left !important;
}

.margin-b7 {
  margin-bottom: 7px;
}

/* .task-cards {
  height: calc(100vh - 305px);
  overflow: auto;
  margin-right: 20px;
} */
.task-cards {
  height: calc(100vh - 305px);
  overflow: auto;
  margin-right: 20px;
  margin-left: 24px;
  padding-bottom: 12px;
}

.task-cards .table-responsive {
   /* overflow: visible; */

}

/* .task-cards .table-responsive {
  overflow: visible;
  padding-left: 24px;
} */
.task-cards .table-responsive .task-card-title+.task-table {
  margin-bottom: 0;
}

.task-table-margin {
  margin-bottom: 2rem;
  opacity: 0;
}

.task-cards>.table-responsive>.show~.task-table-margin {
  margin-bottom: 0;
}

/*remove*/
/* .task-cards>.table-responsive>.show+.task-card-title{
  position: absolute;
  margin-bottom: 0;
} */

.h40,
.h40:hover,
.h40:active {
  height: 40px;
  color: #fff;
  padding: 5px 10px;
  border-radius: 0;
  border: 0;
  box-shadow: none !important;
  width: 100%;
  text-align: left;
}

.h40::after {
  display: none;
}

.w40 {
  width: 25%;
}

.w40 .dropdown-menu {
  margin: 0;
  padding: 0;
}

.task-modal-saveBtn {
  font-size: 15px;
  padding: 3px 10px;
  margin-left: 14px;
  margin-top: 5px;
}

/* .category-dropBtn::after{
  display: none;
} */
.category-dropBtn {
  background-color: #fff;
  color: #5a6c97;
  border: 1px solid #5a6c97;
  font-size: 12px;
  padding: 1px 15px;
  width: 150px;
}

.task-modal-scroll {
  height: calc(100vh - 380px);
  overflow-y: auto;
}

.bottom-comm-fix {
  position: absolute;
  width: 96%;
  bottom: 0;
}

.category-dropBtn+.dropdown-menu {
  padding: 0;
}

.category-dropBtn+.dropdown-menu .dropdown-item {
  font-size: 12px;
  padding: 3px 10px;
}

.dueDate-icon {
  width: 25px;
  margin: 0 20px;
}

.task-header-dropdown .dropdown-toggle {
  padding: 0;
  background-color: white !important;
  border: 0;
  box-shadow: none !important;
}

.task-header-dropdown .dropdown-toggle:after {
  display: none;
}

.taskstatus-heading-right .react-datepicker__input-container {
  border: 0;
}

.taskdetail-datepicker-red {
  font-weight: 700 !important;
  padding: 0 !important;
  color: #e50000 !important;
  outline: 0 !important;
  background-color: transparent !important;
}

.taskdetail-datepicker-green {
  font-weight: 700 !important;
  padding: 0 !important;
  color: #00b600 !important;
  outline: 0 !important;
  background-color: transparent !important;
}

.taskdetail-datepicker-orange {
  font-weight: 550 !important;
  padding: 0 !important;
  color: rgb(247 199 0) !important;
  outline: 0 !important;
  background-color: transparent !important;
}

.taskdetail-datepicker-blue {
  font-weight: 700 !important;
  padding: 0 !important;
  color: blue !important;
  outline: 0 !important;
  background-color: transparent !important;
}

.task-priority-btn {
  width: 36px;
  height: 36px;
  border: 1px dashed #979797 !important;
  border-radius: 50%;
  margin-left: 0;
  text-align: center;
}

.task-priority-btn svg {
  width: 16px;
  color: #979797;
  height: 35px;
  margin: 0;
  padding: 0;
}

.task-header-dropdown .dropdown-menu {
  padding: 0;
  font-size: 15px;
}

/* .task-header-dropdown .dropdown-menu .dropdown-item{

} */
.width25 {
  width: 25px;
}

.selected-avtar {
  width: 35px;
  height: 35px;
  border: 1px solid lightgray;
}

.transparent-dropdown>button {
  background-color: transparent !important;
  border: transparent !important;
  padding: 0;
  box-shadow: none !important;
}

.transparent-dropdown button .selected-avtar {
  margin-left: 0;
  width: 25px;
  height: 25px;
}

/* .task-table tbody{background-color: #e0e0e0;} */
.task-table .react-datepicker__input-container {
  border: 0;
  margin-left: 0px !important;
}

.task-name-highlight:hover {
  color: #007bff;
  /* font-size: 14px; */
}

.highlight-onHover:hover {
  color: #007bff;
}

.subtask-box-wrapper {
  margin: 10px 0px 10px 0px;
  font-size: 15px;
  /* font-weight: bold; */
  padding: 5px 200px 5px 30px;
  border: 1px solid #abb4c9;
  border-radius: 12px;
  /* margin: 10px auto 0; */
  color: #5b6c97;
  width: 100%;
}

.subtask-container {
  position: relative;
  padding: 0;
  margin: 0;
}

/* .input { 
  height:20px;
  margin:0;
  padding-left:30px;
} */
.task-status-icon,
.has-subtask.is-subtask .task-status-icon {
  display: inline-block;
  margin: 0 10px 0 15px;
  line-height: 0.8;
}

/* .task-status-icon{
  margin-left: -10px !important;
} */
.task-status-icon button.task-icon-bg {
  width: 10px;
  height: 10px;
  padding: 0;
  border-radius: 2px;
  border: 0;
  z-index: 9;
  position: relative;
  box-shadow: none !important;
}

.task-status-icon>span {
  position: absolute;
  content: "";
  left: -2.5px;
  top: -1px;
  height: 15px;
  width: 15.5px;
  border: 1px solid #2ecd6f;
  border-radius: 2px;
  display: none;
  z-index: -1;
}

.task-status-icon:hover>span {
  display: block;
  z-index: 0;
}

.task-status-sq {
  padding: 0.3rem 0.8rem;
  font-size: 13px;
}

.task-status-sq>span:first-child {
  width: 10px;
  height: 10px;
  padding: 0;
  border-radius: 2px;
  display: inline-block;
  margin-right: 5px;
}

.subtask-container .image {
  position: absolute;
  bottom: 5px;
  right: 59px;
  width: 25px;
  height: 25px;
}

.subtask-container .save-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0px 5px;
}

.subtask-status-wrapper {
  position: absolute;
  bottom: 0px;
  left: 11px;
}

.subtask-status-wrapper .btn {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  border: none;
  margin-bottom: 5px;
}

.subtask-status-wrapper .btn:active,
.subtask-status-wrapper .btn:focus {
  box-shadow: none;
}

.subtask-status-wrapper .btn svg {
  width: 10px;
}

.assignee-img {
  position: relative;
  border-radius: 50%;
}

.assignee-img .remove-icon {
  display: none;
  position: absolute;
  right: -5px;
  top: -3px;
  color: #ffffff;
  font-size: 15px;
  background: #5b6c97;
  width: 15px;
  padding: 1.5px;
  border-radius: 50%;
  border: 2px solid #f2f2f2;
}

.assignee-img:hover .remove-icon {
  display: block;
}

.no-image-user:hover+.remove-icon {
  display: block;
}

.groupByAssignees {
  /* margin-top: -4px; */
  margin-left: 15px;
  /* text-align: -webkit-center; */
  margin-right: 16px;
}

.subtask-assignee-wrapper {
  position: absolute;
  right: 60px;
  top: 0px;
}

.subtask-assignee-wrapper .btn {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  border: none;
}

.subtask-assignee-wrapper .btn:active,
.subtask-assignee-wrapper .btn:focus {
  box-shadow: none;
}

.subtask-assignee-wrapper .btn img {
  width: 25px;
  padding: 5px 0px;
}

.subtask-priority-wrapper {
  position: absolute;
  right: 95px;
  top: 0px;
}

.subtask-priority-wrapper .btn {
  padding: 7px 0px;
  background-color: transparent !important;
  border: none;
  color: #4e555b;
}

.subtask-priority-wrapper .btn:active,
.subtask-priority-wrapper .btn:focus {
  box-shadow: none;
}

.w-25px {
  width: 25px;
}

.subtask-list-item {
  margin-top: 5px !important;
  margin: 10px 0px 10px 0px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: 5px 200px 5px 10px !important;
  border: 1px solid lightgray !important;
  /* border-radius: 12px !important; */
  /* margin: 10px auto 0; */
  color: #5b6c97 !important;
  width: 100% !important;
}

.subtask-table {
  margin: 0;
  position: relative;
}

.subtask-table td {
  min-width: 170px;
}

.task-table .subtask-table tbody>tr>td:first-child {
  padding-left: 33px;
  position: relative;
}

.task-table .subtask-table:before {
  position: absolute;
  content: "";
  width: 20px;
  height: calc(100% - 17px);
  left: 14px;
  top: 0;
  border-left: 1px solid #5b6c97;
  border-bottom-left-radius: 5px;
  z-index: 9;
}

.task-table .subtask-table tbody>tr>td:first-child span.task-name-highlight {
  position: relative;
  padding-left: 5px;
}

.task-table .subtask-table tbody>tr>td:first-child span.task-name-highlight:before {
  position: absolute;
  content: "";
  width: 20px;
  left: -19px;
  /* height: 8px;
  top: 0; */
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid;
  height: 10px;
  top: -6px;
  bottom: 0;
  margin: auto;
}

.subtask-table tbody tr:last-child {
  /* border-bottom: 1px solid; */
  background: #f2f2f2;
}

.subtask-table .task-status-icon {
  position: absolute;
  left: 55px;
}

.subtask-table .task-status-icon+span {
  padding-left: 50px !important;
}

.subtask-table .task-status-icon>span {
  position: absolute !important;
  padding: 0 !important;
}

.subtask-table .task-status-icon>span:before {
  display: none !important;
}

.subtask-table.metatask-table .task-status-icon {
  left: 75px;
}

.subtask-table.metatask-table .task-status-icon+span {
  padding-left: 35px !important;
}

.padl-25 {
  padding-left: 25px;
}

/* .is-subtask .task-status-icon, */
.is-metatask .task-status-icon {
  margin-left: 0;
}

/*assignee*/
.assignee-dropdown {
  display: inline-block;
}

.assignee-dropdown>button {
  width: 25px;
  height: 26px;
  display: inline-block;
  border-radius: 50%;
  /* box-shadow: 0 0 0 2px #5b6c97 !important; */
  position: relative;
}

.assignee-dropdown>button .no-image-user,
.assignee-dropdown .dropdown-menu .no-image-user {
  width: 25px !important;
  height: 25px !important;
  line-height: 5px !important;
  font-size: 14px !important;
}

.assignee-dropdown+.assignee-dropdown {
  margin-left: -2px;
}

.assignee-dropdown .dropdown-item {
  padding: 4px 10px;
  font-size: 14px;
  line-height: 2;
  float: left;
  width: 145px;
}

.emp-icon-popoup {
  float: left !important;
  width: 124px;
}

.assignee-dropdown .dropdown-menu .no-image-user {
  margin-right: 5px;
}

.modal-assignee-wrapper>button {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: 2px solid #5b6c97 !important;
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
}

.modal-assignee-wrapper+.modal-assignee-wrapper {
  margin-left: -10px;
}

.assignee-dropdown:hover>button,
.modal-assignee-wrapper:hover>button {
  z-index: 9;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  position: relative;
}

.modal-assignee-wrapper>button.no-assignee {
  border: none !important;
}

/* .modal-assignee-wrapper:hover>button.no-assignee{ */
/* transform: none; */
/* } */
.taskstatus-heading .add-assignee {
  background-color: white;
  /* border-radius: 50%; */
}

.add-assignee-table {
  background: #f2f2f2;
  border-radius: 50%;
}

.time_est_popover .popover {
  max-width: 200px;
}

.time_est_fields {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.time_est_fields .time_fields {
  position: relative;
}

.time_est_fields .time_fields .form-control {
  font-size: 14px;
}

.time_unit {
  position: absolute;
  right: 6px;
  top: 8px;
  font-size: 14px;
}

.btn-small {
  padding: 5px 10px 7px;
  font-size: 14px;
  line-height: 1;
}

.timeTrack-playIcon {
  color: rgb(45, 204, 110);
  font-size: 17px;
  vertical-align: sub;
}

.timeTrack-stopIcon {
  color: rgb(204, 45, 45);
  font-size: 20px;
  vertical-align: middle;
}

.time_tracked_tabs .nav-link {
  flex-grow: 1;
  text-align: center;
}

.timeTrackedIcon {
  font-size: 24px;
  color: #5b6c97;
  cursor: pointer;
}

.time_tracked_fields {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.time_tracked_fields input {
  display: inline-block;
  /* width: 30px !important; */
  border-radius: 5px;
  text-align: left;
  height: 34px;
  outline: 0;
}

.desc_icon {
  border: 1px dashed gray;
  color: gray;
  width: 30px;
  height: 30px;
  display: flex;
  margin-left: auto;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.timeTrackedIconPlay {
  font-size: 30px;
  color: rgb(45, 204, 110);
  cursor: pointer;
}

.timeTrackedIconPause {
  font-size: 30px;
  color: rgb(204, 45, 45);
  cursor: pointer;
}

.time-tracked-duration {
  padding: 10px;
  background: #edf0f4;
  padding-left: 38px;
}

.time-tracked-duration p {
  font-size: 11.5px;
  margin: 0 0 8px;
}

.time-tracked-duration p:last-child {
  margin-bottom: 0;
}

.time-tracked-duration p>span {
  width: 35px;
  display: inline-block;
}

p.padl-25 {
  margin: 0;
}

.task-table .metatask-table tbody>tr>td:first-child {
  padding-left: 63px;
}

.task-table .metatask-table tbody {
  position: relative;
}

.task-table .metatask-table tbody:before {
  position: absolute;
  content: "";
  width: 20px;
  /* height: calc(100% - 17px); */
  height: 52%;
  left: 44px;
  top: 0;
  border-left: 1px solid #5b6c97;
  border-bottom-left-radius: 5px;
}

.task-table .subtask-table tbody>tr>td.has-subtask .metaTask_arrow {
  position: absolute;
  left: 40px;
  /* top: 7px; */
}

.task-comment-img {
  width: 80px;
  text-align: center;
}

.task-comment-img img {
  width: 35px !important;
  border-radius: 50%;
}

.task-modal-comment p {
  font-size: 13px;
  margin: 0;
}

.time_tracked_popover .popover {
  min-width: 370px;
}

#timetracklist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
}

#timetracklist:hover {
  background-color: #efefef;
}

#timetracklist img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-time-track {
  padding: 8px 10px;
  border-top: 1px solid #ccc;
}

.user-time-track ul {
  margin-bottom: 0;
}

.user-time-track ul li {
  display: inline-block;
  font-size: 13px;
}

.user-time-track ul li+li {
  margin-left: 15px;
}

.user-time-track ul li .edit {
  color: #0194f7;
  cursor: pointer;
}

.user-time-track ul li .delete {
  color: #a50000;
  cursor: pointer;
}

.user-time-track ul li .view {
  color: #5b6c97;
  cursor: pointer;
}

.time_track_scroll {
  min-height: 5px;
  max-height: 250px;
  overflow-y: auto;
}

.time_track_scroll:empty {
  height: auto;
}

.time-log-popover-outer .popover {
  max-width: 200px;
}

.time-log-popover {
  text-align: right;
  padding: 10px;
}

.time-log-popover .text-center button {
  display: inline-block;
}

.time-log-popover .text-center button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  margin-right: 10px;
}

.time-log-popover .react-time-picker__wrapper {
  margin-bottom: 10px;
}

.custom-check {
  position: relative;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
}

.custom-check input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 99;
}

.custom-check .checkbox-text {
  display: inline-block;
}

.custom-check .checkmark {
  margin-right: 5px;
  height: 18px;
  min-width: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #0194f7;
  cursor: pointer;
  display: block;
  position: relative;
}

.custom-check .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-check:hover input~.checkmark {
  background-color: #eeeeee;
}

.custom-check input:checked~.checkmark {
  background-color: #0194f7;
}

.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check input:checked~.checkmark:after {
  display: block;
}

.task-checklist-update .subtask-assignee-wrapper,
.custom-check .subtask-assignee-wrapper {
  position: relative;
  right: 0;
  display: inline-block;
  margin-left: 10px;
}

.task-checklist-update .subtask-assignee-wrapper button,
.custom-check .subtask-assignee-wrapper button {
  outline: 0;
  box-shadow: none;
}

.task-checklist-update {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  align-items: center;
  margin-bottom: 5px;
}

.task-checklist-update .btn-primary {
  font-size: 12px;
  padding: 1px 5px;
  margin-left: 10px;
}

.task-checklist-update input {
  border: 0;
  outline: 0;
}

.custom-check .subtask-assignee-wrapper img,
.task-checklist-update .subtask-assignee-wrapper .btn img {
  padding: 0px;
}

/*rangepicker*/
.due-date-range .popover {
  max-width: fit-content;
}

.due-date-range .rdrMonth {
  width: 22.667em;
}

.due-date-range .rdrDay {
  line-height: 2.5em;
  height: 2.5em;
}

.due-date-range .rdrDefinedRangesWrapper {
  width: 200px;
}

.due-date-range .rdrMonthAndYearWrapper {
  height: 40px;
}

.due-date-range .rdrDayToday .rdrDayNumber span:after {
  bottom: 1px;
}

.hourglass-border {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px dashed #afafaf;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
}

.hourglass-border svg {
  color: #b4b4b4;
  font-size: 12px;
}

.text-hover-blue:hover {
  color: #0194f7 !important;
  cursor: pointer !important;
}

.task-cat-dropdown>button {
  background-color: transparent;
  color: rgb(91, 108, 151);
  border: 0px;
  font-size: 12px;
  width: 130px;
  font-weight: 600;
}

.task-cat-dropdown .dropdown-menu {
  font-size: 12px;
}

.task-cat-dropdown>button:hover,
.task-cat-dropdown>button:focus,
.task-cat-dropdown>button:active {
  color: #5b6c97;
  background-color: #d0d4df;
  border-color: transparent;
  box-shadow: none;
  outline: 0;
}

.subtask-cat {
  font-size: 12px !important;
  font-weight: 600 !important;
}

.checklist-assignee {
  margin-left: 5px;
}

.dropzone {
  flex: 1;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
  transition: border 0.24s ease-in-out;
  transition-property: border;
  transition-duration: 0.24s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 60px;
}

.dropzone:hover {
  color: gray;
  cursor: pointer;
}

.min-h-taskinfo {
  min-height: 69px;
  text-align: center;
}

.min-h-taskinfo p:nth-child(2) {
  /* line-height: 28px; */
}

.todo-collapse {
  /* border: 1px solid #adb6ca; */
  margin: 10px 0 30px;
}

.todo-subtitle {
  padding: 5px 10px;
  background: #edf0f4;
  border-radius: 5px;
  margin: 0 0 10px;
}

.task-cards .react-datepicker-popper[data-placement^="top"] {
  top: 108% !important;
}

.dependency-popver .popover-header {
  font-size: 12px;
  font-family: 'Avenir Next LT W02';
}

.dependency-popver .popover-body {
  min-width: 200px;
  font-family: 'Avenir Next LT W02';
}

.dependency-popver .dropdown button {
  width: 100%;
  text-align: left;
  background: transparent !important;
  border: 0;
  outline: 0;
  color: black !important;
  font-size: 13px;
  box-shadow: none !important;
}

.dependency-popver .dropdown-toggle::after {
  display: none;
}

.dependencyCollapse {
  margin-left: 14px;
  border-radius: 3px;
  margin-top: 0px;
  /* border: 1px solid #dee2e6 !important; */
}

.dependency-list {
  list-style-type: none;
  font-weight: 400;
  padding: 5px 10px;
  border-bottom: 1px solid #dee2e6 !important;
}

.dependencyCollapse li:last-of-type {
  border-bottom: 0 !important;
}

.dependency-status-icon {
  height: 8px;
  width: 8px;
  border-radius: 2px;
  display: inline-block;
  background: #5b6c97;
  margin-right: 10px;
}

.dependency-popver .popover {
  box-shadow: 0 8px 10px #d2d2d2;
  -webkit-box-shadow: 0 8px 10px #d2d2d2;
}

.delete-dependency-icon {
  float: right;
  display: inline-block;
  color: #e50000;
  margin-top: 2px;
  cursor: pointer;
}

.edit-dependency-icon-edit {
  border-radius: 4px;
  border: 1px solid;
  float: right;
  display: inline-block;
  color: #007bff;
  margin: 10px;
  margin-top: -2px;
  cursor: pointer;
  padding-right: 6px;
}

.edit-dependency-icon {
  float: right;
  display: inline-block;
  color: #007bff;
  margin: 10px;
  margin-top: 2px;
  cursor: pointer;
}

.rotate-ellipses {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.add-column #firstplus-popup {
  padding: 0;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
}

.white-space-right {
  position: relative;
}

.white-space-right:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  background-color: white;
  width: 10px;
  height: 97%;
}

.newField-box {
  width: 95%;
  margin: 0 auto 15px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  /* overflow: hidden; */
  font-size: 14px;
}

.newField-table {
  margin: 0;
}

.newField-table tr td:first-child {
  border-right: 1px solid #dee2e6;
  width: 250px;
  padding-left: 15px;
  vertical-align: middle;
}

.newField-table tr:first-child td {
  border-top: 0;
}

.newField-table tr td .form-control {
  height: 30px;
  font-size: 14px;
  line-height: 1.2;
}

.newField-table tr td .form-control:disabled {
  background: #eaeaea;
  cursor: not-allowed;
  border-color: #eaeaea;
}

.newField-tableOptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  background: #fafafa;
  font-size: 13px;
}

.newField-tableOptions p {
  margin: 0;
  cursor: pointer;
}

.newField-tableOptions a {
  text-decoration-line: underline !important;
  text-decoration-style: dashed !important;
}

.newField-tableOptions p:hover,
.newField-tableOptions a:hover {
  color: #0180C7;
  font-weight: bold;
}

.newField-table .badge {
  font-size: 13px;
  font-weight: 500;
  min-width: 150px;
  border: 0;
  height: 30px;
}

.newField-table .hoverDelete .removeField-icon {
  float: right;
  font-size: 13px;
  margin-top: 5px;
  margin-right: 5px;
  display: none;
  cursor: pointer;
}

.newField-table .hoverDelete:hover .removeField-icon {
  display: inline-block;
}

.hoverDelete .dropdown {
  display: inline-block;
}

.customFieldModal .modal-content {
  border-radius: 10px !important;
  overflow: hidden;
}

.manageField-btns {
  display: flex;
  align-items: center;
}

.manageField-btns a {
  text-decoration-line: underline !important;
  color: #007bff !important;
}

.manageField-btns span {
  text-decoration-line: underline;
  color: #007bff;
  cursor: pointer;
}

.addCustomField-title {
  color: gray;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.addCustomField-table {
  margin-bottom: 25px;
}

.addCustomField-table td {
  border: 0;
}

.addCustomField-table .actionBtns {
  height: 100%;
  display: none;
  padding: 2px 0;
  width: 100px;
  text-align: center;
}

.addCustomField-table .actionBtns svg {
  padding: 0;
  font-size: 13px;
  cursor: pointer;
  margin: 0 10px;
}

.addCustomField-table td .field-icon {
  padding: 0;
  color: #5a6c97;
  margin-right: 10px;
  width: 15px;
}

.addCustomField-table .actionBtns svg:first-child {
  color: gray;
}

.addCustomField-table .labels {
  display: none;
  padding: 0;

  /* opacity: 0; */
}

.addCustomField-table tr:hover .actionBtns {
  display: table-cell;

}

.addCustomField-table tr:hover .labels {
  display: table-cell;

}

.addCustomField-table .labels span {
  margin-right: 10px;
  background-color: #f4f4f4;
  /* text-decoration: line-through; */
  /* text-decoration-color: red; */
  border-radius: 15px;
  padding: 3px 10px;
  font-size: 13px;

}

.addCustomField-table .labels span svg {
  padding: 0;
  color: #5a6c97;
}

.addCustomField-table .labels.label-required span {
  background-color: #2ecd6f;
  color: white;
  text-decoration: none;
}

#createField-modal {
  max-width: 950px;
}

#createField-modal .modal-body {
  max-height: 500px !important;
  overflow-y: auto !important;
}

.model-header-content {
  position: sticky;
  top: 0px;
  z-index: 1000;
  background: #e1e1e1;
}

.createFieldModal.modal-dialog {
  border-radius: 10px;
  overflow: hidden;
}

.createFieldModal .close {
  font-size: 17px;
  font-weight: 400;
  text-shadow: none;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.customFieldCard {
  align-items: center;
  text-align: center;
  margin: 15px 7px 0;
  height: 90%;

}

.customFieldCard:hover {
  border-color: #5b6c97;
  cursor: pointer;
  border-color: blue;
}

.customFieldCard .customField-icon {
  font-size: 25px;
  margin: 0 0 10px;
}

.customFieldCard:hover .customField-icon {
  color: #5b6c97;
}

.customFieldCard p {
  line-height: 1.4;
  font-weight: 400;
  color: #717171;
}

.customFieldCard h5 {
  font-size: 17px;
  margin: 0 0 10px !important;
}

#manageFieldModal,
#existingField-Modal {
  max-width: 700px;
}

.fieldSearch {
  position: relative;
  width: 85%;
  margin: 10px auto 20px;
  padding: 0 10px !important;
}

.fieldSearch input {
  border-radius: 30px;
  height: 30px;
  padding-left: 35px;
  border-color: #abb4c9;
}

.fieldSearch svg {
  position: absolute;
  left: 30px;
  top: 8px;
  font-size: 13px;
  color: gray;
}

.existingField-list {
  margin: 10px auto 20px;
}

.existingField-list li svg {
  color: #5a6c97;
  margin-right: 8px;
}

.existingField-list li {
  padding: 5px 60px;
  font-size: 13px;
}

.existingField-list li:hover {
  background: #f4f4f4;
}

.labelBold {
  font-weight: 600;
  font-size: 14px;
}

.updatedPriorityIcon {
  margin: 0 3px;
  font-size: 14px;
  width: 22px;
  display: inline-block;
  text-align: center;
  /* height: 25px;
  border-radius: 50%;
  border: 1px dashed;
  line-height: 25px; */
}

.newFieldDropColor {
  padding: 0;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  box-shadow: none;
  position: absolute;
  top: 35px;
  right: 5px;
}

input[type="color"].newFieldDropColor::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"].newFieldDropColor::-webkit-color-swatch {
  border-color: #cccccc;
}

.taskModal-contentRight {
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 15px;
}

.addLookupFieldTbl .labels {
  display: table-cell;
  opacity: 0;
}

.addLookupFieldTbl tr:hover .labels {
  opacity: 1;
}

.addCustomField-table .actionBtns svg.lookupFieldDel {
  color: red;
  font-size: 15px;
}

.my-30 {
  margin: 30px 0;
}

.taskComplete-warning .modal-body {
  text-align: center;
  padding: 50px;
  /* height: 525px; */
  overflow-y: auto;
}

.taskComplete-warning .modal-title {
  justify-content: flex-end !important;
}

.taskComplete-warning .modal-content {
  overflow-y: auto;
}

.duplicate-task-modal .modal-body {
  padding: 23px;
}

.taskComplete-warning-subtitle {
  font-size: 14px;
}

.taskComplete-warning .modal-content {
  border-radius: 15px !important;
  width: 550;
}

.taskComplete-warning .modal-body .textField-list {
  text-align: left;
  list-style-type: none;
  font-size: 14px;
  margin: 0 0 25px;
  padding: 0;
}

.taskComplete-warning .modal-body .textField-list li::before {
  content: "\25A0";
  color: #5b6c97;
  font-weight: bold;
  display: inline-block;
  width: 16px;
  margin-left: 0;
  vertical-align: text-bottom;
}

.taskmodal-description-area:hover {
  border-color: #abb4c9;
}

.centered-modal-heading {
  justify-content: center;
}

.centered-modal-heading .close {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 20px;
}

.newField-table .dropdown .badge+.dropdown-menu {
  padding: 5px;
  box-shadow: 0 5px 15px #cacaca;
  border: 0;
  border-radius: 0;
}

.newField-table .dropdown .badge+.dropdown-menu button+button {
  margin-top: 5px;
}

.newField-table tr td .form-control::-webkit-input-placeholder {
  color: #999999;
}

.newField-table tr td .form-control:-ms-input-placeholder {
  color: #999999;
}

.newField-table tr td .form-control::placeholder {
  color: #999999;
}

.newField-table tr:hover {
  background-color: transparent;
}

.toDo-dropdown h5 {
  display: inline-block;
}

.addAttachment-dropdown {
  display: flex;
}

.addAttachment-dropdown .dropdown,
.toDo-dropdown .dropdown {
  display: inline-block;
  vertical-align: super;
  margin-left: 8px;
}

.addAttachment-dropdown .dropdown .btn-secondary,
.toDo-dropdown .dropdown .btn-secondary {
  padding: 0 10px;
  background: white;
  color: #007bff;
  border-color: #007bff;
  font-size: 13px;
  box-shadow: none !important;
}

.addAttachment-dropdown .dropdown .btn-secondary:hover,
.toDo-dropdown .dropdown .btn-secondary:hover {
  background: #007bff;
  color: white;
}

.addAttachment-dropdown .dropdown .dropdown-item,
.toDo-dropdown .dropdown .dropdown-item {
  font-size: 13px;
  padding: 5px 10px;
}

.name-tasklist-label {
  padding: 2.5px 8px !important;
  font-size: 12px;
}

.task-actions-left {
  width: 95%;
  margin: auto;
}

#timeTracked {
  margin-left: -4px !important;
}

.checklist-icon {
  width: 13px;
  vertical-align: text-bottom;
  margin-right: 5px;
}

.subtask-icon {
  transform: rotate(180deg);
  width: 13px;
  margin-right: 5px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.taskExtraOps {
  flex-grow: 1;
  text-align: right;
}

.taskExtraOps>button {
  background: transparent !important;
  border-color: transparent !important;
}

.mileStone-icon {
  margin-top: 6px;
  margin-right: 5px;
}

.mileStone-icon svg {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  font-size: 20px;
}


.taskmodal-title-wrapper-input {
  border: 0;
  font-size: 25px;
  line-height: 1;
  padding: 0 10px;
  vertical-align: text-bottom;
  font-weight: 600;
  color: #5b6c97;
  box-shadow: none;
}
.taskmodal-title-wrapper-input:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}


.rotate-diamod {
  transform: rotate(45deg);
}

.duplicate-taskname {
  border: 1px solid #c5c2c2;
  border-radius: 6px;
  margin-bottom: 10px;
  width: 70%;
}

.duplicate-task-box {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  padding: 15px 0;
  margin: 0 0 15px 0;
}


.create-subtask-modal {
  margin: 0;
  height: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-subtask-modal .modal-header {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}

.create-subtask-modal .modal-content {
  border-radius: 10px !important;
  width: 32%;
}

.create-subtask-modal .modal-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #edf0f4;
  padding-right: 15px;
}

.create-subtask-modal .modal-title .close {
  font-size: 15px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.subtask-breadcrumb {
  font-size: 15px;
  text-decoration: underline !important;
  font-weight: 500 !important;
}

.subtask-breadcrumb+svg {
  font-size: 14px;
  color: #000000;
}

.subtaskHeaderAction {
  display: flex;
  margin: 0;
  border: 0;
}

.create-subtask-modal .assignSubtaskIcon {
  position: relative;
  right: 0;
  top: 0;
  margin-right: 15px;
}

.create-subtask-modal .prioritySubtaskIcon {
  position: relative;
  right: 0;
  top: 0;
}

.create-subtask-modal .assignSubtaskIcon button {
  box-shadow: none !important;
}

.create-subtask-modal .prioritySubtaskIcon>button {
  box-shadow: none !important;
  border: 1px dashed #b4b4b4;
  width: 26px;
  text-align: center;
  height: 26px;
  border-radius: 50%;
  padding: 0;
  font-size: 12px;
  vertical-align: sub;
  color: #878787;
}

.newSubtaskForm {
  padding: 15px 15px 0;
}


.add-dependency-button {
  border-color: #007bff;
  background: #fff;
  color: #007bff;
  padding: 0 10px;
  font-size: 13px;
  box-shadow: none !important;
}

.cross-dependency-icon {
  margin-left: 6px;
}

.comment-replyBox .media {
  margin: 10px 0;
}

.media-reply .media-left {
  width: 35px;
  margin-right: 10px;
  padding: 0;
}

.media-reply .media-left img {
  border-radius: 50%;
  width: 100%;
}

.media-reply .media-body {
  border: 1px solid #dadada;
  border-radius: 5px;
  padding: 5px;
}

.no-image-user {
  width: 40px;
  height: 40px;
  float: left;
  background-color: #add8e6;
  color: #000;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 11px 5px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 18px;
  position: relative;
  /* right: 8px; */
}

.ant-notification {
  z-index: 1100 !important;
}

.task-comment-img .no-image-user {
  margin-left: 5px;
}

.dropdownHeader-search {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

/* EMPLOYEE CALENDAR */
.empCalendar-popover .popover {
  /* min-width: fit-content; */
}

.empCalendar-popover .popover-header {
  background-image: linear-gradient(45deg, #0180C7, #0a557e);
  color: #ffffff;
}

.empCalendar-popover .createdeal-headpadding {
  display: none;
}

.empCalendar-popover .empCalendarWrapper {
  margin: 15px 0;
  padding: 0;
  border: 0;
}

.ant-popover .empCalendarWrapper .rbc-month-row {
  min-width: 40vw;
}

.ant-popover .createdeal-headpadding {
  display: none;
}

.ant-popover {
  z-index: 1060 !important;
}

.ant-popover .popover-header {
  background-image: linear-gradient(45deg, #0180C7, #0a557e);
  color: #ffffff;
}

.ant-popover .empCalendarWrapper {
  padding: 0;
  border: 0;
  height: auto;
  overflow: hidden;
}

.task-name-highlight svg {
  margin: 3px;
  /* margin-top: 7px; */
}

.double-dependency {
  width: 12px;
  height: 12px;
  background-image: linear-gradient(-90deg, #f40600 50%, #FFA500 50%);
  border-radius: 50%;
  position: relative;
}

.double-dependency:before {
  position: absolute;
  content: "";
  height: 100%;
  background: white;
  width: 1px;
  left: 0;
  right: 0;
  margin: auto;
}

.taskDependency-modal {
  max-width: 50%;
}

.taskDependency-modal .modal-content {
  padding: 30px;
  border-radius: 15px !important;
}

.dependency-box {
  border: 1px solid;
  border-left-width: 2px;
  background: #fbfbfb;
  margin-top: 15px;
  padding-bottom: 15px;
}

.dependency-status {
  display: flex;
  font-size: 12px;
  align-items: center;
  position: sticky;
  top: 0px;
  padding: 15px;
  background: #fbfbfb;
}

.dependency-status span {
  text-transform: uppercase;
  margin-left: 7px;
}

.dependency-task-list {
  margin: 0;
  padding: 0 15px;
  max-height: 24vh;
  overflow-y: auto;
}

.dependency-task-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 14px;
}

.dependency-task-list li .badge {
  font-weight: 200;
  background: #5b6c97;
  font-size: 11px;
  padding: 3px 10px;
  color: white;
  cursor: pointer;
}

.dependency-modal {
  max-width: 40%;
}

/* .dependency-input{
  height: 55px;
} */

.minus-circle-v {
  height: 13px;
  margin: 3px;
  width: 13px;

}


.openTask-popup {
  visibility: hidden;
}

.task-table tbody tr td:hover .openTask-popup {
  visibility: visible;
}

.task-table thead .drag-table-icon {
  margin-top: 12px;
  color: #5b6c97;
  visibility: hidden;
  padding: 0px;
  font-size: 13px;
  margin-right: 8px;
  color: #a7a7a7;
}

.task-table thead th:hover .drag-table-icon {
  visibility: visible;
}

.formulaPopUp-listGroup {
  border: solid 0.5px lightgray;
  max-height: 200px;
  margin-bottom: 10px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.deal-table .modal-content .modal-body table tr td {
  width: 33% !important;
}

.deal-table .modal-content .modal-body table tr td:last-child {
  text-align: right !important;
}

.formula-modal .modal-body {
  padding: 18px !important;
}

.formulaPopUp-listGroup li span {
  font-weight: 100;
  font-size: 12px;
  background: #f6e09f;
  color: #da7100;
  border-radius: 0px 5px 5px 0px;
  padding: 4px 7px !important;
  float: right;
  border: 1px solid #da7100 !important;
}

.formulaPopUp-listGroup li {
  padding: 14px 10px !important;
}

.formula-modal .modal-body {
  padding: 18px !important;
}

.formula-header {
  font-size: 18px;
}

.formula-body {
  font-size: 12px;
}

.list-font {
  font-size: 12px;
}

.formula-usage-card {
  background-color: lightgoldenrodyellow;
}

.drop-file-box {
  width: 100%;
  height: 460px;
  border: 5px dashed lightgray;

}

.category-toggle {
  position: relative;
  height: 22px;
}

.category-toggle::after {
  position: absolute;
  right: 10px;
  top: 9px;
}

.drop-file-task {
  border: 6px dashed rgb(221, 221, 221);
  width: 95%;
  color: rgb(221, 221, 221);
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  position: absolute;
  top: 40%;
  left: 2%;
  z-index: 999;
  font-size: 2rem;
  padding: 15% 0;
}


.list-grid-buttons {
  margin-left: 7px;
  height: 22px;
  border-color: #007bff;
  border-radius: 4px;
  border-width: 1px;
  background-color: white;
  color: #007bff;
  border: 1px solid;
  padding: 1px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 12px;
  font-weight: normal;
  cursor: pointer;
}

.custom_dropdown_css .dependencyCollapse {
  margin-left: 16px !important;
}

.task-actions-left .todo-collapse {
  margin-left: 16px !important;
}

.task-actions-left .task-attachment-card {
  margin-left: 16px !important;
}

.list-grid-buttons_custom {
  border: 1px solid rgb(0, 123, 255) !important;
}

.category-dropBtn .sr-only::after {
  margin-left: 9.255em !important;
}

.task-template-card {
  width: 120px !important;
  height: 120px !important;
  text-align: center !important;
  margin-top: 22px !important;
  resize: horizontal !important;
}


.task-attachment-modal-remove-icon {
  bottom: 22px;
  position: relative;
  left: 16px;
  font-size: 18px;
  /* font-weight: 600; */
  color: #518ff5;
}

.fixed-contentleft-head {
  z-index: 100;
  position: sticky;
  left: 0;
  width: 100%;
  background: #fff;
  /* top: 14px; */
}

.task-table>tbody>tr>td:first-child {
  position: sticky;
  left: 0px;
  background-color: #f2f2f2;
  z-index: 9;
  display: flex !important;
  min-width: 370px !important;
  text-align: left !important;
  width: auto !important;
}

.task-table>tbody>tr>td:first-child .radio-check-task {
  position: absolute;
  display: none;
  top: 13px;
  left: 5px;
  z-index: 1000;
}

.task-table>tbody>tr>td:first-child input[type='radio']::before {
  border: 1px solid green;
}

.task-table>tbody>tr:hover td:first-child .radio-check-task {
  display: block;
}

.three-dots-priority button {
  background-color: inherit;
  border: 0px;
  color: rgba(0, 0, 0, 0.85);
  padding: 0px;
  font-size: 14px;
}

.three-dots-priority button:hover {
  background-color: #f0f1f3;
  border: 0px;
  color: rgba(0, 0, 0, 0.85);
  padding: 0px;
  font-size: 14px;
}

.three-dots-priority button:focus {
  background-color: inherit;
  border: 0px;
  color: rgba(0, 0, 0, 0.85);
  padding: 0px;
  font-size: 14px;
}



.task-table tbody tr td {
  padding-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}



.task_table_header {
  background: #fff;
  position: sticky !important;
  left: 0px !important;
  z-index: 90;
}

.cont-box2 {
  position: relative;
  top: 4px;
  left: 6px !important;
  /* padding-left: 20px; */
  font-size: 12px;
  font-weight: 500;
  /* / margin-left: 240px; / */
}

.cont-box2 span:first-child,
.cont-box2 span:last-child {
  background: #5b6c97;
  color: #fff;
  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  padding: 0px 10px;
  line-height: 18px;
  font-weight: normal;
  display: inline-block;
  max-width: 5rem;
  min-width: 5rem;
}

.cont-box3 {
  padding-left: 0;
}

.cont-box4 {
  padding-left: 10px;
}

.custom-slash {
  padding: 0px 5px;
  position: relative;
  top: -4px;
}

.subtask-parent-name {
  padding: 0px 7px !important;
}

.subtask-parent-name span {

  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  padding: 0px 10px;
  line-height: 18px;
  font-weight: normal;
  margin-top: 8px;
  display: inline-block;
}

.task-location-content {
  display: flex !important;
  align-items: center !important;
  top: 10px !important;
}

.task-status-icon button.task-icon-bg {
  margin-top: -3px !important;
}

.task-location-content .badge {
  margin-left: 2px !important;
  font-size: 9px !important;
}

.task-status-icon-left {

  width: 12px;
  height: 12px;
  display: inline-block;
  margin: 0 5px;
  padding: 0px;
  border-radius: 3px;
  border: none;
}


.task-modal-status-btn {
  height: 24px;
  min-width: 116px;
  padding-left: 10px;
  display: flex;
  border: none !important;
  align-items: center;
  border-radius: 30px 0px 0px 30px;
}

.task-modal-status-arrow svg {
  height: 24px !important;
  border-radius: 0px 30px 30px 0;
}


.task-td-status button {
  width: 70% !important;
  padding: 0 10px !important;
  position: relative !important;
}

.task-td-status button span {
  /* font-weight: 600 !important; */
}

.task-td-status button svg {
  position: absolute;
  right: 10px;
  z-index: 1000;
  top: 7px;
}

.task-td-status button svg {
  display: none;
}

.task-table>tbody>tr:hover .task-td-status button svg {
  display: block;
}


/* .datepicker-action .react-datepicker__input-container input{
  display: none !important;
} */

.datepicker-action .react-datepicker__input-container input {
  display: none !important;
}

.datepicker-action .react-datepicker-wrapper {
  /* display: none !important; */
}

.datepicker-action .react-datepicker__input-container {
  border: 0px;
}

.taskmodal-dealname-top {
  cursor: pointer;
  color: white;
  background-color: rgb(91, 108, 151);
  border-radius: 30px;
  height: 24px;
  width: 143px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  margin-left: 10px !important;
  box-shadow: none !important;
  justify-content: space-around;
}

.taskmodal-dealname-top svg {
  display: none;
}

.taskmodal-dealname-top:hover svg {
  display: block;
  position: absolute;
  left: 257px;

}

.taskmodal-dealname-top:hover {
  width: 143px;
  cursor: pointer;
  color: white;
  background-color: rgb(91, 108, 151);
  border-radius: 30px;
  height: 24px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  margin-left: 10px !important;
  box-shadow: none !important;
  justify-content: space-around;

}


.successe-status {
  background-color: rgb(152, 202, 60);
  height: 10px;
  width: 10px;
  display: inline-block;
  /* padding-right: 10px; */
  margin-right: 2px;
}

.time_track_scroll table tbody tr td:first-child {
  width: 12% !important;
  text-align: left !important;
}

.time_track_scroll table tbody tr td {
  width: 25%;
  text-align: left;
}

.tox.tox-tinymce-inline {
  z-index: 999999;
  left: 696px;
  top: 238px;
  width: 599px;
  /* left: 697px !important;
  top: 237px !important;
  width: 598px !important; */
}

.callLog-desc-editor>.mce-content-body {
  height: 150px;
  /* position: relative; */
  border: solid 1px lightgray;
  border-radius: 6px;
  padding: 8px;
}

/* .callLog-desc-editor>.mce-content-body:focus {
  height: 250px;

  border: solid 1px lightgray!important;
  border-radius: 6px;
  padding: 8px;
} */
.callLog-desc-editor>.mce-content-body:focus-visible {
  height: 150px;
  /* position: relative; */
  border: solid 1px lightgray !important;
  border-radius: 6px;
  padding: 8px;
  outline: lightgray;
}

.task-tab{
  width: 100%;
}

.task_global_search {
  overflow: hidden !important;
  background: white;
}

.task_global_search .row .project-tasks-wrapper .task-tab {
  width: 100%;
}

.task_global_search .row .project-tasks-wrapper .task-tab .project-deals-height {
  max-height: calc(100vh - 290px) !important;
  padding-right: 0px !important;
}

.task_global_search .row .project-tasks-wrapper .task-tab .project-deals-height .mb-2 .collapse .task-cards {
  overflow: unset !important;
}

.Comment-Section {
  /* height: 22vh !important; */
  margin-bottom: 15px !important;
}

.task-modal-comment>div>p>span {
  color: rgb(24, 144, 255) !important;
  cursor: pointer;
}

.mymention {
  color: rgb(24, 144, 255) !important;
  cursor: pointer;
}

.task-table tbody tr:hover td:first-child {
  background-color: rgb(229 229 229) !important;
}

.table-hover tbody tr:hover {
  background-color: rgb(229 229 229) !important;
}

/* .task-table>tbody>tr:hover .task-table>tbody>tr>td:first-child{
  background-color: rgb(235 235 235) !important;
 }  */


.task-modal-comment-action {
  float: right;
  margin-top: -15px;
  display: none;
}

.name-comment-box:hover .task-modal-comment-action{
  display: block;
}

.reminder-assignees .user-img-icon img{
  border-radius: 50%;
  padding: 0px !important;
}

.reminder-bell-num {
  top: 17px;
  left: 36px;
  font-size: 9px;
  position: absolute;
}
.reminder-bell-num  {
  padding: 0px 3px 0px 3px !important;
  /* padding: 3px 3px 2px 2px; */
  /* padding-bottom: 0.3em; */
  /* padding-top: 0.3em; */
  /* padding-right: 0.2em; */
  /* padding-left: .2em; */
  border-radius: 10rem;
}
.reminder_popup .time_track_scroll .user-time-track {
   padding: 8px 10px;
   /* border-top:0px solid #ccc !important;  */
   
}

.notificationFilters .editColumn-custom>.dropdown-menu .editColummn-list {
  
  height: calc(52vh - 232px) !important;
 
}

.notificationFilters .editcol-searchpad .search-ico {
  top: 19px !important;
  right: 24px !important;
}
.search-ico2{
  position: absolute !important;
  margin-left: 0.8% !important;
  font-size: small !important;
}

.task_global_search svg .global-search-icon{
  margin-left: 10px !important;
}
.taskFilterModal .ant-modal-footer{
  display: block !important;
}