.multiselect {
  /* width: 200px; */
  width: 105%;
  position: relative;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 34px;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  height: 34px;
}

.checkboxes {
  display: none;
  border: 1px #dadada solid;
}

.checkboxes label {
  display: block;
}

/* .checkboxes label:hover {
  background-color: #1e90ff;
} */

.custom-dropdown-box{
  display: block;
    position: absolute;
    background-color: #fff;
    width: 100%;
    /* padding-left: 5px;
    padding-top: 7px; */
    padding: 10px;
    z-index: 9999;
    /* max-height: 260px;
    overflow-y: auto; */
}

.filter-drop-list{
   max-height: 250px;
    overflow-y: auto;
}

.cust-drop-icon {
  position: relative;
  top: -2px;
  left: 5px;
}
.cust-drop-icon > img {
  width: 18px;
}
.cust-drop-icon > span {
 
  width: 12px!important;
  position: absolute;
  /* padding: 8px; */
  height: 12px;
  /* left: 10px; */
  top: 5px;
 
}
.cust-drop-text {
  position: relative;
  top: -2px;
    left: 15px;
}

.cust-drop-search{
  border: 1px solid #d9d9d9;
    border-radius: 5px;
    height: 24px;
    width: 200px;
}

.custom-tiles{
  border-radius: 5px 0px 0px 5px !important;
  z-index: 100;
  background: #fff;
  width: 92% !important;
  padding: 0 10px;
  line-height: 34px;
}

.custom-tiles span{
    border-radius: 30px;
    font-size: 12px;
    text-decoration: none;
    padding: 0px 6px;
    line-height: 17px;
    font-weight: normal;
    display: inline-block;
    background: rgb(91, 108, 151);
    color: #fff;
    margin-right: 4px;
  
}

.filter-search-box input{ 
  width: 100%;
  border: 1px solid lightgray !important;
}

.filter-search-box svg{
  right: 12px !important;
  top: 17px !important;
}
