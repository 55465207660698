.Folder-Picker-Modal {
    max-width: 100vw !important;
    width: 100vw !important;
    max-height: 100vh !important;
    height: 100vh !important;
    top: 0px !important;
    overflow: hidden;
}
.Folder-Picker-Modal .Draggable-component {
   cursor: all-scroll !important;
}
.Folder-Picker-Modal .ant-modal-content {
    max-width: 57vw !important;
    width: 57vw !important;
    max-height: 77vh !important;
    height: 77vh !important;
    border: 1px solid #c4c4c4 !important;
    overflow: hidden;
}

.Folder-Picker-Modal .ant-modal-header{
    margin: 0px !important;
    border-bottom: 1px solid #d7d7d7 !important;
    background: #f1f1f1 !important;
    padding: 10px !important;
    border-radius: 0px !important;
}
.Folder-Picker-Modal .ant-modal-content .ant-modal-body{
    height: 90%;
    position: relative;
    width: 100%;
    padding: 0px 0px !important;
    overflow: hidden;
}
.Folder-Picker-Modal .ant-modal-close{
    display: none !important;
}
.Folder-Picker-Modal .Sub-Header{
    border-bottom: 1px solid #d7d7d7 !important;
    background: #f1f1f1 !important;
}
.Folder-Picker-Modal .ResizePanel-module_ResizeHandleHorizontal__PkS9u {
    display: none !important;
 }
 .Folder-Picker-Modal .ResizePanel-module_ResizeBarHorizontal__3TBZ5 {
    cursor: ew-resize;
    width: 4px;
    background: #e8e8e8 !important;
    margin-left: 0px;
    margin-right: 0px;
    background: transparent;
    display: -webkit-box;
    display: flex;
    z-index: 10;
    -webkit-box-align: center;
    align-items: center;
    align-content: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.Folder-Picker-Modal .react-resizable-handle-e {
    right: 0 !important;
    transform: rotate(0deg) !important;
    top: 0% !important;
    margin-top: -0px !important;
    cursor: ew-resize;
    height: 100% !important;
}
.Folder-Picker-Modal .react-resizable{
    height: 100% !important;
    border-right: 1px solid #d7d7d7 !important;
}

.Folder-Picker-Modal .Vertical-Line {
    z-index: 2 !important;
}
.Folder-Picker-Modal .tree__input:focus-visible {
    outline-color: #5a6c97;
}

.Folder-Picker-Modal .Text-Color {
    color: #7485b0 !important;
    font-size: 15px;
    width: 85% !important;
    background: transparent;
    font-weight: 500;
}
/* .Folder-Picker-Modal .tree__folder--collapsible {
    max-height: max-content !important;
} */
.Folder-Picker-Modal .Parent-Class:hover .Text-Color {
    color: #4f5f86;
    font-size: 15px;
    font-weight: 500;
}
.Folder-Picker-Modal .Parent-Class{
   background-color: white;
   width: 100% !important;
}
.Folder-Picker-Modal .Selected{
    background-color: aliceblue !important;
}

.Folder-Picker-Modal .Vertical-Line:before {
    top: 0px !important;
}
.File-Preview-Component{
    display: flex;
    height: 100%;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0px;
    /* width: 37%; */
    z-index: 100;
    right: 0px;
    transition: width 0.5s ease-in-out;
    background-color: #f1f1f1;
    box-shadow: -7px 0px 10px -8px #686868;
}
.File-Preview-Component .File-Preview-Component-Content{
   width: 100%;
    padding: 10px;
   height: auto;
}
.File-Preview-Component .File-Preview-Component-Content img{
   width: 100%;
   display: flex;
   flex-direction: column;
   height: auto;
}
.File-Preview-Component .File-Preview-Component-Button {
    display: flex;
    position: absolute;
    top: calc(50% - 37px);
    background: inherit;
    height: 74px;
    align-items: center;
    justify-content: center;
    width: 21px;
    left: -20px;
    border-radius: 9px 0px 0px 9px;
    box-shadow: -7px 0px 10px -8px #686868;
}
.Preview-Header{
    display: flex;
    position: relative;
    width: 100%;
    top: 0px;
    background-color: #f1f1f1;
    color: #5b6c97;
    padding: 4px;
    font-weight: 600;
}
