.activityContent{
    overflow: auto;
    height: calc(100vh - 200px);
    padding:15px;
    padding-top: 0;
    margin-top: 10px;
}
.time-label{
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}
.time-label span{
    display: inline-block;
    background: #f0f0f0;
    border-radius: 20px;
    padding: 2px 10px;
    font-size: 13px;
    border: 1px solid #d2d2d2;
    z-index: 9;
    position: relative;
}
.time-label:before{
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: #d2d2d2;
}
.timelineCards-wrapper{
    max-width: 700px;
    margin: auto;
}
.activityCard{
    box-shadow: 0 3px 6px #eeeeee;
    -webkit-box-shadow: 0 3px 6px #eeeeee;
}
.activityCard+.activityCard{
    margin-top: 20px;
}
.activityCard:hover{
    box-shadow: 0 4px 12px #eaeaea;
    -webkit-box-shadow: 0 4px 12px #eaeaea;
}

.activityCard ul{
    list-style-type: none;
    margin: 0;
    padding: 6px 0;
}
.activity-detail li{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    padding: 5px 20px;
}
.activity-time span{
    margin: 0 5px;
}
.activity-time span:last-child{
    margin-right: 0;
}
.userProfile-link{
    color: #007bff;
    font-weight: 600;
    padding: 2px 4px;    
    border-radius: 3px;
    cursor: pointer;
}
.userProfile-link:hover{
    background: #ececec;
}
.activityCard .card-header span{
    margin-right: 10px;
    color: #5b6c97;
    cursor: pointer;
}
.activityCard .card-header span.taskName{
    font-weight: 600;
}
.activityCard .card-header span svg{
    font-size: 13px;
}
.activityCard .card-header span svg.task-status{
    font-size: 11px;
}
.activitySearch-bar{
    position: sticky;
    top: 0;
    z-index: 99;
    padding: 15px 0 20px;
    background: white;
}
.activitySearch-bar svg{
    position: absolute;
    font-size: 14px;
    top: 26px;
    left: 12px;
    color: gray;
}
.activitySearch-bar input{
    padding-left: 35px;
}

.card-para {
    padding: 15px;
    margin-bottom: 0 !important;
    font-size: 14px;
  }

  .MilstoneSearch-bar{
    position: relative;
    margin: 5px;
  }

  .MilstoneSearch-bar svg{
    position: absolute;
    z-index: 100;
    right: 14px;
    top: 8px;
    color: #5B6C97;
  }

  .MilstoneSearch-bar input{
    border-radius: 30px;
    padding-right: 30px;
    height: 30px;
  }