.project-tasks-tabs.nav-tabs{
    border-bottom: 1px solid #B0BED0;
    border-radius: 0.5rem 0.5rem 0 0;
}
.project-tasks-tabs.nav-tabs .nav-link:first-child{
    border-left: 0;
    border-radius: 0.5rem 0 0 0;
}
.project-tasks-tabs.nav-tabs .nav-link{
    color: #5b6c97;
    border-radius: 0;
    font-size: 14px;
    font-weight: bold;
    background: #d0d4df;
    padding: 13px 20px;
    border: 0;
}
.project-tasks-tabs.nav-tabs .nav-link:focus, 
.project-tasks-tabs.nav-tabs .nav-link:hover {
    border-color: transparent;
}
.project-tasks-tabs.nav-tabs .nav-item.show .nav-link,
.project-tasks-tabs.nav-tabs .nav-link.active{
    color: #5b6c97;
    border-right: 1px solid #bac6d6;
    background-color: white;
}
.task-tab{
    padding: 15px;
}

.task-tab .fa-ellipsis-h{
    /* rotate: 90deg; */
}