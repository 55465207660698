.section-wrapper .section-header-wrapper{
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: space-between;
    padding-right: 5px;
    align-items: center;
    border-bottom: 0.5px solid #b0bed0;
}
.section-wrapper .section-header-wrapper span{
    font-weight: 600;
}
.active{
    color: #5b6c97 !important;
}
.inActive{
    color: #bfbfbf !important;
}
.section-wrapper{
    margin-top: 5px !important;
}