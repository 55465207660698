.progress-inner-tabs{
    justify-content: start;
    margin-bottom: -1px;
    width: 95%;
    margin-top: 2px;
}
.progress-inner-tabs .nav-link{
    font-size: 14px;
    font-weight: normal;
}
.progress-inner-tabs .nav-link.active{
    border-bottom: 0 !important;
    position: relative;
}
.progress-inner-tabs .nav-link.active:after{
    position: absolute;
    content: "";
    width: 100%;
    bottom: 0;
    height: 5px;
    background: #5b6c97;
    left: 0;
    border-radius: 10px;
}
.progress-inner-tabs .nav-link {
    padding: .4rem 0.8rem;
}