.custom-btn-for-range:focus{
    color: gray !important; 
    background-color: white !important; 
    border-color: none !important; 
    box-shadow: none !important; 
}

.custom-btn-for-range:hover{
    color: #fff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
}
