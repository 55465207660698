.empCalendarWrapper{
    background: white;
    padding: 0px 15px;
    /* border: 1px solid #b0bed0; */
    /* border-radius: 1rem; */
    overflow-y: auto;
    height: calc(100vh - 99px);
}
.empCalendarWrapper .stickyTop{
    position: sticky;
    position: -webkit-sticky;
    top: -8px;
    background: white;
    z-index: 999;
}

.empCalendarWrapper .pos-relative .rbc-calendar{
    height: calc(100vh - 114px) !important;
}
.empCalendarWrapper .pos-relative .rbc-calendar .rbc-row.rbc-month-header{
    top: 0px !important;
}

.rbc-toolbar{
    position: sticky;
    top: 40px;
    background: white;
    z-index: 999;
    padding: 0 0 5px;
    /* margin-bottom: 14px;
    border-bottom: 1px solid #ededed; */
}

/* .rbc-month-view{
    margin-top: 20px;
} */

.rbc-time-header{
    /* padding: 10px 0px; */
}

.rbc-row.rbc-month-header{
    position: sticky;
    top: 75px;
    background: white;
    z-index: 999;
}
.rbc-addons-dnd-resizable .rbc-event-label{
    display: none;
}
.empCalendarWrapper .rbc-toolbar button {
    padding: 0.2rem 0.5rem;
    line-height: 1.3;
    font-size: 13px;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active,
.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus{
    box-shadow: inset 0 3px 5px #0087e9;
    background-color: #0194f7;
    border-color: #0194f7;
    color: white;
}
.rbc-toolbar-label{
    font-size: 18px;
    font-weight: 600;
}

.event-assignees{
    display: flex;
    align-items: center;
    margin-right: 5px;
    height: 20px;
}
.event-assignees .assignee-img {
    position: relative;
    border-radius: 50%;
    min-width: 20px;
}
.event-assignees .assignee-img+.assignee-img{
    margin-left: -5px;
}
.event-assignees .assignee-img .selected-avtar {
    width: 20px;
    height: 20px;
}
.event-desc-text{
    font-size: 12px;
}
.empCalendarWrapper .rbc-addons-dnd .rbc-addons-dnd-row-body{
    /* margin-top: 8px; */
}

.empCalendar_chart svg > g > g { pointer-events: none }

.weekend-text{
    background: #D3D3D3;
    margin: 0;
    padding-left: 3px;
}
.border-line{
    position: relative;
    /* padding-left: 3px; */
}
.rbc-date-cell{
    padding-left: 5px;
    
}
.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor {
    display: none;
}
.border-line::before{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: calc(100vh - 225px);
    width: 1px;
    background-color: #dddddd;
    z-index: 9;
}
.eventChart-wrapper{
    width: 50px;
}
.chartTooltip-content{
    position: absolute;
    left: 72px;
    background: #ffffff;
    top: 12px;
    padding: 6px;
    font-size: 13px;
    border: 1px solid #d1d1d1;
    border-radius: 9px;
    transition: all 1s ease-in-out;
    z-index: 999;
    box-shadow: 0 4px 16px #cbcbcb !important;
    display: none;
    
}
.chartTooltip-content:before{
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 8px solid #b7b1b1;
    border-bottom: 5px solid transparent;
    left: -8px;
    top: 25px;
    transition: all 1s ease-in-out;
}
.show-chartContent {
    display: block;
    z-index: 9999;
}
.chartTooltip-content .table thead th{
    border: 0;
}

.ant-popover{
    z-index: 9999 !important;
}
.rbc-addons-dnd .rbc-addons-dnd-resizable{
    padding: 0 5px;
}
.rbc-event:hover .rbc-addons-dnd-resize-ew-anchor{
    display: none;
}
.rbc-events-container{
    margin: 0 !important;
}
.rbc-events-container>div.rbc-event{
    /* border-radius: 8px !important; */
    margin: auto !important;
    width: 98% !important;
    right: 0 !important;
}
.rbc-addons-dnd-row-body{
    content: 'Test';
}
.caledar-txtbox {
    position: absolute;
    top: 51px;
    width: 100%;
    z-index: 999;
}
.caltime {
    bottom: 0px;
    position: absolute;
    font-weight: 600;
    font-size: 13px;
    left: 17px;
    top: -36px;
}

.caltask {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
}
#daily-chart-tooltip{
    width: 20px;
    height: 20px;
    position: relative;
    z-index: 9999;
    cursor: pointer;
    border-radius: 50%;
}
.remove-assginee-calendar{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: end;
    align-self: flex-start;
}
.remove-assginee-calendar p{
    margin: 0;
    margin-right: 10px;
    cursor: pointer;
}

#main-chart-donut svg  rect { 
    /* stroke-width:0px; fill:red;  */
    
    }
    /* .day-chart svg  rect{
        background-color: #e6e6e6;
    } */
/* 
  path {
    stroke-width: 0px;
   
  }  */
  .day-chart-con{
      background-color: #e6e6e6;
  } 
  .day-chart-container{
      height: 22px;
  }
 .rbc-today button span{
    color: #f8720d !important;
    font-weight: 700;
 }
 .rbc-header button span{
   color: #000;
 }
.currentTime-dropdown{
    text-align: center;
    color: black !important;
    font-weight: 600;
    font-size: 15px !important;
    /* height: 26px; */
    min-height: 26px;
    line-height: 1;
}

.calendar-zoomBtn{
    line-height: 1;
    font-size: 10px;
}
.Event_color_picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.Event_color_picker input {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: inherit;
    margin-left: 20px;
    font-size: 21px;
    padding: 0px !important;
    font-weight: 600;
}
.Event_color_picker span.circle {
    height: 20px;
    width: 20px;
    /* background: red; */
    display: block;
    border-radius: 50%;
    border: 1px solid gray;
    margin-right: 5px;
}
.Event_color_picker .color-picker {
    display: flex;
    font-size: 10px;
    align-items: center;
}

.ant-modal-content{
    width: 780px !important;
}

.calender-date-picker{
  border: none !important;
  padding-left: 0px !important;
}

.calender-date-picker{
    box-shadow: none !important;
}

.calender-date-picker .ant-picker-suffix{
    display: none !important;
}

.calender-date-picker .ant-picker-input input{
    font-size: 16px !important;
    font-weight: 600;
 
}

.Event_color_picker input{
    /* padding-left: 10px !important; */
    margin-right: 15px;
    margin-left: 35px;
}


.calender-date-picker .ant-picker-clear{
    display: none;
}

.calender-date-picker .ant-picker-input input::placeholder {
    color: #000 !important;
  }
  
  .calender-dropdown-height{
    height: calc(1.5em + .75rem + 2px) !important;
  }

  .rbc-event, .rbc-day-slot .rbc-background-event{
    background-color: lightgray;
    border-left: solid 5px green;
    /* border: 1px solid #ececec !important; */
  }
  
  .event_edit_icon svg{
    font-size: 13px;
    color: rgb(176 176 176);
    cursor: pointer;
  }
  .event_edit_icon svg:hover{
    color: rgba(0, 0, 0, 0.75);
    transition: color 0.3s;
  }
  .event_ctm_mod{
   width: 100% !important;
   display: flex;
   justify-content: center;
  }
  .event_ctm_mod .ant-modal-close-x{
    width: 30px !important;
    padding-right: 5px !important;
  }

  .jobnumber-heding-text2{
    padding-left: 12px !important;
  }

  .calendar_search_icon{
    position: absolute;
    color: lightgray;
    top: 10px;
    left: 9px;
  }


  .calendar-feature-header .d-flex span:nth-child(2){
    margin-right: 15px !important;
    width: 34px;
    cursor: pointer;
    border-radius: 3px;
    line-height: 30px;
    height: 33.5px;
    margin-top: 1px;
    background-color: #fff !important;
    border: 1px solid rgb(1, 148, 247);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .calendar-feature-header .d-flex span:nth-child(2) svg{
    color: rgb(1, 148, 247) !important;
  }

  .calendar-feature-header .d-flex img:nth-child(1){
    width: 25px !important;
    margin-top: 1px !important;
  }

  .search-card-list {
    width: 55% !important;
    z-index: 9999 !important;
    top: 93px !important;
    right: 0px !important;
    left: unset !important;
  }

  .search-card-header{
    clip-path: polygon(0 0, 38% 0, 45% 100%, 0 100%, 0 37%);
    background: rgb(91, 108, 151);
    padding: 10px;
    color: #fff;
    width: auto;
    min-width: 32rem;
    font-size: 0.95rem;
  }

  .search-card-list .ant-card-body {
    padding: 0px !important;
  }

  .calender-date-picker  .ant-picker-input > input{
    line-height: inherit !important;
    color: hsl(0, 0%, 20%);
  }

  /* .empCalendarWrapper .rbc-day-bg{
    border-left: 1px solid;
    border-right: 1px solid;
  } */


  .today-date-label{
    font-size: 12px;
    border-radius: 3px;
    z-index: 9999999;
    padding: 1px 7px;
    background-color: gray;
    color: #fff;
  }

  .rbc-off-range-bg {
    background-color: #f9f9f9;
  }

  .event_edit_icon{
    display: flex;
    align-items: center;
  }

  .event_edit_icon svg:last-child{
    font-size: 17px;
    margin-left: 7px;
  }
  .empCalendarWrapper .rbc-timeslot-group{
    min-height: 3.5rem !important;
  }
  .empCalendarWrapper .rbc-time-view .rbc-row{
    min-height: 2.5rem !important;
  }
  /* .empCalendarWrapper .rbc-day-slot{
    border-left: 2px solid #018ee0 !important;
  }
  .empCalendarWrapper .rbc-time-header-content{
    border-left: 3px solid #018ee0 !important;
  } */
  .empCalendarWrapper .rbc-event-content svg{
    /* color: white !important; */
  }
  .empCalendarWrapper .rbc-day-slot .rbc-event-content{
    display: flex !important;
    align-items: center !important;
    flex-direction: row;
    font-size: 0.9rem !important;
    font-weight: bold;
  }
  .empCalendarWrapper .rbc-event{
    /* background-color: rgb(1 142 224); */
    /* color: white !important; */
    /* border-left: 5px solid #f58319 !important; */
    border-radius: 5px !important;
    padding: 0px 8px !important;
    border-bottom: none !important;
  }
  .empCalendarWrapper .rbc-time-header.rbc-overflowing{
    border: 0px !important;
    min-height: 5rem;
    display: flex;
    /* margin-right: 0px !important; */
    align-items: center;
  }
  /* .rbc-overlay {
    max-height: 14rem !important;
    height: auto !important;
    overflow: auto !important;
  } */

  /* .rbc-overlay .rbc-overlay-header{
    position: sticky !important;
    top: -10px !important;
    z-index: 11 !important;
    background: white !important;
  } */

  .rbc-overlay .rbc-event{
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }

  
  .header-wrapper .today-button {
    cursor: pointer;
    color: hsl(0, 0%, 20%);
    font-size: 16px;
    font-weight: 500;
    border: 1px solid hsl(0, 0%, 80%);
    padding: 0.3rem 0.55rem;
    border-radius: 0.3rem;
  }

  .header-wrapper .backward-btn, .header-wrapper .forward-btn{
    border: 1px solid hsl(0, 0%, 80%);
    padding: 0.45rem 0.6rem;
    border-radius: 0.3rem;
    font-size: 14px;
    margin-right: 0.5rem !important;
    margin-left: 0rem !important;
  }

  .header-wrapper .backward-btn svg , .header-wrapper .forward-btn svg{
    color: hsl(0, 0%, 20%);
    font-size: 13px;
  }
  .header-wrapper .css-1nmdiq5-menu{
    position: absolute !important;
    z-index: 9999 !important;
  }
  .header-wrapper .css-13cymwt-control{
    width: 9rem !important;
    min-height: 1.1rem !important;
    height: 2rem !important;
  }
  .header-wrapper .css-t3ipsp-control{
    width: 9rem !important;
    min-height: 1.1rem !important;
    height: 2rem !important;
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: none !important;
  }
  .css-t3ipsp-control:hover{
    border-color: hsl(0, 0%, 80%) !important;
  }
  /* .header-wrapper .css-1u9des2-indicatorSeparator{
    height: 100% !important;
  } */
  .header-wrapper .css-b62m3t-container .css-1hb7zxy-IndicatorsContainer{
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
  }
  .header-wrapper .css-1dimb5e-singleValue{
    font-size: 15px !important;
  }
  .header-wrapper .css-b62m3t-container svg{
    height: 17px !important;
    width: 17px !important;
  }

  .header-wrapper .css-b62m3t-container .css-1fdsijx-ValueContainer{
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    padding: 0px 8px !important;
  }
  .header-wrapper .pad-l20{
    padding-left: 1rem !important;
  }
  .header-wrapper .dropdown-text{
    margin: 0px !important;
    color: #495057 !important;
    background-color: #fff !important;
    border-color: hsl(0, 0%, 80%);
    outline: 0 !important;
    box-shadow: none !important;
  }
  .empCalendarWrapper .rbc-header .rbc-button-link{
    font-weight: 600 !important;
  }
  .empCalendarWrapper .rbc-row-segment .rbc-event{
    margin-left: 1% !important;
    margin-right: 1% !important;
    min-width: 98% !important;
  }
  .empCalendarWrapper .rbc-header{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    font-weight: 600;
  }
  .calendar-feature-header input::placeholder{
    font-size: 15px !important;
  }
  .Event-Modal-Class .ant-modal-body{
    padding-top: 0px !important;
    padding-bottom: 11px !important;
  }
  .Event-Modal-Class .ant-modal-footer{
    padding-top: 0px !important;
    padding-bottom: 11px !important;
    display: block !important;
  }
  
  .Event-Modal-Class .add-participants{
    display: flex;
  }
  .Event-Modal-Class .add-participants .participants-wrapper{
    display: flex;
    gap: 3.2rem;
    align-items: center;
  }
  .Event-Modal-Class .add-participants .participants-wrapper .Invite-participants{
    width: 17.8rem !important;
  }
  .Event-Modal-Class .add-participants .conference-wrapper{
    display: flex;
    gap: 2.8rem;
    align-items: center;
  }
  .Event-Modal-Class .height-control{
    height: 2.2rem !important;
  }
  .Event-Modal-Class .row{
    display: flex;
    align-items: center;
  }

  .settings-search-card .settings-dropdown .table .serach-tbl-header{
    vertical-align: bottom;
    font-size: 13px;
    border-bottom: 2px solid #dee2e6 !important;
    border-right: 2px solid #dee2e6 !important;
  }
  .settings-search-card .settings-dropdown .table .serach-tbl-title{
    vertical-align: bottom;
    font-size: 13px;
    width: 50%;
    cursor: pointer;
    border-bottom: 2px solid #dee2e6 !important;
    border-right: 2px solid #dee2e6 !important;
  }

  .settings-search-card .settings-dropdown .table tbody{
    background-color: aliceblue;
  }
  .settings-search-card .settings-dropdown .table tbody td, th{
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6 !important;
    border-right: 2px solid #dee2e6 !important;
  }

  .Event-Modal-Class .ant-picker-dropdown .ant-picker-content .table tbody td, th{
    border: none !important;
  }
  

.Event-Modal-Class .Meeting-link .Meeting-link-input{
  position: relative;
}

.Event-Modal-Class .Meeting-link button{
  background-color: #018ee0;
  color: #fff;
}
.Event-Modal-Class .Meeting-link button:hover{
  background-color: #018ee0;
  color: #fff !important;
}

.Event-Modal-Class .Meeting-link a{
  position: absolute;
  top: 2px;
  right: 1.55rem;
}

.Event-Modal-Class .conference-wrapper .Meeting-link{
  display: flex;
  align-items: center;
  gap: 2.2rem;
}
.Event-Modal-Class .event-title{
  border-bottom: 0.1px solid #cdcdcd !important;
}
.Event-Modal-Class .event-title:focus{
  border-color: #fff;
  border-bottom: 2px solid #2196f3 !important;
  box-shadow: none !important;
}
.Event_color_picker .color-picker svg{
  display: none !important;
}

.Event-Modal-Class .crete-event-icons {
  color: #9c9d9d  !important;
  font-size: 1.1rem !important;
}
 .current-date-label{
  font-size: 12px;
  border-radius: 3px;
  z-index: 9999999;
  padding: 1px 7px;
  color: #fff;
  background-color: #f8720d !important;
}
.rbc-event-label{
  display: none !important;
}
.Event-Modal-Class .footer-btns{
  top: 0.35rem !important;
}

.Calendar-Filter-Wrapper .btn-primary{
  background-color: #018ee0 !important;
}
.Calendar-Filter-Wrapper .btn-primary:hover{
  color: #ffffff !important;
  border-color: #018ee0 !important;
}
.Calendar-Filter-Wrapper .column{
  padding: 0px !important;
}
.Calendar-Filter-Wrapper .Calendar-Filter-Card{
  border: 1px solid #d5d5d5;
  margin-top: 2px;
  padding: 7px;
  border-radius: 8px;
  color: #333333;
  font-weight: 500;
  box-shadow: 0px 0px 8px -6px black;
}
.Calendar-Filter-Wrapper .Check-Box{
  margin-left: 0px !important;
}
.Calendar-Filter-Header{
  background-color: #018ee0;
    padding: 6px 10px;
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
    color: white;
    border-radius: 5px 5px 0px 0px;
}
.Filter-Calender .Cancel-Icon svg{
  color: red !important;
}
.Filter-Calender .Cancel-Icon:hover{
  opacity: 1 !important;
}
.Filter-Calender .Cancel-Icon{
  display: flex !important;
  position: absolute !important;
  opacity: 0.5 !important;
  padding: 2px 4px !important;
  width: auto !important;
  font-size: small !important;
  height: auto !important;
  border-radius: 50% !important;
  top: -9px !important;
  right: -28px !important;
}

.Event-Modal-Class .calendar-attachments .Attachmnets-Btn{
    position: absolute;
    right: 0.4rem;
    bottom: 1rem;
    border: 1px solid gray;
    padding: 3px;
    border-radius: 50%;
    width: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 111;
    background: #fff;
}

.Event-Modal-Class .react-draggable .ant-modal-content{
  /* height: 33rem; */
  /* min-height: 16rem; */
  max-height: 44rem !important;
  overflow: hidden !important;
}
.Event-Modal-Class .react-draggable .ant-modal-body{
  height: max-content !important;
  overflow: scroll !important;
  max-height: 36rem;
}

.Event-Modal-Class{
  height: 100% !important;
  overflow: hidden !important;
  top: 0px;
}
.Event-Modal-Class .jodit-toolbar-editor-collection_size_middle.jodit-toolbar-editor-collection_mode_horizontal{
  background-image: none !important;
}

.Event-Modal-Class .delete-icon:hover{
  color: red;
} 
.Event-Modal-Class .calender-date-picker2 .ant-picker-input input{
  color: #495057 !important;
}

.empCalendarWrapper .rbc-header{
  font-size: 1rem !important;
}

.Serach-Event-DropDown .dropdown-toggle::after{
  display: none !important;
}
.Serach-Event-DropDown .dropdown-toggle{
  border: none !important;
}
.Serach-Event-DropDown .Serach-Event-Toggle{
  display: flex;
  background: none;
  border: none;
  width: 100% !important;
  padding: 0px;
}
.Serach-Event-DropDown .Serach-Event-Menu{
  padding: 0px !important;
  width: max-content !important;
  box-shadow: 0 6px 15px #cfcfcf !important;
  width: 50vw !important;
  top: 6px !important;
  height: max-content !important;
  /* overflow: hidden; */
}
.Serach-Event-DropDown .Serach-Event-Menu .Header{
  padding: 5px 15px 5px 15px;
  font-size: 16px;
  font-weight: 600;
  background: rgb(1, 142, 224);
  color: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  /* justify-content: space-between; */
  height: 50px;
}
.Serach-Event-DropDown .Serach-Event-Menu .Icon{
  position: absolute;
  right: 1rem;
  top: -15px;
  font-size: 24px;
  color: #018ee0;
}
.Serach-Event-DropDown .Event-Card{
  padding: 4px 4px;
  border-bottom: 1px solid #e3e3e3;
  cursor: default;
}
.Serach-Event-DropDown .Date{
  display: flex;
    /* padding: 9px; */
  /* background: #ededed; */
  width: 3rem;
  font-size: 1.3rem;
  height: 3rem;
  font-weight: 500;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Serach-Event-DropDown .Date-String{
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: small;
  font-weight: 500;
  color: #7d7d7d;
}
.Serach-Event-DropDown .Date:hover{
  background: #ededed;
}
.Serach-Event-DropDown .row{
  margin: 0px !important;
}
.Serach-Event-DropDown .Column{
  padding: 0px !important;
}
.Serach-Event-DropDown .Event-Details{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Serach-Event-DropDown .ant-input-group-wrapper{
  padding: 0px !important;
}
.Serach-Event-DropDown .ant-input-group-wrapper{
  border: 1px solid #cccccc !important;
  border-radius: 4px !important;
}
.Serach-Event-DropDown .ant-input-group-wrapper input,.Serach-Event-DropDown .ant-input-wrapper,.Serach-Event-DropDown .ant-input-group-addon,.Serach-Event-DropDown .ant-input-search-button{
  border: none !important;
  height: 100% !important;
}
.Serach-Event-DropDown .ant-input-group-wrapper .ant-btn-loading{
  border: none;
  background-color: white;
  box-shadow: none;
}
.Serach-Event-DropDown .Event-Details-Row{
  display: flex;
  align-items: center;
  padding: 4px 0px;
  /* background-color: #ededed; */
  border-radius: 32px;
  cursor: pointer !important;
  font-size: 14px;
  color: #2f2f2f;
  font-weight: 500;
}
.Serach-Event-DropDown .Event-Details-Row:hover{
  background-color: #ededed;
}
.Serach-Event-DropDown .Event-Type{
  font-size: small;
  font-weight: 500;
  color: #7d7d7d;
}
.Serach-Event-DropDown .Content{
  height: max-content;
  max-height: 70vh;
  overflow: scroll;
  overflow-x: hidden;
}
.custom-popover .ant-popover-arrow {
  display: block !important; /* Ensure the arrow is displayed */
  border-color: #f0f0f0 !important; /* Customize arrow color if needed */
}

.custom-popover .ant-popover-inner {
  border-radius: 4px !important; 
}
.custom-popover .ant-popover-inner .ant-popover-inner-content{
  width: max-content !important;
}
.compactColorPicker{
  position: absolute; 
  top: 2rem;
  left: 0;
  z-index: 1
}
.address-text2 {
  /* / margin-left: -22px; / */
  outline: none;
  width: 100%;
  padding: 0.12rem 0.3rem;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
}
.iconPosition{
  position: relative;
  /* / margin-left: 1rem; / */
  left: 1.2rem;
}