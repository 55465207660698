.Status-Wrapper{
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 14px;
    margin: 5px 0px !important;
}
.Main-Status-Button{
    position: relative;
}
.Main-Status-Button2{
    position: relative;
}

.Main-Status-Button::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 100%;
    background-color: #5b6c97;
    margin-left: 10px;
    position: absolute;
    top: 0;
    right: -7px;
  }
.Status-Wrapper .ant-btn{
    height: 24px !important;
    font-size: small !important;
    font-weight: 600 !important;
}
.dropdown-status-label span:nth-child(2){
    display: block;
    overflow: hidden;
    max-width: 6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}