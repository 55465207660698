.Dependency-Table-Wrapper .TaskGroup2Card{
    margin: 5px 0px !important;
}

.Dependency-Table-Wrapper tr td:first-child,
.Dependency-Table-Wrapper thead th:first-child{
   justify-content: center !important;
   max-width: 260px !important;
   border: none !important;
}
.Dependency-Table-Wrapper tr td:first-child .Elipsis-Class{
   text-align: center !important;
}
.Dependency-Table-Wrapper tbody tr td{
   padding: 0px !important;
}
.Dependency-Table-Wrapper .task_table_header{
    border-bottom: 1px solid #d3d3d3 !important;
}
.Dependency-Table-Wrapper thead{
    background-color: white;
}
.Dependency-Table-Wrapper .custom-stick{
    position: sticky !important;
    right: 0;
    z-index: 1;
    min-width: max-content !important;
    background-color: inherit !important;
}
.custome-border-left {
    position: relative;
    padding-left: 10px;
}

.custome-border-left::before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 0;
    width: 2px;
    background-color: #e1e1e1;
}
.custome-border-right {
    position: relative;
    padding-left: 10px;
    left: 0px !important;
}

.custome-border-right::before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    right: 0;
    width: 2px;
    background-color: #e1e1e1;
}
.Custome-Table thead th {
    position: relative;
    text-align: center;
    cursor: default !important;
    font-size: 10px;
    font-family: "Avenir Next LT W02";
    font-weight: bold;
    color: #5b6c97;
    border-top: 0;
    min-width: 170px;
    padding: 0.5rem 0.75rem;
  }
  .Custome-Table thead th,.Custome-Table td{
    font-size: 13px;
  }
  .Custome-Table thead th:nth-child(1),.Custome-Table td:nth-child(1){
    min-width: 270px !important;
    /* background-color: white !important; */
  }
  .Custome-Table thead th:last-child,.Custome-Table td:last-child{
    /* min-width: 370px !important; */
    /* background-color: white !important; */
  }
  
  .Custome-Table td {
    text-align: center;
    /* padding: 2px 5px; */
    font-size: 13px;
    vertical-align: middle;
    font-family: "Avenir Next LT W02";
    font-weight: normal;
    color: #5b6c97;
    border: 0;
  }
  
  .Custome-Table tbody tr {
    background-color: #ffffff;
  }
  
  .Custome-Table tbody tr:nth-child(1) {
    border-top: 1px solid lightgray !important;
  }
  
  .Custome-Table-icon {
    width: 25px;
  }
  
  .Custome-Table td.status {
    padding: 0;
  }
  
  .Custome-Table td.status .dropdown-menu {
    padding: 0;
    min-width: 100%;
    border: 5px solid #ffffff;
    box-shadow: 0 5px 10px #00000042;
    -webkit-box-shadow: 0 5px 10px #00000042;
  }
  .TaskGroup2Card td{
    cursor: default;
  }