.Email-Body-Wrapper{
    display: flex;
    flex-direction: row;
    min-height: 84vh;
    height: 92vh;
    z-index: 5;
    /* position: relative; */
    background-color: white;
    /* top: -4.5rem; */
}

.Email-Body-Wrapper .Email-Recepien-Row{
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0px;
    flex-direction: row;
    overflow: hidden;
}
.Email-Body-Wrapper .Email-Body-Content-Wrapper{
    display: flex;
    width: 100%;
    flex-direction: column;
    min-height: 100%;
    /* background-color: aqua; */
}
.Email-Body-Wrapper .Email-Recepient-Sidebar{
    border-right: 1px solid #dee2e6;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 5px;
}
.Email-Body-Wrapper .Email-Recepient-Content{
    /* background-color: rgb(255, 39, 39); */
}
.Email-Body-Wrapper .Content{
    display: flex;
    position: relative;
    width: 100%;
    /* height: 80%; */
    flex-direction: column;
    overflow: scroll;
    /* padding-bottom: 15rem !important; */
}
.Email-Body-Wrapper .Content .User-Card{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 5px;
    /* justify-content: center; */
    flex-direction: row;
    cursor: pointer;
    /* background-color: aqua; */
}
.Email-Body-Wrapper .Content .User-Card:hover .Flag{
    display: block;
}
.Email-Body-Wrapper .Content .User-Card .Flag{
    display: none;
    color: gray;
}
.Email-Body-Wrapper .Email-Main-Header .Content .User-Card{
    align-items: center !important;
    padding: 0px !important;
}
.Email-Body-Wrapper .Email-Main-Header .Content{
    align-items: center !important;
    padding: 0px !important;
    margin: 0px;
    height: auto;
    overflow: hidden;
}
.Email-Body-Wrapper .Content .User-Card .Second-Child{
    display: flex;
    width: calc(100% - 3rem);
    align-items: center;
    padding: 5px;
    position: relative;
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: aqua;*/
}
.Email-Body-Wrapper .Content .User-Card .Second-Child .Date{
    font-style: italic !important;
    color: #6d6d6d; 
    font-size: 12px;
    /* background-color: aqua;*/
}
.Email-Body-Wrapper .Content .User-Card .Header .Delete-Icon{
    display: none;
    position: absolute;
    right: 9px;
}
.Email-Body-Wrapper .Content .User-Card .Header h6{
    width: 70%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.Email-Body-Wrapper .Content .User-Card .Header .unread_message_text{
    /* width: 70%;
    overflow: hidden;
    text-overflow: ellipsis; */
    font-weight: bold;
}
.Email-Body-Wrapper .Content .User-Card .Header .Delete-Icon:hover{
   color: red !important;
}
.Email-Body-Wrapper .Content .User-Card:hover .Delete-Icon{
    display: flex;
    position: absolute;
    right: 9px;
    opacity: 0.7;
}
.Email-Body-Wrapper .Email-Main-Header .Content .User-Card .Second-Child{
    padding: 0px !important;
}
.Email-Body-Wrapper .Content .User-Card .First-Child{
    display: flex;
    width: 3rem;
    /* padding: 7px; */
    align-items: center;
    height: 100%;
    /* padding-top: 10px; */
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: aqua; */
    gap: 10px;
}
.Email-Body-Wrapper .Email-Main-Header .Content .User-Card .First-Child{
    padding: 0px !important;
    width: 3rem;
}
.Email-Body-Wrapper .Email-Main-Header .Content .User-Card .First-Child input{
    height: 14px;
}
.Child-Rows{
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 10px; 
}
.Email-Body-Wrapper .Content .User-Card .Header{
    
}
.Email-Body-Wrapper .Search-Wrapper .form-group{
    margin: 0px !important;
    margin-bottom: 5px !important;
}
.Email-Body-Wrapper .sidebar-header{
    display: flex;
    margin: 0px;
    align-items: center;
    justify-content: center;
    color: #018ee0;
}
.Email-Body-Wrapper .Child-Rows p{
    margin: 0px !important;
}
.Email-Body-Wrapper .sender-details {
    display: flex;
    flex-direction: row;
}
/* .Email-Body-Wrapper .Email-Recepient-Sidebar{
    height: 100% !important;
} */
.Email-Body-Wrapper .Email-Recepient-Content{
    height: 100% !important;
    /* padding: 0px !important; */
}
.Email-Body-Wrapper .mail-view{
    height: 100% !important;
}
.Email-Body-Wrapper .message-body{
    height: 100% !important;
}
.Email-Body-Wrapper .message-content{
    height: 100%;
    overflow: scroll;
    /* padding: 1rem; */
}
.Email-Body-Wrapper .reply-message-body{
    padding: 0px !important;
}
.Email-Body-Wrapper .comment-here-box{
    padding: 5px !important;
}
.Email-Recepient-Content .row{
    margin: 0px !important;
}
.Email-Recepient-Content .Compose-Email-Header input{
    border: none !important;
}
.Email-Recepient-Content .Compose-Email-Header .form-control[readonly]{
    background-color: white !important;
    opacity: 1;
}
.Email-Recepient-Content .Compose-Email-Header .form-control[readonly]:hover{
    cursor: not-allowed;
}
.Email-Recepient-Content .Compose-Email-Header input:focus{
    border: none !important;
    box-shadow: none !important;
}
.Compose-Email-Wrapper{
    padding-top: 10px !important;
    height: 100%;
    padding: 0px !important;

}
.Compose-Email-Wrapper .right .cross-btn{
   top: 0px !important;
}
.Compose-Email-Wrapper .right{
   padding: 0px !important;
   display: flex;
   align-items: flex-start;
   justify-content: flex-end;
}
.Compose-Email-Wrapper .left{
    padding: 5px 0px;
}
.Compose-Email-Wrapper .right .SendIcon{
    height: 50%;
    display: flex;
    border: 1px solid #e6e6e6;
    align-items: center;
    border-radius: 7px;
    justify-content: center;
    margin: 2px;
    padding: 15px;
    cursor: pointer;
}
.Compose-Email-Wrapper .right .CancelIcon{
    height: 50%;
    margin: 2px;
    display: flex;
    border: 1px solid #e6e6e6;
    align-items: center;
    border-radius: 7px;
    justify-content: center;
    padding: 15px;
    cursor: pointer;
}
.Compose-Email-Wrapper .Compose-Email-Header{
    border-bottom: 1px solid #e6e6e6;
}
.Compose-Email-Wrapper .right .SendIcon .Icon{
    width: 69% !important;
    height: auto;
}
.Email-Recepient-Content .btn-outline-secondary{
    margin: 0px 2px !important;
    color: #6c757d !important;
    border-color: #dedede !important;
}
.Email-Recepient-Content .comment-here-box{
   margin-top: 5px !important;
   margin: 0px;
}
.Email-Recepient-Content .btn-outline-secondary:not(:disabled):not(.disabled):active{
   background-color: #e8e8e8 !important;
}
.Email-Recepient-Content .btn-outline-secondary:not(:disabled):not(.disabled):hover{
   background-color: #e8e8e8 !important;
}
.Email-Body-Wrapper .Email-Body-Content-Wrapper .Email-Main-Header{
    display: flex;
    flex-direction: row;
    margin: 0px !important;
    background-color: #f0f0f0;
    color: #5b6c97;
    font-weight: bold;
    align-items: center;
    height: 7%;
    padding: 5px;
}
.Email-Body-Wrapper .Email-Body-Content-Wrapper .Email-Main-Header .email-global-search{
    height: 2rem;
    border-radius: 26px;
    font-size: small;
}
.Email-Main-Header .email_cancel_icon{
    position: absolute;
    top: 0.5rem;
    right: 26px;
}
.Email-Main-Header .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25) !important;
}
.custom_email_header .reply-header-right{
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
    padding: 0px;
}
.custom_email_header .reply-header-left{
    padding: 0px;
}
.Email-Body-Wrapper .Email-Recepient-Sidebar .Content .User-Card:hover {
    background-color: #fbfbfb !important;
}
.Email-Body-Wrapper .Email-Recepient-Sidebar .Content .User-Card {
    border-left: 2px solid #ffffff;
}
.selected-email-card{
    border-left: 2px solid #018ee0 !important;
    background-color: #fbfbfb;
}
.Closed-Content-Css{
    position: absolute !important;
    left: 0px !important;
    bottom: 0px;
    display: flex !important;
    justify-content: end !important;
}
.No-Content-Class{
    height: 100%;
    position: absolute;
    /* top: 50%; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.Date-Band-Email{
    display: flex;
    width: 100%;
    padding-left: 17px;
    background-color: #f4f4f4;
    box-shadow: 0px 0px 8px -4px;
    position: relative;
    z-index: 1;
    color: #9e9e9e;
    font-weight: bold;
    opacity: 1;
}
.Email-Selected{
    background-color: #e1f4ff4a !important;
}
.header-options{
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding-left: 10px;
    margin-left: 5px;
    border-left: 1px solid;
}
.header-options div:hover{
   cursor: pointer;
}
.header-options div{
   font-size: small;
}
.Email-Body-Wrapper .Add-Emoji-Icon img {
    position: relative !important;
    top: 1.6rem !important;
    left: 0.2rem !important;
  }
  
  .Email-Body-Wrapper .comment-here-box .small-3d-button{
      height: 1.8rem !important;
      width: max-content !important;
      padding: 0.5rem 1rem !important;
      line-height: 0.9rem !important;
  }

  .Subject .unread_message_text{
    font-weight: bold;
  }

  .reply-content .message_text_head_div{
    position: relative;
    top: 3rem;
    margin-left: 1rem;
  }
  .Email-Body-Wrapper .Emoji-Wrapper-Note .Emoji-Picker-Wrapper{
    position: absolute !important;
    top: -6rem !important;
    z-index: 500 !important;
  }
  .Attachment-PopUp .ant-modal-content{
    width: 100% !important;
  }
  .Attach-Delete-Icon{
    display: flex !important;
    align-items: center !important;
    justify-content: end !important;
  }
  .Attach-Delete-Icon svg:hover{
    color: red;
  }
  .Attachment-Card{
    display: flex;
    max-width: 14rem;
    width: auto;
    flex-direction: row;
    font-size: small;
    color: #5a5a5a;
    background-color: aliceblue;
    gap: 7px;
    overflow: hidden;
    align-items: center;
    border: 1px solid #dedede;
    padding: 9px;
    margin: 2px;
    border-radius: 5px;
  }
  .Attachment-Card .Name{
    width: 87%;
    display: inline-block;
    overflow: hidden;
    /* overflow-y: hidden; */
    /* overflow-wrap: anywhere; */
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .Attachment-Card:hover{
    cursor: pointer;
    background-color: rgb(210, 234, 255);
  }
  .Email-Body-Wrapper .Mentions-Note-Collapse{
    display: flex !important;
    position: absolute !important;
    left: 0.1rem !important;
    width: max-content !important;
    height: 18rem !important;
    max-height: 18rem !important;
    overflow: hidden !important;
    background-color: white !important;
    z-index: 11111 !important;
    top: -10rem !important;
  }
  .Dotted-Border{
    border: 1px dotted black !important;
  }

  .Email-Body-Wrapper .jodit-text-editor-wrapper{
    height: 80% !important;
  }
  .Email-Body-Wrapper .Association-Question-Icon {
    display: flex;
    align-items: center;
    /* height: 30px;
    right: -45px;
    position: absolute;
    top: 0px; */
}

/* Add this to create the beating animation */
.Email-Body-Wrapper .Shortcut-Tags-Section {
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    gap: 3px;
    right: 41px;
}
.Email-Body-Wrapper .Shortcut-Tags-Section .Tag-Card{
    display: flex;
    align-items: center;
    width: max-content;
    justify-content: center;
    padding: 0px 5px;
    height: 100%;
    border: 1px solid #d6d6d6;
    border-radius: 16px 0px 0px 0px;
    background-color: #ffe7bc;
}
.RemovePaddingAndJustifyEnd{
    padding: 0px !important;
    justify-content: end !important;
    display: flex !important;
}
.custom_email_header .btn-outline-secondary:focus {
    box-shadow: none !important;
}

.Email-Body-Wrapper .List .List{
    padding: 0px 0px 0px 15px !important;
}

@keyframes beat {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
}