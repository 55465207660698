.Toolbox-wrapper ul{
    list-style: none;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 15px;
    margin-bottom: 0px;
}
.Toolbox-wrapper ul li{
    padding: 4px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 13px;
}
.Toolbox-wrapper ul li:hover{
    background-color: #efefef;
}
/* .Toolbox-wrapper svg{
    color: #007bff;
} */