.Options-Icon .collapse-horizontal{
    margin-right: 5px !important;
    margin-left: 5px !important;
}
.Options-Icon .Three-Dot-Icon{
    margin-right: 5px !important;
    margin-left: 5px !important;
}
#collapseWidthExample svg{
    margin: 0px 4px !important;
}
.Mentions-Collapse{
    display: flex;
    position: relative;
    top: -0.5rem;
    width: max-content !important;
    height: auto;
    max-height: 18rem;
    overflow: hidden;
    background-color: white;
}
.Mentions-Collapse .collapse{
    width: max-content !important;
    max-height: 18rem;
    overflow: scroll;
}
.Mentions-Collapse .Deal-Account-Wrapper{
    display: flex;
    flex-direction: row;
    gap: 5px;
}
.Bordered-Box{
    padding: 5px;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
}

.user-card{
    display: flex;
    /* width: 100%; */
    padding: 2px 6px;
    border: 1px solid #018ee0;
    border-radius: 20px;
    background-color: #018ee0;
    color: white;
    margin-top: 2px;
    cursor: pointer;
}

.All-Pinned-Chats-Wrapper{
    position: absolute;
    z-index: 5;
    top: 2rem;
    width: 25rem;
    left: -24rem;
}
.Pinned-Expand-Wrapper{
    padding: 5px;
    background-color: white;
    border: 1px solid #d1d1d1;
    border-radius: 6px;
}
.Pinned-Expand-Wrapper .Pinned-Massage-Card{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3px;
}
.Pinned-Expand-Wrapper .Pinned-Massage-Card .Profile-Wrapper{
    display: flex;
    flex-direction: row;
    /* gap: 1rem; */
    width: 100%;
    position: relative;
    align-items: center;
}
.Pinned-Expand-Wrapper .Heading{
    display: flex;
    width: 100%;
    background: #018ee0;
    padding: 3px 11px;
    color: white;
    font-weight: 600;
}
.Pinned-Expand-Wrapper .profile{
    display: flex;
    height: 1rem;
    width: auto;
}
.Pinned-Massage-Card .Message-Wrapper{
    display: flex;
    flex-direction: row;
    /* gap: 1rem; */
}
.Pinned-Massage-Card .Message-Wrapper .content{
    margin-left: 3rem;
}
.Profile-Wrapper .header span:first-child{
    color: rgb(0, 0, 0) !important;
    font-weight: bold !important;
}
.Profile-Wrapper .header span:nth-child(2){
    font-size: 11px;
    margin: 0px 5px;
    font-style: italic;
}
.Profile-Wrapper .header span:nth-child(3){
    font-style: italic;
    color: #018ee0;
}
.Message-Wrapper .massage-body-wrapper{
    margin: 0px;
    box-shadow: none;
}
.Message-Wrapper .massage-body-wrapper:first-child{
    min-width: 4rem;
    margin: 5px;
    padding: 5px;
    box-shadow: 0px 0px 2px 0px black;
    border-radius: 5px;
    border-left: 2px solid #018ee0 !important;
}
.Pinned-Expand-Wrapper .Pinned-Content-Body{
    max-height: 16rem !important;
    overflow: scroll !important;
    /* margin-left: 9px; */
}
.Pinned-Massage-Card .Profile-Wrapper .Actions{
    position: absolute;
    display: none;
    right: 1rem;
}
.Pinned-Massage-Card:hover .Profile-Wrapper .Actions{
    display: block !important;
}
.Pinned-Massage-Card:hover{
    background-color: #efeffd96;
}

.Pinned-Massage-Card img{
    width: 40px;
    border-radius: 50%;
    padding: 3px;
    border: 2px solid #ada4a3;
    height: 40px;
    float: left;
    cursor: pointer;
    transition: 0.3s border ease;
}
.Mentions-Collapse .User-Mention-Header{
    position: sticky;
    top: 0;
    background-color: #0181cb;
    font-weight: bold;
    padding: 3px;
    color: white;
}
.Attachment-Main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Attachment-Wrapper{
    margin: 0.5rem !important;
    gap: 1rem !important;
    align-items: center !important;
}
.Attachment-Main .Delete-Icon{
    color: rgb(255, 0, 0);
    opacity: 0.4;
}
.Attachment-Main:hover .Delete-Icon{
    opacity: 1 !important;
}

.group-collapse{
    width: 100%;
    opacity: 0;
    background-color: white;
    transition: all ease-in-out 0.4s;
}
.Mentions-group-Collapse{
    display: flex;
    position: relative;
    left: 0.8rem;
    width: 96%;
    height: 100%;
    max-height: 79%;
    overflow: hidden;
    z-index: 111;
}
.group-section-wrapper{
    height: 33.33%;
    display: flex;
}
.Group-profile-photo{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Group-profile-photo img{
    width: 50%;
    border-radius: 50%;
    border: 1px solid #a1a2a3;
    padding: 2px;
}
.Group-info{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    border-left: 3px solid #9f9f9f;
    padding-left: 10px;
}
.group-name{
    font-size: 1.5rem;
    font-weight: 500;
    color: #9f9f9f;
}
.add-member span{
    color: #018ee0;
    font-weight: 500;
}
.group-search span{
    cursor: pointer;
    color: #018ee0;
    font-weight: 500;
    margin-left: 3px;
}
.group-search{
    opacity: 0.4;
}
.group-search:hover{
    opacity: 1;
}
.group-member-wrapper{
    width: 100%;
    max-height: 96%;
    overflow: scroll;
}
.member-list-wrapper{
    width: 100%;
    padding: 0.5rem;
    border-radius: 10px;
    box-shadow: 0px 0px 15px -9px grey;
    display: flex;
    margin-top: 9px;
    margin-bottom: 9px;
    overflow: hidden;
    align-items: center;
    gap: 10px;
    position: relative;
}
.member-list-wrapper img{
    width: 7%;
    border: 1px solid #018ee0;
    border-radius: 50%;
    padding: 2px;

}
.Mentions-group-Collapse .show{
    opacity: 1;
}
.top-header{
    font-weight: 500 !important;
    position: sticky !important;
    top: 0px !important;
    z-index: 1 !important;
    margin-top: 0px !important;
    background-color: white !important;
    border-bottom: 2px solid #9f9f9f76 !important;
    border-top: 2px solid #9f9f9f76 !important;
    border-radius: 0px !important;
    justify-content: space-between !important;
}
.top-header .add-member{
    display: flex;
    align-items: center;
}
.ADD{
    display: flex;
    position: absolute;
    cursor: pointer;
    right: 0px;
    width: 5rem;
    opacity: 0.5 !important;
    justify-content: center; 
}
.REMOVE{
    display: flex;
    position: absolute;
    cursor: pointer;
    right: 0px;
    width: 5rem;
    opacity: 0.5 !important;
    justify-content: center;
}
.ADD:hover{
    opacity: 1 !important;
}
.REMOVE:hover{
    opacity: 1 !important;
}
.ADD{
    color: rgb(0, 0, 0);
    font-weight: 500;
    font-size: 12px;
}
.REMOVE{
    color: rgb(255, 0, 0);
    font-weight: 500;
    font-size: 12px;
}
.img-wrapper-div{
    display: flex;
    align-items: center;
    flex-direction: row;
    overflow: scroll;
    flex-wrap: nowrap;
}
.docs-wrapper{
    width: 100px;
    height: 100px;
    margin: 5px;
    box-shadow: 0px 0px 13px -7px grey;
}
.docs-wrapper img {
    width: 100px !important;
    height: 100px;
    max-width: 100px !important;
}
.main-div-wrapper{
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
}
.group-section-wrapper .doc-label{
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    padding-left: 3px;  
}
.main-group-wrapper{
    padding: 10px !important;
    background-color: #f8f8f8;
}
.Attachments-Wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
}