.criteria-container {
    font-family: Arial, sans-serif;
    background: #ffffff;
    color: #5b6c97;
    padding: 20px 0px;
    height: 23vh;
    overflow: scroll;
}

.criteria-container .No-Borders {
    margin-left: 0px !important;
    border-left: none !important;
    padding-left: 0px !important;
}

.criteria-container .tree {
    margin-left: 20px;
    border-left: 2px solid #d7d7d7;
    padding-left: 15px;
}

.criteria-container .node {
    position: relative;
    padding-left: 20px;
    padding-bottom: 10px;
}

.criteria-container .condition {
    display: inline-block;
    background: #007bff;
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 8px;
}

.criteria-container .text {
    font-size: 16px;
}

.criteria-container .highlight {
    color: #2196f3;
    border-bottom: 1px solid #2196f3;
    background-color: transparent !important;
    font-weight: bold;
}

.criteria-container .connector {
    position: absolute;
    left: -16px;
    top: 50%;
    width: 15px;
    height: 2px;
    background: #d7d7d7;
}