.scroll-wrapper {
  flex-wrap: nowrap;
  overflow: auto;
  margin: 0;
}

.task-container {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  /* padding: 5px 15px 0px 15px; */
  font-size: 12px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  box-shadow: 0 1px 4px #968d8d;
}

.task-cardbody {
  padding: 5px;
}

.task-card,
.task-card-header {
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 12px;
  margin: 5px;
  box-shadow: 0 1px 4px #a9a9a9;
}

.task-card-header {
  border-top: 2px solid #5b6c97;
  background: whitesmoke;
}

.task-vertical-scroll {
  height: 51vh;
  overflow: auto;
}

.task-card-header .card-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.task-card-header .card-text .badge {
  background-color: #5b6c97;
  padding: 3px 10px;
  vertical-align: text-top;
  margin-left: 3px;
  border-radius: 10px;
}

.task-card-header .card-text svg:first-of-type {
  width: 3em;
  height: 1.1em;
}

.task-card-header .card-text svg {
  color: #1d4999;
}

.task-container .nav-tabs {
  border-bottom: 1px solid #b0bed0;
}

.task-container .nav-tabs .nav-link {
  font-family: "Avenir Next LT W02";
  font-size: 13px;
  color: #0070c0;
  font-weight: 700;
  text-decoration: underline;
}

.task-container .nav-tabs .nav-item.show .nav-link,
.task-container .nav-tabs .nav-link.active {
  color: #5b6c97;
  border-color: #b0bed0 #b0bed0 #fff;
  text-decoration: none;
}

.task-card .card-text>small {
  line-height: 1;
  line-height: 1.5;
  display: block;
}

.task-card .card-text .deal-title-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4px;
}

.task-header .search-filter {
  border: 2px solid #b0bed0;
}

.task-header .search-filter svg {
  color: #2f2f2f;
  width: 12px;
}

.assigntask-button {
  width: 23px;
}

.bottom-icons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bottom-icons img+img {
  margin-left: 8px;
}

.bottom-icons svg+svg {
  margin-left: 8px;
}

.task-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  margin: 15px auto;
}

.open-task-btn {
  font-size: 14px;
  color: #007bff;
  font-weight: 600;
  font-family: "Avenir Next LT W02";
  cursor: pointer;
}

.open-task-btn svg {
  margin-left: 5px;
}

.add-task-btn {
  background: #54b9fe;
  padding-right: 12px;
  border-radius: 20px;
  overflow: hidden;
}

.add-task-btn span {
  color: white;
  background: #0194f7;
  font-size: 14px;
  padding: 3px 20px;
  margin-right: 10px;
  display: inline-block;
  font-weight: normal;
}

.add-task-btn svg {
  color: white;
  height: 26px;
  vertical-align: top;
  font-size: 14px;
  cursor: pointer;
}

.task-filter-btn {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 2px solid #cccccc;
}

.task-filter-btn img {
  width: 20px;
}

.task-table thead th {
  position: relative;
  text-align: center;
  font-size: 10px;
  font-family: "Avenir Next LT W02";
  font-weight: bold;
  color: #5b6c97;
  border-top: 0;
  min-width: 170px;
  padding: 0.5rem 0.75rem;
}
.task-table thead th:nth-child(1),.task-table td:nth-child(1){
  min-width: 370px !important;
  /* background-color: white !important; */
}
.task-table thead th:last-child,.task-table td:last-child{
  /* min-width: 370px !important; */
  /* background-color: white !important; */
}

.task-table td {
  text-align: center;
  /* padding: 2px 5px; */
  font-size: 13px;
  vertical-align: middle;
  font-family: "Avenir Next LT W02";
  font-weight: normal;
  color: #5b6c97;
  border: 0;
}

.task-table tbody tr {
  background-color: #f2f2f2;
  /* box-shadow: inset 0 -2px 3px 0px #00000012; */
}
.task-table tbody tr:nth-child(1) {
  border-top: 1px solid lightgray !important;
}

.task-table-icon {
  width: 25px;
}

.task-table td.status {
  padding: 0;
}

.task-table td.status .dropdown-menu {
  padding: 0;
  min-width: 100%;
  border: 5px solid #ffffff;
  box-shadow: 0 5px 10px #00000042;
  -webkit-box-shadow: 0 5px 10px #00000042;
}

.transparent-dropdown .dropdown-menu {
  padding: 0;
}

/* .task-table td.status button {
  color: white;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  font-size: 13.5px;
  box-shadow: none;
  border: 0;
  padding: 8px 0px;
  outline: 0;
  box-shadow: inset 0 -6px 12px #00000026;
} */

.task-table td.status button+button {
  margin-top: 5px;
}

.task-table td.status .dropdown>button {
  border-radius: 0;
}

.task-table td.status .dropdown-menu button {
  box-shadow: none;
}

.task-progress {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  padding: 2px 10px !important;
}

.task-progress .progress-wrapper {
  display: inline-block;
  width: 90px;
  vertical-align: text-bottom;
}

.task-progress span {
  min-width: 35px;
  display: inline-block;
  vertical-align: super;
}

.task-progress .progress {
  width: 80%;
  margin: 6px 5px 6px 0;
  border-radius: 20px;
  border: 1px solid #5b6c97;
  background: transparent;
  height: 12px;
}

.task-progress .progress .progress-bar {
  background-color: #5b6c97;
}

.task-settings-icon {
  /* float: right;
  margin: 2px 0;
  font-size: 17px; */
  opacity: 0;
}

.task-card-title:hover .task-settings-icon {
  opacity: 1;
}

.task-pen-icon {
  font-size: 12px;
}

/* .task-toggle-down {
  border-radius: 50%;
  background: white;
  color: #5b6c97;
  font-size: 12px;
  cursor: pointer;
  position: absolute;
  z-index: 9;
  top: 9px;
  left: 0px;
  border: 1px solid #5b6c97;
  width: 17px;
  height: 17px;
  text-align: center;
  line-height: 17px;
  position: absolute !important;
  top: 40px !important;
  left: 24px !important;

} */
/* .task-card-title {
  position: absolute;
  top: 4px;
  width: fit-content;
  background: #5b6c97;
  color: white;
  padding: 8px 10px;
  border-radius: 12px 12px 0 0;
  font-size: 13.5px;
  font-weight: 500;
  height: 33px;
  font-family: "Avenir Next LT W02";
} */
/* .task-table tbody > tr > td:first-child {
  min-width: 250px;
  width: 250px;
  text-align: left;
  padding-left: 10px;
  font-weight: bold;
  display: inline-block;
  align-items: center;
  padding: 2px 0;
  width: 60%;
} */
/* task-content-table1 tbody > tr > td:first-child {
   display: inline-block;
} */
/* .task-content-table tbody > tr > td:first-child {
  display: flex!important;
  
} */
.task-table tbody>tr>td:first-child span.task-name-highlight {
  min-height: auto;
  display: inline-block;
  align-items: center;
  word-break: break-word;
}

.task-table tbody>tr>td:first-child span.task-name-highlight .task-table tbody>tr>td:first-child span svg:first-of-type {
  vertical-align: middle;
}

.paddding-0 {
  padding: 0 !important;
}

.cont-box2 {
  position: sticky;
  top: 2px !important;
  left: 1rem !important;
  font-size: 12px;
  font-weight: 500;
}

.subtask-parent-name {
  text-align: left;
  padding-top: 6px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: 500;
}

.cont-box3 {
  padding-left: 0;
}

.cont-box4 {
  padding-left: 20px;
}

.cont-box5 {
  padding-left: 20px;
}

.task-location-content {
  text-align: left;
  padding-left: 7px;
  position: relative;
  top: 4px;
  vertical-align: middle;
}

.cont-box {
  text-align: left;
  padding-left: 7px;
  position: relative;
  top: 0px;
}

.taskopen-popup li {
  cursor: pointer;
  width: 135px;
  padding: 3px 2px 3px 20px;
  font-family: 'Avenir Next LT W02';
  color: #5b6c97;
  font-weight: normal;
  /* font-size: 12px; */
}

.taskopen-popup li:hover {
  background-color: #EDF0F4;
}

.taskopen-popup {
  padding: 0;
  margin-bottom: 0;
}

.plusView-popup li {
  cursor: pointer;
  width: 150px;
  text-align: center;
  padding: 3px 2px;
  font-family: 'Avenir Next LT W02';
  color: #5b6c97;
  font-weight: bold;
}

.plusView-popup li:hover {
  background-color: #EDF0F4;
}

.plusView-popup {
  padding: 0;
}

.cursor-point {
  cursor: pointer;
}

.min-width-250 {
  min-width: 290px !important;
}
.min-width-100 {
  min-width: 100px !important;
}
.min-width-120 {
  min-width: 120px !important;
}

.subtasktask-toggle-icon {
  margin-right: 10px;
  margin-left: 10px;
  font-size: 15px;
}

.parenttask-align {
  text-align: left !important;
}

.padding-l30 {
  padding-left: 30px;
}

.hourGlass-icon {
  font-size: 15px;
  margin-right: 10px;
}

.font-size15 {
  font-size: 15px;
}

.taskTable-border {
  border: #5b6c97 1px solid;
}

.taskdetail-modal {
  max-width: 95%;
  margin-top: 7rem;
  border-radius: 1rem;
  border: 1px solid #5b6c97;
  font-family: 'Avenir Next LT W02';
  margin-bottom: 0.5rem;
}

.taskdetail-modal-height {
  max-height: inherit;
  color: #5a6c97;
  min-height: 80vh;
}

.taskdetail-modal-header {
  background-color: #edf0f4;
  color: #5b6c97;
  border-bottom: 1px solid #5b6c97;
  padding: 5px 20px;
  font-family: "Avenir Next LT W02";
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  text-align: left;
  border-radius: 1rem 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.taskdetail-modal .modal-content {
  border-radius: 1rem !important;
  border: 1px solid transparent !important;
  overflow: hidden;
}

.taskstatus-heading {
  display: inherit;
  position: relative;
  /* border-right: 2px solid #c9cedc; */
  margin-bottom: 15px;
}

.taskstatus-text {
  background-color: #e22033;
  color: #fff;
  padding: 8px 84px 12px 8px;
  height: 40px;
}

.taskstatus-text-icon {
  background-color: #e22033;
  color: #fff;
  width: 22px !important;
  padding: 5px;
  margin-left: 2px;
  height: 2.5em;
  font-size: inherit;
  display: inline-block;
}

.taskstatus-text-tags {
  margin-left: 15px;
  width: 40px;
  padding: 0px 0px 5px 5px;
}

.taskstatus-heading-seperator {
  margin-top: -3px;
  margin-bottom: 0px;
  border: 1px solid;
  margin: 0 15px;
  height: 45px !important;

}

.taskstatus-heading-right {
  color: #5b6c97;
  font-family: 'Avenir Next LT W02';
}

.fontsize-13 {
  font-size: 10px;
  letter-spacing: .2px;
  font-weight: 500 !important;
}

.fontsize-12 {
  font-size: 12px;
}

.taskstatus-heading-wrapper {
  border-bottom: 1px solid #5b6c97;
}

.font-s12 {
  font-size: 12px;
}

.pad-bottom10 {
  padding-bottom: 5px;
  margin-bottom: 0;
}

.mar-l50 {
  margin-left: 50%;
}

.taskInfo-inputs {
  width: 95%;
  margin: 0 auto 15px;
}

.taskmodal-title-wrapper {
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 12px;
  width: 95%;
  margin: 15px 15px 15px 18px;
  display: flex;
  align-items: start;
}

.taskmodal-title-wrapper {
  font-size: 25px;
  font-weight: bold;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 12px;
  color: #5b6c97;
  width: 95%;
  margin: 15px 15px 15px 18px;
}

.taskmodal-description-area {
  font-size: 13px;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 12px;
  color: #5b6c97;
  box-shadow: none;
}

.taskmodal-title-wrapper::placeholder {
  color: #aeb1ba;
}

.taskmodal-title-wrapper:-ms-input-placeholder {
  color: #aeb1ba;
}

.taskmodal-title-wrapper::-ms-input-placeholder {
  color: #aeb1ba;
}

.taskmodal-description-area::placeholder {
  font-weight: 300 !important;
}

.taskmodal-description-area:-ms-input-placeholder {
  font-weight: 300 !important;
}

.taskmodal-description-area::-ms-input-placeholder {
  font-weight: 300 !important;
}

.taskmodal-title-wrapper:hover,
.taskmodal-description-area:hover {
  border-color: #abb4c9;
}

textarea.form-control {
  font-weight: 600;
  color: #847199;
  font-size: 12px;
}

.font-bold {
  /* font-weight: 600; */
  font-size: 12px;
}

.progress-table {
  width: 100%;
  margin-bottom: 15px;
}

.progress {
  height: 11px;
  background-color: #fbfbfb;
  border-radius: 8px;
  width: calc(100% - 73px);
  float: left;
  margin-top: 6px;
  margin-right: 15px;
  border: 1px solid #5a6c97;
}

.progress-bar {
  background-color: #5a6c97;
}

.progress-bar-tdwidth {
  width: 280px;
}

.pad-l20 {
  padding-left: 20px;
  font-weight: 100;
}

.in-progress {
  color: #5de367;
}

.dots-3 {
  text-align: center;
  padding: 15px 0 0 0;
  color: #acb4c9;
}

.name-tasklist {
  font-size: 13px;
  margin: 10px 0;
}

.right-box-popup {
  border-right: 1px solid #5a6c97;
}

.left-box-popup {
  background-color: #edf0f4;
  padding-top: 15px;
  height: 70vh;
  padding-right: 0;
}

.off-gray {
  color: #afb8cc;
}

.just-now {
  text-align: right;
  padding-top: 10px;
}

.dots-padd-t {
  padding-top: 182px;
}

.pad-t15 {
  padding-top: 15px;
}

.Comment-Section {
  /* height: 35vh; */
  overflow-y: auto;
  overflow-x: hidden;
}

.name-comment-box {
  background-color: #fff;
  /* padding: 15px; */
  border-radius: 12px;
  font-size: 12px;
}

.comment-here-box {
  background-color: #fff;
  margin: 0 -15px;
  padding: 15px;
  font-size: 14px;
}

.comment-here-box input {
  width: 100%;
  border: none;
  outline: none;
}

.quiz-checkbox-container {
  position: relative;
  margin-bottom: 5px;
}

.quiz-checkbox-container label {
  cursor: pointer;
  display: block;
  position: absolute;
  border: 2px solid #5a6c97;
  border-radius: 100%;
  height: 13px;
  width: 13px;
  top: 3px;
  left: 12px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
  box-sizing: border-box;
  padding: 0;
}

.quiz-checkbox-square-container label {
  border-radius: 0;
  left: 15px;
}

.quiz-checkbox-container span {
  position: absolute;
  left: 40px;
  top: 0;
  font-size: 12px;
}

.quiz-checkbox-square-container span {
  left: 32px;
}

.mar-t10 {
  margin-top: 10px;
}

.quiz-checkbox-container label:after {
  display: block;
  position: absolute;
  content: '';

}

.quiz-checkbox-container input[type="checkbox"] {
  visibility: hidden;
}

.quiz-checkbox-container input[type="checkbox"]:checked+label {
  border: 2px solid #5a6c97;
}

.quiz-checkbox-container input[type="checkbox"]:checked+label:after {
  opacity: 1;
  border-radius: 0;
  margin: auto;
  border-left: 2px solid #5a6c97;
  border-bottom: 2px solid #5a6c97;
  transform: rotate(-45deg);
  transition: opacity 300ms ease;
  top: -1px;
  left: 1px;
  width: 11px;
  height: 5px;
}

.dropdown-list {
  padding: 15px 15px 10px !important;
  font-size: 12px;
  color: #adb5cb !important;
  text-align: left !important;
}

.margin-b7 {
  margin-bottom: 7px;
}

/* .task-cards{
  min-height: 0px !important;
  overflow: auto;
}
.task-cards .table-responsive{
  overflow: visible;
  /* margin-bottom: 20px; 
}
.task-cards .table-responsive .task-card-title+.task-table{
  margin-bottom: 0;
}
.task-table-margin{
  margin-bottom: 2rem;
  opacity: 0;
}
.task-cards>.table-responsive>.show~.task-table-margin{
  margin-bottom: 0;
}
*/
/*remove*/
/* .task-cards>.table-responsive>.show+.task-card-title{
  position: absolute;
  margin-bottom: 0;
} */

.h40,
.h40:hover,
.h40:active {
  height: 40px;
  /* background-color: #e22033 !important; */
  color: #fff;
  padding: 8px 84px 12px 8px;
  border-radius: 0;
  border: 0;
  box-shadow: none !important;
  width: 100%;
}

.h40::after {
  display: none;
}

.w40 {
  width: 40%;
}

.w40 .dropdown-menu {
  margin: 0;
  padding: 0;
}

.task-modal-saveBtn {
  font-size: 15px;
  padding: 3px 10px;
  margin-left: 14px;
  margin-top: 5px;
}

/* .category-dropBtn::after{
  display: none;
} */
.category-dropBtn {
  background-color: #fff;
  color: #5a6c97;
  border: 1px solid #5a6c97;
  font-size: 12px;
  padding: 1px 15px;
  width: 150px;
}

.task-modal-scroll {
  height: 50vh;
  overflow-y: auto;
}

.bottom-comm-fix {
  position: absolute;
  width: 95%;
  bottom: 0;
}

.category-dropBtn+.dropdown-menu {
  padding: 0;
}

.category-dropBtn+.dropdown-menu .dropdown-item {
  font-size: 12px;
  padding: 3px 10px;
}

.dueDate-icon {
  width: 25px;
  margin: 0 20px;
}

.task-header-dropdown .dropdown-toggle {
  padding: 0;
  background-color: white !important;
  border: 0;
  box-shadow: none !important;
}

.task-header-dropdown .dropdown-toggle:after {
  display: none;
}

.taskstatus-heading-right .react-datepicker__input-container {
  border: 0;
}

.taskdetail-datepicker {
  padding: 0 !important;
  color: #5b6c97;
  outline: 0 !important;
  background-color: transparent;
}

.task-priority-btn {
  width: 36px;
  height: 36px;
  border: 1px dashed #979797 !important;
  border-radius: 50%;
  margin-left: 16px;
  text-align: center;
}

.task-priority-btn svg {
  width: 16px;
  color: #979797;
  height: 35px;
  margin: 0;
  padding: 0;
}

.task-header-dropdown .dropdown-menu {
  padding: 0;
  font-size: 15px;
}

/* .task-header-dropdown .dropdown-menu .dropdown-item{

} */
.width25 {
  width: 25px;
}

.selected-avtar {
  width: 35px;
}

.transparent-dropdown>button {
  background-color: transparent !important;
  border: transparent !important;
  padding: 0;
  box-shadow: none !important;
}

.transparent-dropdown button .selected-avtar {
  margin-left: 0;
  width: 25px;
  border: 1px solid lightgray;
}

.task-table .react-datepicker__input-container {
  border: 0;
}

.task-name-highlight:hover {
  color: #007bff;
  /* font-size: 14px; */
}

.highlight-onHover:hover {
  color: #007bff;
}

.subtask-box-wrapper {
  margin: 10px 0px 10px 0px;
  font-size: 15px;
  /* font-weight: bold; */
  padding: 5px 200px 5px 30px;
  border: 1px solid #abb4c9;
  border-radius: 12px;
  /* margin: 10px auto 0; */
  color: #5b6c97;
  width: 100%;
}

.subtask-container {
  position: relative;
  padding: 0;
  margin: 0;
}

/* .input { 
  height:20px;
  margin:0;
  padding-left:30px;
} */
.task-status-icon {
  display: inline-block;
  margin-right: 12px;
  line-height: 1;
}

.task-status-icon button.task-icon-bg {
  width: 10px;
  height: 10px;
  padding: 0;
  border-radius: 2px;
  border: 0;
  z-index: 9;
  position: relative;
  box-shadow: none !important;
}

.task-status-icon>span {
  position: absolute;
  content: "";
  left: -3px;
  top: -1px;
  height: 17px;
  width: 16px;
  border: 1px solid #2ecd6f;
  border-radius: 2px;
  display: none;
  z-index: -1;
}

.task-status-icon:hover>span {
  display: block;
  z-index: 0;
}

.task-status-sq {
  padding: .3rem 0.8rem;
  font-size: 13px;
}

.task-status-sq>span:first-child {
  width: 10px;
  height: 10px;
  padding: 0;
  border-radius: 2px;
  display: inline-block;
  margin-right: 5px;
}

.subtask-container .image {
  position: absolute;
  bottom: 5px;
  right: 59px;
  width: 25px;
  height: 25px;
}

.subtask-container .save-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0px 5px;
}

.subtask-status-wrapper {
  position: absolute;
  bottom: 0px;
  left: 5px;
}

.subtask-status-wrapper .btn {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  border: none;
  margin-bottom: 5px;
}

.subtask-status-wrapper .btn:active,
.subtask-status-wrapper .btn:focus {
  box-shadow: none;
}

.subtask-assignee-wrapper {
  position: absolute;
  right: 60px;
  top: 0px;
}

.subtask-assignee-wrapper .btn {
  padding: 0;
  margin: 0;
  background-color: transparent !important;
  border: none;
}

.subtask-assignee-wrapper .btn:active,
.subtask-assignee-wrapper .btn:focus {
  box-shadow: none;
}

.subtask-assignee-wrapper .btn img {
  width: 25px;
  padding: 5px 0px;
}

.subtask-priority-wrapper {
  position: absolute;
  right: 95px;
  top: 0px;
}

.subtask-priority-wrapper .btn {
  padding: 7px 0px;
  background-color: transparent !important;
  border: none;
  color: #4e555b;
}

.subtask-priority-wrapper .btn:active,
.subtask-priority-wrapper .btn:focus {
  box-shadow: none;
}

.w-25px {
  width: 25px;
}

/* .subtask-list-item {
  margin-top: 5px;
  margin: 10px 0px 10px 0px;
  font-size: 15px;

  padding: 5px 200px 5px 10px;
  border: 1px solid #ffffff;
  border-radius: 12px;

  color: #5b6c97;
  width: 100%;
} */

.subtask-table {
  margin: 0;
  position: relative;
}

.subtask-table td {
  min-width: 170px;
}

.task-table .subtask-table tbody>tr>td:first-child {
  padding-left: 33px;
  position: relative;
}

.task-table .subtask-table:before {
  position: absolute;
  content: "";
  width: 20px;
  height: calc(100% - 17px);
  left: 14px;
  top: 0;
  border-left: 1px solid #5b6c97;
  border-bottom-left-radius: 5px;
  z-index: 9;
}

.task-table .subtask-table tbody>tr>td:first-child span.task-name-highlight {
  position: relative;
  padding-left: 5px;
}

.task-table .subtask-table tbody>tr>td:first-child span.task-name-highlight:before {
  position: absolute;
  content: "";
  width: 20px;
  left: -19px;
  /* height: 8px;
  top: 0; */
  border-bottom-left-radius: 8px;
  border-bottom: 1px solid;
  height: 10px;
  top: -6px;
  bottom: 0;
  margin: auto;
}

.subtask-table tbody tr:last-child {
  /* border-bottom: 1px solid; */
  background: #f2f2f2;
}

.subtask-table .task-status-icon {
  position: absolute;
  left: 55px;
}

.subtask-table .task-status-icon+span {
  padding-left: 50px !important;
}

.subtask-table .task-status-icon>span {
  position: absolute !important;
  padding: 0 !important;
}

.subtask-table .task-status-icon>span:before {
  display: none !important;
}

.subtask-table.metatask-table .task-status-icon {
  left: 75px;
}

.subtask-table.metatask-table .task-status-icon+span {
  padding-left: 35px !important;
}

.padl-25 {
  padding-left: 25px;
}

/*assignee*/
.assignee-dropdown {
  display: inline-block;
}

.assignee-dropdown>button {
  width: 25px;
  height: 26px;
  display: inline-block;
  border-radius: 50%;
  /* box-shadow: 0 0 0 2px #5b6c97 !important; */
  position: relative;
}

.assignee-dropdown+.assignee-dropdown {
  margin-left: -2px;
}

.modal-assignee-wrapper>button {
  width: 39px;
  height: 39px;
  border-radius: 50%;
  border: 2px solid #5b6c97 !important;
  padding: 0;
  background-color: transparent !important;
  box-shadow: none !important;
}

.modal-assignee-wrapper+.modal-assignee-wrapper {
  margin-left: -10px;
}

.assignee-dropdown:hover>button,
.modal-assignee-wrapper:hover>button {
  z-index: 9;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  position: relative;
}

.modal-assignee-wrapper>button.no-assignee {
  border: none !important;
}

/* .modal-assignee-wrapper:hover>button.no-assignee{ */
/* transform: none; */
/* } */
.taskstatus-heading .add-assignee {
  background-color: white;
  /* border-radius: 50%; */
}

.add-assignee-table {
  background: #f2f2f2;
  border-radius: 50%;
}

.time_est_popover .popover {
  max-width: 200px;
}

.time_est_fields {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.time_est_fields .time_fields {
  position: relative;
}

.time_est_fields .time_fields .form-control {
  font-size: 14px;
}

.time_unit {
  position: absolute;
  right: 6px;
  top: 8px;
  font-size: 14px;
}

.btn-small {
  padding: 5px 10px 7px;
  font-size: 14px;
  line-height: 1;
}

.time_tracked_tabs .nav-link {
  flex-grow: 1;
  text-align: center;
}

.timeTrackedIcon {
  font-size: 24px;
  color: #5b6c97;
  cursor: pointer;
}

.time_tracked_fields {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.time_tracked_fields input {
  display: inline-block;
  /* width: 30px !important; */
  border-radius: 5px;
  text-align: left;
  height: 34px;
  outline: 0;
}

.desc_icon {
  border: 1px dashed gray;
  color: gray;
  width: 30px;
  height: 30px;
  display: flex;
  margin-left: auto;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.timeTrackedIconPlay {
  font-size: 30px;
  color: rgb(45, 204, 110);
  cursor: pointer;
}

.timeTrackedIconPause {
  font-size: 30px;
  color: rgb(204, 45, 45);
  cursor: pointer;
}

.time-tracked-duration {
  padding: 10px;
  background: #edf0f4;
  padding-left: 38px;
}

.time-tracked-duration p {
  font-size: 11.5px;
  margin: 0 0 8px;
}

.time-tracked-duration p:last-child {
  margin-bottom: 0;
}

.time-tracked-duration p>span {
  width: 35px;
  display: inline-block;
}

p.padl-25 {
  margin: 0;
}



.task-table .metatask-table tbody>tr>td:first-child {
  padding-left: 63px;
}

.task-table .metatask-table tbody {
  position: relative;
}

.task-table .metatask-table tbody:before {
  position: absolute;
  content: "";
  width: 20px;
  /* height: calc(100% - 17px); */
  height: 52%;
  left: 44px;
  top: 0;
  border-left: 1px solid #5b6c97;
  border-bottom-left-radius: 5px;
}

.task-table .subtask-table tbody>tr>td.has-subtask .metaTask_arrow {
  position: absolute;
  left: 40px;
  /* top: 7px; */
}

.task-comment-img {
  width: 80px;
  text-align: center;
}

.task-comment-img img {
  width: 50px;
  border-radius: 50%;
}

.task-modal-comment p {
  font-size: 13px;
  margin: 0;
}

/*TIME TRACKED*/
.time_tracked_popover .popover {
  min-width: 370px;
}

#timetracklist {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px;
}

#timetracklist:hover {
  background-color: #efefef;
}

#timetracklist img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-time-track {
  padding: 8px 10px;
  border-top: 1px solid #ccc;
}

.user-time-track ul {
  margin-bottom: 0;
}

.user-time-track ul li {
  display: inline-block;
  font-size: 13px;
}

.user-time-track ul li+li {
  /* margin-left: 15px; */
}

.user-time-track ul li .edit {
  color: #0194f7;
  cursor: pointer;
}

.user-time-track ul li .delete {
  color: #a50000;
  cursor: pointer;
}

.user-time-track ul li .view {
  color: #5b6c97;
  cursor: pointer;
}

.time_track_scroll {
  min-height: 5px;
  max-height: 50%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

.time_track_scroll:empty {
  height: auto;
}

.time-log-popover-outer .popover {
  max-width: 200px;
}

.time-log-popover {
  text-align: right;
  padding: 10px;
}

.time-log-popover .text-center button {
  display: inline-block;
}

.time-log-popover .text-center button.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  margin-right: 10px;
}

.time-log-popover .react-time-picker__wrapper {
  margin-bottom: 10px;
}

.custom-check {
  position: relative;
  margin: 0 0 8px;
  display: flex;
  align-items: center;
}

.custom-check input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 99;
}

.custom-check .checkbox-text {
  display: inline-block;
}

.custom-check .checkmark {
  margin-right: 5px;
  height: 18px;
  min-width: 18px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #0194f7;
  cursor: pointer;
  display: block;
  position: relative;
}

.custom-check .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-check:hover input~.checkmark {
  background-color: #eeeeee;
}

.custom-check input:checked~.checkmark {
  background-color: #0194f7;
}

.custom-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-check input:checked~.checkmark:after {
  display: block;
}

.task-checklist-update .subtask-assignee-wrapper,
.custom-check .subtask-assignee-wrapper {
  position: relative;
  right: 0;
  display: inline-block;
  margin-left: 10px;
}

.task-checklist-update .subtask-assignee-wrapper button,
.custom-check .subtask-assignee-wrapper button {
  outline: 0;
  box-shadow: none;
}

.task-checklist-update {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px;
  align-items: center;
  margin-bottom: 5px;
}

.task-checklist-update .btn-primary {
  font-size: 12px;
  padding: 1px 5px;
  margin-left: 10px;
}

.task-checklist-update input {
  border: 0;
  outline: 0;
}

.custom-check .subtask-assignee-wrapper img,
.task-checklist-update .subtask-assignee-wrapper .btn img {
  padding: 0px;
}

/***********Project Tasks************/

.search-filter-deals {
  border-radius: 14px;
  padding: 3px 20px 3px 15px;
  font-size: 12px;
  height: auto;
  margin: 5px auto 0;
  width: 94%;
}

.show-all-deals {
  margin: 8px 1px;
  background-color: #2774cd1a;
  margin-right: 13px;
  font-size: 16px;
  border-radius: 5px;
  padding: 6px;
  color: #5b6c97;
  font-weight: 600;
  cursor: pointer;
}

.project-tasks-wrapper {
  height: calc(100vh - 7rem);
}
.project-tasks-wrapper{
  position: relative !important;
  /* top: -1rem !important; */
}
.project-tasks-wrapper .project-task-card {
  height: 100%;
  background-color: #fff;
  /* border-radius: 1rem; */
  /* margin-top: 15px; */
  overflow: hidden;
  /* box-shadow: 0 0 0 2px #b0bed0; */

}

.project-tasks-wrapper .task-tab {
  margin: 0px !important;
}

.project-deals-height {
  overflow-y: auto;
  max-height: calc(100vh - 177px);
  padding-right: 12px;
}

.project-deals-height.sidebar {
  height: 96%;
}

.deal-num {
  border: 1px solid #eaeaea;
  padding: 5px;
  margin-top: 30px;
  margin-bottom: 0 !important;
  border-radius: 5px;
  margin: 0px 16px;
}

.deal-num span {
  color: #5b6c97;
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s linear;
}

/* .deal-num span:hover {
  text-decoration: underline;
} */

.all-deals-list li {
  background-color: #edf0f4;
  font-size: 12px;
  border-radius: 5px;
  padding: 6px;
  color: #5b6c97;
  font-weight: 600;
  cursor: pointer;
}

.all-deals-list li:hover,
.all-deals-list li.active {
  background-color: #d0d4df;
}

.all-deals-list li+li {
  margin-top: 8px;
}

.project-search-filter {
  display: flex;
  align-items: center;
  /* margin: 0 0 15px; */
}

.project-search-filter .search-filter-deals {
  width: 100%;
  margin: 0;
}

/*rangepicker*/
.due-date-range .popover {
  max-width: fit-content;
}

.due-date-range .rdrMonth {
  width: 22.667em;
}

.due-date-range .rdrDay {
  line-height: 2.5em;
  height: 2.5em;
}

.due-date-range .rdrDefinedRangesWrapper {
  width: 200px;
}

.due-date-range .rdrMonthAndYearWrapper {
  height: 40px;
}

.due-date-range .rdrDayToday .rdrDayNumber span:after {
  bottom: 1px;
}

.deal-toggle-down {
  border-radius: 50%;
  background: #5b6c97;
  cursor: pointer;
  margin-right: 8px;
  height: 17px;
  width: 17px;
  display: inline-block;
  text-align: center;
  line-height: 15px;
  border: 1px solid #5b6c97;
}

.deal-toggle-down svg {
  color: #ffffff;
  font-size: 12px;
}

.dropdown-search {
  padding: 10px;
  padding-bottom: 15px;
  position: sticky;
  top: 0;
  background: white;
}

.dropdown-search .form-group {
  margin: 0;
  padding-bottom: 10px;
}

.dropdown-search .search-filter-deals {
  border-radius: 4px;
}

.timechart-filter-dropdown .dropdown-menu {
  min-width: 13rem;
  margin: 0px;
  padding-top: 0;
}

.collapse-tabs-btn {
  display: inline-block;
  cursor: pointer;
  padding: 7px 5px;
  border-radius: 0;
  border-left: 2px solid #cecece;
  margin-left: 6px;
}

.collapse-tabs-btn svg {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}

.collapse-tabs-popover .arrow {
  display: none;
}

.collapse-tabs-popover .popover-inner {
  padding: 5px 0;
}


.timechart-filters {
  border: 2px solid #d0d4df;
  padding: 5px;
  padding-left: 0;
  margin: 0 10px;
  display: inline-block;
  width: fit-content;
}

.timechart-filters .dropdown {
  display: inline-block;
}

.timechart-filters .dropdown+.dropdown {
  border-left: 1px solid #cccccc;
}

.timechart-filters .dropdown button,
.timechart-filters .dropdown button:hover,
.timechart-filters .dropdown button:focus,
.timechart-filters .btn-secondary:not(:disabled):not(.disabled):active,
.timechart-filters .show>.btn-secondary.dropdown-toggle {
  color: #6c757d;
  background: white;
  border-color: transparent;
  padding: 2px 15px;
  box-shadow: none;
  outline: 0;
  font-size: 13px;
}



.timechart-range {
  margin: 0 15px 0 auto;
  padding: 6px;
  border-radius: 0;
  border: 2px solid #d0d4df !important;
  text-align: center;
}

.timechart-filter-dropdown input[type="checkbox"] {
  vertical-align: middle;
  margin-right: 6px;
}

.timechart-filter-dropdown .dropdown-menu.show {
  max-height: 155px;
  overflow-y: auto;
  top: 7px !important;
}

.dropdown-search {
  padding: 10px;
  padding-bottom: 0px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 9;
}

.pie-table {
  max-height: 340px;
  overflow: auto;
}

.pie-table .table thead {
  position: sticky;
  top: 0;
}

.deal_setting_icon {
  rotate: 90deg;
  font-size: 13px;
  margin-left: 4px;
  display: none;
}

.project-deals-height .deal-num:hover .deal_setting_icon {
  display: block;
}


.project-search-filter .fa-ellipsis-h {
  rotate: 90deg;
}


.task-group-by,
.deal-group-by {
  max-height: 245px;
  overflow-y: hidden;
}

.task-group-by ul,
.deal-group-by ul {
  max-height: 170px;
  overflow-y: auto;
  margin-top: 5px;
}

.deal-group-by .list-group-item-header,
.task-group-by .list-group-item-header {
  position: sticky;
  top: -3px;
  z-index: 100;
  background-color: #e1ecf9;
  height: 30px !important;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 700;
  padding: 6px 6px !important;
}

.deal-group-by .list-group-item-header span,
.task-group-by .list-group-item-header span {
  margin-left: 4px;
  margin-bottom: 3px;
  display: inline-block;
}

.task-group-by .list-group-item,
.deal-group-by .list-group-item {
  padding: 1px 12px !important;
  border-radius: 3px !important;
  margin-bottom: 5px !important;
  cursor: pointer;
}

.ant-popover .ant-popover-content .ant-popover-inner {
  border-radius: 5px !important;
}

/* .deal-group-by ul li:first-child,
.task-group-by ul li:first-child:hover {
 color: black;
} */

.column-show-hide .custom-edit-column>button {
  height: 29px !important;
  font-size: 13px !important;
  padding: 0px 5px !important;
  border: none;
  border: 0px;
  border: 0px solid #e5e8ed !important;
  border-radius: 6px !important;
  width: 100%;
  text-align: left;
  background: #fff;
  color: #495057 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  width: 220px;
  margin: 0 auto;
}

.column-show-hide .custom-edit-column>button:hover {
  border-radius: 6px !important;
  background: #f0f1f3 !important;
}

.column-show-hide .custom-edit-column>button :focus {
  color: #495057 !important;
  font-size: 14px !important;
  font-weight: normal !important;
  border-radius: 6px !important;
  background: #f0f1f3 !important;
  box-shadow: none !important;

}

.column-show-hide .custom-edit-column>button.dropdown-toggle::after {
  display: inline-block;
  margin-left: 138px;
  border-top: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-bottom: 0;
  border-left: 0.4em solid transparent;
  /* background: #f0f1f3 !important; */
}

.hrcls {
  margin-top: .5rem;
  margin-bottom: .5rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.task-filter-setting-popup.ant-popover {
  left: 1105px !important;
  position: relative !important;
}

.ant-popover-content {
  position: relative;
  right: 20px;
}

.task-filter-hovr {
  border-radius: 6px !important;
  background-color: #ffff !important;
  /* width: 230px; */
  margin: 0 auto;
  padding: 3px 0px 5px 0px;
}

.task-filter-hovr:hover {
  border-radius: 6px !important;
  background-color: hsl(220, 11%, 95%) !important;
  /* width: 230px;  */
  margin: 0 auto;
  padding: 3px 0px 5px 0px;
}

.filter-expand-lefticon-1 {
  position: absolute;
  left: 13px;
  width: 52px;
  cursor: pointer;
  top: 13px;
  z-index: 9;
}

.filter-expand-lefticonactive-1 {
  position: absolute;
  left: -2px;
  width: 52px;
  cursor: pointer;
  top: -2px;
  z-index: 9;
}


.project-deals-height .deal-num span a {
  /* background: #5b6c97;
  color: #fff; */
  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  padding: 1px 15px;
  line-height: 18px;
  font-weight: normal;
  display: inline-block;
}

.project-deals-height .deal-num span a:hover {
  color: #fff !important;
}

.group-by-popover .ant-popover-inner-content {
  width: 370px !important;
}

.group-by-popover {
  top: 150px !important;
}

.group-by-search .search-ico {
  position: absolute !important;
  left: 8px !important;
  top: 8px !important;
}

.group-by-tasklist .list-group .active {
  color: #fff !important;
}

.task-filter-hovr .ant-switch {
  width: 50px !important;
}

.task-filter-hovr .ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 21px) !important;
}

.add-columns-tasks .ant-popover-inner-content {
  width: max-content !important;
  min-width: 235px !important;
}

.add-columns-option::after {
  content: "";
  display: block;
  position: absolute;
  top: -9px;
  right: 11px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 9.5px solid #fff;
}

.add-columns-tasks {
  top: 150px !important;
}

.search-settings-popover {
  top: 150px !important;
}

.search-settings-popover .ant-popover-inner-content ,.individual-task-modal .ant-popover-inner-content{
  width: max-content !important;
  min-width: 150px !important;
}

.search-settings-popover .ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 21px) !important;
}

.search-settings-popover .ant-switch , .add-columns-tasks .ant-switch, .individual-task-modal .ant-switch{
  width: 44px !important;
  height: 23px !important;
}

.search-settings-popover .ant-switch-handle::before {
  width: 0.7rem !important;
  height: 0.7rem !important;
  top: 3px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 5px !important;
}
.add-columns-tasks .ant-switch-handle::before {
  width: 0.7rem !important;
  height: 0.7rem !important;
  top: 3px !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 5px !important;
}



.task_filter_box {
  margin-top: 1px !important;
  background-color: rgb(1, 148, 247);
  border-radius: 3px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 15px;
}

.task_filter_box span svg {
  margin: 0 5px;
}

.add-columns-tasks .ant-popover-arrow {
  display: block !important;
}

.individual-task-modal .ant-popover-arrow {
  left: -15px !important;
}
/* .add-columns-tasks .ant-popover-arrow ::after {
  background-color: #ffffff !important;
  z-index: 1 !important;
} */
.project-tasks-wrapper{
  background: white;
}
.project-tasks-wrapper .Header{
  padding: 6px 12px;
  border-bottom: 1px solid #e3e3e3;
}
.project-tasks-wrapper .Header svg:focus{
  outline: none;
}
.project-tasks-wrapper .Tasks-Tab-Wrapper,.project-tasks-wrapper .Pipline-Tab-Wrapper{
  width: 100%;
  height: 95%;
}
.Pipline-Panel-hover:hover .Pipeline-View-Btn{
  display: flex;
  gap: 4px;
  cursor: pointer;
}
.Pipeline-Delete-Btn{
  display: none;
  margin-left: 7px;
  height: auto;
  border: 0px;
}
.Pipline-Panel-hover:hover .Pipeline-Delete-Btn{
  display: flex;
  gap: 4px;
  cursor: pointer;
  margin-right: 5px;
}
.Pipeline-Delete-Btn-Wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.Pipeline-View-Btn{
  display: none;
  margin-left: 7px;
  height: auto;
  border: 0px;
}
.Group-Panel-hover:hover .Group-View-Btn{
  /* display: flex; */
  /* gap: 4px;
  cursor: pointer; */
}
.Group-View-Btn{
  display: none;
  margin-left: 7px;
  height: auto;
  border: 0px;
}
.Header .list-compact-view{
  display: flex;
  flex-direction: row;
  border: 1px solid #018EE0;
  border-radius: 4px; 
  padding: 1.6px;
  cursor: pointer;
  width: 10.0rem; 
}

.TaskGroup2Card::-webkit-scrollbar{
  width: 7px !important;
  height: 7px !important;
}
.task-card-title .sort-icon, .column-header .sort-icon, .min-width-120 .sort-icon{
  margin-left: 0.3rem !important;
}
.Category-CheckBox .ant-checkbox-inner, .Project-Checkbox .ant-checkbox-inner,.Individual-Checkbox .ant-checkbox-inner{
  border-color: #1677ff !important;
  border-radius: 12px !important;
  outline: none !important;
}
.add-columns-tasks .ant-switch, .search-settings-popover .ant-switch{
  background-color: #a9a9a9 !important;
  border-color: #a9a9a9 !important;
}
.add-columns-tasks .ant-switch-checked, .search-settings-popover .ant-switch-checked{
  background-color: #018EE0 !important;
  border-color: #018EE0 !important;
}