/* .navbarbtn2-100 {
    border-radius: 0%;
    border: 0ch;
    width: 100%;
    font-size: 12px;
    padding: 3px;
} */

.navbarbtn-top-100 {
  margin-bottom: 0rem;
  width: 100%;
  text-align: center;
  background-color: #fff;
  border: 2px solid #018EE0;
  color: #018EE0;
  border-radius: 7px;
  padding: 7px 20px;
  font-size: 17px;
  font-weight: bold;
  box-shadow: none;
}
.navbarbtn-top-100:hover {
  background-color: #018EE0;
  color: #fff;
  border: 2px solid #018EE0;
  box-shadow: none;
}

.navbarbtn-top-active {
  margin-bottom: 0rem;
  width: 100%;
  text-align: center;
  background-color: #018EE0;
  border: 2px solid #018EE0;
  color: #fff;
  border-radius: 7px 7px 0 0;
  padding: 7px 20px;
  font-size: 17px;
  font-weight: bold;
  box-shadow: none;
}
/* .navbarbtn-top-active:hover {
  background-color: #5b6c97;
  color: #fff;
} */

  .navbarbtn-top-active,
  .navbarbtn-top-active:focus,
  .navbarbtn-top-active:hover {
    background-color: #018EE0;
    color: #fff;
    box-shadow: none;
    border-color: #018EE0;
  }

.navbarbtn-100 {
  margin-top: 0.7rem;
  margin-bottom: 0rem;
  width: 100%;
  text-align: center;
  background-color: #fff;
  border: 2px solid #018EE0;
  color: #018EE0;
  border-radius: 7px;
  padding: 7px 20px;
  font-size: 17px;
  font-weight: bold;
  box-shadow: none;
}

.navbarbtn-100:focus,
.navbarbtn-100:hover {
  background-color: #018EE0;
  color: #fff;
  box-shadow: none;
  border-color: #018EE0;
}


.navbarbtn-active {
  box-shadow: none;
  margin-top: 15px;
  margin-bottom: 0rem;
  width: 100%;
  text-align: center;
  background-color: #018EE0 !important;
  border: 2px solid #018EE0 !important;
  color: #fff;
  border-radius: 7px 7px 0 0;
  padding: 7px 20px;
  font-size: 17px;
  font-weight: bold;
  
}

.navbarbtn-active:focus,
.navbarbtn-active:hover {
  background-color: #018EE0;
  color: #fff;
  box-shadow: none;
  border-color: #018EE0;
}


.navbutton-border{
  border-radius: 6px 6px 0 0;
}
/* .list-group {
  border: 1px solid #70ad47;
} */
.navbar-sublist-card{
  padding: 0px;
  text-align: center;
  font-size: 13px;
 
}

.sublist-text{
  color:#018EE0;
  border-bottom: 1px solid #018EE0;
  padding: 5px;
  cursor: pointer;
}
.sublist-text:hover {
  background-color: #018EE0;
  color: #fff;
}

.sublist-text-active{
  background-color: #018EE0;
  border-top: 1px solid #fff;
  color: #fff;
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}

.sublist-text2{
  color:#018EE0;
  padding: 5px;
  cursor: pointer;
}
.sublist-text2:hover {
  background-color: #018EE0;
  color: #fff;
}

.disabled-subheader-div{
  background-color: #6c757d;
  color: #fff;
  padding: 5px;
  border-bottom: 1px solid #018EE0;
  pointer-events: none;
  opacity: .65;
}

.deal-list-sticky{
  position: sticky;
  top: 0;
}

.pointer-class-deactive{
  pointer-events: none ;
}
