
.contact-icon{
    width: 20px;
}


.clientmiddle-tab-padding > .nav-link{
/* padding: 0px 29px 10px 29px; */
}
.clientmiddle-tab-padding-active > .nav-link{
padding: 0px 52px 10px 52px;
}

.clientmiddle-tab-padding-doubleActive > .nav-link{
padding: 0px 75px 10px 75px;
}
.star-color{
  color: #FFC000;
}

.head-list-style{
/* font-weight: bold; */
/* color: #5B6C97; */
/* padding: 4px 14px 4px 4px; */
font-family: 'Avenir Next LT W02';
/* margin-bottom: 5px; */
}
.font-size18{
font-size: 18px;
}

.custom-label-client-head{
width: 35%;
display: inline-block;
vertical-align: top;
position: relative;
word-break: break-word;
}

.custom-label-client-head+span{
display: inline-block;
width: 62%;
}
/* .custom-label-job-head:after{
position: absolute;
content: ":";
right: -3px;
font-size: 15px;
color: #5b6c97;
top: 0;
line-height: 1.1;
} */
.custom-label-client-head.custom-label-client-detail {
width: 42%;
}
.custom-label-client-head.custom-label-client-detail+span{
width: 58%;
padding-left: 7px;
}

.client-body-task .col-sm-12{
    padding: 0px !important;
}

.client-body-task .col-sm-12 .project-task-card{
    margin-top: 0px !important;
    border-radius: 0px !important;
    box-shadow: none;
}

.client-body-task  .project-deals-height .pl-4{
   padding-left: 0px !important;
}
.mid-left-icon-active{
    left: 0px !important;
}
.mid-right-icon-active{
    right: 0px !important;
}
.line-height-36{
    line-height: 36px !important;
}
.line-height-33{
    line-height: 33px !important;
}


.client_body_deals .deal-module .active-deal{
    border: 0px;
}

.client_body_deals .deal-module{
    padding: 0px !important;
}

.client_body_deals .deal-module .active-deal .alldealtabs.nav-tabs .nav-link:not(:nth-child(1)){
    width: 13.9% !important; 
}

.deal_column_hide{
    display: none;
}

.client_body_deals .deal-module .active-deal .ag-theme-alpine{
    height: calc(100vh - 344px) !important; 
}

.client_body_deals .task-filter-float{
    float: none !important;
}

.client_body_deals .deal-list-wrap .pad-col{
    position: relative !important;
    top: 0px !important;
}
.about-this-account-content{
    height: 255px !important;
    overflow: auto !important;
    width: calc(100vw - 82.5vw);
}