.settings-card{
    width: 100%;
    border-radius: 20px !important;
    height: 235px;
}

.settings-wrapper{
    background: #DDE3EB;
    height: calc(100vh - 50px);
    overflow: hidden;
}
/* .setting-dashboard{
    height: 80vh;
    overflow: auto;
} */
.settings-search{
    position: sticky;
    top: 0;
    padding: 15px;
    display: flex;
    align-items: center;
    padding-left: 1%;
}

.search-input{
    padding-left: 45px;
    border: 1px solid gray;
    border-radius: 8px;
    width: 284px;
    height: 37px;
}
.searchbar-icon{
    position: absolute;
    left: 30px;
    top: 19px;
    color: gray;
}
.setting-child:hover{
    color: rgb(1, 142, 224);
}

.user-profile{
    padding: 11px 47px;
    border-radius: 5px;
}

.user-profile:hover{
background-color: #f1f5f9;
transition: all .2s ease;
}

.user-profile-content{
    padding-left: 2%;
}

.user-profile-content h5{
    font-size: 17px;
}

.user-profile-content h6{
    font-size: 14px;
    color: gray;
}
.user-profile-content span{
    background-color: #9744d0;
    color: white;
    padding: 3px 8px;
    border-radius: 5px;
    margin-left: 5px;
}


.user-profile-content{
    padding-left: 2%;
}

.user-profile-content h5{
    font-size: 17px;
}

.user-profile-content h6{
    font-size: 14px;
    color: gray;
}
.user-profile-content span{
    background-color: #9744d0;
    color: white;
    padding: 3px 8px;
    border-radius: 5px;
    margin-left: 5px;
}
.users-pd {
    padding: 5px 25px 25px 45px;
}
.usersname {
    font-weight: bold;
    display: block;
    font-size: 16px;
    padding-bottom: 5px;
}
.companyneme {
    color: #616e88;
    display: block;
    padding-bottom: 5px;
}
.admintype {
    color: #e97800;
    background-color: #fff3e7;
    border: 1px solid #ffe4c5;
    padding: 3px 7px;
    border-radius: 12px;
    font-size: 14px;
    margin-right: 5px;
}
.emailid {
    color: #111111;
    display: block;
    padding-bottom: 7px
}
.user-info {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 15px;
    display: block;
}

.settings-search-card{
    position: absolute !important;
    width: 313px !important;
    z-index: 100;
    top: 110px;
    left: 129px;
}
.settings-dropdown{
    min-height: 150px;
    max-height: 500px;
    height: auto;
    overflow-y: auto;
    box-shadow: 0 2px 8px 0 #b1b1b1;
}

.settings-dropdown-menu{
    padding: 6px 0px!important;
    color: #5a5a5a!important;
}
.settings-dropdown-menu:hover{
    background-color: lightblue !important;
}

.settings-dropdown-menu h5{
    font-size: 16px;
    color: #6b6b6b;
}

.settings-dropdown-menu h6{
    font-size: 13px;
    color: #b5b5b5;
    line-height: 3px;
}
.user-profile-right{
    height: 65vh;
    overflow-y: auto;
}

.user-profile-left{
    height: 49vh;
    overflow-y: auto;
}

.reinvite-btn{
    background-color: #edf6ff!important;
}

.deactivate-btn{
    background-color: #fff2f3 !important;
}