.drag-n-drop{
    text-align: center;
    border: 1px solid #818181;
    padding: 50px 5px;
    border-radius: 5px;
}
.workdrive-heading-box {
    width: 100%;
    clear: both;
}
.clear {
    clear: both;
}
.upload-box2 {
    width: auto;
    float: left;
    margin-right: 15px;
}
.creat-box2 {
    width: auto;
    float: left;
    margin-right: 20px;
}

.upload-box2 .dropzone {
    height: 35px;
    border: none;
}

.search-box2 {
    width: calc(100% - 260px);
    float: left;
}
.search-box2 > div > input {
    width: 210px;
    float: right;
    margin-right: 46px;
}
.creat-txt-box2 {
    width: auto;
    float: left;
}
.icons-box2 {
    width: auto;
    float: right;
}
.btn-creat {
    /* border-radius: 0 4px 4px 0 !important; */
    border: 1px solid #ced4da !important;
}
.btn-creat:hover {
    background-color:transparent !important
    ;
}
.btn-creat-close {
    border-radius: 0 4px 4px 0 !important;
    border: 1px solid #ced4da !important;
}
.btn-creat-close:hover {
    background-color:transparent !important
    ;
}
.btn-reset {
    float: right;
    margin-right: 15px;
}

.drag-overlay {
    min-height: 100vh;
    position: fixed;
    z-index: 9999999;
    top: 0;
    background:linear-gradient(rgba(24, 24, 24, 0.7),rgba(29, 29, 29, 0.7));
    width: 100%;
    right: 0px;
    bottom: 0px;
    left: 0px;
    display:'';
   
}

.drop-file{
    border: 6px dashed rgb(221, 221, 221);
    width: 80%;
    color: rgb(221, 221, 221);
    text-align: center;
    position: absolute;
    top: 15%;
    left: 10%;
    font-size: 2rem;
    padding: 15% 0;
}
.workdrive-searh-icon{
    right: 55px !important;
    top: 8px !important;
}

.workdrive-searchbar{
    border: 2px solid #b0bed0; 
    border-radius: 15px;
}
.workdrive-table{
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid lightgray;
    color: #5b6c97;
    padding: 1rem !important;
}

.workdrive-table thead{
   
    position: sticky;
    top: 0px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.Manage-options-upload{
    z-index: 99991 !important;
}