.headerDiv{
    display: flex; 
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
}
.createButton{
    position: relative; 
    left:36rem;
    padding: 0.4rem;
    height: fit-content;
    cursor:pointer;
    font-size:medium;
    font-weight:500;
}
.templateWrapper{
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;
  border-top: 1px solid lightgrey;
  margin: 0;
  overflow: scroll;
  height: 72vh;
  gap: .5rem;
}
.templates{
  background: #fafafa;;
  display: flex;
  align-items: center;
  padding: 1rem;
  position: relative;
  height: 6vh;
  width: 92%;
  font-size: larger;
  border: 1px solid #c4d2d3;
  border-radius: 5px;
}
.templates:hover {
  background-color: #dcdcdc;
}
.Fade-Btn:hover{ 
  opacity: 0.9 !important;
}
.Fade-Btn2:hover{
  color: red;
}
  .searchField{
    width: 18%;
    display: flex;
    position: relative;
    top: 0.5em; 
    padding: 0.5rem;
    margin-left: 8px;
  }
  .search-ico2{
    position: relative;
    top: 6px;
    margin-left: 6px;
  }
.containerRow label{
font-size: medium;
font-weight: 500;
}
.createPipeline .ant-modal-title {
  font-size: 20px !important;
}

.createPipeline .ant-modal-footer{
    display: block !important;
}
.pipelineIdBatch{
    border: .5px solid lightgrey;
    border-radius: 5px;
    margin-right: 1em;
    width: 6%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.newEl:hover{
  background: lightgray;
}
.spanTag{
  color: red;
    position: absolute;
    right: 8px;
    background: white;
    cursor: pointer;
    top: 3px;
}
.select-dropdown{
    width: 100%; 
    height: 2rem;
    border: 1px solid lightgrey;
    border-radius: 5px
}

.PiplinesList-Wrapper .pipeline-card .view-pipeline-btn{
  display: none;
}

.PiplinesList-Wrapper .pipeline-card:hover .view-pipeline-btn{
  display: flex !important;
}
 .Services-select  {
  height: auto !important;
  min-width: 33.8rem !important;
}