/* .code-box-demo .demo {
  overflow: auto;
}
.code-box-demo .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}
.code-box-demo .ant-btn-rtl {
  margin-right: 0;
  margin-left: 8px;
  margin-bottom: 8px;
}
#components-popover-demo-arrow .ant-btn {
  width: 70px;
  text-align: center;
  padding: 0;
} */

.status-container .ant-popover-inner-content{
  padding: 5px !important;
  border-radius: 4px;
  width: 120px;
}

.status-container .ant-popover-content{
  margin-bottom: -18px;
  margin-left: 20px;
}

.status-container .ant-popover-arrow{
  display: none !important;
}

.Prject-Status-Container .ant-popover-arrow{
  display: none !important;
}

.custum-dropdown-status-popover p{
  background-color: rgb(245, 107, 98);
    padding:4px 8px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 5px !important;
    cursor: pointer;
}

.custum-dropdown-status-popover-createtask p{
    background-color: rgb(245, 107, 98);
    padding:4px 8px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    margin-bottom: 5px !important;
    cursor: pointer;
}

.ant-popover-placement-bottomLeft{
  padding-top: 0px !important;
  z-index: 9999;
}

.Prject-Status-Container .ant-popover-inner-content{
  width: 6.5rem !important;
}