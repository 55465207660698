.task-details{
    display: flex;
    flex-direction: column;
    gap: .7em;
}
._WuQ0f{
    text-align: center !important;
}
._3_ygE{
    border-right: 1px solid lightgray !important;
}
._3ZbQT{
    border-right: 1px solid lightgray !important; 
}
/* .ViewContainer{
    width: 10rem;
    position: fixed;
    top: 7rem;
    right: 20rem;
} */
/* .buttonsForDateChange{  
    position: fixed;
    top: 7.5rem;
} */
.btnclass{
    font-size: small;
    font-weight: 500;
}