.scrollingSideBar {
  height: 74vh;
  overflow: scroll;
}
.createuser-height {
height: 92vh;
overflow: hidden;
}
.client-list-sticky .listdiv button{
border-radius: 5px !important;
box-shadow: none !important;
background: aliceblue;
color: #018ee0;
}
.client-list-sticky .listdiv .Active:hover,.client-list-sticky .listdiv .Active:focus{
background: #018ee0;
color: aliceblue;
}
.client-list-sticky .listdiv{
padding: 0.2rem !important;
}
.client-list-sticky{
position: sticky !important;
top: 0 !important;
}
.addclss{
transition: width 0.4s ease-in-out;
border-radius: 5px;
  box-shadow: rgb(255 98 98) 0px 0px 7px 1px;
  border-color: #ff0000;
  border: 1px solid red;
}
.Client-sidebar-wrapper .css-13cymwt-control .css-1dimb5e-singleValue,.css-1jqq78o-placeholder{
font-size: 14px !important;
font-family: 'Avenir Next LT W02' !important;
}
.Client-sidebar-wrapper .css-t3ipsp-control .css-1dimb5e-singleValue, .css-1jqq78o-placeholder{
font-size: 14px !important;
font-family: 'Avenir Next LT W02' !important;
}
