.gray-action-btn{
  height: auto !important;
  padding: 3px 20px !important;
}
/* .chat-box-area{
  
  position: fixed;
  bottom: 32px;
  right: 42px;
  z-index: 111;
  display: flex;
  align-items: center;
} */

.chat-box-area {
  position: fixed;
  bottom: 1px;
  right: 6px;
  z-index: 111;
  display: flex;
  align-items: center;
}
.chatUserAlert{
  margin-right: 10px;
  position: relative;
}
.chatUserAlert img{
  border-radius: 50%;
  width: 35px;
  height: 35px;
}
.chat-box {
  background-color: rgb(1, 142, 224);
  color: #fff;
  padding: 4px 10px;
  border-radius: 10px 10px 1px 10px;
  cursor: pointer;
  font-size: 15px;
}
.chatUserAlert .badge,
.chat-box .badge{
  position: absolute;
  right: -7px;
  top: -7px;
  background: white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  line-height: 18px;
  border: 1px solid blue;
}
.chatUserAlert .badge{
  height: 20px;
  width: 20px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: #018ee0;
  color: white;
}
.accountexpansion {
  width: 290px;
  padding: 18px 15px 15px !important;
  top: 53px !important;
}
.accountexpansion:before {
  content: "";
  display: block;
  position: absolute;
  top: -10px;
  right: 10px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 10px solid #cbd6e2;
}
.accountexpansion:after {
  content: "";
  display: block;
  position: absolute;
  top: -9px;
  right: 11px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 9.5px solid #fff;
}
.user-ico {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #cbd6e2;
  margin-right: 10px;
  float: left;
}
.user-details-box {
  float: left;
}
.user-details-box > span {
  display: block;
}
.user-details-box .username {
  color: rgb(51, 71, 91);
  font-weight: 600;
  font-size: 16px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.user-details-box .useremail {
  color: rgb(124, 152, 182);
  font-size: 12px;
  line-height: 20px;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.user-details-box .userrole {
  color: #0091ae;
  font-size: 14px;
  line-height: 32px;
}
.navAccountMenu-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding-top: 15px;
  width: 100%;
  clear: both;
}
.navAccountMenu-bottom .logout {
  text-align: left;
  width: 50%;
  color: #0091ae;
}
.navAccountMenu-bottom .setting {
  text-align: right;
  width: 50%;
  color: #0091ae;
}
.modal-dialog .modal-title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal-dialog .modal-title svg.close{
  font-size: 15px;
  cursor: pointer;
  padding: 0;
  opacity: 1;
  margin: 0;
}
.user-img-icon{
  position: relative;
}

.user-img-icon .remove-user{
  position: absolute;
  right: -6px;
  top: -3px;
  border: 2px solid #ffffff;
  background: #5b6c97;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  color: white !important;
  padding: 1px;
  display: none;
  cursor: pointer;
  z-index: 9;
}
.user-img-icon:hover .remove-user{
  display: block;
}

.centered-modal.modal-dialog{
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hr-datepicker-btns {
  border: 1px solid #d9d9d9;
  height: 25px;
  border-left: 0;
  border-radius: 0 3px 3px 0;
  overflow: hidden;
}
.hr-datepicker-btns button {
  border-radius: 0;
  background: white;
  border: 0;
  color: gray;
  line-height: 1;
}
.hr-datepicker-btns button+button{
  border-left: 1px solid #d9d9d9 !important;
}
.hr-datepicker-btns button:hover{
  color: #5b6c97;
  background-color: #edf0f4;
}

.no-image-user{
  width: 35px !important;
  height: 35px !important;
  float: left;
  background-color: #add8e6;
  color: #000;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 11px 5px;
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 14px !important;
}

.createuser-border{
  border: 1px solid #018EE0;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 1px;
  height: calc(100vh - 154px);
  overflow-y: auto;
  padding: 5px 5px 5px 5px;
}

.create-user-box{
  padding: 0 15px;
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y);
}