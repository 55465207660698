.custom_card {
    background: rgba(0, 0, 0, .15) !important;
    padding: 0 5px 5px;
    margin: 20px -5px;
    background: rgba(0, 0, 0, .03);
    border: 0;
    box-shadow: none;
    border-radius: 8px !important;
}

.custom_card:hover{
    box-shadow: 0px 0px 11px #00000026; ;
}
.parent_link {
    font-size: 11px;
    height: 25px;
    line-height: 25px;
    padding-left: 5px;
    cursor: pointer;
    outline: none;
    color: #49494980;
}

.parent_link:hover {
    text-decoration: none;
    color: #49494980;
}

.sub-card-heder-section {
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #dedede;
    position: sticky;
    top: 0;
    transition: background 0s;
    margin-bottom: -1px;
    z-index: 20;
    display: flex;
    align-items: center;
    padding: 11px 11px 5px 20px;
    background: #fff;
}

.sub_card_header_name {
    flex-grow: 1;
    min-width: 0;
}

.sub_card_name_title {
    font-size: 11px;
    font-weight: 500;
    align-items: flex-start;
    display: flex;
    opacity: .8;
    color: #7c828d;
    overflow-y: hidden;
    overflow-x: auto;
}

.sub_card_breadcrumb {
    border-radius: 3px;
    border: 1px solid #e9ebf0;
    padding: 3px 6px;
    display: flex;
    align-items: center;
    margin-right: 6px;
}

.breadcrumb_tabs {
    font-size: 11px;
    font-weight: 500;
    line-height: 1;
    color: #7c828d;
    white-space: nowrap;
    cursor: pointer;
    position: relative;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    flex-shrink: 0;
}

.breadcrumb_icon {
    width: 3px;
    height: 7px;
    fill: #7c828d;
    margin: 0 6px;
}

.icon svg {
    display: block;
    width: 100%;
    height: 100%;
}

.custom-box-icon {
    font-size: 12px;
    color: rgb(107, 201, 80);
}


.custom_task_name {
    font-size: 17px;
    font-weight: 500;
    overflow: hidden;
    color: black;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 5px 5px 5px 0px;
}

.custom_task_name:hover {
    color: #08c7e0;
    ;
}

.custom_minus_icon {
    font-size: 13px;
    color: orange;
}

.sub-card-body {
    transition: background 0s;
    border-top: 1px solid #efeef0;
    background: rgb(249, 249, 249);
    border-radius: 0 0 6px 6px;
    position: relative;
}

.sub_card_body_item:not(:last-of-type) {
    border-bottom: 1px solid #dbdbdb;
}

.avatar {
    background-color: #08c7e0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    font-size: 10px;
    font-weight: 400;
    height: 28px;
    color: #fff;
}

.status-badge {
    color: #08c7e0;
    font-size: 11px;
    font-weight: 500;
    line-height: 11px;
    padding: 4px 8px;
    text-transform: uppercase;
    border-radius: 20px;
    display: flex;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 3px #00000042;
}

.start-badge {
    box-shadow: none !important;
    color: #fff !important;
    background-color: rgb(181, 188, 194) !important;
    padding: 5px 12px !important;
    border-radius: 3px !important;
}

.complete-badge{  
background-color: rgb(107, 201, 80) !important;
}

.custom_card{
    width: 100% !important;
}
.custom_card:hover .task_name_group_icons{
    display: flex !important;
}


.activity_card_content span:last-child{
    margin-left: 10px;
    padding-top: 20px;
}

.sub_card_body_item{
    padding: 0px 14px !important;
}

.activity_custom_search{
    padding-top: 7px !important;
}


.activity_custom_search svg{
    top: 19px !important;
}

.activity_custom_search input{
    border-radius: 50px ;
}