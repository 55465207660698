.Selected-Reaction-Wrapper{
    position: relative;
}
.Emoji-Picker-Wrapper{
    position: absolute !important;
    z-index: 1 !important;
}
.Selected-Reaction-Header{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2px;
}
.Selected-Reactions{
    border: 1px solid #5b6c97;
    padding: 0px 5px;
    border-radius: 21px;
}
.Add-Emoji-Icon img{
    border-radius: 0px !important;
    width: 17px !important;
    position: relative;
    top: -3px;
    cursor: pointer;
    opacity: 0.5;
}
.others-reaction-container,
.user-reaction-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}
.user-reaction-container svg{
    opacity: 0.5;
}
.user-reaction-container svg:hover{
    opacity: 1;
    color: red !important;
}
.All-Reactions .add-reaction-icon img{
    height: 0.7rem !important;
    width: auto !important;
    margin: 3px !important;
    padding: 0px !important;
}
.All-Reactions .inner-div{
    display: flex;
    align-items: center;
}
.All-Reactions .add-reaction-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}
.Emoji-Picker-Wrapper-Chats{
    position: fixed !important;
    right: 5rem !important;
    top: 10rem !important;
    /* left: 0px !important; */
  }
.inner-div .Chat-Reaction-Info{
    position: absolute;
    bottom: -2rem;
}