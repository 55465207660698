/* .header {
    background-color: #0085e7;
    padding: 12px 15px;
    width: 100%;
    color: #fff;
    box-sizing: border-box;
}
.logo-txt {
    width: auto;
    float: left;
    font-size: 18px;
    font-weight: 600;
    margin-right: 32px;
} */
/* .nav-bar {
    width: auto;
    float: left;
    margin: 0 16px 0 0;
    padding:0;
}
.nav-item {
    list-style:none;
    display:inline-block;
} */
.navbar{
    padding: .1rem 1rem;
}
.header-navbar> .nav-link {
    text-decoration: none;
    color: #ffff !important;
    font-size: 15px;
    font-family: 'Avenir Next LT W02';
    margin-right: 1.5rem;
    padding: 14px 14px 14px 16px ;
    font-weight: bold;
}

.header-navbar>.nav-item> .nav-link {
    text-decoration: none;
    color: #ffff !important;
    font-size: 15px;
    font-family: 'Avenir Next LT W02';
    margin-right: 1.5rem;
    padding: 14px 14px 14px 16px ;
    font-weight: bold;
}
.navbar-brand{
    color: #ffff !important;
}


.header-ul .dropdown-item {
    margin: 0 20px;
    width: auto;
    color: #ffff;
    padding: 5px 0;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
}
.header-ul .dropdown-item:hover {
    border: 0px solid transparent;
    color: #EBB748;
    background-color: transparent;
}

.button-padd:hover {
    border: 0px solid transparent;
}

.clear{
    clear:both !important;
}
.user-box {
    width: auto;
    float: right;
}

.user-img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    padding: 0;
}
.user-dropdown .dropdown-item{
    padding: 0.25rem 1rem;
    font-size: 15px;
}
.logo-img {
    width: 57px;
    height: 46px;
    cursor: pointer;
    padding-top: 1.5px;
    margin-bottom: 1.5px;
}

.fontawsome-text{
    font-size: 20px;
    color: #fff;
    cursor:pointer;
}
.mar-right-20{
    margin-right: 20px;
}
.pad-fix{
    padding: .0rem .5rem;
    height: calc(0.8em + .75rem + 2px);
}

.search-header{
    width: 300px !important;
    padding: 3.5px 7px 3.5px 30px;
    font-size: 13px;
    height: auto;
}
.header-search-icon{
    position: absolute;
    color: #ccc;
    font-size: 14px;
    left: 9px;
    top: 7px;
}

.divider {
    display: inline-block;
    width: 1px;
    background-color: #fff;
    margin-right: 20px;
    height: 1.5em;
  }

  .button-padd{
   
    border: 0px solid transparent;
    outline: none;
    width: 100%;
    padding: 0px 8px;
    font-size: 15px;
    border-radius: 0;
  }

  .dropdown-divider {
    margin: 15px 20px;
    width: auto;
    border-top: 2px solid #48bdfe;
}

.display-none{
    display: none !important;
}

.pad-left-40{
    padding-left: 40px;
}


.height-48:focus,
.height-48:hover,
.height-48:active {
    background-color:#0180C7;
    padding-top: 0.5px;
    /* padding-bottom: 0.5px;
    padding-right: 25px;
    padding-left: 25px;
    background-color: #fff;

    font-size: 15px;
    font-weight: bold; */
}

.highlight-color{
    background-color:#0180C7;
}
 .height-48  .dropdown-toggle::after { 
     margin-left: 10px;
    top: 24px;
    position: absolute;
} 
.highlight-color  .dropdown-toggle::after { 
    margin-left: 10px;
   top: 24px;
   position: absolute;
} 

@font-face {
    font-family: 'Avenir Next LT W02';
    src: url('../../assets/fonts/Avenir Next LT W02 Medium.ttf') format('truetype');
}

.height-48 .dropdown-menu {
    position: absolute;
    top: 50px;
    box-shadow: none;
    width: 215px;
    border: none;
    border-radius: 0;
    background-color: #007bff;
    padding: 0;    
    z-index: 99999;
}

.highlight-color .dropdown-menu {
    position: absolute;
    top: 50px;
    box-shadow: none;
    width: 215px;
    border: none;
    border-radius: 0;
    background-color: #007bff;
    padding: 0;
    z-index: 99999;
} 

.dropright .dropdown-menu {
    top: 0px !important;
    right: auto;
    left: 100%;
    margin-top: 0;
    /* margin-left: .125rem !important; */
}

.navbar-light{
    height: 50px;
}

.header-ul{

        padding-left: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-bottom: 15px;
        padding-top: 15px;

}
.navbar-nav a:hover {
    /* background-color:#fff; */
}
.mar-right-7{
    margin-right: 7px;
}

.clock-button{
    padding: 1px 7px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 92px;
    margin-right: 8px;
}
.time-style{
    color: #fff;
    font-size: 16px;
    padding: 0px 11px;
}

.clock-red{
    background-color: #f15050;
}
.clock-green{
    background-color: #27cda5;
}
.clockin-pad{
    padding-left: 10px;
    padding-right: 10px;
}
.playstop-button{
    padding: 1px 5px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 0px 11px 11px 0px;
    color: #fff;
    width: 25px;
    border-left: 1px solid;
    cursor: pointer;
}
.playpausesize{
    font-size: 14px;
}
.clockicon-align-green{
    position: relative;
    left: 16px;
    /* margin-right: 25px; */
}
.clockicon-align-red{
    position: relative;
    left: 8px;
    /* margin-right: 15px; */
}


.drop-menu-notificatn{
    background-color: white;
    height: 40rem;
 position: absolute;
}
.content-div{
    padding: 5px 15px 5px 15px;
    font-size: 16px;
    font-weight: 600;
    /* background-image: linear-gradient(45deg, #0180C7, #0a557e); */
    background: rgb(1, 142, 224);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;   
}
    
/* NOTIFICATION POPUP */
.notification-header {
    padding: 8px 15px;
    background: #f0f0f0;
    color: #5b6c97;
    font-weight: 600;
    border-bottom: 1px solid #b3c0d1;
}
.notification-drop, .notification-drop:focus, .notification-drop:hover{
    padding: .375rem .25rem !important;
    height: 34px;
}
.notification-drop::after{
    display: none !important;
    }
    
.notificaton>.dropdown-menu {
    position: absolute;
    top: 37px;
    border: 1px solid #ced3d9 !important;
    border-radius: 0;
    background-color: #ffffff;
    padding: 0 0 7px 0;
    right: calc(-100% - 53px);
    left: -15.9rem;
    min-width: 385px;
    box-shadow: 0 6px 15px #cfcfcf;
    overflow: visible;
    height: calc(100vh - 100px);
}
.notificaton>.dropdown-menu:before {
    position: absolute;
    border-top: 1px solid #fff;
    content: "";
    border-left: 1px solid #fff;
    display: block;
    height: 12px;
    width: 12px;
    top: -6px;
    right: 110px;
    transform: rotate(45deg);
    z-index: 99999999;
    background: rgb(1, 142, 224);
    color: #000;
    margin: auto;
}
      
.notificaton>.dropdown-menu>.dropdown-item {
    margin: 0px auto;
    width: 100%;
    color: #000 !important;
    padding: 5px 27px;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
}
.notificaton>.dropdown-menu>.dropdown-item:hover {
    background-color: #EDF0F4;
}
.notification-btn{
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    padding: 8px;
}
      
.notification-drop.dropdown-toggle.btn.btn-primary{
    background-color: #018ee0 !important;
    border-color: #018ee0!important;
}
.notificationArea {
    height: calc(100% - 75px);
}
.notification-topMenu{
    box-shadow: 0 1px 3px #c3c3c3;
    padding-bottom: 10px;
    z-index: 9;
    position: relative;
}
.notificationFilters{
    display: flex;
    align-items: center;
    padding: 10px;
}

.notification-action  {
    font-size: 13px !important;
}

.notification-action button{
    padding: 6px !important;
    border-bottom: 1px solid lightgray !important;
}

.notifications-hyperlinks a:last-child , .notifications-hyperlinks a:nth-last-child(2){
    border-radius: 30px;
    font-size: 12px;
    text-decoration: none;
    padding: 1px 5px;
    line-height: 18px;
    font-weight: normal;
    display: inline-block;
    margin-left: 2px;
    /* background-color: #5b6c97; */
    color: #fff !important;
}

.notifications-hyperlinks a{
    display: inline-block;
    margin-bottom: 8px;
}

.notifications-hyperlinks a:first-child{
    color: #1890ff !important;
    font-weight: 700;
    padding: 0px !important;
}

.notifications-hyperlinks a:nth-child(3){
    margin: 5px 0px;
}

.notificationFilters .dropdown{
    line-height: 1;
}
.notificationFilters .notification-link,
.notificationFilters .notification-link:hover,
.notificationFilters .notification-link:focus{
    color: #5B6C97 !important;
    padding: 3px 10px;
    background: transparent !important;
    border: 0;
    font-size: 13px;
    box-shadow: none !important;
    margin-right: 10px;
}

.notificationFilters .notification-link.filterBtn,
.notificationFilters .notification-link.filterBtn:hover,
.notificationFilters .notification-link.filterBtn:focus{
    background: #efefef !important;
}
.notificationFilters .notification-link.filterBtn+.dropdown-menu.show{
    padding: 5px 10px !important;
    display: flex;
    flex-wrap: wrap;
    width: 330px;
}
.notificationFilters .notification-link.filterBtn+.dropdown-menu .dropdown-item {
    padding: 0;
    width: 49%;
    display: inline-block;
    font-size: 13px;
    line-height: 1;
    margin: 5px 0;
    background: transparent;
}
.notificationFilters .notification-link.filterBtn+.dropdown-menu .dropdown-item input{
    vertical-align: bottom;
    margin-right: 3px;
}
.notificationArea .about-deal-search{
    padding: 0 10px;
    margin: 0;
}
.notificationArea .about-deal-search input {
    width: 100%;
    margin: 0;
    border-width: 1px;
    outline: 0;
}
.notificationList{
    margin: 0;
    overflow-y: auto;
    height: calc(100vh - 35vh);
}
.notificationList li {
    padding: 15px 10px;
    font-size: 12px;
    color: #000;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.2s linear;
    border-bottom: 1px solid #e3e8ef;
    display: flex;

}
.notificationList li:hover{
    background: #f3f3f3;
    cursor: pointer;
}
.notificationList li .more-icon{
    display: none;
 }


.notificationList li:hover .more-icon{
   display: initial;
}
.notificationList li .notificationIcon{
    margin-right: 8px;
    min-width: 30px;
}
.notificationList li .notificationIcon span{
    min-width: 30px;
    height: 30px;
    background: #5b6c97;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}
.notificationList li .notificationIcon span.checkBox-bg{
    background: transparent;
}
.notificationList li .notificationIcon span.checkBox-bg input{
    cursor: pointer;
}
.notificationList li .notificationTime{
    margin-left: 10px;
    min-width: fit-content;
    font-size: 11px;
    color: gray;
    flex-grow: 1;
    text-align: right;
    display: flex;
    padding: 0 15px;
    justify-content: end;
}

.selectCheck-topMenu{
    margin-right: 15px;
}
.selectCheck-topMenu input[type="checkbox"]{
    vertical-align: middle;
}
.notification-unread-button {
    text-align: right;
    text-decoration: underline;
    color: #5b6c97;
}

.checkedList-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 0;
    font-size: 13px;
}
.checkedList-actions .text-blue {
    color: #0061a7;
    cursor: pointer;
}
.checkedList-actions .text-blue svg{
    color: gray;
    margin-right: 5px;
}
.drop-menu-createnew.dropdown-menu.show {
    border: 1px solid gray;
    width: 225px;
    margin: 0px 50px 0px -175px!important;
}


    
.createnew-drop, .createnew-drop:focus, .createnew-drop:hover{
    height: 34px;
    cursor: pointer !important;
}
.createnew-drop::after{
    display: none !important;
    }
    
    .createnew>.dropdown-menu {
        top: 44px !important;
    box-shadow: none;
    width: 225px;
    border: 1px solid #B0BED0 !important;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 7px 0;
      }
      
      .createnew>.dropdown-menu>.dropdown-item {
        margin: 0px auto;
        width: 100%;
        color: #000 !important;
        padding: 5px 27px;
        font-family: 'Avenir Next LT W02';
        font-size: 15px;
        cursor: pointer;
      }
      .createnew>.dropdown-menu>.dropdown-item:hover {
        background-color: #EDF0F4;
      }
   
      
      .createnew-drop.dropdown-toggle.btn.btn-primary{
        background-color: #018ee0 !important;
        border-color: #018ee0!important;
    }
    .hrmar-top0{
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .notification-box{
        padding: 5px 5px 5px 15px;
    margin: 5px 5px;
    border-radius: 9px;
    border: solid 1px #e2e2e2;
    background: #e2e2e2;
    font-size: 12px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    }

    .notification-count{
        font-size: 12px;
        padding: 0px 0px 0px 5px;
        font-weight: 600;
    }

    .show>.notification-drop.dropdown-toggle {
       color: #fff !important;
    }

    .invertor-drop>.dropdown-toggle{
        margin: 0px 0px;
        width: 215px;
        color: #ffff !important;
        font-family: 'Avenir Next LT W02';
        font-size: 15px;
        padding: 0px 115px 0px 0px;
        background-color: rgb(1, 128, 199) !important;
        border: 0;
}

.invertor-drop>.dropdown-toggle:active ,
.invertor-drop>.dropdown-toggle:focus ,
.invertor-drop>.dropdown-toggle:hover  {
    box-shadow: 0 0 0 0 !important;
    background-color: rgb(1, 128, 199);
    border: 0;
    color: #EBB748 !important;
}


.invertor-drop .dropdown-toggle::after {
    left: 190px;
    top: 7px;
}
.header-ul .sub-list-inverter{
    margin: 0 0px;
    width: auto;
    color: #ffff;
    padding: 5px 0;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
    background-color: rgb(1, 128, 199);
    padding-left: 20px;
}

.header-ul .sub-list-inverter:active ,
.header-ul .sub-list-inverter:focus ,
.header-ul .sub-list-inverter:hover  {
    box-shadow: 0 0 0 0 !important;
    background-color: rgb(1, 128, 199);
    border: 0;  
    color: #EBB748 !important;
}

.pad-mar-fromtodate{
    padding-left: 0px;
    margin-left: -30px;
}
.min-h-150{
    min-height: 150px;
}
.underline-text{
    display: inline-block;
}

.select-all-editColumn{
    display: flex;
    align-items: center;
    padding-top: 15px;
    font-size: 12px;
    font-weight: 300;
}
.notifType{
    color:#6c6868;
    text-transform: uppercase;
    font-size: 11px;
    margin: 0;
}

.custom-dot-icon{
    font-size: 7px;
    vertical-align: inherit;
    margin-right: 3px;
    color: #d70000;
}
.nav-single-item{
    padding-right: 0px !important;
}

.nav-dropdown-item{
    padding-right: 5px !important;
}