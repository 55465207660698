.profile-wrapper{
    height: calc(100vh - 60px);
}

.userprofile-img{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 0px;
    box-shadow: 0 4px 10px #dedede;
}

.profile-wrapper .hrMenuRight .form-control{
    border: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    padding-left: 0;
}

.profile-wrapper .hrMenuRight .form-control:focus{
    box-shadow: none;
    border-color: #5b6c97;   
}

.leftmenu-title{
    font-size: 22px;
    color: #5b6c97;
    font-weight: 600;
    margin: 5px 0 20px;
}
.profile-back-button{
    margin-top: 5px;
    margin-left: 20px;
    border-radius: 27px;
    padding: 3px 14px 3px 14px;
    background-color: #5daff0;
    color: white;
    text-transform: capitalize;
}
.btn-profile-save{
    padding: 16px;
    margin: 24px;
    margin-left: 880px;
    background-color: #5daff0;
    border: 2px solid #5daff0;
    border-radius: 8px;
    color: white;
}
.btn-profile-outer-border{
    background: gray;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
}

.hrMenuLeft {
    height: 100%;
    padding: 15px;
}

.hrMenuRight {
    height: calc(100vh - 160px);
    overflow-y: auto;
}

.hr-tabs-left .nav-item.nav-link,
.hr-tabs-left .nav-item.nav-link.active,
.logout-link{
    padding: 0;
    margin: 0 0 8px;
    border: 0;
    background: white;
    color: gray;
    cursor: pointer;
}
.hr-tabs-left .nav-item.nav-link:hover,
.hr-tabs-left .nav-item.nav-link.active,
.hr-tabs-left .nav-item.nav-link.active:hover,
.logout-link:hover{
    color: #018ee0;
}
.view-icons {
    position: absolute;
    right: 7px;
    top: 11px;
}
.pos-relative {
    position: relative;
}
.pr-25 {
    padding-right: 25px;
}


.setting-txt {
    color: #018ee0;;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px
}
.accordion-button::after {
    width: 0; 
    height: 0; 
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent; 
    
    border-left:7px solid #000; 
    background-image: none;
  }
  .accordion-button:not(.collapsed)::after {
    width: 0; 
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    
    border-bottom: 7px solid #000;
    background-image: none;
  }
  .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: inherit;
    box-shadow: none;
}
.project-deals-height {
    padding-right: 0px;
}
.menu-listbox {
    padding-left: 20px;
}
.menu-listbox > li {
    list-style: none;
    padding: 7px 7px 7px 24px;
    border-radius: 6px;;
}
.accordion-body {
    padding: 0;
}
.menuleft-active {
    background-color: #dde9f6;
}
.search-box {
    position: relative;
    margin-top: 15px;
    margin-bottom: 5px;
}
.search-box > svg {
    position: absolute;
    left: 10px;
    top: 9px;
    font-size: 16px;
    color: #018ee0;
}
.search-box > input {
    border-radius: 6px;
    border: 1px solid #018ee0;
    background-color: transparent;
    padding: 5px 5px 5px 35px;
    width: 100%;
}
.ptb-15 {
    padding: 15px 0;
}
.pl-60 {
    padding-left: 60px;
}
.user-selectbox {
    padding-left: 0;
}
.user-selectbox > select {
    border: 1px solid #c4c4c4;
    padding: 5px;
    border-radius: 4px;
}
.nav-tabs2 {
    padding-left: 15px;
}
.accordion-button::after{
    border-left: 7px solid #9d9d9d;
}
.icon-down-width {
    width: 20px !important;
}
.icon-right-width {
    width: 20px !important;  
}
.personal-profile-icon{
    position: absolute;
    top: 62%;
    right: 15%;
    cursor: pointer;
}

