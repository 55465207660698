.reminder-box {
    background: #f26f56;
    border-radius: 10px;
    font-size: 9px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-right: 5px;
    cursor: pointer;
}
.reminderModalContent input{
    height: 40px;
    font-size: 18px;
    padding: 0;
    border-color: transparent;
    box-shadow: none;
}
.reminderModal-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: whitesmoke;
    border-radius: 0 0 20px 20px;
    border-top: 1px solid #d5d5d5;
}
.reminder-footerIcon{   
    margin-right: 15px;
}
.reminder-assignees{
    display: flex;
    align-items: center;
}
.reminder-assignees .user-img-icon {
    /* z-index: 1; */
}
.reminder-assignees .user-img-icon + .user-img-icon{
    /* margin-left: -7px;
    z-index: 0; */
}
.reminder-assignees .user-img-icon .no-image-user{
    width: 30px;
    height: 30px;
    line-height: 8px;
}
.reminderModal-footer .reminder-footerIcon svg{
    font-size: 18px;
    color: #007bff;
    outline: 0;
}
.reminderUserDropdown .user-ico{
    min-width: 40px;
}

.reminder-attach-dropzone{
    padding: 10px;
}
.reminder-attach-dropzone .dropzone{
    position: relative;
    height: auto;
    padding: 10px;
}
.reminder-notification .header{
    line-height: 1;
    text-transform: uppercase;
    font-size: 13px;
    color: #6d6d6d;
    margin: 8px;
    display: block;
}
.reminder-notification .header .back-arrow{
    width: 10px;
    margin-right: 8px;
    color: #007bff;
    cursor: pointer;
}
.reminder-notification .body {
    padding: 0 0 8px;
}
.reminder-notification .body span {
    display: block;
    margin: 5px 0;
    padding: 0 8px;
    cursor: pointer;
}
.reminder-notification .body span:hover{
    background: #efefef;
    color: black;
}
.reminder-notification .popover-body{
    min-width: 200px;
}
.reminder-notification .footer{
    padding: 8px;
    border-top: 1px solid #cccccc;
    line-height: 1;
    text-align: right;
    color: #5b6c97;
    font-weight: 500;
}
.reminder-notification .footer span{
    cursor: pointer;
}
.reminder-notification .btn-done{
    background: #007bff;
    display: block;
    width: 100%;
    color: white;
    padding: 4px;
}

.reminder-modal .modal-content{
    height: 38vh !important;
}

.common_reminder_box img{
    padding: 0px !important;
    border-radius: 50% !important;
}
.reminder-modal .modal-content {
    text-align: center;
    height: auto!important;
    border-radius: 8px !important;
}
.reminder-duedate-btn{
    position: relative;
    top: 25px;
    float: right;
}
.reminder-footerIcon .ant-picker{
    border-radius: 13px;
}

