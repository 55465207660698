.btn-gray {
    font-weight: 500;
    background-color: #e5e8ec;
    border-radius: 3px;
    padding: 6px 14px;
    color: #5b6c97;
}
.btn-gray-active {
    border: 2px solid #b0bed0;
    background-color:	#F4F7FB;
    border-radius: 6px;
    padding: 5px 14px;
}

.filter_icon{
    color: #576891;
}

.filter_icon-active{
    color: #576891;
}

.pad-r0{
    padding-right: 0px;
}

.pad-l0{
    padding-left: 0px;
}
 .nycButton-head,
.nycButton-head:focus,
.nycButton-head:hover,
.nycButton-head:active {
  
color: #007bff;
  box-shadow: none;
  border: 0px;
  padding-bottom: 0px;
  background-color: transparent;
  padding: 5px 0 0 0;
  font-size: 19px;
  font-weight: 600;
  font-family: 'Avenir Next LT W02';
}

.nycText{
    color: #007bff;
    font-size: 19px;
  font-weight: 600;
  font-family: 'Avenir Next LT W02';
}

.pad-0{
    padding-left: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
}
.popover-body {
    padding: 0px;
}
.pad-top-5{
    background-color: #fff;
    /* padding-top: 5px; */
    padding-bottom: 11px;
    /* border-bottom: 2px solid #B0BED0; */
    margin-bottom: 15px;
}

.float-right{
    float: right;
}



.pipeline-text,
.pipeline-text:focus,
.pipeline-text:hover
 {
    width: 200px;
    display: flex  !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important; 
    
    padding-right: 25px !important;
    padding-left: 25px !important;
    background-color: #fff !important;
    color: #5b6c97 !important;
    border: 1px solid #B0BED0 !important;
    font-size: 15px !important;
    font-weight: bold !important;
    border-radius: 9px !important;
    box-shadow: none !important;
}
.pipeline-text:active{
    background-color: #fff !important;
    color: #5b6c97 !important;
    border: 1px solid #B0BED0 !important;
    box-shadow: none !important;
}
.show>.btn-primary.dropdown-toggle {
    color: #5b6c97;
    border: 1px solid #B0BED0;
    background-color: transparent !important;
    box-shadow: none !important;
    border-radius: 9px !important;
}

.dropdown-menu.show{
    width : 125px !important;
}

.dealsToggle .btn-primary.dropdown-toggle {
    color: #fff;
    text-align: left;
    border: 1px solid #B0BED0;
    background-color: #007bff !important;
    box-shadow: none !important;
    border-radius: 5px !important;
}

.pipeline>.dropdown-menu {
    position: absolute;
    top:-2px !important;
    box-shadow: none;
    width: 200px !important;
    border: 1px solid #B0BED0 !important;
    border-radius: 6px;
    background-color: #ffffff;
    font-family: 'Avenir Next LT W02';
    max-height: 475px;
    overflow-y:auto ;
    padding: 7px 0;
}

.pipeline>.dropdown-menu hr{
    border: 0;
    margin-top: 0;
    margin-bottom: 5px;
}
.mar-right-20{
    margin-right: 20px;
}
.view-list-setting {
    width: 42px;
    float: left;
}
.pipeline>.dropdown-menu>.dropdown-item {
    margin: 0px auto;
    width: 100%;
    /* color: #007bff !important; */
    color: #000 !important;
    padding: 5px 27px;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
}
.pipeline>.dropdown-menu>.dropdown-item:hover {
   
    
    background-color: #EDF0F4;
}

.listUI>.dropdown-menu {
    position: absolute;
    top: -2px !important;
    box-shadow: none;
    width: 180px;
    color: #5b6c97;
    border: 1px solid #B0BED0 !important;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 7px 0;
}

.listUI>.dropdown-menu>.dropdown-item {
    margin: 0px auto;
    width: 100%;
    color: #000 !important;
    padding: 5px 13px;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
    display: block;
}
.listUI>.dropdown-menu>.dropdown-item:hover {
 
    color: #000 !important;
    background-color: #EDF0F4;
}
.clear{
    clear: both;
}
.action>.dropdown-menu {
    position: absolute;
    top: -2px !important;
    box-shadow: none;
    width: 225px;
    border: 1px solid #B0BED0 !important;
    border-radius: 6px;
    background-color: #ffffff;
    padding: 7px 0;
}

.action>.dropdown-menu>.dropdown-item {
    margin: 0px auto;
    width: 100%;
    color: #000 !important;
    padding: 5px 27px;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
}
.action>.dropdown-menu>.dropdown-item:hover {
   
    color: #000 !important;
    background-color: #EDF0F4;
}


.action-text,
.action-text:focus,
.action-text:hover,
.action-text:active{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    border-radius: 9px !important;
    display: flex !important;
    font-family: 'Avenir Next LT W02';
    width: 135px;
    padding-right: 25px !important;
    padding-left: 25px !important;
    background-image: url('../../assets/images/btn-gray-bg.png');
    background-repeat: repeat-x;
    color: #5b6c97  !important;
    border: 1px solid #B0BED0 !important;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 34px;
}

.listUI-text,
.listUI-text:focus,
.listUI-text:hover,
.listUI-text:active {
    width: 85px;
    display: flex !important;
    padding-top: 5px !important;
    padding-bottom: 2px  !important;
    padding-right: 25px !important;
    padding-left: 14px !important;
    background-color: #fff !important;
    color: #5b6c97 !important;
    border: 1px solid #B0BED0 !important;
    font-size: 15px !important;
    font-weight: bold !important;
    height: 34px !important;
    border-radius: 9px !important;
    box-shadow: none !important;
}

.pad-top-15{
    padding-top: 14px;
}

.top-botton-pad{
    float: right;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-family: 'Avenir Next LT W02';
    width: 135px;
    font-size: 16px !important;
    font-weight: bold !important;
    /* border-radius: 9px !important; */
  
}
.top-botton-pad-hr{
    float: right;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    font-family: 'Avenir Next LT W02';
    width: max-content;
    font-size: 12px !important;
    font-weight: bold !important;
}

.blue-background3D{
    /* background-image: url('../../assets/images/btn-gray-bg.png'); */
    background-repeat: repeat-x;
    height: 38.5px;
}

.text-size-small{
    font-size: 15px;
    font-family: 'Avenir Next LT W02';
    font-weight: 400;
}
.pipeline-toggle {
    position: relative;
    text-align: left;
}
.pipeline-toggle::after{
    right: 10px;
    position: absolute;
    top: 13px;
    font-size: 24px;
}

.action-arrow::after{
    right: 20px ;
}

.popover {
    border: 1px solid #B0BED0 !important;
    border-radius: 6px;
}
.nycvotton-icon{
    color:#007bff;
    font-size: 24px !important;
    position: absolute;
    margin-left: 7px;
    cursor: pointer;
}
.falist-fontsize{
    font-size: 22px;
    color: #4F71AF;
}
.nyc-popup-pad {
    padding: 7px 0;
    margin: 0;
}
.nyc-popup-pad li {
    padding: 8px 15px;
    font-family: 'Avenir Next LT W02';
    color: #000;
}
.nyc-popup-pad li:hover {
    background-color: #EDF0F4;

}
.list-style2{
    font-weight: bold;
    font-family: 'Avenir Next LT W02';
    padding: 4px;
    color: #5B6C97;
    margin: 0 7px;
    font-size: 15px;
    cursor: pointer;
}
.list-group-text{

    color:#000;
    font-family: 'Avenir Next LT W02';
    font-size: 15px;
    cursor: pointer;
    padding: 5px 1.75rem 5px 42px !important;
}
.list-group-text:hover {
    background-color: #EDF0F4;
}
.pipeline-list {
    display: block;
    padding: 5px 25px;
    font-size: 15px;
}
.create-dealbox{
    padding-top: 8px;
    width: 325px;
    float: right;
}

.create-dealbox2{
    float: left;
    padding-right: 15px;
    padding-bottom: 4px;
}
.create-dealbox3{
    float: left;
}
.pipeline-dealbox{
    padding-top: 14px;
    width: 361px;
    float: right;
}

.kanban-img{
    width: 22px;

}

.pad-l20{
    padding-left: 20px;
}
.exportmodal-width{
    max-width: 300px;
    margin: 6rem auto;
}
.importmodal-width{
    max-width: 350px;
    margin: 6rem auto;
}
.pipeline-task-status{
    padding: 2px 10px 2px 55px;
}
.pipeline-task-status svg{
    font-size: 11px;
    vertical-align: middle;
    margin-right: 5px;
}









/* Job Header Css */


.edit-dealbox{
    padding-top: 10px;

}

.edit-dealbox-button{
    float: left;
    padding-right: 15px;
}
.job-action-box{
    float: left;
}
.jobhead-box{
    padding: 5px 0;
}
.jobheadmain-box{
    padding: 14px 0px 8px;
}

.job-top-logo{
    width: 25px;
}
.job-top-text{
    font-size: 11px;
    color: #5B6C97 ;

}
.job-subheader-box{
    background-color: #fff;
    /* padding-top: 5px; */
    /* padding-bottom: 11px; */
    border-bottom: 2px solid #B0BED0;
    margin-bottom: 12px;
}
.job-back-icon{
    color:#425C70;
    font-size: 20px;
    cursor: pointer;
}

.jobnumber-heding-text{
    padding-left: 60px;
    color:#0180C7;
    font-size: 20px;
}

.jobheader-folder-icon{
    font-size: 22px;
    color: #5b6c97;
    margin-left: 20px;
    
    cursor: pointer !important;
}

.job-icons-box{
    padding: 12px 0px;
}

.padl-32{
    padding-left: 32px;
    display: flex;
    justify-content: end;
}

/* .pad-12{
    padding: 12px;
} */
.pad-t-b-15{
    padding-top: 15px;
    padding-bottom: 15px;
}
.job-rightHead-box{
    float: right;
    font-size: 22px;
    color: #8099C6;
    padding-left: 15px;
}

.mar-r-l-30 {
    margin-left: 30px;
    margin-right: 30px;
}

.dropdown-toggle::after{
    font-size: 21px !important;
}
.create-dealbox .dropdown-menu.show{
    width : 20px !important;
}
.create-dealbox .dropdown-menu{
    min-width : 9.4rem !important;
}
.create-dealbox .dropdown-menu .btn{
    padding: 3px !important;
}
.rotate-refresh-icon {

    /* margin:-60px 0 0 -60px; */
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@-moz-keyframes spin { 
    100% { -moz-transform: rotate(360deg); } 
}
@-webkit-keyframes spin { 
    100% { -webkit-transform: rotate(360deg); } 
}
@keyframes spin { 
    100% { 
        -webkit-transform: rotate(360deg); 
        transform:rotate(360deg); 
    } 
}
.Action-Btn-Ant{
    font-family: 'Avenir Next LT W02' !important;
    font-size: 16px !important;
    font-weight: bold !important;
}
.Action-Btn-Ant-Dropdown{
    font-size: 15px;
    margin-left: 9px;
    position: relative;
    top: 1px;
}
.ant-dropdown-menu-item{
    border-bottom: 2px solid transparent;
    /* border-image: linear-gradient(90deg, rgba(146,146,146,1) 0%, rgba(255,255,255,1) 0%, rgba(189,189,189,1) 50%, rgba(255,255,255,0.9249824929971989) 100%); */
    border-image-slice: 1;
    width:100%;
}
.Bold-Highlited-Word{
    color: #007bff !important;
    font-weight: bold !important;
}

.ant-select-selector span{
    font-weight: 400 !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px !important;
}

.create-dealbox3 .Actions-btn-subheader{
    padding: .42rem .75rem !important;
}
.container-box .createdeal-headpadding{
    margin-bottom: 0px !important;
}
.req-btn-wrapper{
    display: flex;
}
.create-dealbox2 .css-13cymwt-control{
 border: 1px solid #018ee0 !important;
}
.create-dealbox2 .css-1dimb5e-singleValue{
    color: #007bff !important;
    font-weight: bold !important;
    font-family: 'Avenir Next LT W02' !important;
}

.Actions-btn-subhdr{
    position: absolute;
    right: 0.8rem !important;
    width: max-content;
}
.Action-Btn-Ant{
    position: relative;
}
.Task-Subheader{
    margin-bottom: 0px !important;
}