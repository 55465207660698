.headerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.ag-react-container{
    width: 100%;
}

.clearBtn{
    background: none !important;
    border: none !important;
    cursor: pointer !important;
    padding: 0px !important;
}


.showClearMenuicon{
    visibility: hidden;
}

.showClearMenuicon{
    visibility: visible !important;
    position: absolute;
    bottom: 11px;
    right: -5px;
    font-size: 12px !important;
    color: rgb(250, 43, 43);
}

.clearBtn i{
    position: absolute;
    bottom: 10px;
    right: 6px;
    color: rgb(250, 43, 43);
}
.mainContaner{
    display: flex;
    justify-content: space-between;
}

.showMenu{
    position: relative;
}

.showMenuicon{
    font-size: 20px !important;
    margin-top: 2px !important;
}

/* .showMenu:hover .showMenuicon{
    visibility: visible;
} */

