.Add-task-Wrapper .Task-Modal-Body-Wrapper .ant-select{
    height: 32px !important;
    padding: 0px !important;
    /* border: none !important;
    box-shadow: none !important; */
} 
.Add-task-Wrapper .Task-Header .ant-select{
    height: 25px !important;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
}
.Add-task-Wrapper .Task-Info-Wrapper .ant-select-selector{
    height: 100% !important;
    box-shadow: none !important;
    min-width: 10rem !important;
    /* border: 0.1px solid #cfcfcf !important; */
    opacity: 0.85 !important;
}
.Add-task-Wrapper .Task-Header .ant-select-selector{
    height: 25px !important;
    box-shadow: none !important;
    min-width: 10rem !important;
    border: 0.1px solid #cfcfcf !important;
    opacity: 0.85 !important;
}
.Add-task-Wrapper .ant-select-selector:active{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .ant-select-selector:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.Add-task-Wrapper .ant-select-arrow{
    display: none !important;
}
.Add-task-Wrapper .Task-Modal-Body-Wrapper .ant-select-selection-search input{
    height: 16px !important;
}
.Add-task-Wrapper .Task-Header .ant-select-selection-search input{
    height: 16px !important;
}
.Add-task-Wrapper .ant-select-selection-item{
    font-size: 12px !important;
    font-weight: 500 !important;
}
.dropdown-create-task .ant-select-item-option-content{
    font-size: 13px !important;
    font-weight: 500 !important;
}

.Add-task-Wrapper .ant-modal{
    top: 75px !important;
    right: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.Add-task-Wrapper .ant-modal-content{
    width: 94vw !important;
    height: auto !important;
    max-height: 85vh !important;
}

.Add-task-Wrapper .ant-modal-content .ant-modal-close {
    top: 4px !important;
}
.Add-task-Wrapper .ant-modal-content .ant-modal-close svg{
    fill: black !important;
    width: 13px !important;
    height: 13px !important;
}

.Add-task-Wrapper .ant-modal-header{
    /* border-bottom: 1.2px solid rgb(13, 13, 13) !important; */
    border-radius: 0px !important;
    border-top-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    margin: 0px !important;
    padding: 5px !important;
}
.Add-task-Wrapper .ant-modal-footer{
    margin: 0px !important;
    padding: 5px !important;
}

.Add-task-Wrapper .ant-modal-body{
    padding: 1rem 2rem !important;
}
.Add-task-Wrapper .task-details-wrapper{
    height: 70vh;
    overflow: scroll;
    border-right: 0.1px solid rgb(206, 206, 206);
    font-size: 12px;
    font-weight: 500;
}
.Add-task-Wrapper .task-details-wrapper .form-group{
margin-bottom: 0.2rem !important;
}
.Add-task-Wrapper .add-task-info .row{
    margin: 1px -15px !important;
}

.dependecy-modal{
    z-index: 999999 !important;
}

.create-task-field-label{
    display: flex;
    align-items: center;
}

.milestone-name-input:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
}

.due-date-range .rdrDefinedRangesWrapper{
    width: 150px !important;
}
.rdrStaticRangeLabel{
    width: 9rem !important;
}
.create-task-datepicker{
    top: 12.2rem !important;
}
.create-task-datepicker .ant-popover-content{
    width: 29rem !important;
}
.create-task-datepicker .ant-popover-inner{
    /* position: relative !important; */
    top: 0px !important;
}

.create-task-time_est_fields .time_unit{
    position: absolute;
    right: 6px;
    top: 8px;
    font-size: 12px;
}
/* New Task Modal CSS */
.Add-task-Wrapper .ant-modal-body{
    padding: 0px !important;
    margin: 0px !important;
}
.Add-task-Wrapper .ant-modal-content{
    overflow: hidden !important;
}
.Add-task-Wrapper .ant-modal-close,.Add-task-Wrapper .ant-modal-footer{
    display: none !important;
}
.transitionCommentPopup .ant-popover-content{
    width: 470px !important;
}
.transitionCommentPopup .ant-popover-content .ant-popover-inner{
    padding: 1rem 1rem 0.5rem;
}
.transitionCommentPopup .ant-popover-inner-content{
    width: 425px !important;
}
.transitionCommentPopup .ant-popconfirm-message-text{
    width: 100% !important;
}

.Task-Modal-Wrapper{
    display: flex;
    height: 80vh;
    flex-direction: column;
    color: #5a6c97;
    font-size: 12px;
    font-family: "Avenir Next LT W02";
}
.Task-Modal-Wrapper .Task-Header{
    display: flex;
    flex-direction: row;
    height: 6% !important;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    border-bottom: 1px solid #353434;
    background-color: #edf0f4;
}
.Task-Modal-Wrapper .Task-Header-Two{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 14% !important;
    width: 100%;
    border-bottom: 1px solid #353434;
}
.Add-dependecny-Root-Wrappersss .ant-modal-wrap{
    z-index: 9999 !important;
}
.Task-Modal-Wrapper .Task-Header-Two .Text-Center{
    text-align: center;
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
}
.Task-Modal-Wrapper .Task-Header-Two .ant-select .Icon{
    cursor: pointer;
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
    border: 1px dashed #cecece !important;
    padding: 2px;
    justify-content: center;
}
.Task-Modal-Wrapper .Task-Header-Two .ant-select-selector{
    box-shadow: none !important;
}
.Add-task-Wrapper .Task-Header-Two .ant-select-selection-search input{
    height: 32px !important;
}
.Add-task-Wrapper .Task-Header-Two .ant-select{
    height: 34px !important;
    padding: 0px !important;
    border: none !important;
    box-shadow: none !important;
}
.Add-task-Wrapper .Task-Header-Two .ant-select-selection-item{
    padding-inline-end: 5px !important;
}
.Add-task-Wrapper .Task-Header-Two .ant-select-selection-item .Icon svg{
    opacity: 1 !important;
}
.Task-Modal-Wrapper .Task-Modal-Body{
    height: 100%;
    width: 100%;
    overflow: hidden;
}
.Task-Modal-Wrapper .Task-Modal-Body-Wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80%;
}
.Task-Modal-Wrapper .col,.Task-Modal-Wrapper .row{
    /* margin: 0px !important; */
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    padding: 0px !important;
}
.Task-Modal-Wrapper .margin-15{
    margin: 15px 0px 0px 0px !important;
    padding: 0px !important;
}
.Task-Modal-Wrapper .Task-Modal-Body-Child1{
    display: flex;
    flex-direction: row;
    width: calc(100% - 5rem);
    height: 100%;
}
.Task-Modal-Wrapper .Task-Modal-Body-Child2{
    display: flex;
    flex-direction: column;
    width: 5rem;
    font-size: 12px;
    height: 100%;
}
.Task-Modal-Wrapper .Task-Info-Wrapper{
    /* overflow-y: scroll;
    overflow-x: hidden; */
    padding: 0px 34px 8px 34px !important;
    height: 100%;
    border-right: 1px solid #e6e6e6;
}
.Task-Modal-Wrapper .Task-Activity-Wrapper{
   overflow-y: scroll;
   overflow-x: hidden;
   padding: 0px !important;
   border-right: 1px solid #e6e6e6;
   height: 100%;
}
.Task-Info-Wrapper .Task-Name{
    height: 5rem;
    font-size: 2rem;
    color: #5b6c97;
}
.Task-Info-Wrapper .Custom-Field-Row{
    display: flex;
    align-items: center;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}
.Task-Info-Wrapper .SupervisorBtn{
    border: none !important;
    justify-content: flex-start !important;
    box-shadow: none !important;
}
.Task-Info-Wrapper .ant-tabs-tab-btn{
    font-size: 1rem !important;
    font-weight: 600;
}
.Task-Modal-Body-Child2 .Task-Sidebar{
    display: flex;
    flex-direction: column;
    font-weight: 600;
    align-items: center;
    padding-top: 15px;
}
.Task-Modal-Body-Child2 .Task-Sidebar .SidebarBtns:hover{
   color: #4096ff;
   cursor: pointer;
}
.Task-Info-Wrapper .SupervisorBtn:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
    color: black !important;
}
.Task-Modal-Wrapper .Task-Activity-Wrapper .Activity-Header{
    display: flex;
    width: 100%;
    height: 10%;
    padding: 5px 10px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
}
.Task-Modal-Wrapper .TimePicker-Estimate{
    border: none;
}
.Task-Modal-Wrapper .TimePicker-Estimate:hover{
    background-color: rgba(0, 0, 0, 0.06) !important;
}
.Task-Modal-Wrapper .Task-Activity-Wrapper .Activity-Header .header{
    font-size: 1rem;
    font-weight: 600;
}
.Task-Modal-Wrapper .Task-Activity-Wrapper .Activity-Body{
    display: flex;
    height: 75%;
    width: 100%;
    /* overflow-y: scroll; */
    overflow: auto;
    background-color: #edf0f4;
}
.Task-Modal-Wrapper .TaskNameWrapper{
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 0;
    background: white;
    z-index: 1;
    border-bottom: 1px solid #e6e6e6;
}
.dropdown-create-task .rc-virtual-list-scrollbar{
    width: 4px !important;
}

.add-dependency-wrapper-div{
    display: flex;
    padding: 1rem;
    /* margin-top: 2rem;
    border-top: 1px solid lightgray; */
    /* height: 40vh; */
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
}

.add-dependency-wrapper-div .dependency-header{
    display: flex;
    flex-direction: row;
    height: 10% !important;
    width: 100%;
    /* background-color: aqua; */
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 10px;
    border-bottom: 1px solid #e6e6e6; */
}

.dependency-modal-body-wrapper .Custom-Field-Row{
    display: flex;
    align-items: center;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
    overflow-y: auto;
}
.Add-task-Wrapper{
    z-index: 1000 !important;
}
.Add-dependecny-Root-Wrapper .ant-modal-wrap{
    z-index: 99992 !important;
}
.dependency-modal-body-wrapper .ant-select{
    height: 34px !important;
    box-shadow: none !important;
    border: none !important;
}
.dependency-modal-body-wrapper .ant-select-selector{
    position: relative !important; 
    top: -6px !important;
    border: 1px solid #eaeaea !important;
}
.dependency-modal-body-wrapper .ant-select-selection-search input{
    height: 32px !important;
}
.dependency-modal-body-wrapper .ant-select-arrow{
    display: none !important;
}
.waitingOndiv{
    border: 1px solid #c9c6c1;;
    width: 98%;
    border-radius: 6px;
}
.renderTasksDiv{
    padding: 1rem;
    max-height: 9rem;  
  overflow-y: auto;  
  /* border: 1px solid #ddd;   */
  border-radius: 4px; 
}
.innerRendertaskdiv{
    height: auto;
    display: grid;
    background: aliceblue;
    border-radius: 5px;
    /* text-indent: 1rem; */
    margin-top: .2rem;
    align-items: center;
} 
.innerRendertaskdiv:hover{
    background: #dad8d8;
}
.dropdownForWaiting{
    margin-bottom: 10px;
}
.Add-dependecny-Wrapper .ant-modal-footer{
display: block !important;
}
/* .Activity-Comment-Body {
    height: 15% !important;
} */
.Activity-Comment-Body .ant-collapse, .Activity-Comment-Body .ant-collapse-content, .Activity-Comment-Body .ant-collapse-item{
    height: 100% !important;
}
.Activity-Comment-Body .ant-collapse-content textarea:focus{
   box-shadow: none !important;
}
.Create-Task-TimePicker .ant-select .ant-select-selector{
    height: 28px !important;
}
.Create-Task-TimePicker .ant-popover-inner-content{
    width: 16rem !important;
}
.Create-Task-TimePicker .ant-select-selection-search-input{
    height: 22px !important;
}
.Create-Task-TimePicker .ant-select-arrow{
    display: none !important;
}
.Create-Task-TimePicker{
    top: 12.2rem !important;
}
.displayNone{
    display: none !important;
}
.taskStatusCustom{
    cursor: pointer;
    min-width: 9.2rem;; 
    border-radius: .7rem;
    border: 1px solid gray; 
    display: flex;
    align-items: center;
    justify-content: center;
}
.taskStatusCustom .statusButton{
    background: transparent;
    cursor: pointer;
    border: none;
    border-radius: .5rem;
    font-size: medium;
    width: 73px;
}
.taskStatusCustom .statusButtonView{
    width: -webkit-fill-available;
    border: none;
    border-radius: inherit
}
.Create-Task-TimePicker .ant-select-selection-item{
    padding-inline-end: 5px !important;
}

.create-task-select .ant-select-item{
    padding: 5px !important;
}
.play-stop-button{
    margin-left: 10px;
    font-size: 17px;
}
.Create-Task-TimePicker-Class{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Create-Task-TimePicker-Class .anticon{
    height: 35px !important;
    width: 35px !important;
    border: 1px dashed #cecece !important;
    /* padding: 1px !important; */
    border-radius: 50% !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.Create-Task-TimePicker-Class svg{
    height: 20px !important;
    width: 20px !important;
}
.Create-Task-TimePicker-Class .remove-icon svg{
    height: 11px !important;
    width: 11px !important;
    position: relative;
    left: 8px;
    z-index: 11;
}
.Create-Task-TimePicker .ant-popover-arrow::before{
    background-color: #fcfcfc !important;
}
.Create-Task-TimePicker .ant-popover-content .ant-popover-inner{
    border-radius: 20px !important;
    padding: 1.2rem !important;
    background: #f6f6f6 !important;
}
.Task-Modal-Wrapper .ant-collapse-header-text , .Activity-Comment-Body .ant-collapse-header-text{
    color: #5a6c97 !important;
    font-size: 12px !important;
    font-weight: 700 !important;
}
.Add-task-Wrapper .Task-Header-Two .statusDropdownBlueprint .ant-select{
    border: 1px solid lightgray !important;
    min-width: 9rem;
    max-width: 15rem;
    width: auto;
    height: 25px !important;
}
.Add-task-Wrapper .Task-Header-Two .statusDropdownBlueprint .ant-select-selector{
    height: 23px !important;
    color: #5a6c97 !important;
}
.Add-task-Wrapper .Task-Header-Two .statusDropdownBlueprint{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex-direction: row;
}
.Add-task-Wrapper .Task-Header-Two .statusDropdownBlueprint .ant-btn span{
    display: inline-block;
    width: 7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.Add-task-Wrapper .Task-Header-Two .statusDropdownBlueprint .ant-select-selector input{
    height: 23px !important;
}
.Add-task-Wrapper .Task-Header-Two .table-dropdown .ant-select-clear{
    top: 5% !important;
    right: 20% !important;
    color: #5b6c97 !important;
}
.Add-task-Wrapper .Task-Header-Two .table-dropdown .ant-select-clear svg{
    width: 17px !important;
    height: 15px !important;
}

.Add-task-Wrapper .Subtask-Table .ant-select-selector{
    height: 32px !important;
    box-shadow: none !important;
    min-width: 10rem !important;
    /* border: 0.1px solid #cfcfcf !important; */
    opacity: 0.85 !important;
}
.Add-task-Wrapper .Subtask-Table .ant-select-selection-search input{
    height: 32px !important;
}
.Task-Header-Two .project_status_custom{
    max-width: 8rem !important;
    width: auto !important;
    padding: 2px 0.5rem!important;
}
.Task-Complete-Popover{
    z-index: 100000 !important;
}
.Task-Status-Dropdown .ant-select-selector{
    /* background-color: gray;
    color: white; */
    width: 9rem !important;
    align-items: center !important;
    background-color: transparent !important;
    display: flex;
    justify-content: center !important;
}
.Task-Status-P{
    display: block;
    text-indent: 9px;
    border-radius: 5px;
    cursor: pointer;
    height: 24px !important;
    font-size: small !important;
    font-weight: 600 !important;
    margin-bottom: 5px !important;
    overflow: visible !important;
}
.Task-Status-P:hover{
    border: 1px solid rgb(178, 178, 178) !important;
}
.Task-Status-Div{
    display: flex;
    flex-direction: column;
    max-height: 20rem;
    overflow: scroll;
}
.Status-Wrapper-Class{
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;
}
.Duplicate-Modal {
    top: 115px !important;
}
.Duplicate-Modal .Duplicate-modal-btns{
    width: 100% !important;
    border: none !important;
    display: flex !important;
    justify-content: flex-start !important;
    box-shadow: none !important;
    font-weight: 700;
    color: #5b6c97;
}
.Duplicate-Modal .ant-popover-title{
    font-size: 15px !important;
}
.Duplicate-Modal .ant-popover-inner-content{
    max-width: 250px !important;
    min-width: 180px !important;
    width: auto !important;
}
.stickyDivWrapper{
    height: 75%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}
.stickyDivWrapper .sticky-container .ant-tabs-nav{
    position: sticky !important;
    background-color: white !important;
    top: -1 !important;
    z-index: 999 !important; 
    padding-bottom: .4em !important;
}
.Task-Flow-View-Popup .ant-popover-content,
.Task-Flow-View-Popup .ant-popover-inner,
.Task-Flow-View-Popup .ant-popover-inner-content{
    width: 100% !important;
    height: 100% !important;
}
.Task-Flow-View-Popup .ant-popover-inner-content{
    width: 100% !important;
    height: 92% !important;
}
.Task-Flow-View-Popup .ant-popover-title{
    color: #65759d;
}
.view-flow-icon {
    width: 16px;
    height: 16px;
    fill: #7a7a7a !important;
}
.Look-Up-Container .Look-Up-Div{
    display: flex;
    flex-direction: column;
    max-height: 20rem;
    min-height: 20rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.Look-Up-Container .ant-input-group-addon{
    border: 1px solid #d9d9d9 !important;
}

.Look-Up-Container .ant-input-affix-wrapper-focused:focus-within, .Look-Up-Container .ant-input-affix-wrapper-focused:hover{
    border-color: #c9c6c1 !important;
    box-shadow: 0 0 0 2px rgb(255 255 255 / 10%) !important;
}

.Look-Up-Container .ant-input-group-addon .ant-btn:hover{
    border-color: #c9c6c1 !important;
}

.Look-Up-Container .Look-Up-P:nth-child(odd) .ant-btn{
    height: 20px !important;
    box-shadow: none !important;
    background: #ffffff !important;
    border: 1px solid #cbcbcb !important;
}
.Look-Up-Container .Look-Up-P:nth-child(even) .ant-btn{
    height: 20px !important;
    box-shadow: none !important;
    background: #f5f5f5 !important;
    border: 1px solid #cbcbcb !important;
}

.Look-Up-P:nth-child(even) {
    background-color: #f5f5f5; /* Light gray color for even rows */
}

.Look-Up-P:nth-child(odd) {
    background-color: #ffffff; /* White color for odd rows */
}

.Look-Up-Container .Look-Up-P{
    margin-bottom: 2px !important;
}

.Look-Up-P{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
    height: 30px !important;
    font-size: small !important;
    font-weight: 600 !important;
}
.Look-Up-Container .Look-Up-P button{
    border: none !important;
}

.Look-Up-Container .ant-popover-inner-content{
    width: 400px !important;
}
.Look-Up-Container .ant-btn-icon{
    display: flex !important;
}

.Look-Up-Container .Look-Up-Header{
    position: sticky;
    top: 0px;
    z-index: 111;
    background: #fff;
    margin-bottom: 10px;
}
.Look-Up-Container .Look-Up-Title{
    font-weight: 700;
    font-size: 15px;
    opacity: 0.8;
}
.Task-Activity-Wrapper .Actvity-Items-Wrapper{
    padding: 16px 2px !important;
}
.Add-task-Wrapper .Group1-Card{
    padding: 4px 13px !important;
    border-radius: 19px !important;
    margin: 0px 5px;
}

.Task-Modal-Body-Child1 .look-up-tile-container {
    display: flex;
    align-items: center;
    gap: 12px;
}
.Task-Modal-Body-Child1 .look-up-tile-wrapper {
    padding: 4px 10px;
    border: 1px solid #bdb8b8;
    border-radius: 15px;
    min-width: 7rem;
    max-width: 15rem;
    width: auto;
    min-height: 28px;
    color: #000;
    position: relative;
}
.look-up-tile-container .look-up-tile-wrapper .labeltext label{
    position: absolute;
    top: -11px;
    left: 6px;
    font-size: 11px;
    z-index: 1;
    color: #5b6cb2;
    background: white;
    padding: 0px 8px;
    max-width: 130px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.look-up-collpase .Look-Up-P{
    padding: 5px !important;
}
.look-up-collpase .Look-Up-P .ant-btn-icon{
    display: flex !important;
}

.Task-Info-Wrapper .ant-select-selection-item{
    color: #5a6c97 !important;
}
.look-up-collpase .ant-collapse-content{
    overflow: hidden !important;
}
.Add-Input-Icon svg, .Add-Lookup-Icon svg{
    margin: 0px 7px !important;
    color: #5b6c97;
}
.Add-Lookup-Icon{
    width: 29px !important;
}
.Look-Up-Container .Look-Up-Add-Btn svg{
    margin: 0px 7px !important;
    opacity: 0.9;
    color: rgb(90, 108, 151) !important;
    width: 12px !important;
    height: 12px !important;
}
.look-up-tile-container .Look-Up-Table-Btn{
    width: 30px;
    margin: 0px 3px;
}
.look-up-tile-container .Look-Up-Table-Btn svg{
    margin: 0px 7px;
    color: rgb(90, 108, 151);
}
.ant-collapse-extra .Add-Input-Icon, .ant-collapse-extra .Add-Lookup-Icon{
    height: auto;
}

 .Warning-Modal-Popup .ant-modal-footer{
    display: block !important;
 }
  
  .task-message-heading {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #5a6c97;
  }
  
  .dep-task-container {
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    overflow-x: hidden;
  }
  
  .task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }

  .task-item:nth-child(odd){
    background-color: #f6f6f6;
  }
  .task-item:nth-child(even){
    background-color: #ffffff;
  }
  
  .dep-task-container .task-name {
    font-size: 14px;
    font-weight: 500;
    color: #5a6c97;
  }
  
  .task-status {
    font-size: 14px;
    padding: 4px 8px;
    border-radius: 12px;
    color: #fff;
    text-align: center;
    display: block;
    width: 90px !important;
  }
  
  .task-status.not-started {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .task-status.complete {
    background-color: #4caf50;
  }
  
  .task-status.in-progress {
    background-color: #ff9800;
  }
  
  .task-container::-webkit-scrollbar {
    width: 6px;
  }
  
  .task-container::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
  }
  .Warning-Modal-Popup .ant-modal-body{
    padding: 10px 20px !important;
  }
  .Warning-Modal-Popup .ant-modal-content{
    width: 600px !important;
  }
  .Add-task-Wrapper .ant-badge{
    color: #5a6c97 !important;
  }
  .innerRendertaskdiv1{
    height: 1.8rem;
    display: grid;
    background: aliceblue;
    border-radius: 5px;
    /* text-indent: 1rem; */
    margin-top: .2rem;
    align-items: center;
} 
.innerRendertaskdiv1:hover{
    background: #dad8d8;
}
.depClasspopup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: inherit;
    justify-content: start;
    font-size: 16px;
    color: #5b6c97;
    font-weight: 600;
  }
  .create-task-tabs .job-parent-tabs {
    border-bottom: 1px solid rgb(183, 183, 183) !important;
    display: flex;
    gap: 10px;
    padding-bottom: 1px;
  }
  .create-task-tabs .job-parent-tabs .nav-link{
    background: transparent !important;
    border: 0px !important; 
    color: #000000 !important;
    box-shadow: inset 1px 0px 12px -3px #cbcbcb !important;
    background-color: #fff !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    font-size: 11px !important;
    font-weight: 600 !important;
  }
  .create-task-tabs .job-parent-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #018ee0 !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
  }
  .create-task-tabs .drop-file-box{
    width: 100% !important;
    height: 400px !important;
    border: 3px dashed lightgray !important;
  }
  .Add-task-Wrapper .Task-Modal-Body-Wrapper .filterOfActivitySection{
    height: auto !important;
  }
  .Task-Activity-Wrapper .filterOfActivitySection .ant-select-selector{ 
      height: 3.5vh !important;
      min-width: 5rem !important;
  }
  .TaskReminderPopover .checkBoxForReminder .attendeWrapper{
    width: 13.9rem !important;
    margin-left: 7px !important;
  }
.Task-Activity-Wrapper .inputForActivitySearch .ant-input-search-button{
    /* height: 4vh!important; */
}
.Task-Activity-Wrapper .inputForActivitySearch .ant-input-outlined:focus,
.Task-Activity-Wrapper .inputForActivitySearch .ant-input-outlined:focus-within,
.Task-Activity-Wrapper .inputForActivitySearch .ant-input-outlined:hover{
    outline: none !important;
    box-shadow: none !important;
    /* border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9; */
    border: none;
}
.Task-Activity-Wrapper .inputForActivitySearch .ant-input-outlined{
    outline: none !important;
    box-shadow: none !important;
    /* border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9; */
    border: none;
}
.task_filter_box .ant-scroll-number-only-unit{
    background: #1677ff !important;
}
.task_filter_box .ant-scroll-number-only{
    display: contents !important;
}
.additionalFeaturesOfActivity{
    width: auto;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
}
.additionalFeaturesOfActivity .ant-input-wrapper{
    border-radius: 30px !important;
    border: 1px solid #d9d9d9;
    overflow: hidden;
}
.additionalFeaturesOfActivity .ant-input-search-button{
    border: none !important;
    outline: none !important;
}
.Activity-Filter .ant-popover-title{
    padding: 2px 8px;
    background: #5b6c97;
    color: white;
}