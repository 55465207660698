.Date-Range-Custome-Fields-Wrapper{
    /* display: flex !important;
    position: relative !important; */
    padding: 10px !important;
}

.Date-Range-Custome-Fields-Wrapper .header{
    display: flex;
    justify-content: space-between;
    color: #1890ff;
    font-weight: 400;
}
.Date-Range-Custome-Fields-Wrapper .header .col:nth-child(2) svg{
    cursor: pointer !important;
}
.Date-Range-Custome-Fields-Wrapper .header .col:nth-child(2) svg:hover{
    color: red !important;
}
.Date-Range-Custome-Fields-Wrapper .header div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 4px;
}
.Date-Range-Custome-Fields-Wrapper .header div .apply-btn:hover{
    cursor: pointer;
    color: #1890ff !important;
}