.Reminder-Content-Wrapper{
}
.Reminder-Content-Wrapper .Reminder-Popup-Heading{
    display: flex;
    align-items: center;
    /* position: absolute; */
    padding-left: 5px !important;
    font-weight: 500;
    font-size: medium;
}
.Reminder-Content-Wrapper .Head-Text-Link{
    cursor: pointer;
}
.Reminder-Content-Wrapper .Card:hover{
    border-color: #add5ff !important;
    background-color: #ecf5ff;
}
.Reminder-Content-Wrapper  .ant-modal-body{
    padding: 0px 10px !important;
    overflow: scroll;
    height: 67%;
}
.Reminder-Content-Wrapper .ant-modal-content{
    /* height: auto !important; */
}
.Reminder-Content-Wrapper .Card{
    display: flex;
    flex-direction: row;
    height: auto;
    align-items: center;
    background-color: #f6f6f6;
    border-radius: 5px;
    margin: 3px !important;
    border: 1px solid #d6d6d6;
}
.Reminder-Content-Wrapper .row{
    margin: 0px;
    padding: 0px;
}
.Reminder-Content-Wrapper .col{
    margin: 0px;
    padding: 0px;
}
.Reminder-Content-Wrapper .Header{
    position: relative;
    padding: 1px 9px;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    margin: 2px 0px;
    background-color: #007bff;
}
.Reminder-Content-Wrapper .Option-Wrapper{
    position: relative;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}
.Reminder-Content-Wrapper .Option-Wrapper .Options{
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    gap:10px;
    color: #007bff;
}
.Snooze-Popover .ant-popover-inner-content{
    width: 24rem !important;
    max-height: 18rem;
    border-radius: 2px !important;
    padding: 2px 2px !important;
    overflow: scroll;
}
.Snooze-Popover .ant-popover-inner-content ul{
    list-style: none;
    width: max-content !important;
    padding: 0px;
}
.Snooze-Popover .ant-popover-inner-content ul li{
    cursor: pointer;
    padding: 1px 13px;
    margin: 2px 0px;
}
.Snooze-Popover .ant-popover-inner-content ul li:hover{
    background-color: rgb(247 247 247);
}
.Snooze-Popover .ant-popover-inner{
   padding: 4px !important;
}
.Reminder-Content-Wrapper .Option-Wrapper .Options span{
    cursor: pointer;
    opacity: 0.5;
}
.Reminder-Content-Wrapper .Option-Wrapper .Options span:hover{
    opacity: 1;
}
.Reminder-Content-Wrapper .Option-Wrapper .Options span:last-child:hover{
    color: red !important;
}
.Reminder-Content-Wrapper .Header .Expand-Icon{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: absolute;
    right: 6px;
    width: max-content;
}
.Reminder-Content-Wrapper .Custome-Snooze-Time{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 5px;
    gap: 5px;
}
.Reminder-Content-Wrapper .Preset-Cards{
    display: flex;
    font-size: small;
    padding: 1px;
    margin: 2px;
    /* height: 1.8rem; */
    cursor: pointer;
    border-radius: 27px;
    align-items: center;
    justify-content: center;
    border: 1px solid #007bff;
    background-color: #007bff;
    color: white;
}
.Reminder-Content-Wrapper .Snooze-Content .row{
    margin: 0px !important;
    justify-content: center;
}
.Reminder-Content-Wrapper .Snooze-Content{
    width: 14rem !important;
}
.Reminder-Content-Wrapper .Triggered-Alerts-Wrapper .Icon-Wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Reminder-Content-Wrapper .Triggered-Alerts-Wrapper { 
    padding:5px;
    margin-bottom: 5px;
    border-radius: 14px;
    border-left: 5px solid #0072ec;
}
.Reminder-Content-Wrapper .Icon-Wrapper img{
    max-height: 2.3rem;
}
