.Blueprint-flow-wrapper .react-flow__node-custom{
    min-width: 100px !important;
    max-width: 300px !important;
    display: flex;
    width: auto;
    background: white;
    border: 1px solid #b1b1b1;
    border-radius: 6px;
    box-shadow: 0px 0px 14px -9px #000000;
    align-items: center;
    justify-content: center;
}
/* .Blueprint-flow-wrapper .react-flow__node-custom div:nth-child(1){
   border: none !important;
   background-color: transparent !important;
} */
.Blueprint-flow-wrapper .react-flow__attribution a{
   display: none !important;
}
.Editable-Card{
    height: 1rem;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
}
.react-flow__edge-textbg{
    fill:#acd4e1 !important 
}
.Conflict-Row{
    background-color: #f8f8f8;
    padding: 11px;
    color: #5b6c97;
    font-weight: 700;
}
.Conflict-Row .J-end{
    display: flex;
    justify-content: end;
}
.react-flow__node-diverse .Direction-icons{
    height: 1rem;
    position: relative;
    width: 1rem;
    background: #9fcce7;
    padding: 3px;
    opacity: 1;
    border-radius: 40px;
    /* border: 1px solid #dedede; */
    margin: 0px !important;
    top: -1.3px;
}